import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import axios from 'axios'
import './ImportFieldFromThirdParty.scss'
import base64url from 'base64-url'
import {
  getFieldsFromAPI,
  getFieldsFromSessionStorage,
  fixUnusualFieldCoords,
} from './importedFieldsHelperFuncs'

const ImportClimateFieldDataComponent = ({
  updateImportedFieldData,
  setLoading,
  location,
  selectedImportedFieldId,
  importedFieldData,
  passSelectedImportedFieldId,
  loading,
  setFieldNameInputFromSidePanel,
}) => {
  const [climateAccessToken, setClimateAccessToken] = useState('')

  useEffect(() => {
    const previouslyImportedFields = getFieldsFromSessionStorage('climate')
    if (previouslyImportedFields) {
      updateImportedFieldData(JSON.parse(previouslyImportedFields))
      setLoading(false)
    } else if (!location.query.code) {
      getFieldsFromAPI('climate')
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    const { REACT_APP_CLIMATE_CLIENT_ID, REACT_APP_CLIMATE_SECRET } = process.env

    function getClimateToken(code) {
      let tempCode = typeof code === 'string' ? code : code[0]
      axios({
        method: 'post',
        url: 'https://cors-anywhere.herokuapp.com/https://api.climate.com/api/oauth/token',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Basic ${base64url.encode(
            `${REACT_APP_CLIMATE_CLIENT_ID}:${REACT_APP_CLIMATE_SECRET}`
          )}`,
        },
        data: `grant_type=authorization_code&redirect_uri=${window.location.origin +
          window.location.pathname}&code=${tempCode}`,
      })
        .then(res => {
          setClimateAccessToken(res.data.access_token)
        })
        .catch(err => {
          console.log('err getting token', err, 'refresh')
        })
    }

    if (location.query.code && !getFieldsFromSessionStorage('climate')) {
      getClimateToken(location.query.code)
    }
  }, [location])

  useEffect(() => {
    const { REACT_APP_CLIMATE_CLIENT_ID } = process.env
    function getUsersClimateFields() {
      const getFieldsUrl =
        'https://cors-anywhere.herokuapp.com/https://platform.climate.com/v4/fields'

      return axios({
        method: 'get',
        url: getFieldsUrl,
        headers: {
          authorization: `Bearer ${climateAccessToken}`,
          'x-api-key': 'partner-aker-b1a8a026-933a-4d80-a53e-c569e9c81c85',
        },
      }).then(res => {
        return res.data.results
      })
    }

    function getClimateFieldBoundaries(fields) {
      const boundaryPromises = []
      fields.forEach(climateField => {
        boundaryPromises.push(getFieldBoundary(climateField))
      })

      return Promise.all(boundaryPromises)
    }

    function getFieldBoundary(field) {
      const getBoundaryUrl = `https://cors-anywhere.herokuapp.com/https://platform.climate.com/v4/boundaries/${field.boundaryId}`

      return axios({
        method: 'get',
        url: getBoundaryUrl,
        headers: {
          authorization: `Bearer ${climateAccessToken}`,
          'x-api-key': `${REACT_APP_CLIMATE_CLIENT_ID}-b1a8a026-933a-4d80-a53e-c569e9c81c85`,
        },
      }).then(importBoundary => {
        let parsedField = { ...field }
        const coordinates = importBoundary.data.geometry.coordinates
        parsedField.boundary = coordinates
        parsedField.acres = (importBoundary.data.properties.area.q / 0.404685642).toFixed(2)
        parsedField.geojson = {
          type: 'Polygon',
          coordinates,
        }
        return parsedField
      })
    }

    if (climateAccessToken) {
      getUsersClimateFields().then(fields => {
        getClimateFieldBoundaries(fields).then(parsedClimateFields => {
          let fixedFields = fixUnusualFieldCoords(parsedClimateFields)
          fixedFields = fixedFields.map((field, i) => ({ ...field, importedFieldId: i }))
          window.sessionStorage.setItem('importedClimateFields', JSON.stringify(fixedFields))
          updateImportedFieldData(fixedFields)
          setLoading(false)
        })
      })
    }
    climateAccessToken && getUsersClimateFields()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [climateAccessToken])

  const handleMouseEventOnFieldName = (fieldId, event) => {
    const { id, mouseEvent } = selectedImportedFieldId
    const hoveringNoFields = !fieldId && !event
    const hoveringDifferentField = fieldId !== id && event === mouseEvent
    const hoverEventChange = hoveringDifferentField || hoveringNoFields
    if (hoverEventChange) {
      importedFieldData.forEach(field => {
        const currentFieldId = field.importedFieldId
        const importMarkers = document.getElementsByClassName(`importMarker-${currentFieldId}`)
        if (importMarkers[0]) {
          importMarkers[0].style.height = importMarkers[0].previousHeight
          importMarkers[0].style.width = importMarkers[0].previousWidth
          importMarkers[0].style.opacity = importMarkers[0].previousOpacity
        }
      })
    }
    if (event === 'hover') {
      resizeMarkerOnHover(fieldId)
    }
    if (event === 'click') {
      const fieldName = importedFieldData[fieldId].name
      setFieldNameInputFromSidePanel(fieldName)
    }
    passSelectedImportedFieldId(fieldId, event)
  }

  const resizeMarkerOnHover = fieldId => {
    importedFieldData.forEach(field => {
      if (fieldId === +field.importedFieldId) {
        const importMarkers = document.getElementsByClassName(`importMarker-${fieldId}`)
        if (importMarkers[0]) {
          importMarkers[0].previousHeight = importMarkers[0].style.height
          importMarkers[0].previousWidth = importMarkers[0].style.width
          importMarkers[0].previousOpacity = importMarkers[0].style.opacity
          importMarkers[0].style.height = '20px'
          importMarkers[0].style.width = '20px'
          importMarkers[0].style.opacity = '0.9'
        }
      }
    })
  }

  return (
    <>
      <h2>Select field to import</h2>
      <div className='create-field-import-body'>
        {loading && <p>Loading fields...</p>}
        {importedFieldData.map((field, i) => {
          return (
            <div
              className='import-field-row'
              key={i}
              onMouseEnter={() => handleMouseEventOnFieldName(field.importedFieldId, 'hover')}
              onMouseLeave={() => handleMouseEventOnFieldName(null, null)}
              onClick={() => handleMouseEventOnFieldName(field.importedFieldId, 'click')}
            >
              <p>{field.name}</p>
              <p>{field.acres} ac</p>
            </div>
          )
        })}
      </div>
    </>
  )
}

export const ImportClimateFieldData = withRouter(ImportClimateFieldDataComponent)

ImportClimateFieldData.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  updateImportedFieldData: PropTypes.func.isRequired,
  importedFieldData: PropTypes.array.isRequired,
  setSelectedPolygon: PropTypes.func.isRequired,
  selectedImportedFieldId: PropTypes.object.isRequired,
  passSelectedImportedFieldId: PropTypes.func.isRequired,
}
