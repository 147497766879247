import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {
  FlightDisplay,
  ReportContactInfo,
  ReportHighlights,
  RevenueAdvantage,
  TreatmentDetails,
  TreatmentDetailsMap,
  YieldAdvantage,
  YieldAnalysisChart,
} from '../_shared'
import { AerialImageryReportOriginalImageDetails } from './AerialImageryReportOriginalImageDetails'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportOriginal({
  season,
  regions,
  flights,
  brandColor,
  brandColorLight,
  brandLogo,
  title,
  commodityPrice,
  yieldAdvantage,
  revenueAdvantage,
  footnote,
  highlightsHeader,
  highlights,
  productHeader,
  crop,
  seedType,
  location,
  plantDate,
  applicationDate,
  contactName,
  contactPhone,
  contactEmail,
  displayYieldAnalysis,
  displayYieldAdvantage,
  displayReveneueAdvantage,
}) {
  return (
    <React.Fragment>
      <Helmet title='View Aerial Imagery Report' />
      <section
        className={`${styles.aerialImageryReport} ${styles.print} ${styles.aerialImageryReportOriginal}`}
      >
        <div className='row'>
          <div className='col-8'></div>
          <div
            className={`col-4 ${styles.brandColorHeaderContainer}`}
            style={{
              backgroundColor: brandColorLight,
            }}
          ></div>
        </div>

        <div className='row'>
          <div
            className={`col-12 ${styles.brandLogoHeaderContainer}`}
            style={{
              backgroundColor: brandColor,
            }}
          >
            <div className={styles.brandHeaderBar}>
              <img className={styles.brandLogo} alt='Company Logo' src={brandLogo} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-8 main-content'>
            <h1 className={styles.pageTitle} style={{ color: brandColor }}>
              {title}
            </h1>

            <div className={styles.withBottomMargin}>
              <h2 className='h4'>Plant Health Imagery</h2>
              <div className='row'>
                {flights.map((flight, index) => {
                  return (
                    <div className='col-6' key={flight.id}>
                      <FlightDisplay
                        flight={flight}
                        season={season}
                        regions={regions}
                        hideBaseLayers
                      >
                        <AerialImageryReportOriginalImageDetails
                          flight={flight}
                          compareDate={flights[0].dateFlown}
                          brandColor={brandColor}
                          label={index === 0 ? 'Before Application' : 'After Application'}
                        />
                      </FlightDisplay>
                    </div>
                  )
                })}
              </div>
            </div>
            {displayYieldAnalysis && (
              <div className={styles.withBottomMargin}>
                <h2 className='h4'>Yield Analysis</h2>
                <YieldAnalysisChart regions={regions} brandColor={brandColor} />
              </div>
            )}
            {(displayYieldAdvantage || displayReveneueAdvantage) && (
              <div className={styles.withBottomMargin}>
                <div className='row'>
                  {displayYieldAdvantage && (
                    <div className='col-6'>
                      <YieldAdvantage brandColor={brandColor} yieldAdvantage={yieldAdvantage} />
                    </div>
                  )}
                  {displayReveneueAdvantage && (
                    <div className='col-6'>
                      <RevenueAdvantage
                        brandColor={brandColor}
                        revenueAdvantage={revenueAdvantage}
                        commodityPrice={commodityPrice}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {footnote && (
              <div className={`row ${styles.footer}`}>
                <div className='col-12'>{footnote}</div>
              </div>
            )}

            <div className={`row ${styles.footer}`}>
              <div className='col-12'>
                Aerial imagery and document template is copyright of Aker Technologies, Inc. All
                Rights Reserved.
              </div>
            </div>
          </div>
          <div
            className={`col-4 ${styles.aerialImagerySidebar}`}
            style={{
              backgroundColor: brandColorLight,
            }}
          >
            <div>
              <ReportHighlights
                brandColor={brandColor}
                highlightsHeader={highlightsHeader}
                highlights={highlights}
              />

              <div className={styles.withBottomMargin}>
                <h2 className={`h4 ${styles.withBottomMargin}`}>{productHeader}</h2>
                <h3 className={styles.subheader} style={{ color: brandColor }}>
                  On Farm Trial Details
                </h3>
                <div>
                  {location && (
                    <div>
                      Location: <strong>{location}</strong>
                    </div>
                  )}
                  {crop && (
                    <div>
                      Crop: <strong>{crop}</strong>
                    </div>
                  )}
                  {seedType && (
                    <div>
                      Hybrid/Variety: <strong>{seedType}</strong>
                    </div>
                  )}
                  {plantDate && (
                    <div>
                      Planting Date: <strong>{plantDate}</strong>
                    </div>
                  )}
                  {applicationDate && (
                    <div>
                      Application Date: <strong>{applicationDate}</strong>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.withBottomMargin}>
                {regions && regions.length > 0 && (
                  <h3 className={styles.subheader} style={{ color: brandColor }}>
                    Treatment Details
                  </h3>
                )}
                <TreatmentDetails regions={regions} />
                <TreatmentDetailsMap regions={regions} season={season} />
              </div>
            </div>
            <ReportContactInfo
              brandColor={brandColor}
              header='Questions?'
              contactName={contactName}
              contactPhone={contactPhone}
              contactEmail={contactEmail}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

AerialImageryReportOriginal.propTypes = {
  season: PropTypes.object.isRequired,
  regions: PropTypes.array,
  flights: PropTypes.array.isRequired,
  brandColor: PropTypes.string.isRequired,
  brandColorLight: PropTypes.string.isRequired,
  brandLogo: PropTypes.string.isRequired,
  title: PropTypes.string,
  commodityPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yieldAdvantage: PropTypes.string,
  revenueAdvantage: PropTypes.string,
  footnote: PropTypes.string.isRequired,
  highlightsHeader: PropTypes.string,
  highlights: PropTypes.array,
  productHeader: PropTypes.string.isRequired,
  crop: PropTypes.string.isRequired,
  seedType: PropTypes.string,
  location: PropTypes.string,
  plantDate: PropTypes.string,
  applicationDate: PropTypes.string,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  contactEmail: PropTypes.string,
  displayYieldAnalysis: PropTypes.bool,
}
