import React from 'react'
import PropTypes from 'prop-types'
import { FarmsReportsAndTrials } from './FarmsReportsAndTrials'
import { TableWithCustomData } from '_shared'

export function TimelyReportsAndTrials(props) {
  const { timelyData, title, deleteReport, deleteTrial } = props
  const sortByFarmTitleAlphabetically = (farmIdA, farmIdB) => {
    var nameA = timelyData[farmIdA].title.toLowerCase() // ignore upper and lowercase
    var nameB = timelyData[farmIdB].title.toLowerCase() // ignore upper and lowercase
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    // names must be equal
    return 0
  }

  const getSortedFarmIds = () => {
    const allFarmIds = Object.keys(timelyData)
    return allFarmIds.sort(sortByFarmTitleAlphabetically)
  }

  const sortedFarmIds = getSortedFarmIds()

  return (
    <TableWithCustomData title={title}>
      {sortedFarmIds.map(farmId => {
        const farm = timelyData[farmId]
        return (
          <FarmsReportsAndTrials
            key={farmId}
            farmId={farmId}
            farmData={farm}
            title={farm.title}
            deleteReport={deleteReport}
            deleteTrial={deleteTrial}
          />
        )
      })}
    </TableWithCustomData>
  )
}

TimelyReportsAndTrials.propTypes = {
  timelyData: PropTypes.object,
  title: PropTypes.string,
  deleteReport: PropTypes.func,
  deleteTrial: PropTypes.func,
}
