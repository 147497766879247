import React from 'react'
import Helmet from 'react-helmet'

export default function NotAllowed() {
  return (
    <div className='container'>
      <Helmet title='Not allowed' />
      <h1>Not allowed - 403</h1>
      <p>
        You're <em>not</em> allowed to visit this page!
      </p>
    </div>
  )
}
