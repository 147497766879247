import React from 'react'
import styles from './CreateFieldForm.module.scss'

const ImportFieldInstructions = () => {
  return (
    <>
      <h2>Instructions</h2>
      <div className={styles.instructions}>
        1. Enter your address or use the map controls to navigate to your field.
      </div>
      <div className={styles.instructions}>
        2. Click on a prepopulated polygon or click on multiple to join them together.
      </div>
      <div className={styles.instructions}>
        3. If there are no prepopulated polygons please use the{' '}
        <div className={styles.polygonButton}></div> button to create one.
      </div>
      <div className={styles.instructions}>3. Enter a field name.</div>
      <div className={styles.instructions}>4. Click "Create" and start managing your field!</div>
    </>
  )
}

export default ImportFieldInstructions
