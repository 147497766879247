import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { FormGroup, FormControl, Row, Button, Alert } from 'react-bootstrap'
import { ReduxFormInput, WrappedInputField } from '../../_shared'
import signUpFormValidation from './signUpFormValidation'
import styles from './SignUpForm.module.scss'

const reduxFormWrapper = reduxForm({
  form: 'signUp',
  touchOnBlur: false,
  validate: signUpFormValidation,
})

class SignUpForm extends Component {
  render() {
    const { loggingIn, handleSubmit, handleAlertDismiss, formErrors } = this.props

    return (
      <form className={styles.signUp} onSubmit={handleSubmit}>
        {formErrors &&
          formErrors.map(error => {
            return (
              <Alert variant='danger' key={error.code} onDismiss={() => handleAlertDismiss(error)}>
                {error.message}
              </Alert>
            )
          })}
        <Row>
          <WrappedInputField
            columnWidth={12}
            name={'farmTitle'}
            type={'text'}
            placeholder={'Farm Title'}
            title={'Farm Title(Required)'}
            required={true}
            disabled={loggingIn}
          />
        </Row>
        <Row>
          <WrappedInputField
            columnWidth={12}
            name={'name'}
            type={'text'}
            placeholder={'Name'}
            title={'Name(Required)'}
            required={true}
            disabled={loggingIn}
          />
        </Row>
        <Row>
          <WrappedInputField
            columnWidth={12}
            name={'email'}
            type={'email'}
            placeholder={'Email address'}
            title={'Email address(Required)'}
            required={true}
            disabled={loggingIn}
          />
        </Row>
        <Row>
          <WrappedInputField
            columnWidth={6}
            name={'password'}
            type={'password'}
            placeholder={'Password'}
            title={'Password (Required)'}
            required={true}
            disabled={loggingIn}
          />
          <WrappedInputField
            columnWidth={6}
            name={'passwordConfirmation'}
            type={'password'}
            placeholder={'Password Confirmation'}
            title={'Confirm Password'}
            required={true}
            disabled={loggingIn}
          />
        </Row>
        <div className={`row ${styles.initialHeight}`}>
          <WrappedInputField
            columnWidth={8}
            name={'address1'}
            type={'text'}
            placeholder={'Address'}
            title={'Address'}
            required={false}
            disabled={loggingIn}
          />
          <WrappedInputField
            columnWidth={4}
            name={'address2'}
            type={'text'}
            placeholder={'Unit #'}
            title={'Unit #'}
            required={false}
            disabled={loggingIn}
          />
        </div>
        <Row>
          <WrappedInputField
            columnWidth={5}
            name={'city'}
            type={'text'}
            placeholder={'City'}
            title={'City'}
            required={false}
            disabled={loggingIn}
          />
          <WrappedInputField
            columnWidth={4}
            name={'zipCode'}
            type={'text'}
            placeholder={'Zip Code'}
            title={'Zip Code (Required)'}
            required={true}
            disabled={loggingIn}
          />
          <div className='col-3'>
            <div className={styles.small}>State</div>
            <FormGroup>
              <ReduxFormInput fieldName={'state'} type={'select'}>
                {({ errorClassName, ...reduxInput }) => (
                  <FormControl
                    key='state'
                    as='select'
                    id='state'
                    disabled={loggingIn}
                    data-test={'state-select'}
                    className={styles.createOrderInput}
                    {...reduxInput}
                  >
                    <option value={''}> State </option>
                    <option value='AL'>AL</option>
                    <option value='AK'>AK</option>
                    <option value='AZ'>AZ</option>
                    <option value='AR'>AR</option>
                    <option value='CA'>CA</option>
                    <option value='CO'>CO</option>
                    <option value='CT'>CT</option>
                    <option value='DE'>DE</option>
                    <option value='DC'>DC</option>
                    <option value='FL'>FL</option>
                    <option value='GA'>GA</option>
                    <option value='HI'>HI</option>
                    <option value='ID'>ID</option>
                    <option value='IL'>IL</option>
                    <option value='IN'>IN</option>
                    <option value='IA'>IA</option>
                    <option value='KS'>KS</option>
                    <option value='KY'>KY</option>
                    <option value='LA'>LA</option>
                    <option value='ME'>ME</option>
                    <option value='MD'>MD</option>
                    <option value='MA'>MA</option>
                    <option value='MI'>MI</option>
                    <option value='MN'>MN</option>
                    <option value='MS'>MS</option>
                    <option value='MO'>MO</option>
                    <option value='MT'>MT</option>
                    <option value='NE'>NE</option>
                    <option value='NV'>NV</option>
                    <option value='NH'>NH</option>
                    <option value='NJ'>NJ</option>
                    <option value='NM'>NM</option>
                    <option value='NY'>NY</option>
                    <option value='NC'>NC</option>
                    <option value='ND'>ND</option>
                    <option value='OH'>OH</option>
                    <option value='OK'>OK</option>
                    <option value='OR'>OR</option>
                    <option value='PA'>PA</option>
                    <option value='RI'>RI</option>
                    <option value='SC'>SC</option>
                    <option value='SD'>SD</option>
                    <option value='TN'>TN</option>
                    <option value='TX'>TX</option>
                    <option value='UT'>UT</option>
                    <option value='VT'>VT</option>
                    <option value='VA'>VA</option>
                    <option value='WA'>WA</option>
                    <option value='WV'>WV</option>
                    <option value='WI'>WI</option>
                    <option value='WY'>WY</option>
                  </FormControl>
                )}
              </ReduxFormInput>
            </FormGroup>
          </div>
        </Row>
        <Button
          type='submit'
          block
          variant='primary'
          size='lg'
          className={styles.submitButton}
          onClick={handleSubmit}
          disabled={loggingIn}
        >
          Sign Up
        </Button>
      </form>
    )
  }
}

export default reduxFormWrapper(SignUpForm)

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool,
  formErrors: PropTypes.array,
  handleAlertDismiss: PropTypes.func.isRequired,
}
