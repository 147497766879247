import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import { AkerLogo } from '_shared'
import {
  StackedTrialImageDetails,
  FlightDisplay,
  ReportContactInfo,
  ReportHighlights,
  StackedTrialFieldDetails,
  YieldAnalysisChart,
} from '../_shared'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportStackedTrial({
  season,
  regions,
  flights,
  brandColor,
  brandColorLight,
  brandLogo,
  title,
  footnote,
  highlightsHeader,
  highlights,
  productHeader,
  crop,
  location,
  applicationDate,
  contactName,
  contactPhone,
  contactEmail,
  displayYieldAnalysis,
  isBasf,
}) {
  const latDiff = Math.abs(season.field.bounds[0][0] - season.field.bounds[1][0])
  const lngDiff = Math.abs(season.field.bounds[0][1] - season.field.bounds[1][1])

  const landscapeView = flights.length === 1 && lngDiff >= latDiff
  const tallPortraitView = flights.length === 1 && latDiff > lngDiff
  const showYieldAnalysis = displayYieldAnalysis && flights.length > 1

  return (
    <React.Fragment>
      <Helmet title='View RevX Results' />
      <section
        className={`${styles.aerialImageryReport} ${styles.print} ${
          styles.aerialImageryReportBasf
        } ${landscapeView ? styles.landscape : null} ${
          tallPortraitView ? styles.tallPortrait : null
        }`}
      >
        <div className='row'>
          <div className={`${landscapeView ? 'col-10' : 'col-8'}`}></div>
          <div
            className={`${styles.brandColorHeaderContainer} ${landscapeView ? 'col-2' : 'col-4'}`}
            style={{
              backgroundColor: brandColorLight,
            }}
          ></div>
        </div>

        <div className='row'>
          <div className={styles.brandLogoHeaderContainer}>
            <div className={styles.brandHeaderBar} style={{ backgroundColor: brandColor }}>
              <div className={styles.basfInner}>
                <img className={styles.brandLogo} alt='Company Logo' src={brandLogo} />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className={`main-content ${landscapeView ? 'col-10' : 'col-8'}`}>
            <h1 className={styles.pageTitle} style={{ color: brandColor }}>
              {isBasf ? 'Experience the Difference with BASF Plant Health' : title}
            </h1>
            <div className={styles.withBottomMargin}>
              {isBasf && <h2 className='h4'>{title}</h2>}
              {flights.map((flight, index) => {
                return (
                  <FlightDisplay
                    key={flight.id}
                    flight={flight}
                    season={season}
                    regions={regions}
                    hideBaseLayers={false}
                    hideLegend={index !== flights.length - 1}
                  >
                    <StackedTrialImageDetails
                      flight={flight}
                      applicationDate={applicationDate}
                      brandColor={brandColor}
                    />
                  </FlightDisplay>
                )
              })}
            </div>
            {showYieldAnalysis && (
              <div className={styles.withBottomMargin}>
                <h2 className='h4'>Yield Analysis</h2>
                <YieldAnalysisChart regions={regions} brandColor={brandColor} />
              </div>
            )}
            {footnote && <div className={classNames('row', styles.footer)}>{footnote}</div>}
            <div className={classNames('row', styles.footer)}>
              Aerial imagery and document template is copyright of Aker Technologies, Inc. All
              Rights Reserved.
            </div>
          </div>

          <div
            className={`${styles.aerialImagerySidebar} ${landscapeView ? 'col-2' : 'col-4'}`}
            style={{
              backgroundColor: brandColorLight,
            }}
          >
            <div>
              <div className={styles.withBottomMargin}>
                <StackedTrialFieldDetails
                  brandColor={brandColor}
                  location={location}
                  crop={crop}
                  totalAcreage={season.field.size}
                  productHeader={productHeader}
                  applicationDate={applicationDate}
                  regions={regions}
                  season={season}
                  productLabel={isBasf ? 'BASF PH Product' : 'Product'}
                />
              </div>
              <ReportHighlights
                brandColor={brandColor}
                highlightsHeader={highlightsHeader}
                highlights={highlights}
              />
            </div>
            <ReportContactInfo
              brandColor={brandColor}
              header={isBasf ? 'Questions? Contact your BASF Rep.' : 'Contact info'}
              contactName={contactName}
              contactPhone={contactPhone}
              contactEmail={contactEmail}
            />
            <div className={styles.akerInner}>
              <AkerLogo color={brandColor} />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

AerialImageryReportStackedTrial.propTypes = {
  season: PropTypes.object.isRequired,
  regions: PropTypes.array,
  flights: PropTypes.array.isRequired,
  brandColor: PropTypes.string.isRequired,
  brandColorLight: PropTypes.string.isRequired,
  brandLogo: PropTypes.string.isRequired,
  title: PropTypes.string,
  footnote: PropTypes.string.isRequired,
  highlightsHeader: PropTypes.string,
  highlights: PropTypes.array,
  productHeader: PropTypes.string.isRequired,
  crop: PropTypes.string.isRequired,
  location: PropTypes.string,
  applicationDate: PropTypes.string,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  contactEmail: PropTypes.string,
  displayYieldAnalysis: PropTypes.bool,
}
