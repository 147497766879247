import React from 'react'
import PropTypes from 'prop-types'
import { FaCheck } from 'react-icons/fa'
import styles from './Form.module.scss'

export function CheckboxInput({ label, name, value, onChange, className, ...props }) {
  return (
    <div className={`${styles.formInput} ${styles.checkboxInputContainer} ${className}`}>
      <label htmlFor={name}>
        <input
          type='checkbox'
          id={name}
          name={name}
          checked={value}
          onChange={onChange}
          {...props}
        />
        <div className={`${styles.checkboxIconContainer} ${value ? styles.active : ''}`}>
          <svg className={`${styles.checkmarkBackground}`} viewBox='0 0 16 16'>
            <rect stroke='currentColor' fill='none' height='16' width='16' strokeWidth='3' />
          </svg>
          {value && <FaCheck className={`${styles.checkmark}`} />}
        </div>
        <span>{label}</span>
      </label>
    </div>
  )
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
}
