const LOAD_CLUS = 'aker/MAPLAYERS/LOAD_CLUS'
const LOAD_CLUS_SUCCESS = 'aker/MAPLAYERS/LOAD_CLUS_SUCCESS'
const LOAD_CLUS_ERROR = 'aker/MAPLAYERS/LOAD_CLUS_ERROR'

const CHANGE_ACTIVE_WORK_ORDER = 'aker/MAPLAYERS/CHANGE_ACTIVE_WORK_ORDER'
const CREATE_ANNOTATION_PIN_ADDED = 'aker/MAPLAYERS/CREATE_ANNOTATION_PIN_ADDED'

const initalState = {
  loaded: false,
  activeWorkOrder: null,
  annotationPin: null,
}

export default function maplayers(state = initalState, action = {}) {
  switch (action.type) {
    case LOAD_CLUS:
      return {
        ...state,
      }
    case CHANGE_ACTIVE_WORK_ORDER:
      return {
        ...state,
        activeWorkOrder: action.workOrder,
      }
    case CREATE_ANNOTATION_PIN_ADDED:
      return {
        ...state,
        annotationPin: action.annotationPin,
      }
    default:
      return state
  }
}

export function fetchClus(northEast, southWest) {
  const uLeftY = northEast.lat
  const uLeftX = northEast.lng
  const lRightY = southWest.lat
  const lRightX = southWest.lng
  return {
    types: [LOAD_CLUS, LOAD_CLUS_SUCCESS, LOAD_CLUS_ERROR],
    promise: client =>
      client.get(
        `/common_land_units?uleftx=${uLeftX}&ulefty=${uLeftY}&lrighty=${lRightY}&lrightx=${lRightX}`
      ),
  }
}

export function changeActiveWorkOrder(workOrder) {
  return {
    type: CHANGE_ACTIVE_WORK_ORDER,
    workOrder: workOrder,
  }
}

export function mountAnnotationPin(pin) {
  return {
    type: CREATE_ANNOTATION_PIN_ADDED,
    annotationPin: pin,
  }
}
