const LOAD_ACCOUNT = 'aker/ACCOUNT/LOAD_ACCOUNT'
const LOAD_ACCOUNT_SUCCESS = 'aker/ACCOUNT/LOAD_ACCOUNT_SUCCESS'
const LOAD_ACCOUNT_FAIL = 'aker/ACCOUNT/LOAD_ACCOUNT_FAIL'

const LOAD_ACCOUNT_BY_TOKEN = 'aker/ACCOUNT/LOAD_ACCOUNT_BY_TOKEN'
export const LOAD_ACCOUNT_BY_TOKEN_SUCCESS = 'aker/ACCOUNT/LOAD_ACCOUNT_BY_TOKEN_SUCCESS'
const LOAD_ACCOUNT_BY_TOKEN_FAIL = 'aker/ACCOUNT/LOAD_ACCOUNT_BY_TOKEN_FAIL'

const LOAD_OPSMANAGERS = 'aker/ACCOUNT/LOAD_OPSMANAGERS'
const LOAD_OPSMANAGERS_SUCCESS = 'aker/ACCOUNT/LOAD_OPSMANAGERS_SUCCESS'
const LOAD_OPSMANAGERS_FAIL = 'aker/ACCOUNT/LOAD_OPSMANAGERS_FAIL'

const ADD_ACCOUNT = 'aker/ACCOUNT/ADD_ACCOUNT'
const ADD_ACCOUNT_SUCCESS = 'aker/ACCOUNT/ADD_ACCOUNT_SUCCESS'
const ADD_ACCOUNT_FAIL = 'aker/ACCOUNT/ADD_ACCOUNT_FAIL'

const LOAD_SCOUTS = 'aker/ACCOUNT/LOAD_SCOUTS'
const LOAD_SCOUTS_SUCCESS = 'aker/ACCOUNT/LOAD_SCOUTS_SUCCESS'
const LOAD_SCOUTS_FAIL = 'aker/ACCOUNT/LOAD_SCOUTS_FAIL'

const LOAD_GROWERS = 'aker/ACCOUNT/LOAD_GROWERS'
const LOAD_GROWERS_SUCCESS = 'aker/ACCOUNT/LOAD_GROWERS_SUCCESS'
const LOAD_GROWERS_FAIL = 'aker/ACCOUNT/LOAD_GROWERS_FAIL'

const UPDATE_ACCOUNT = 'aker/ACCOUNT/UPDATE_ACCOUNT'
const UPDATE_ACCOUNT_SUCCESS = 'aker/ACCOUNT/UPDATE_ACCOUNT_SUCCESS'
const UPDATE_ACCOUNT_FAIL = 'aker/ACCOUNT/UPDATE_ACCOUNT_FAIL'

const LOAD_CUSTOMER_ACCOUNT = 'aker/ACCOUNT/LOAD_CUSTOMER_ACCOUNT'
const LOAD_CUSTOMER_ACCOUNT_SUCCESS = 'aker/ACCOUNT/LOAD_CUSTOMER_ACCOUNT_SUCCESS'
const LOAD_CUSTOMER_ACCOUNT_FAIL = 'aker/ACCOUNT/LOAD_CUSTOMER_ACCOUNT_FAIL'

const CLEAR_UPDATE_SUCCESS = 'aker/ACCOUNT/CLEAR_UPDATE_SUCCESS'
const CLEAR_ALL_ERRORS = 'aker/ACCOUNT/CLEAR_ALL_ERRORS'
const CLEAR_ERROR = 'aker/ACCOUNT/CLEAR_ERROR'

const initialState = {
  loaded: false,
  isUpdated: false,
  opsManagers: {
    loaded: false,
  },
  scouts: {
    loaded: false,
  },
  growers: {
    loaded: false,
  },
  data: {},
}

export default function account(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ACCOUNT:
      return {
        ...state,
        loading: true,
        errors: undefined, // Clear errors
      }
    case LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.data,
      }
    case LOAD_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }

    case LOAD_ACCOUNT_BY_TOKEN:
      return {
        ...state,
        loading: true,
        tokenErrors: undefined, // Clear errors
      }
    case LOAD_ACCOUNT_BY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.data,
      }
    case LOAD_ACCOUNT_BY_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        tokenErrors: action.error.errors,
      }

    // OpsManager
    case LOAD_OPSMANAGERS:
      return {
        ...state,
        opsManagers: {
          ...state.opsManagers,
          loading: true,
          errors: undefined, // Clear errors
        },
      }
    case LOAD_OPSMANAGERS_SUCCESS:
      return {
        ...state,
        opsManagers: {
          ...state.opsManagers,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case LOAD_OPSMANAGERS_FAIL:
      return {
        ...state,
        opsManagers: {
          ...state.opsManagers,
          loading: false,
          loaded: false,
          errors: action.error.errors,
        },
      }

    // Scouts
    case LOAD_SCOUTS:
      return {
        ...state,
        scouts: {
          ...state.scouts,
          loading: true,
          errors: undefined, // Clear errors
        },
      }
    case LOAD_SCOUTS_SUCCESS:
      return {
        ...state,
        scouts: {
          ...state.scouts,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case LOAD_SCOUTS_FAIL:
      return {
        ...state,
        scouts: {
          ...state.scouts,
          loading: false,
          loaded: false,
          errors: action.error.errors,
        },
      }

    // growers
    case LOAD_GROWERS:
      return {
        ...state,
        growers: {
          ...state.growers,
          loading: true,
          errors: undefined, // Clear errors
        },
      }
    case LOAD_GROWERS_SUCCESS:
      return {
        ...state,
        growers: {
          ...state.growers,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case LOAD_GROWERS_FAIL:
      return {
        ...state,
        growers: {
          ...state.growers,
          loading: false,
          loaded: false,
          errors: action.error.errors,
        },
      }

    case ADD_ACCOUNT:
      return {
        ...state,
        opsManagers: {
          ...state.opsManagers,
          loading: true,
        },
        scouts: {
          ...state.scouts,
          loading: true,
        },
      }
    case ADD_ACCOUNT_SUCCESS:
      const data = action.result.data
      return {
        ...state,
        opsManagers: {
          ...state.opsManagers,
          loading: false,
          data:
            data.role === 'OpsManager'
              ? [...state.opsManagers.data, action.result.data]
              : [...state.opsManagers.data],
        },
        scouts: {
          ...state.scouts,
          loading: false,
          data:
            data.role === 'Scout'
              ? [...state.scouts.data, action.result.data]
              : [...state.scouts.data],
        },
      }
    case ADD_ACCOUNT_FAIL:
      const addErrors = action.error.errors.map(error => {
        return {
          method: 'add',
          ...error,
        }
      })
      return {
        ...state,
        addErrors,
        opsManagers: {
          ...state.opsManagers,
          loaded: false,
          loading: false,
        },
        scouts: {
          ...state.scouts,
          loaded: false,
          loading: false,
        },
      }

    case UPDATE_ACCOUNT:
      return {
        ...state,
        updating: true,
        errors: undefined, // Clear errors
      }
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        updating: false,
        isUpdated: true,
        data: action.result.data,
      }
    case UPDATE_ACCOUNT_FAIL:
      return {
        ...state,
        updating: false,
        isUpdated: false,
        errors: action.error.errors,
      }
    case LOAD_CUSTOMER_ACCOUNT:
      return {
        ...state,
        loading: true,
        errors: undefined, // Clear errors
      }
    case LOAD_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        customerAccount: action.result.data,
      }
    case LOAD_CUSTOMER_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }

    case CLEAR_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdated: false,
      }
    case CLEAR_ALL_ERRORS:
      return {
        ...state,
        errors: [],
        addErrors: [],
      }
    case CLEAR_ERROR:
      return {
        ...state,
        errors: state.errors && [...state.errors.filter(error => error.code !== action.error.code)],
        addErrors: state.addErrors && [
          ...state.addErrors.filter(error => error.code !== action.error.code),
        ],
      }

    default:
      return state
  }
}

export function isLoaded(globalState) {
  return globalState.account && globalState.account.loaded
}
export function isOpsManagersLoaded(globalState) {
  return globalState.account.opsManagers && globalState.account.opsManagers.loaded
}
export function isScoutsLoaded(globalState) {
  return globalState.account.scouts && globalState.account.scouts.loaded
}
export function isGrowersLoaded(globalState) {
  return globalState.account.growers && globalState.account.growers.loaded
}

// ASYNC ACTION CREATORS
export function loadAccount(userId) {
  return {
    types: [LOAD_ACCOUNT, LOAD_ACCOUNT_SUCCESS, LOAD_ACCOUNT_FAIL],
    promise: client => client.get(`/users/${userId}`),
  }
}
export function loadAccountByToken(token) {
  return {
    types: [LOAD_ACCOUNT_BY_TOKEN, LOAD_ACCOUNT_BY_TOKEN_SUCCESS, LOAD_ACCOUNT_BY_TOKEN_FAIL],
    promise: client => client.get(`/invitation/${token}`),
  }
}

// OpsManager
export function loadOpsManagers() {
  return {
    types: [LOAD_OPSMANAGERS, LOAD_OPSMANAGERS_SUCCESS, LOAD_OPSMANAGERS_FAIL],
    promise: client => client.get('/opsmanagers'),
  }
}
// Scout
export function loadScouts() {
  return {
    types: [LOAD_SCOUTS, LOAD_SCOUTS_SUCCESS, LOAD_SCOUTS_FAIL],
    promise: client => client.get('/scouts'),
  }
}
// Growers
export function loadGrowers() {
  return {
    types: [LOAD_GROWERS, LOAD_GROWERS_SUCCESS, LOAD_GROWERS_FAIL],
    promise: client => client.get('/growers'),
  }
}

export function addAccount(data) {
  return {
    types: [ADD_ACCOUNT, ADD_ACCOUNT_SUCCESS, ADD_ACCOUNT_FAIL],
    promise: client =>
      client.post('/account', {
        data: {
          data,
        },
      }),
  }
}

export function updateAccount(userId, data) {
  return {
    types: [UPDATE_ACCOUNT, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAIL],
    promise: client =>
      client.put(`/users/${userId}`, {
        data: {
          data,
        },
      }),
  }
}

export function clearUpdateSuccess() {
  return {
    type: CLEAR_UPDATE_SUCCESS,
  }
}
export function clearAllErrors() {
  return {
    type: CLEAR_ALL_ERRORS,
  }
}
export function clearError(error) {
  return {
    type: CLEAR_ERROR,
    error,
  }
}
