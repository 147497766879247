import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import {
  ListContainer,
  ListTitleHeader,
  ListSection,
  ListItemsContainer,
  EmptyListItems,
  LoadingState,
} from '_shared'
import { CustomerAccountDropdown, CustomerAccountsContext } from 'domains'
import { AddFarm } from './AddFarm'
import { FarmListItem } from './FarmListItem'
import styles from './FarmList.module.scss'

function FarmListComponent({
  farms,
  clearError,
  clearAllErrors,
  addFarm,
  farmsErrors,
  user,
  onCustomerAccountChange,
}) {
  const { customerAccounts, selectedCustomerAccountId } = React.useContext(CustomerAccountsContext)
  const [canUpdate, setCanUpdate] = useState(false)

  const sortedFarms =
    farms &&
    farms.sort((first, second) => {
      return first.title && first.title.localeCompare(second.title)
    })

  useEffect(() => {
    let selectedCustomerAccount = customerAccounts.find(c => c.id === selectedCustomerAccountId)
    if (selectedCustomerAccount) {
      setCanUpdate(selectedCustomerAccount.permissions.canUpdate)
    }
  }, [selectedCustomerAccountId]) //eslint-disable-line react-hooks/exhaustive-deps

  const showAddFarmButton = selectedCustomerAccountId ? canUpdate : user.isCustomerAccountManager

  return (
    <ListContainer key='farms'>
      <ListTitleHeader
        title='Farms'
        selectedSection={false}
        className={styles.farmListTitleHeaderContainer}
      >
        {showAddFarmButton && (
          <AddFarm
            clearAllErrors={clearAllErrors}
            clearError={clearError}
            addFarm={addFarm}
            user={user}
            farmsErrors={farmsErrors}
          />
        )}
      </ListTitleHeader>
      <CustomerAccountDropdown onCustomerAccountChange={onCustomerAccountChange} />
      <ListSection>
        <ListItemsContainer key='farms-items' itemType='farms' hideEndOfListText={!sortedFarms}>
          {!sortedFarms && <LoadingState />}
          {sortedFarms && sortedFarms.length === 0 && <EmptyListItems itemType='farms' />}
          {sortedFarms &&
            sortedFarms.length &&
            sortedFarms.map(farm => <FarmListItem key={farm.id} farm={farm} />)}
        </ListItemsContainer>
      </ListSection>
    </ListContainer>
  )
}

export const FarmList = withRouter(FarmListComponent)

FarmListComponent.propTypes = {
  farms: PropTypes.array,
  clearError: PropTypes.func,
  clearAllErrors: PropTypes.func,
  addFarm: PropTypes.func,
  farmsErrors: PropTypes.array,
  user: PropTypes.object,
  onCustomerAccountChange: PropTypes.func.isRequired,
}
