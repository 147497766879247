import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportOriginalImageDetails({
  flight,
  compareDate,
  brandColor,
  label,
}) {
  const renderTimePassage = () => {
    let diff = 0
    if (compareDate) {
      diff = moment.utc(flight.dateFlown).diff(moment.utc(compareDate), 'days')
    }

    return (
      <div>
        Time Passage: <strong>{diff > 0 ? `${diff} Days` : '-'}</strong>
      </div>
    )
  }

  return (
    <div>
      <h3 className={styles.subheader} style={{ color: brandColor }}>
        {label}
      </h3>
      <div>
        Date: <b>{moment.utc(flight.dateFlown).format('MM/DD/YYYY')}</b>
      </div>
      {flight.growthStage && (
        <div>
          Crop Stage: <b>{flight.growthStage}</b>
        </div>
      )}

      {renderTimePassage()}
    </div>
  )
}

AerialImageryReportOriginalImageDetails.propTypes = {
  compareDate: PropTypes.string,
  flight: PropTypes.shape({ dateFlown: PropTypes.string }).isRequired,
  brandColor: PropTypes.string.isRequired,
  label: PropTypes.string,
}
