/* eslint jsx-a11y/aria-role: 0*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import { Timeline } from './Timeline'
import styles from './Field.module.scss'

export function WorkOrderHistory({ workOrder }) {
  const [showDetails, setShowDetails] = useState(false)

  function renderOverviewColumn(label, value) {
    return (
      <div className={styles.workOrderOverviewColumn}>
        <div className={styles.columnLabel}>{label}</div>
        <div>{value}</div>
      </div>
    )
  }

  function formatDate(date) {
    if (date) {
      return moment(date).format('L')
    }
    return ''
  }

  return (
    <div className={styles.workOrderHistoryContainer}>
      <div className={styles.workOrderHistory}>
        <div className={`${styles.workOrderOverview} ${showDetails ? styles.detailsExpanded : ''}`}>
          {renderOverviewColumn('Order #', workOrder.id)}
          {workOrder.completedDate
            ? renderOverviewColumn('Completed', formatDate(workOrder.completedDate))
            : renderOverviewColumn('Scheduled', formatDate(workOrder.scheduledDate))}

          {renderOverviewColumn('Service Item', workOrder.service)}
          {renderOverviewColumn('Field Size', workOrder.fieldSize)}
        </div>
        {!showDetails && (
          <div className={`${showDetails ? styles.detailsExpanded : ''}`}>
            <button
              className={`btn btn-link ${styles.detailsLink}`}
              onClick={() => setShowDetails(true)}
            >
              <FaAngleDown color='#007cc0' />
              View Details
            </button>
          </div>
        )}
        {showDetails && (
          <React.Fragment>
            <Timeline events={workOrder.timeline} />
            <div className={`${showDetails ? styles.detailsExpanded : ''}`}>
              <button
                className={`btn btn-link ${styles.detailsLink}`}
                onClick={() => setShowDetails(false)}
              >
                <FaAngleUp color='#007cc0' />
                Close Details
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

WorkOrderHistory.propTypes = {
  workOrder: PropTypes.object.isRequired,
}
