import React from 'react'
import PropTypes from 'prop-types'
import styles from './RevXResultsToolShow.module.scss'

export function ContactInfoComponent({ brandColor, contactPhone, contactName, ...props }) {
  return (
    <div className={styles.contactInfoContainer}>
      <h2 className={styles.h2} style={{ color: brandColor }}>
        Questions? Contact your BASF Rep.
      </h2>
      <div className={styles.contactDetails}>
        {contactName && <span>{contactName}</span>}
        {contactPhone && <span>{` - ${contactPhone}`}</span>}
      </div>
    </div>
  )
}

ContactInfoComponent.propTypes = {
  contactPhone: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  brandColor: PropTypes.string.isRequired,
}
