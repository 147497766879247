import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import Helmet from 'react-helmet'
import {
  updateCustomerAccountSettings,
  clearUpdateSuccess,
  clearAllErrors,
  clearError,
} from 'redux/modules/customerAccounts'
import { SideMenu } from 'components'
import { CustomerAccountSettingsForm } from './CustomerAccountSettingsForm'
import styles from './CustomerAccountSettings.module.scss'

/**
 * Settings
 * Show settings form and handles submission of the form.
 */

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {},
  },
])
const connectWrapper = connect(
  state => ({
    user: state.auth.user,
    isUpdated: state.customerAccounts.isUpdated,
    errors: state.customerAccounts.errors,
  }),
  {
    clearUpdateSuccess,
    clearError,
    clearAllErrors,
    updateCustomerAccountSettings,
  }
)

class CustomerAccountSettingsContainer extends Component {
  componentWillUnmount() {
    this.props.clearAllErrors()
    this.props.clearUpdateSuccess()
  }

  handleAlertSuccessDismiss = () => {
    this.props.clearUpdateSuccess()
  }

  handleAlertDismiss = alertItem => {
    this.props.clearError(alertItem)
  }

  render() {
    const { user, isUpdated, errors } = this.props
    return (
      <div className={styles.customerSettings}>
        <Helmet title='Settings' />
        <SideMenu user={user} />

        <div className={styles.customerSettingsContainer}>
          <h1>General Settings</h1>
          <CustomerAccountSettingsForm
            isUpdated={isUpdated}
            onSubmit={this.props.updateCustomerAccountSettings}
            handleAlertSuccessDismiss={this.handleAlertSuccessDismiss}
            formErrors={errors}
            handleAlertDismiss={this.handleAlertDismiss}
          />
        </div>
      </div>
    )
  }
}

export default asyncConnectWrapper(connectWrapper(CustomerAccountSettingsContainer))

CustomerAccountSettingsContainer.propTypes = {
  user: PropTypes.object.isRequired,
  customerAccounts: PropTypes.array,
  selectedCustomerAccount: PropTypes.object,
  updateCustomerAccountSettings: PropTypes.func.isRequired,
  isUpdated: PropTypes.bool,
  clearUpdateSuccess: PropTypes.func.isRequired,
  errors: PropTypes.array,
  clearError: PropTypes.func.isRequired,
  clearAllErrors: PropTypes.func.isRequired,
}
