import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import styles from './ProbeData.module.scss'
import { ProbeDataFlightToggle } from './ProbeDataFlightToggle'
import { ProbeDataImageContainer } from './ProbeDataImageContainer'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'

export function NewImageryByDate({
  dateData,
  open,
  updateSelectedObservationDate,
  hoveredMapPinId,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleClick = open => {
    updateSelectedObservationDate(open ? dateData.observedAt : null)
    setIsOpen(open)
  }

  const className = classNames(styles.observationDateContainer, {
    [styles.open]: isOpen,
  })

  return (
    <div className={className}>
      <h3 className={`h5 ${styles.dateHeader}`}>
        {isOpen ? (
          <button onClick={() => handleClick(false)}>
            <FaChevronUp />
          </button>
        ) : (
          <button onClick={() => handleClick(true)}>
            <FaChevronDown />
          </button>
        )}
        {moment(dateData.observedAt).format('MM/DD/YYYY')}:{' '}
      </h3>
      {dateData.flights && dateData.flights.length > 0 && (
        <div className={styles.flightsContainer}>
          <h4 className='h5'>Aerial imagery</h4>
          <ProbeDataFlightToggle flights={dateData.flights} {...props} />
        </div>
      )}
      <div className={styles.stressTypesContainer}>
        <h4 className='h5'>Probe imagery</h4>
        <div className={styles.issuesContainer}>
          {dateData.cycles.map((cycle, index) => (
            <ProbeDataImageContainer
              key={cycle.id}
              issueData={cycle}
              observationNumber={index + 1}
              hovered={hoveredMapPinId === cycle.id}
              {...props}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

NewImageryByDate.propTypes = {
  dateData: PropTypes.object,
  open: PropTypes.bool,
  updateSelectedObservationDate: PropTypes.func.isRequired,
}
