import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { UsersToInviteList } from './UsersToInviteList'
import styles from './ModalSections.module.scss'

export const UsersToInviteSection = ({
  roleOptions,
  emailAddresses,
  handleUsersToInvite,
  ...props
}) => {
  const mapRoleOptions = () => {
    return roleOptions.map(option => {
      return { label: option, value: option }
    })
  }

  const handleDropdownChange = (selectedOption, index, emailAddress) => {
    const updatedUsersToInvite = [
      ...usersToInvite.slice(0, index),
      { email: emailAddress, role: selectedOption.value },
      ...usersToInvite.slice(index + 1),
    ]
    setUsersToInvite(updatedUsersToInvite)
  }

  const getUserRole = emailAddress => {
    const user = usersToInvite.find(user => user.email === emailAddress)
    return user.role ? { label: user.role, value: user.role } : null
  }

  const allRolesSet = () => {
    return usersToInvite.every(user => user.role)
  }

  const defaultUsersToInvite = () => {
    return emailAddresses.map(email => {
      return { email: email, role: null }
    })
  }

  const [usersToInvite, setUsersToInvite] = useState(defaultUsersToInvite())
  return (
    <div className={styles.usersToInviteSection}>
      <h2>Invited Users</h2>
      <p>
        To subscribe these people to this resource, please set their role. An email will be sent to
        notify them that you have invited them.
      </p>
      <UsersToInviteList
        allRolesSet={allRolesSet}
        getUserRole={getUserRole}
        emailAddresses={emailAddresses}
        roleOptions={roleOptions && mapRoleOptions()}
        onRoleDropdownChange={handleDropdownChange}
        handleUsersToInvite={() => handleUsersToInvite(usersToInvite)}
        {...props}
      />
    </div>
  )
}

UsersToInviteSection.propTypes = {
  cancel: PropTypes.func.isRequired,
  emailAddresses: PropTypes.array.isRequired,
  roleOptions: PropTypes.array.isRequired,
}
