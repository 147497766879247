import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { LeafletMap } from 'domains'
import styles from '../AerialImageryReport.module.scss'

export function FieldRegionDisplay({ regions, season }) {
  return (
    <React.Fragment>
      {(!regions || regions.length === 0) && (
        <Link
          to={`/farms/${season.farm.id}/field/${season.field.id}/season/${season.id}/create-region`}
        >
          Add treatment region
        </Link>
      )}
      {regions && regions.length > 0 && (
        <React.Fragment>
          <div className={styles.regionsMap}>
            <LeafletMap
              hideLayerControls
              hideTransparencySlider
              hideZoomControls
              zoomDisabled
              panDisabled
              hideLegend
              hideBaseLayers
              bounds={season ? season.field.bounds : []}
              boundaryGeometry={season ? season.field.geometry : {}}
              innerGeometries={
                regions && regions.map(r => ({ id: r.id, geometry: r.geometry, display: true }))
              }
              innerGeometriesOutlineOnly
              innerGeometriesOutlineColor='#000'
            />
          </div>
          {regions.map((region, index) => (
            <div className={styles.treatmentRegion} key={region.id}>
              <p>
                {index + 1}. {region.name}
              </p>
            </div>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

FieldRegionDisplay.propTypes = {
  regions: PropTypes.array,
  season: PropTypes.object,
}
