import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '_shared'
import styles from './UserAccess.module.scss'

export const UserAccessListItem = ({ user, editable, onChange }) => {
  const userCells = () => {
    return (
      <div className={styles.userAccessCells}>
        <div className={styles.userAccessCheckbox}>
          {editable && (
            <input
              type='checkbox'
              id={`${user.id}-checkbox`}
              name='accessCheckbox'
              value={true}
              onChange={event => onChange(event, user.subscriptionId)}
            />
          )}
        </div>
        <div className={styles.userNameAndEmail}>
          <p>{user.name || 'Name Not Set'}</p>
          <p>{user.email}</p>
        </div>
        <div className={styles.userRole}>
          <p>{user.role}</p>
        </div>
      </div>
    )
  }
  return <TableRow withHighlights fullWidth noBorder left tableCells={userCells} />
}

UserAccessListItem.propTypes = {
  user: PropTypes.object.isRequired,
}
