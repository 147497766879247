import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { isEmpty } from 'helpers'
import { Dropdown, AddButton } from '_shared'
import { ProbeDataTab } from '../ProbeData/ProbeDataTab'
import { OrderHistoryTab } from './OrderHistoryTab'
import { AerialImageryTab } from './AerialImageryTab'
import { NewImageryTab } from '../ProbeData/NewImageryTab'
import { RegionsListNew } from '../Region/RegionsListNew'
import styles from './Field.module.scss'

export const ORDER_HISTORY_DATA_KEY = 'orderHistory'
export const AERIAL_IMAGERY_DATA_KEY = 'aerialImagery'
export const PROBE_DATA_KEY = 'probeData'
export const NEW_IMAGERY_DATA_KEY = 'allImagery'

export function SeasonData({
  field,
  seasons,
  regions,
  activeSeason,
  onSeasonChange,
  visibleData,
  updateVisibleData,
  handleSingleRegionToggle,
  editRegion,
  cancelEditRegion,
  saveEditRegion,
  saveNewRegion,
  toggleAddNewRegion,
  deleteRegion,
  handleRegionInfoUpdate,
  regionToEdit,
  ...props
}) {
  const seasonOptions = seasons.map(s => ({
    value: s.id,
    label: s.title,
  }))
  const permissions = activeSeason.permissions || {}

  function handleSeasonChange(selectedOption) {
    onSeasonChange(seasons, selectedOption.value)
  }

  function renderTabButton(label, dataKey) {
    return (
      <button
        className={`btn ${styles.seasonDataTabItem} ${
          visibleData[dataKey] ? styles.tabVisible : ''
        }`}
        onClick={() => updateVisibleData(dataKey)}
      >
        {label}
      </button>
    )
  }

  return (
    <React.Fragment>
      <div className={styles.seasonActionsContainer}>
        <div className={styles.seasonDropdownContainer}>
          <div>
            <label className={`${styles.seasonHeader}`} htmlFor='seasonDropdown'>
              Season
            </label>
            {!isEmpty(activeSeason) && (
              <Link
                to={`/farm/${activeSeason.farmId}/field/${activeSeason.field.id}/season/${activeSeason.id}/reports`}
              >
                View Reports
              </Link>
            )}
          </div>
          <div>
            <Dropdown
              options={seasonOptions}
              onChange={selectedOption => handleSeasonChange(selectedOption)}
              value={seasonOptions.find(opt => opt.value === activeSeason.id)}
              inputId='seasonDropdown'
            />
          </div>
        </div>
        <div className={styles.seasonActions}>
          {activeSeason && !isEmpty(activeSeason) && permissions.canManage && (
            <Link
              to={`/farm/${field.farm.id}/field/${field.id}/season/${activeSeason.id}/edit`}
              className='btn btn-default'
            >
              Edit Season
            </Link>
          )}
          {field.permissions.canManage && (
            <Link
              to={`/farm/${field.farm.id}/field/${field.id}/create-season`}
              className='btn btn-default'
            >
              Create New Season
            </Link>
          )}
          {permissions.canManage && (
            <AddButton
              to={`/farm/${field.farm.id}/field/${field.id}/season/${activeSeason.id}/aerial-imagery-report/new`}
              buttonText='New Report'
              secondary
            />
          )}
        </div>
        {/* //////////////////////// add regions drop down here ///////////////////////// */}
        <RegionsListNew
          regions={regions}
          deleteRegion={deleteRegion}
          handleSingleRegionToggle={handleSingleRegionToggle}
          editRegion={editRegion}
          cancelEditRegion={cancelEditRegion}
          saveEditRegion={saveEditRegion}
          saveNewRegion={saveNewRegion}
          toggleAddNewRegion={toggleAddNewRegion}
          handleRegionInfoUpdate={handleRegionInfoUpdate}
          regionToEdit={regionToEdit}
        />
      </div>
      <div className={styles.season}>
        <div className={styles.seasonDataTabs}>
          {renderTabButton('Aerial Imagery', AERIAL_IMAGERY_DATA_KEY)}
          {renderTabButton('TrueCause', PROBE_DATA_KEY)}
          {renderTabButton('New Imagery', NEW_IMAGERY_DATA_KEY)}
          {renderTabButton('Order History', ORDER_HISTORY_DATA_KEY)}
        </div>
        <div className={styles.seasonData}>
          {visibleData[AERIAL_IMAGERY_DATA_KEY] && (
            <AerialImageryTab activeSeason={activeSeason} {...props} />
          )}
          {visibleData[PROBE_DATA_KEY] && <ProbeDataTab activeSeason={activeSeason} {...props} />}
          {visibleData[NEW_IMAGERY_DATA_KEY] && (
            <NewImageryTab activeSeason={activeSeason} {...props} />
          )}
          {visibleData[ORDER_HISTORY_DATA_KEY] && (
            <OrderHistoryTab activeSeason={activeSeason} field={field} />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

SeasonData.propTypes = {
  field: PropTypes.object.isRequired,
  seasons: PropTypes.array.isRequired,
  activeSeason: PropTypes.object.isRequired,
  onSeasonChange: PropTypes.func.isRequired,
  visibleData: PropTypes.shape({
    orderHistory: PropTypes.bool,
    aerialImagery: PropTypes.bool,
    probeData: PropTypes.bool,
  }).isRequired,
  updateVisibleData: PropTypes.func,
}
