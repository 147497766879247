import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FindUserForm } from './FindUserForm'

export const FindUserFormContainer = ({ cancel, onSubmit }) => {
  const isValidEmailFormat = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)
  }

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      if (isValidEmailFormat()) {
        setEmailAddresses([...emailAddresses, emailAddress])
        setEmailAddress('')
        setIsInvalidEmailFormat(false)
      } else {
        setIsInvalidEmailFormat(true)
      }
    }
  }

  const handleSubmit = () => {
    if (!emailAddress) {
      onSubmit(emailAddresses)
    } else if (isValidEmailFormat()) {
      onSubmit([...emailAddresses, emailAddress])
    } else {
      setIsInvalidEmailFormat(true)
    }
  }

  const removeEmailAddress = emailToRemove => {
    setEmailAddresses(emailAddresses.filter(email => email !== emailToRemove))
  }

  const [emailAddresses, setEmailAddresses] = useState([])
  const [emailAddress, setEmailAddress] = useState('')
  const [isInvalidEmailFormat, setIsInvalidEmailFormat] = useState(false)
  return (
    <FindUserForm
      handleSubmit={handleSubmit}
      handleKeyUp={handleKeyUp}
      emailAddress={emailAddress}
      emailAddresses={emailAddresses}
      handleInputChange={e => setEmailAddress(e.target.value)}
      removeEmailAddress={removeEmailAddress}
      isInvalidEmailFormat={isInvalidEmailFormat}
      cancel={cancel}
    />
  )
}

FindUserFormContainer.propTypes = {
  cancel: PropTypes.func.isRequired,
}
