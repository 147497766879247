import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'redux-form'
import { FormGroup, FormText } from 'react-bootstrap'
import styles from './ReduxFormInput.module.scss'

// Define stateless component to render input and errors
const renderInput = ({ children, ...field }) => {
  const hasError = field.meta.touched && field.meta.error

  return (
    <FormGroup
      className={classNames(styles.reduxFormInput, { [styles.touched]: field.meta.touched })}
    >
      {children({
        ...field.input,
        errorClassName: classNames([styles.errorFormControl], { [styles.hasError]: hasError }),
      })}
      {hasError && (
        <FormText name={field.name} className={styles.errorFormText}>
          {field.meta.error}
        </FormText>
      )}
    </FormGroup>
  )
}

export default class ReduxFormInput extends Component {
  render() {
    const { fieldName, type, children, validate, value } = this.props
    return (
      <Field
        name={fieldName}
        component={renderInput}
        type={type}
        value={value}
        children={children}
        validate={validate}
      />
    )
  }
}

ReduxFormInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validate: PropTypes.any, //This can be a function, or an array of functions
}
