import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MainNavigation } from 'domains'
import Helmet from 'react-helmet'
import { logout } from 'redux/modules/auth'
import { push } from 'react-router-redux'
import config from '../../config'
import { asyncConnect } from 'redux-connect'
import styles from './App.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    promise: ({ store: { dispatch, getState } }) => {
      const promises = []
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  (state, ownProps) => ({
    user: state.auth.user,
    ownProps,
  }),
  { logout, pushState: push }
)

class AppComponent extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    // Login is now handled in the Login.js file
    if (this.props.user && !nextProps.user) {
      // logout
      this.props.pushState('/login')
    }
  }

  handleLogout = event => {
    event.preventDefault()
    this.props.pushState('/logout')
    window.sessionStorage.clear()
  }

  render() {
    const { user, sidebar, map, wideSidebar, ownProps } = this.props

    return (
      <div className={styles.app}>
        <Helmet {...config.app.head} />

        {!(ownProps.children && ownProps.children.props.route.noNav) && (
          <MainNavigation user={user} handleLogout={this.handleLogout} />
        )}

        <div className={styles.appContent}>
          {this.props.children && (
            <div style={{ display: 'flex', width: '100%' }}>{this.props.children}</div>
          )}

          {!this.props.children && (
            <div style={{ display: 'flex', width: '100%' }}>
              <div className={`Sidebar ${wideSidebar ? 'Sidebar-wide' : null}`}>{sidebar}</div>
              <div className={`Map ${wideSidebar ? 'Map-narrow' : null}`}>{map}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

AppComponent.propTypes = {
  ownProps: PropTypes.object,
  children: PropTypes.object,
  user: PropTypes.object,
  sidebar: PropTypes.object,
  map: PropTypes.object,
  logout: PropTypes.func.isRequired,
  pushState: PropTypes.func.isRequired,
}

export default asyncConnectWrapper(connectWrapper(AppComponent))
