import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  AccessListSection,
  FindUserSection,
  UsersToInviteSection,
  InvitationConfirmationSection,
  RemoveAccessSection,
  UnsubscribedConfirmationSection,
} from './ModalSections'
import { Modal } from '_shared'
export const ADD_SUBSCRIPTIONS_ACTION = 'UserAccess/ADD_SUBSCRIPTIONS'

export const UserAccessModal = ({
  userAccessList,
  show,
  close,
  resourceType,
  resourceTitle,
  resourceId,
  handleAddSubscriptions,
  handleRemoveSubscriptions,
  handleCloseConfirmation,
  subscribers,
  removedSubscribers,
  ...props
}) => {
  const [showAccessListSection, setAccessListSection] = useState(true)
  const [showFindUserSection, setFindUserSection] = useState(false)
  const [showUsersToInviteSection, setUsersToInviteSection] = useState(false)
  const [showInvitationConfirmationSection, setInvitationConfirmationSection] = useState(false)
  const [emailAddresses, setEmailAddresses] = useState([])
  const [showRemoveAccessSection, setRemoveAccessSection] = useState(false)
  const [showUnsubscribedConfirmationSection, setUnsubscribedConfirmationSection] = useState(false)

  const onAddUsers = () => {
    setAccessListSection(false)
    setFindUserSection(true)
  }

  const onFindUsers = emailAddresses => {
    setFindUserSection(false)
    setEmailAddresses(emailAddresses)
    setUsersToInviteSection(true)
  }

  const onCloseConfirmation = () => {
    handleCloseConfirmation()
    setInvitationConfirmationSection(false)
    setUnsubscribedConfirmationSection(false)
    setAccessListSection(true)
  }

  const onAddMoreUsers = () => {
    setInvitationConfirmationSection(false)
    setFindUserSection(true)
  }

  const onRemoveUsers = () => {
    setAccessListSection(false)
    setRemoveAccessSection(true)
  }

  const addSubscriptions = usersToInvite => {
    const params = {
      resourceId,
      resourceType,
      subscriberParams: usersToInvite,
    }
    handleAddSubscriptions(ADD_SUBSCRIPTIONS_ACTION, params)
    setFindUserSection(false)
    setUsersToInviteSection(false)
    setInvitationConfirmationSection(true)
  }

  const onRemoveSubscriptions = subscriptionIds => {
    handleRemoveSubscriptions(subscriptionIds)
    setRemoveAccessSection(false)
    setUnsubscribedConfirmationSection(true)
  }

  const onCancel = () => {
    setAccessListSection(true)
    setFindUserSection(false)
    setUsersToInviteSection(false)
  }

  const setModalTitle = () => {
    if (showAccessListSection) {
      return `Users with ${resourceType} Access`
    } else if (showFindUserSection) {
      return `Add ${resourceType} Permissions`
    } else if (showRemoveAccessSection) {
      return 'Removing User Access'
    }
  }
  return (
    <Modal show={show} close={close} size='xl' title={setModalTitle()} subTitle={resourceTitle}>
      {showAccessListSection && (
        <AccessListSection
          onAddUsers={onAddUsers}
          onRemoveUsers={onRemoveUsers}
          list={userAccessList}
          close={close}
        />
      )}
      {showRemoveAccessSection && (
        <RemoveAccessSection
          list={userAccessList}
          close={close}
          handleRemoveSubscriptions={onRemoveSubscriptions}
        />
      )}
      {showFindUserSection && <FindUserSection cancel={onCancel} onFindUsers={onFindUsers} />}
      {showUsersToInviteSection && (
        <UsersToInviteSection
          cancel={onCancel}
          roleOptions={userAccessList && userAccessList.allowedInvitationRoles}
          emailAddresses={emailAddresses}
          handleUsersToInvite={addSubscriptions}
          {...props}
        />
      )}
      {subscribers && showInvitationConfirmationSection && (
        <InvitationConfirmationSection
          handleCloseConfirmation={onCloseConfirmation}
          subscribers={subscribers}
          handleAddMoreUsers={onAddMoreUsers}
        />
      )}
      {removedSubscribers && showUnsubscribedConfirmationSection && (
        <UnsubscribedConfirmationSection
          handleCloseConfirmation={onCloseConfirmation}
          removedSubscribers={removedSubscribers}
        />
      )}
    </Modal>
  )
}

UserAccessModal.propTypes = {
  resourceTitle: PropTypes.string,
  resourceType: PropTypes.string,
  subscribers: PropTypes.array,
  handleAddSubscriptions: PropTypes.func,
  handleRemoveSubscriptions: PropTypes.func,
  handleCloseConfirmation: PropTypes.func.isRequired,
  userAccessList: PropTypes.object,
  show: PropTypes.bool,
  close: PropTypes.func,
}
