const UPDATE_PROBE_IMAGE = 'aker/PROBE_IMAGES/UPDATE_PROBE_IMAGE'
const UPDATE_PROBE_IMAGE_SUCCESS = 'aker/PROBE_IMAGES/UPDATE_PROBE_IMAGE_SUCCESS'
const UPDATE_PROBE_IMAGE_FAIL = 'aker/PROBE_IMAGES/UPDATE_PROBE_IMAGE_FAIL'

const initialState = {
    mlReview: {
        loaded: false,
    },
}

export default function probeImages(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_PROBE_IMAGE:
            return {
                ...state,
                mlReview: {
                    ...state.mlReview,
                    loading: true,
                    loaded: false,
                    error: undefined
                },
            }
        case UPDATE_PROBE_IMAGE_SUCCESS:
            return {
                ...state,
                mlReview: {
                    ...state.mlReview,
                    loading: false,
                    loaded: true,
                    data: action.result.data,
                },
            }
        case UPDATE_PROBE_IMAGE_FAIL:
            return {
                ...state,
                mlReview: {
                    ...state.mlReview,
                    loading: false,
                    loaded: false,
                    error: action.error.errors,
                },
            }
        default:
            return state
    }
}

export function updateProbeImage(fileName, params) {
    return {
        types: [UPDATE_PROBE_IMAGE, UPDATE_PROBE_IMAGE_SUCCESS, UPDATE_PROBE_IMAGE_FAIL],
        promise: client => client.put(`/probe_images/${fileName}`, {
            data: {
                data: {
                    ...params,
                },
            },
        }),
    }
}