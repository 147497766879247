import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Leaflet from 'leaflet'

export function ZoomControls({ map }) {
  useEffect(() => {
    if (!map) return

    new Leaflet.Control.Zoom({ position: 'topleft' }).addTo(map)
  }, [map])
  return null
}

ZoomControls.propTypes = {
  map: PropTypes.object,
}
