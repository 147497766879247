import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { SidePanel } from 'components/_shared'
import { LeafletMap } from 'domains'
import { Breadcrumbs } from '_shared'
import { SeasonForm } from './SeasonForm'
import { RegionsList } from '../Region/RegionsList'
import styles from './Season.module.scss'

export function EditSeasonFormAndMap({
  season,
  crops,
  regions,
  editSeason,
  deleteSeason,
  deleteRegion,
  updateRegions,
}) {
  const [regionsDisplay, setRegionsDisplay] = useState(
    regions.map(r => ({ id: r.id, geometry: r.geometry, display: false }))
  )

  const breadcrumbs = [
    { to: '/farms', text: 'All Farms' },
    { to: `/farms/${season.farm.id}`, text: season.farm.title },
    { to: `/farms/${season.farm.id}/field/${season.field.id}`, text: season.field.title },
  ]

  const handleRegionToggle = (regionId, shouldDisplay) => {
    let newRegionsDisplay = [...regionsDisplay]
    let region = newRegionsDisplay.find(r => r.id === regionId)
    region.display = shouldDisplay
    setRegionsDisplay(newRegionsDisplay)
  }

  return (
    <React.Fragment>
      <Helmet title='Edit Season' />
      <div className='Sidebar'>
        <SidePanel background={false}>
          <Breadcrumbs items={breadcrumbs} linkLastItem />
          <div className={styles.seasonForm}>
            <h1>Edit Season</h1>
            <SeasonForm
              season={season}
              crops={crops}
              onSave={editSeason}
              deleteSeason={deleteSeason}
            />
            <RegionsList
              season={season}
              regions={regions}
              updateRegions={updateRegions}
              deleteRegion={deleteRegion}
              onRegionChange={handleRegionToggle}
            />
          </div>
        </SidePanel>
      </div>
      <div className='Map'>
        <LeafletMap
          bounds={season.field.bounds}
          boundaryGeometry={season.field.geometry}
          innerGeometries={regionsDisplay}
          hideTransparencySlider
        />
      </div>
    </React.Fragment>
  )
}

EditSeasonFormAndMap.propTypes = {
  season: PropTypes.object.isRequired,
  crops: PropTypes.array.isRequired,
  editSeason: PropTypes.func,
  deleteSeason: PropTypes.func,
  deleteRegion: PropTypes.func,
  updateRegions: PropTypes.func,
}
