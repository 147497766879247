import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router'
import { Button, Alert } from 'react-bootstrap'
import { required, email } from 'helpers'
import { WrappedInputField } from '../_shared'
import styles from './ForgotPasswordForm.module.scss'

/**
 * ForgotPasswordForm
 * Redux form to send a password reset request to the API, the form only provides
 * an email address.
 */

class ForgotPasswordFormComponent extends Component {
  render() {
    const { handleSubmit, formSuccess, formErrors, handleAlertDismiss, isDisabled } = this.props

    const renderLeftArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#FFFFFF' strokeMiterlimit='10' points='7,13 1.5,7.5 7,2  ' />
      </svg>
    )

    return (
      <form className='form-inverse' onSubmit={handleSubmit}>
        <div className={styles.passwordForgotWindowBody}>
          {formSuccess && (
            <Alert variant='success'>
              An email was sent to: <strong>{formSuccess.email}</strong>
            </Alert>
          )}
          {formErrors &&
            formErrors.map(error => {
              return (
                <Alert
                  variant='danger'
                  key={error.code}
                  onDismiss={() => handleAlertDismiss(error)}
                >
                  {error.message}
                </Alert>
              )
            })}

          {!formSuccess && (
            <WrappedInputField
              name={'email'}
              placeholder={'Email address'}
              title={'Email address'}
              columnWidth={12}
              type={'email'}
              required={true}
              validate={[email, required]}
              disabled={false}
              horizontal={true}
            />
          )}

          <Link to='/login' className={styles.forgotPasswordLink}>
            {renderLeftArrow} Return to login page
          </Link>
        </div>

        <Button
          type='submit'
          variant='primary'
          bsSize='large'
          block
          groupClassName={styles.submitButton}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Reset password
        </Button>
      </form>
    )
  }
}

ForgotPasswordFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formSuccess: PropTypes.object,
  formErrors: PropTypes.array,
  handleAlertDismiss: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'forgetPassword',
  touchOnBlur: false,
})(ForgotPasswordFormComponent)
