import React, { Component } from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import PropTypes from 'prop-types'
import { getField } from 'redux/modules/fields'
import { getSeasons, changeActiveSeason } from 'redux/modules/seasons'
import {
  getProbeObservationsBySeasonId,
  getProbeImageryBySeasonId,
  getFlaggedProbeObservationsBySeasonId,
} from 'redux/modules/probeData'
import { toggleFavoriteProbeImage } from 'redux/modules/favoriteImages'
import {
  getRegionsBySeasonId,
  updateRegion,
  createRegion,
  deleteRegion,
} from 'redux/modules/regions'
import FieldViewAndMap from './FieldViewAndMap'

import { ALL_PROBE_DATA_TAB_ACTION, FLAGGED_PROBE_DATA_TAB_ACTION } from '../ProbeData/ProbeDataTab'
import { NEW_IMAGERY_TAB_ACTION } from '../ProbeData/NewImageryTab'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { params, store } = options
      const { dispatch } = store
      if (params && params.fieldId) {
        promises.push(dispatch(getField(params.fieldId)))
        promises.push(dispatch(getSeasons(params.fieldId)))
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    field: state.fields.field.data,
    seasons: state.seasons.seasons.data,
    seasonsErrors: state.seasons.seasons.error,
    activeSeason: state.seasons.activeSeason.data,
    flaggedObservations: state.probeData.flaggedProbeObservationsBySeason.data || [],
    observations: state.probeData.probeObservationsBySeason.data || [],
    probeCycles: state.probeData.probeCyclesBySeason.data || [],
    favoriteImageData: state.favoriteimages.toggleFavoriteProbeImageData.data,
    regions: state.regions.regionsBySeason,
  }),
  {
    changeActiveSeason,
    getRegionsBySeasonId,
    updateRegion,
    createRegion,
    deleteRegion,
  }
)

class FieldViewContainer extends Component {
  constructor() {
    super()
    this.state = {
      showFlaggedObservations: false,
      showObservations: false,
    }
  }

  onDataChange = (type, data) => {
    switch (type) {
      case FLAGGED_PROBE_DATA_TAB_ACTION:
        this.setState({ showFlaggedObservations: true, showObservations: false })
        return this.props.dispatch(getFlaggedProbeObservationsBySeasonId(data))
      case ALL_PROBE_DATA_TAB_ACTION:
        this.setState({ showFlaggedObservations: false, showObservations: true })
        return this.props.dispatch(getProbeObservationsBySeasonId(data))
      case NEW_IMAGERY_TAB_ACTION:
        this.setState({ showFlaggedObservations: false, showObservations: false })
        return this.props.dispatch(getProbeImageryBySeasonId(data))
      default:
        break
    }
  }

  toggleFavoriteImage = imagePath => {
    this.props.dispatch(toggleFavoriteProbeImage(imagePath))
  }

  render() {
    const {
      field,
      seasons,
      activeSeason,
      regions,
      flaggedObservations,
      observations,
      probeCycles,
      favoriteImageData,
      changeActiveSeason,
      getRegionsBySeasonId,
      updateRegion,
      createRegion,
      deleteRegion,
    } = this.props

    return (
      <FieldViewAndMap
        field={field}
        seasons={seasons}
        activeSeason={activeSeason}
        regions={regions || {}}
        observations={this.state.showFlaggedObservations ? flaggedObservations : observations}
        probeCycles={probeCycles}
        favoriteImageData={favoriteImageData}
        changeActiveSeason={changeActiveSeason}
        onDataChange={this.onDataChange}
        toggleFavoriteImage={this.toggleFavoriteImage}
        getRegionsBySeasonId={getRegionsBySeasonId}
        updateRegion={updateRegion}
        createRegion={createRegion}
        deleteRegion={deleteRegion}
      />
    )
  }
}

export default asyncConnectWrapper(connectWrapper(FieldViewContainer))

FieldViewContainer.propTypes = {
  params: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  seasons: PropTypes.array.isRequired,
  activeSeason: PropTypes.object.isRequired,
  observations: PropTypes.array.isRequired,
  favoriteImageData: PropTypes.object,
  changeActiveSeason: PropTypes.func.isRequired,
  toggleFavoriteProbeImage: PropTypes.func,
}
