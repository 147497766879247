import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Leaflet from 'leaflet'

export function MapBounds({ map, boundaryBounds, pinBounds }) {
  useEffect(() => {
    if (!map) return

    if (boundaryBounds && boundaryBounds.length > 0) {
      map.setMaxBounds(null).fitBounds(boundaryBounds, {
        padding: [0, 0],
        animated: false,
      })
    } else if (pinBounds && pinBounds.length > 0) {
      const mappedBounds = pinBounds.map(f => f.bounds)
      let overallBounds
      mappedBounds.forEach(bounds => {
        const pinBound = Leaflet.latLngBounds(
          Leaflet.latLng(bounds[0][0], bounds[0][1]),
          Leaflet.latLng(bounds[1][0], bounds[1][1])
        )
        overallBounds = !overallBounds ? pinBound : overallBounds.extend(pinBound)
      })

      map.setMaxBounds(null).fitBounds(overallBounds, {
        padding: [0, 0],
        animate: false,
      })
    } else {
      // Default the view to USA
      map.setMaxBounds(null).setView([37.8, -96], 4)
    }
  }, [map, boundaryBounds, pinBounds])

  return null
}

MapBounds.propTypes = {
  map: PropTypes.object,
  boundaryBounds: PropTypes.array,
  pinBounds: PropTypes.array,
}
