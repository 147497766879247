import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { login, clearAllErrors, clearError, signUp } from 'redux/modules/auth'
import { CustomerAccountsContext } from 'domains'
import { Login } from './Login'

/**
 * Login
 * Displaying a login form and handle submitting the form by changing the state
 * by a login action.
 */
const connectWrapper = connect(
  state => ({
    loginErrors: state.auth.errors,
    loggingIn: state.auth.loggingIn,
  }),
  {
    login,
    clearAllErrors,
    clearError,
    signUp,
  }
)

export function LoginContainerComponent({ loginErrors, loggingIn, login, signUp, clearError }) {
  const { refreshCustomerAccounts } = React.useContext(CustomerAccountsContext)

  const handleLogin = data => {
    login(data)
      .then(() => {
        refreshCustomerAccounts()
        browserHistory.push('/')
      })
      .catch(() => {
        // Could not login
      })
  }

  const handleSignUp = data => {
    signUp(data).then(() => {
      refreshCustomerAccounts()
      browserHistory.push('/')
    })
  }

  const handleAlertDismiss = alertItem => {
    clearError(alertItem)
  }

  return (
    <Login
      handleLogin={handleLogin}
      handleSignUp={handleSignUp}
      handleAlertDismiss={handleAlertDismiss}
      loginErrors={loginErrors}
      loggingIn={loggingIn}
    />
  )
}

const LoginContainer = connectWrapper(LoginContainerComponent)
export default LoginContainer

LoginContainer.propTypes = {
  loginErrors: PropTypes.array,
  loggingIn: PropTypes.bool,
  login: PropTypes.func,
  clearAllErrors: PropTypes.func,
  clearError: PropTypes.func,
  signUp: PropTypes.func,
}
