import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import pluralize from 'pluralize'
import { flatten } from 'helpers'
import { TextAreaInput } from '_shared'
import { ProbeImageDisplay } from 'domains/ImageReviewer/_shared'
import styles from '../AerialImageryReport.module.scss'

export function ObservationPicker({
  observationsGroupedByStressType,
  selectedObservations,
  setSelectedObservations,
  observationLimit,
}) {
  const [displayState, setDisplayState] = useState({})

  useEffect(() => {
    const initialDisplayState = observationsGroupedByStressType.reduce(
      (initialDisplayState, stressType) => {
        const allImagesForStressType = flatten(
          flatten(stressType.cropStressItems.map(stressItem => stressItem.issues)).map(
            issue => issue.images
          )
        )
        const imagesSelectedForStressType = selectedObservations.filter(image =>
          allImagesForStressType.find(i => i.id === image.id)
        )
        const expanded = imagesSelectedForStressType && imagesSelectedForStressType.length > 0
        initialDisplayState[stressType.name] = expanded
        return initialDisplayState
      },
      {}
    )
    setDisplayState(initialDisplayState)
  }, [observationsGroupedByStressType]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleStressTypeClick = (e, name) => {
    e.preventDefault()
    setDisplayState({ ...displayState, [name]: !displayState[name] })
  }

  const handleImageSelection = (imageId, notes) => {
    let updatedObservations = [...selectedObservations]
    const existingImage = updatedObservations.find(img => img.id === imageId)
    if (existingImage) {
      updatedObservations = updatedObservations.filter(img => img.id !== imageId)
    } else {
      updatedObservations.push({ id: imageId, notes: notes })
    }
    setSelectedObservations(updatedObservations)
  }

  const handleNotesChange = (imageId, notes) => {
    let updatedObservations = [...selectedObservations]
    const existingImage = updatedObservations.find(img => img.id === imageId)
    if (existingImage) {
      existingImage.notes = notes
      setSelectedObservations(updatedObservations)
    }
  }

  const totalObservationsCount = flatten(
    flatten(observationsGroupedByStressType.map(c => c.cropStressItems)).map(c => c.issues)
  ).length
  return (
    <div className={styles.observationDataPicker}>
      <h3>Choose imagery</h3>
      <p>
        Select images within the stress categories below to include TrueCause data in your report
      </p>
      <p className={styles.observationCount}>
        {`${totalObservationsCount} flagged ${pluralize('observation', totalObservationsCount)}`}
      </p>
      {observationsGroupedByStressType.map(stressType => (
        <ObservationsByStressType
          key={stressType.name}
          stressType={stressType}
          onStressTypeClick={handleStressTypeClick}
          showStressType={displayState[stressType.name]}
          onImageSelection={handleImageSelection}
          onNotesChange={handleNotesChange}
          selectedObservations={selectedObservations}
          observationLimit={observationLimit}
        />
      ))}
    </div>
  )
}

function ObservationsByStressType({
  stressType,
  onStressTypeClick,
  showStressType,
  onImageSelection,
  onNotesChange,
  selectedObservations,
  observationLimit,
}) {
  const issueCount = flatten(stressType.cropStressItems.map(c => c.issues)).length

  return (
    <div className={styles.stressTypeContainer}>
      <div className={styles.stressTypeHeader}>
        <div className={styles.stressTypeNameContainer}>
          <h4 className='h3'>{stressType.name}</h4>
          <p>
            ({issueCount} {pluralize('observation', issueCount)})
          </p>
        </div>
        <button
          className={styles.stressTypeButton}
          onClick={e => onStressTypeClick(e, stressType.name)}
        >
          {showStressType ? <FaAngleUp /> : <FaAngleDown />}
        </button>
      </div>
      {showStressType &&
        stressType.cropStressItems.map(stressItem => (
          <ObservationsByStressItem
            key={stressItem.name}
            stressItem={stressItem}
            onImageSelection={onImageSelection}
            onNotesChange={onNotesChange}
            selectedObservations={selectedObservations}
            observationLimit={observationLimit}
          />
        ))}
    </div>
  )
}

function ObservationsByStressItem({
  stressItem,
  onImageSelection,
  onNotesChange,
  selectedObservations,
  observationLimit,
}) {
  const [imageNotes, setImageNotes] = useState([])

  const handleNoteChange = (imageId, notes) => {
    let updatedNotes = [...imageNotes]
    const existingNote = updatedNotes.find(img => img.id === imageId)
    if (existingNote) {
      updatedNotes = updatedNotes.filter(img => img.id !== imageId)
    } else {
      updatedNotes.push({ id: imageId, notes: notes })
    }

    setImageNotes(updatedNotes)
    if (!!selectedObservations.find(i => i.id === imageId)) {
      onNotesChange(imageId, notes)
    }
  }

  return (
    <div className={styles.stressItemContainer}>
      <div className={styles.stressItemHeader}>
        <h5 className='h3'>{stressItem.name}</h5>
        <p>({stressItem.issues.length})</p>
      </div>
      <div className={styles.observationImagesContainer}>
        {flatten(stressItem.issues.map(i => i.images)).map(img => {
          const observation = selectedObservations.find(i => i.id === img.id)
          const selected = !!observation
          return (
            <div key={img.id} className={styles.imageContainer}>
              <ProbeImageDisplay
                key={img.id}
                image={img}
                active={selected}
                selected={selected}
                disabled={!selected && selectedObservations.length >= observationLimit}
                showSelectionCheckbox
                onThumbnailSelect={id =>
                  onImageSelection(id, imageNotes.find(i => i.id === img.id))
                }
                onCheckboxClick={id => onImageSelection(id, imageNotes.find(i => i.id === img.id))}
                className={styles.image}
              />
              <TextAreaInput
                name={`notes-${img.id}`}
                label='Notes'
                placeholder='Add a description'
                onChange={e => handleNoteChange(img.id, e.target.value)}
                className={styles.observationImageNote}
                value={observation ? observation.notes : ''}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

ObservationPicker.propTypes = {
  observationsGroupedByStressType: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      cropStressItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          issues: PropTypes.arrayOf(
            PropTypes.shape({
              images: PropTypes.array,
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ),
}
