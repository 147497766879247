import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListGroup from 'react-bootstrap/ListGroup'
import { LinkContainer } from 'react-router-bootstrap'
import styles from './DashboardMiniList.module.scss'

/**
 * DashboardMiniList
 * Generates a list of items for dashboard.
 */
export default class DashboardMiniList extends Component {
  getListItem(actionRequired, ListItem) {
    const rightArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#ffffff' strokeMiterlimit='10' points='2,2 7.5,7.5 2,13  ' />
      </svg>
    )

    let renderedItem
    let header

    if (actionRequired) {
      header = `${ListItem.farm.name}, ${ListItem.title}`
      renderedItem = (
        <LinkContainer key={ListItem.id} to={`/farm/${ListItem.farm.id}/field/${ListItem.id}`}>
          <div className={styles.listGroupItem + ' ' + styles.listGroupItemReport}>
            <span className={styles.indicator} />
            <div className={styles.textContainer}>
              <div className={styles.title}>{header}</div>
              <div className={styles.subtitle}>
                <span>{ListItem.package || '-'}</span>
                {ListItem.flight && <span> flight {ListItem.flight}</span>}
              </div>
            </div>
            <span className={styles.arrow}>{rightArrow}</span>
          </div>
        </LinkContainer>
      )
    } else {
      header = `${ListItem.farm.name}, ${ListItem.title}`
      renderedItem = (
        <LinkContainer key={ListItem.id} to={`/farm/${ListItem.farm.id}/field/${ListItem.id}`}>
          <div className={styles.listGroupItem}>
            <div className={styles.textContainer}>
              <div className={styles.title}>{header}</div>
              <div className={styles.subtitle}>
                {ListItem.issues.issueCount} {ListItem.issues.issueCount === 1 ? 'issue' : 'issues'}
                :{' '}
              </div>
            </div>
            <span className={styles.arrow}>{rightArrow}</span>
          </div>
        </LinkContainer>
      )
    }
    return renderedItem
  }

  render() {
    const { list, actionRequired } = this.props
    return (
      <div>
        <ListGroup className={styles.listGroup}>
          {list.map(item => this.getListItem(actionRequired, item))}
        </ListGroup>
      </div>
    )
  }
}
DashboardMiniList.propTypes = {
  actionRequired: PropTypes.bool,
  list: PropTypes.array.isRequired,
}
