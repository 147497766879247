import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { OnOffSwitch } from '_shared'
import styles from './ProbeData.module.scss'

export function ProbeDataFlightToggle({ selectedFlight, flights, updateSelectedFlight }) {
  const isUniqueFlightType = flight => {
    return flights.filter(f => f.type === flight.type).length === 1
  }

  const getFlightLabel = flight => {
    return isUniqueFlightType(flight)
      ? flight.type.toUpperCase()
      : `${flight.type.toUpperCase()} - ${moment(flight.dateFlown).format('M/D')}`
  }

  const handleChange = flight => {
    // switching off
    if (selectedFlight && selectedFlight.id === flight.id) {
      updateSelectedFlight(0)
      // switching on
    } else {
      updateSelectedFlight(flight.id)
    }
  }

  return flights.map(flight => (
    <OnOffSwitch
      key={flight.id}
      label={getFlightLabel(flight)}
      name={flight.id}
      value={!!selectedFlight && selectedFlight.id === flight.id}
      onChange={() => handleChange(flight)}
      className={styles.probeDataFlightToggle}
    />
  ))
}

ProbeDataFlightToggle.propTypes = {
  selectedFlight: PropTypes.object,
  flights: PropTypes.array.isRequired,
  updateSelectedFlight: PropTypes.func.isRequired,
}
