import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { LoginForm } from './LoginForm'
import SignUpForm from 'components/Login/SignUpForm/SignUpForm'
import { ReactComponent as LogoImage } from '../../assets/safari-pinned-tab.svg'
import styles from './Login.module.scss'

export function Login({ handleLogin, handleSignUp, handleAlertDismiss, loginErrors, loggingIn }) {
  const [activeState, setActiveState] = useState('login')

  return (
    <div className={styles.siteWrapper}>
      <Helmet title='Login' />
      <div className={styles.loginWindowWrapper}>
        <div className={styles.akerInformation}>
          <div className={styles.logoWrapper}>
            <LogoImage className={styles.logo} />
            <div className={styles.slogan}>Capture in-season threats to protect your profits.</div>
          </div>
          <div className={styles.descriptionWrapper}>
            <ul>
              <li className={styles.listItem}>Aker is free and mobile</li>
              <li className={styles.listItem}>
                Download all your data and collaborate with your team
              </li>
              <li className={styles.listItem}>Scout faster</li>
              <li className={styles.listItem}>Visualize and prioritize in-season threats</li>
            </ul>
          </div>
        </div>
        <div className={styles.loginWindow}>
          <div className={styles.signUpLoginActionWrapper}>
            <button
              className={styles.action + ' ' + (activeState === 'login' ? styles.active : '')}
              data-test={'action-login'}
              onClick={() => {
                setActiveState('login')
                loginErrors &&
                  loginErrors.length > 0 &&
                  loginErrors.forEach(err => handleAlertDismiss(err))
              }}
            >
              <div className={styles.actionText}>Log In</div>
            </button>
            <button
              className={styles.action + ' ' + (activeState === 'signUp' ? styles.active : '')}
              data-test={'action-signUp'}
              onClick={() => {
                setActiveState('signUp')
                loginErrors &&
                  loginErrors.length > 0 &&
                  loginErrors.forEach(err => handleAlertDismiss(err))
              }}
            >
              <div className={styles.actionText}>Sign Up</div>
            </button>
          </div>
          <div className={styles.formWrapper}>
            {activeState === 'login' && (
              <LoginForm
                onSubmit={handleLogin}
                loggingIn={loggingIn}
                formErrors={loginErrors}
                handleAlertDismiss={handleAlertDismiss}
              />
            )}
            {activeState === 'signUp' && (
              <SignUpForm
                onSubmit={handleSignUp}
                loggingIn={loggingIn}
                formErrors={loginErrors}
                handleAlertDismiss={handleAlertDismiss}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  loginErrors: PropTypes.array,
  loggingIn: PropTypes.bool,
  login: PropTypes.func,
  clearAllErrors: PropTypes.func,
  clearError: PropTypes.func,
  signUp: PropTypes.func,
}
