import React from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import moment from 'moment'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { getImagesDataByTask, deleteImages } from 'redux/modules/imagereview'
import { BulkDeleteImages } from './BulkDeleteImages'
import styles from '../ImageReviewer.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { params, store } = options
      const { dispatch } = store
      if (params && params.taskId) {
        promises.push(dispatch(getImagesDataByTask(params.taskId)))
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    imageDataByTask: state.imagereview.imageDataByTask.data,
  }),
  { deleteImages }
)

function BulkImageReviewContainer({ imageDataByTask, deleteImages, dispatch }) {
  let task = null
  if (imageDataByTask) {
    task = {
      ...imageDataByTask,
      percentComplete:
        (
          (imageDataByTask.totalImageCount - imageDataByTask.unreviewedImageCount) /
          imageDataByTask.totalImageCount
        ).toFixed(2) * 100,
      updatedOn: moment.utc(imageDataByTask.recentProbeBatchDate),
    }
  }

  return (
    <div className={`container ${styles.imageReviewerContainer}`}>
      <div className={styles.imageReviewerContainer}>
        <Helmet title='Image Reviewer Bulk Image Review' />
        <div className='container'>
          {task && <BulkDeleteImages task={task} deleteImages={deleteImages} />}
        </div>
      </div>
    </div>
  )
}

export default asyncConnectWrapper(connectWrapper(BulkImageReviewContainer))

BulkImageReviewContainer.propTypes = {
  imageDataByTask: PropTypes.object.isRequired,
  deleteImages: PropTypes.func,
}
