/* eslint no-useless-constructor: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import config from '../../config'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import { FarmsModalForm } from 'components'
import { FormattedNumber } from 'react-intl'
import PledgeStatus from './PledgeStatus'
import DashboardHeader from './DashboardHeader'
import DashboardMiniList from './DashboardMiniList'
import { ImageReviewerSummary } from '../../domains/ImageReviewer/Summary/ImageReviewerSummary'
import { addFarm } from 'redux/modules/farmsandfields'
import { getTasksWithUnreviewedImages } from 'redux/modules/imagereview'
import { clearError } from 'redux/modules/farmsandfields'
import tableStyles from '_shared/Table/Table.module.scss'
import styles from './Dashboard.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: ({ store: { dispatch, getState } }) => {
      const promises = []
      promises.push(dispatch(getTasksWithUnreviewedImages()))
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    farmsErrors: state.farmsandfields.farms.error,
    farmErrors: state.farmsandfields.farm.error,
    tasks: state.imagereview.tasks.data,
    user: state.auth.user,
  }),
  { addFarm, clearError, pushState: push }
)

class Dashboard extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.setModalState(this.props)
  }

  // Prevents errors when logging out
  shouldComponentUpdate(nextProps) {
    return this.props.user && !nextProps.user ? false : true
  }

  setModalState = props => {
    if (props.farms && props.farms.length < 1) {
      this.setState({
        showModal: true,
      })
    } else {
      this.setState({
        showModal: false,
      })
    }
  }

  handleFarmSubmit = data => {
    this.props.addFarm(data).then(res => {
      this.props.pushState(`/farm/${res.data.id}`)
    })
  }

  handleDismiss = alertItem => {
    this.props.clearError(alertItem)
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    })
  }

  render() {
    const { user, fields, tasks } = this.props

    const createFarmErrors = []
      .concat(this.props.farmsErrors, this.props.farmErrors)
      .filter(val => typeof val !== 'undefined')
    const modalErrors = createFarmErrors.filter(error => error.method === 'add')

    const rightArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#ffffff' strokeMiterlimit='10' points='2,2 7.5,7.5 2,13  ' />
      </svg>
    )

    let actionRequiredList = []
    let actionRequiredListCount = 0

    if (fields) {
      // Filter list of fields that require scouting.
      actionRequiredList = fields.filter(listItem => listItem.actionRequired)
      actionRequiredListCount = actionRequiredList.length
    }

    return (
      <div>
        <Helmet title='Dashboard' />
        <div className={styles.DashboardContainer}>
          <DashboardHeader />

          <div className={styles.Row}>
            <div className={styles.colLeft}>
              <div className={styles.innerColLeft}>
                {user && user.role !== 'Grower' && (
                  <div>
                    <p className={styles.introText}>
                      {config.app.contactInfo.companyName} is part of your team, dedicated to keep
                      every plant healthy during the growth season and protect your yield.
                    </p>
                    {/** <a href={'http://admin.staging.aker.ag/partners/john_deere/' + cookie.load('authToken')} className="btn btn-default"> Connect to John Deere </a> **/}
                  </div>
                )}
                {/* Not shown as a Grower: https://www.pivotaltracker.com/story/show/119181569 */}
                {user && (
                  <PledgeStatus
                    title='Work Order Status'
                    total={user.workOrdersCount}
                    used={user.completedWorkOrdersCount}
                    errors={null}
                  />
                )}
              </div>
            </div>
            <div className={styles.colRight}>
              <div className={styles.innerColRight}>
                {/* Action required reports list */}
                {user && user.role !== 'Grower' && actionRequiredListCount > 0 && (
                  <div>
                    <h3 className={styles.heading}>
                      <span className={styles.headingNumber}>
                        <FormattedNumber value={actionRequiredListCount} />
                      </span>
                      {actionRequiredListCount === 1 ? 'field' : 'fields'}{' '}
                      {actionRequiredListCount === 1 ? 'requires' : 'require'} scouting
                    </h3>
                    <DashboardMiniList actionRequired list={actionRequiredList} />
                  </div>
                )}

                {user.isImageReviewer && (
                  <div className={styles.imageReviewerSummaryWrapper}>
                    <div className={tableStyles.tableTitle}>
                      <h1>Image Reviewer Summary</h1>
                    </div>
                    <ImageReviewerSummary tasks={tasks} isCondensed />
                  </div>
                )}

                <div className={styles.buttonBar}>
                  <LinkContainer to='/reports'>
                    <Button variant='primary'>
                      See all reports <span className='icon-align-right'>{rightArrow}</span>
                    </Button>
                  </LinkContainer>

                  {user.role !== 'Grower' && (
                    <LinkContainer to='/farms'>
                      <Button variant='outline-primary'>
                        Browse farms{' '}
                        <span className={'icon-align-right ' + styles.blueIcon}>{rightArrow}</span>
                      </Button>
                    </LinkContainer>
                  )}

                  {user.role === 'Grower' && (
                    <LinkContainer to='/fields'>
                      <Button variant='outline-primary'>
                        Browse fields{' '}
                        <span className={'icon-align-right ' + styles.blueIcon}>{rightArrow}</span>
                      </Button>
                    </LinkContainer>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create a new Farm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FarmsModalForm
              addButtonType={'farm'}
              onSubmit={this.handleFarmSubmit}
              handleDismiss={this.handleDismiss}
              modalErrors={modalErrors}
              customerAccounts={user.customerAccounts}
              handleCancel={this.closeModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default asyncConnectWrapper(connectWrapper(Dashboard))

Dashboard.propTypes = {
  farms: PropTypes.array,
  farmsErrors: PropTypes.array,
  farmErrors: PropTypes.array,
  fields: PropTypes.array,
  customer: PropTypes.object,
  user: PropTypes.object,
  pushState: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  addFarm: PropTypes.func.isRequired,
}
