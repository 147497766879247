import React from 'react'
import AirbrakeClient from 'airbrake-js'
import config from '../../config'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }

    if (config.ENVIRONMENT === ('production' || 'staging')) {
      this.airbrake = new AirbrakeClient({
        projectId: config.airbrakeProjectId,
        projectKey: config.airbrakeProjectKey,
      })
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // Send error to Airbrake
    if (this.airbrake) {
      this.airbrake.notify({
        error: error,
        params: { info: info },
      })
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}
