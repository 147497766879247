import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { AddButton } from '_shared'
import { FarmsModalForm } from 'components'

export function AddFarm({ clearError, clearAllErrors, addFarm, farmsErrors, user }) {
  const [showModal, setShowModal] = useState(false)

  function closeModal() {
    clearAllErrors()
    setShowModal(false)
  }

  function handleDismiss(alertItem) {
    clearError(alertItem)
  }

  async function handleFarmSubmit(data) {
    try {
      await addFarm(data)
      setShowModal(false)
    } catch (e) {
      console.log(e)
    }
  }

  const modalErrors = []
    .concat(farmsErrors)
    .filter(error => typeof error !== 'undefined' && error.method === 'add')

  return (
    <React.Fragment>
      <AddButton onClick={() => setShowModal(true)} buttonText='Add New Farm' />
      <Modal size='lg' show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create a new farm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FarmsModalForm
            addButtonType={'farm'}
            onSubmit={handleFarmSubmit}
            customerAccounts={user.customerAccounts}
            handleDismiss={handleDismiss}
            modalErrors={modalErrors}
            handleCancel={closeModal}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddFarm.propTypes = {
  clearError: PropTypes.func.isRequired,
  clearAllErrors: PropTypes.func.isRequired,
  addFarm: PropTypes.func.isRequired,
  farmsErrors: PropTypes.array,
}
