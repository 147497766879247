import React from 'react'
import PropTypes from 'prop-types'
import { flatten } from 'helpers'
import { MapPinsLayer } from './MapPinsLayer'
import { StressItemPopup } from './StressItemPopup'

export function StressItemsLayer({ stressItems, map, hoveredMapPinId, updateHoveredMapPinId }) {
  const allIssues = flatten(stressItems.map(si => si.issues.map(i => ({ name: si.name, ...i }))))

  const pins = stressItems.reduce((parsedPins, stressItem) => {
    let mappedIssues = stressItem.issues.map(issue => ({
      active: stressItem.active,
      carouselIssues: [
        { ...issue, name: stressItem.name },
        ...allIssues.filter(i => i.id !== issue.id && i.lat === issue.lat && i.lng === issue.lng),
      ],
      name: stressItem.name,
      ...issue,
    }))
    return parsedPins.concat(mappedIssues)
  }, [])

  return (
    <MapPinsLayer
      pinLocations={pins}
      map={map}
      popupContent={pin => <StressItemPopup pin={pin} />}
      hoveredMapPinId={hoveredMapPinId}
      updateHoveredMapPinId={updateHoveredMapPinId}
    />
  )
}

StressItemsLayer.propTypes = {
  stressItems: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      issues: PropTypes.array,
    })
  ),
  map: PropTypes.object,
  hoveredMapPinId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateHoveredMapPinId: PropTypes.func,
}
