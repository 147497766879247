import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FaRegCircle, FaRegDotCircle } from 'react-icons/fa'
import styles from './ProbeData.module.scss'
import { ImageCarousel } from './ImageCarousel'

export function ProbeDataImageContainer({
  issueData,
  observationNumber,
  hovered,
  updateHoveredMapPinId,
  favoriteImageData,
  toggleFavoriteImage,
}) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    setIsHovered(hovered)
  }, [hovered])

  const updateIsHovered = hovered => {
    setIsHovered(hovered)
    if (!!updateHoveredMapPinId) {
      updateHoveredMapPinId(issueData.id, hovered)
    }
  }

  const containerStyles = classnames(styles.stressFindingsContainer, {
    [styles.hovered]: isHovered,
  })

  return (
    <div
      className={containerStyles}
      key={issueData.id}
      onMouseEnter={() => updateIsHovered(true)}
      onMouseLeave={() => updateIsHovered(false)}
    >
      <figure>
        <figcaption className={styles.issueLabelContainer}>
          <span className={styles.issueLabel}>Observation {observationNumber}</span>
          <span>
            Image {activeIndex + 1}/{issueData.images.length}
          </span>
        </figcaption>
        <ImageCarousel
          images={issueData.images}
          observationNumber={observationNumber}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          displayFavoriteToggle={true}
          favoriteImageData={favoriteImageData}
          toggleFavoriteImage={toggleFavoriteImage}
        />
        <div className={styles.stressFindingCarouselIndicators}>
          {issueData.images.map((_finding, index) => {
            if (index === activeIndex) {
              return <FaRegDotCircle key={index} />
            } else {
              return <FaRegCircle key={index} />
            }
          })}
        </div>
      </figure>
    </div>
  )
}

ProbeDataImageContainer.propTypes = {
  issueData: PropTypes.shape({
    images: PropTypes.array,
  }),
  observationNumber: PropTypes.number,
  hovered: PropTypes.bool,
  updateHoveredMapPinId: PropTypes.func,
  favoriteImageData: PropTypes.object,
  toggleFavoriteImage: PropTypes.func,
}
