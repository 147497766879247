import React from 'react'
import PropTypes from 'prop-types'
import styles from './ModalSections.module.scss'

export const SectionButtonContainer = ({ children }) => {
  return <div className={styles.sectionButtonContainer}>{children}</div>
}

SectionButtonContainer.propTypes = {
  children: PropTypes.node,
}
