import React from 'react'
import cornStalk from '../assets/icons/corn-stalk.svg'
import styles from './LoadingState.module.scss'

export default function LoadingState() {
  let cornStalks = []
  const displayCornStalks = () => {
    for (let i = 0; i < 5; i++) {
      cornStalks.push(
        <img
          key={i}
          alt='corn stalk by B Barrett from the Noun Project'
          className={styles.cornStalk}
          src={cornStalk}
        />
      )
    }
    return <div className={styles.loadingState}>{cornStalks}</div>
  }
  return <div className={styles.loadingStateWrapper}>{displayCornStalks()}</div>
}
