import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaRegTimesCircle, FaRegPlusSquare } from 'react-icons/fa'

export function ListEditor({
  items,
  setItems,
  render,
  maxNumberOfItems,
  outerItemClassName,
  innerItemClassName,
  customItemMap,
}) {
  const [list, setList] = useState(items && items.length > 0 ? items : [''])
  maxNumberOfItems = maxNumberOfItems || Number.MAX_SAFE_INTEGER

  const handleChange = updatedItems => {
    if (!!customItemMap) {
      setItems(updatedItems.map(customItemMap))
    } else {
      setItems(updatedItems)
    }
  }

  const addItem = e => {
    e.preventDefault()
    const updatedItems = [...list, '']
    setList(updatedItems)
  }

  const removeItem = item => {
    const updatedItems = list.filter(h => h !== item)
    setList(updatedItems)
    handleChange(updatedItems)
  }

  const changeItem = (item, index) => {
    const updatedItems = [...list]
    updatedItems[index] = item
    setList(updatedItems)
    handleChange(updatedItems)
  }

  return (
    <React.Fragment>
      <div className={outerItemClassName}>
        {list.map((item, index) => (
          <div className={innerItemClassName} key={item.id || index}>
            {render(item, index, changeItem)}
            <button
              className='btn'
              onClick={e => {
                e.preventDefault()
                removeItem(item)
              }}
            >
              <FaRegTimesCircle color='#ff4c5a' />
            </button>
          </div>
        ))}
      </div>
      {list.length < maxNumberOfItems && (
        <button className='btn' onClick={addItem}>
          <FaRegPlusSquare color='#ff4c5a' /> Add item
        </button>
      )}
    </React.Fragment>
  )
}

ListEditor.propTypes = {
  items: PropTypes.array,
  maxNumberOfItems: PropTypes.number,
  setItems: PropTypes.func,
  outerItemClassName: PropTypes.string,
  innerItemClassName: PropTypes.string,
  customItemMap: PropTypes.func,
  render: PropTypes.func,
}
