import React from 'react'
import PropTypes from 'prop-types'
import { FaFlag, FaRegFlag } from 'react-icons/fa'
import styles from './FavoriteImage.module.scss'

export function FavoriteProbeImageButton({ isFavorited, imagePath, toggleFavorite }) {
  return (
    <button
      className={styles.favoriteButton}
      onClick={() => toggleFavorite && toggleFavorite(imagePath)}
    >
      {isFavorited ? <FaFlag /> : <FaRegFlag />}
    </button>
  )
}

FavoriteProbeImageButton.propTypes = {
  isFavorited: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  toggleFavorite: PropTypes.func,
}
