/* eslint no-useless-constructor: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { LeafletMap } from 'domains'

/**
 * MapView
 * Shows a map containing elements based on the route.
 */
import { compose } from 'redux'
const enhance = compose(
  connect(state => ({
    farm: state.farms.farm.data,
  }))
)

export default enhance(
  class EditFarmMap extends Component {
    static propTypes = {
      farm: PropTypes.object,
      pushState: PropTypes.func.isRequired,
      setEditFieldPolygon: PropTypes.func,
    }

    constructor() {
      super()
    }

    render() {
      const { farm } = this.props

      const fieldsWithBounds =
        farm.fields && farm.fields.filter(field => field.bounds && field.bounds.length === 2)

      const fields = fieldsWithBounds.map(f => ({
        onClick: () => {
          browserHistory.push(`/farm/${farm.id}/field/${f.id}`)
        },
        ...f,
      }))

      return <LeafletMap boundariesForPinMarkers={fields} hideTransparencySlider />
    }
  }
)
