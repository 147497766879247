import React from 'react'
import PropTypes from 'prop-types'
import { FaSquare } from 'react-icons/fa'
import styles from './BulletedTextComponent.module.scss'

export function BulletedText({ children, brandColor, ...props }) {
  return (
    <div className={styles.highlightWrapper}>
      <div className={styles.bullet}>
        <FaSquare
          style={{
            color: brandColor,
          }}
        />
      </div>
      {children}
    </div>
  )
}

BulletedText.propTypes = {
  brandColor: PropTypes.string,
  children: PropTypes.any,
}
