import React from 'react'

export function PlusIcon({ azure, black, cloudburst, white }) {
  let color = 'black'
  if (black) {
    color = '#000'
  } else if (white) {
    color = '#FFF'
  } else if (azure) {
    color = '#007cc0'
  } else if (cloudburst) {
    color = '#1e303f'
  }

  return (
    <svg
      version='1.1'
      x='0px'
      y='0px'
      width='11px'
      height='11px'
      viewBox='0 0 11 11'
      enableBackground='new 0 0 11 11'
      xmlSpace='preserve'
    >
      <g>
        <line
          fill='none'
          stroke={color}
          strokeWidth='2'
          strokeMiterlimit='10'
          x1='11'
          y1='5.5'
          x2='0'
          y2='5.5'
        />
        <line
          fill='none'
          stroke={color}
          strokeWidth='2'
          strokeMiterlimit='10'
          x1='5.5'
          y1='11'
          x2='5.5'
          y2='0'
        />
      </g>
    </svg>
  )
}

export function AkerLogo({ color }) {
  let fillColor = color ? color : '#000000'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='132px'
      height='34px'
      viewBox='0 0 132 34'
      enable-background='new 0 0 132 34'
    >
      <g>
        <path
          fill={fillColor}
          d='M54.514,1.568l-11.71,30.866h2.351l3.046-8.14h14.933l3.133,8.14h2.307L56.735,1.568H54.514z M49.029,22.16
    l6.574-17.458l6.704,17.458H49.029z'
        />
        <path
          fill={fillColor}
          d='M90.423,10.667h-2.745L75.445,23.902V1h-2.221v31.434h2.221v-5.399l5.617-6.132
    c0.861,0.281,6.84,2.58,6.84,11.937h2.166c0-9.122-5.164-12.56-7.471-13.641l0.118-0.13L90.423,10.667z'
        />
        <path
          fill={fillColor}
          d='M103.777,10.102c-3.045,0-5.571,1.304-7.399,3.786c-1.698,2.308-2.134,5.006-2.134,7.75
    c0,3.309,1.001,6.18,2.785,8.271c1.568,1.786,3.789,3.092,6.924,3.092c2.482,0,4.658-0.958,6.225-2.526
    c1.219-1.175,2.135-2.699,2.44-4.569h-2.265c-0.261,1.306-0.871,2.262-1.654,3.091c-1.176,1.218-2.83,1.914-4.746,1.914
    c-2.352,0-4.049-0.871-5.268-2.305c-1.394-1.655-2.22-4.008-2.265-6.532h16.632c0-0.524,0.043-0.914,0.043-1.438
    C113.096,15.15,109.657,10.102,103.777,10.102z M96.466,20.068c0-4.311,2.828-7.92,7.312-7.92c4.44,0,7.011,3.788,7.097,7.92
    H96.466z'
        />
        <path
          fill={fillColor}
          d='M128.673,10.449c-3.047,0-5.963,1.611-6.923,4.616l-0.264-4.398h-1.913v21.767h2.177V21.984
    c0-4.57,1.48-9.446,6.923-9.446c0.695,0,1.437,0.043,2.132,0.219v-2.178C130.109,10.449,129.369,10.449,128.673,10.449z'
        />
        <path
          fill={fillColor}
          d='M2.075,11.652h6.361V8.91H3.377C2.856,9.771,2.419,10.69,2.075,11.652z'
        />
        <path
          fill={fillColor}
          d='M1.2,15.994h7.236v-2.742H1.598C1.386,14.14,1.25,15.055,1.2,15.994z'
        />
        <path
          fill={fillColor}
          d='M3.32,24.68h5.116v-2.742H2.042C2.381,22.9,2.81,23.816,3.32,24.68z'
        />
        <path
          fill={fillColor}
          d='M12.814,1.916c-0.967,0.242-1.896,0.576-2.781,0.991v18.34l2.781-2.927V1.916z'
        />
        <path
          fill={fillColor}
          d='M17.97,15.214l-1.779,1.874c0.536,0.449,1.046,0.932,1.524,1.443c3.168,3.376,4.881,7.753,4.829,12.322
    c-0.001,0.059-0.004,0.116-0.004,0.172c0.695-0.293,1.365-0.635,2.004-1.023c0.007-0.215,0.011-0.43,0.011-0.645
    c0-2.969-0.685-5.808-2.036-8.435C21.401,18.752,19.841,16.799,17.97,15.214z'
        />
        <path
          fill={fillColor}
          d='M12.147,21.346L10.573,23c0.404,0.364,0.795,0.748,1.167,1.159c2.119,2.335,2.819,5.278,3.03,7.965
    c0.587,0.067,1.186,0.104,1.795,0.104c0.169,0,0.336-0.004,0.505-0.009c0.002-0.075,0.005-0.149,0.005-0.226
    c0.039-3.32-1.245-6.698-3.528-9.275C13.108,22.223,12.64,21.766,12.147,21.346z'
        />
        <path
          fill={fillColor}
          d='M15.089,18.249l-1.841,1.937c0.525,0.451,1.026,0.943,1.496,1.476c2.542,2.871,3.975,6.644,3.93,10.354
    c0,0.023-0.002,0.047-0.002,0.073c0.768-0.106,1.518-0.271,2.242-0.481c0.015-0.258,0.027-0.514,0.028-0.771
    c0.049-4.092-1.552-8.178-4.394-11.208C16.09,19.136,15.6,18.673,15.089,18.249z'
        />
        <path
          fill={fillColor}
          d='M1.576,20.337h6.86v-2.742H1.194C1.24,18.535,1.369,19.451,1.576,20.337z'
        />
        <path
          fill={fillColor}
          d='M10.034,24.691v6.086c0.957,0.449,1.965,0.802,3.014,1.048c-0.332-3.687-1.377-5.364-2.492-6.593
    C10.386,25.046,10.212,24.868,10.034,24.691z'
        />
        <path
          fill={fillColor}
          d='M21.572,2.291c-0.891-0.308-1.822-0.536-2.78-0.675v10.413l2.78-2.925V2.291z'
        />
        <path
          fill={fillColor}
          d='M21.054,15.994l7.884-8.299c-0.552-0.746-1.172-1.442-1.848-2.076l-8.017,8.436
    C19.775,14.656,20.438,15.304,21.054,15.994z'
        />
        <path
          fill={fillColor}
          d='M8.436,29.907v-3.626H4.41C5.525,27.714,6.89,28.943,8.436,29.907z'
        />
        <path
          fill={fillColor}
          d='M26.148,28.879c2.759-2.197,4.743-5.326,5.485-8.906l-5.753,6.055
    C26.037,26.963,26.126,27.915,26.148,28.879z'
        />
        <path
          fill={fillColor}
          d='M17.194,1.468c-0.208-0.008-0.417-0.014-0.628-0.014c-0.731,0-1.449,0.053-2.15,0.15v15.034l2.779-2.925
    L17.194,1.468L17.194,1.468z'
        />
        <path fill={fillColor} d='M4.483,7.31h3.953V3.777C6.923,4.719,5.586,5.916,4.483,7.31z' />
        <path
          fill={fillColor}
          d='M24.436,21.227c0.422,0.948,0.768,1.918,1.034,2.912l6.474-6.815c0.006-0.16,0.009-0.319,0.009-0.481
    c0-1.091-0.114-2.153-0.331-3.179L24.436,21.227z'
        />
        <path
          fill={fillColor}
          d='M23.681,19.701l7.446-7.84c-0.336-0.981-0.768-1.917-1.284-2.796l-7.765,8.174
    C22.669,18.02,23.207,18.843,23.681,19.701z'
        />
        <path
          fill={fillColor}
          d='M23.173,2.941v4.477l2.692-2.835C25.031,3.949,24.129,3.399,23.173,2.941z'
        />
      </g>
    </svg>
  )
}
