import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Table.module.scss'

/* Renders a table of data where data is just a collection of rows */
export function Table(props) {
  const { title, tableRowData, emptyMessage } = props

  const renderRows = () => {
    return tableRowData.map(row => <TableRow {...row} />)
  }

  const renderEmptyMessage = () => {
    return <span>{emptyMessage}</span>
  }
  return (
    <div className={styles.table}>
      {!!title && (
        <div className={styles.tableTitle}>
          <h1>{title}</h1>
        </div>
      )}
      <div>
        {!!tableRowData && tableRowData.length > 0 && renderRows()}
        {(!tableRowData || tableRowData.length === 0) && renderEmptyMessage()}
      </div>
    </div>
  )
}

Table.propTypes = {
  title: PropTypes.string,
  tableRowData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.node.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      tableCells: PropTypes.func,
    })
  ).isRequired,
  emptyMessage: PropTypes.string.isRequired,
}

export function TableWithCustomData(props) {
  const { title, emptyMessage, isEmpty } = props

  const renderEmptyMessage = () => {
    return <span>{emptyMessage}</span>
  }
  return (
    <div className={styles.table}>
      {!!title && (
        <div className={styles.tableTitle}>
          <h1>{title}</h1>
        </div>
      )}
      <div>{isEmpty && renderEmptyMessage()}</div>
      <div>{!isEmpty && props.children}</div>
    </div>
  )
}

export function TableGroupingPrimaryHeader({ id, text, children }) {
  return (
    <h2 id={id} className={`${styles.tableGroupingHeader} ${styles.tableGroupingPrimaryHeader}`}>
      {text}
      {children && <div>{children}</div>}
    </h2>
  )
}

export function TableGroupingSecondaryHeader({ id, text, children }) {
  return (
    <h3
      id={id}
      className={`h4 ${styles.tableGroupingHeader} ${styles.tableGroupingSecondaryHeader}`}
    >
      {text}
      {children && <div>{children}</div>}
    </h3>
  )
}

export function TableGroupingTernaryHeader({ id, text, children }) {
  return (
    <h4 id={id} className={`h3 ${styles.tableGroupingHeader} ${styles.tableGroupingTernaryHeader}`}>
      {text}
      {children && <div>{children}</div>}
    </h4>
  )
}

/* Renders one row of data with the title & subtitle left justified and the other cells filling in from the right */
export function TableRow(props) {
  const {
    title,
    subtitle,
    tableCells,
    primary,
    secondary,
    ternary,
    left,
    noBorder,
    withHighlights,
  } = props

  const className = classNames(styles.tableRow, {
    [styles.tableRowPrimary]: !!primary,
    [styles.tableRowSecondary]: !!secondary,
    [styles.tableRowTernary]: !!ternary,
    [styles.tableRowAlignedLeft]: !!left,
    [styles.tableRowNoBorder]: !!noBorder,
    [styles.tableRowWithHighlights]: !!withHighlights,
  })

  return (
    <div className={className}>
      {(title || subtitle) && (
        <div>
          {title && <span className={styles.tableRowTitle}>{title}</span>}
          {subtitle && (
            <React.Fragment>
              <br />
              <span className={styles.tableRowSubtitle}>{subtitle}</span>
            </React.Fragment>
          )}
        </div>
      )}
      {!!tableCells && <div className={styles.tableCells}>{tableCells()}</div>}
    </div>
  )
}

TableRow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string,
  tableCells: PropTypes.func,
}
