import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BulletedText, TreatmentDetails, TreatmentDetailsMap } from '../_shared'
import { BoundsCenter } from 'domains/Map/GeoJsonParser'
import styles from '../AerialImageryReport.module.scss'

export function StackedTrialFieldDetails({
  brandColor,
  location,
  crop,
  totalAcreage,
  productHeader,
  applicationDate,
  regions,
  season,
  mapIsLoaded,
  productLabel,
}) {
  const [county, setCounty] = useState('')
  const [state, setState] = useState('')

  useEffect(() => {
    async function fetchLocation(lat, lon) {
      await fetch(`https://geo.fcc.gov/api/census/area?lat=${lat}&lon=${lon}&format=json`, {
        method: 'GET',
      })
        .then(response => response.json())
        .then(({ results }) => {
          if (results && results.length > 0) {
            const { county_name, state_code } = results[0]
            setCounty(county_name)
            setState(state_code)
          }
        })
    }
    const { x, y } = BoundsCenter(season.field.bounds)
    fetchLocation(x, y)
  }, [season])

  return (
    <React.Fragment>
      <h2 className='h4' style={{ color: brandColor }}>
        RevX Field Details
      </h2>
      <div>
        {county && state && (
          <BulletedText brandColor={brandColor}>
            <div className={styles.highlight}>
              Location: {county}, {state}
            </div>
          </BulletedText>
        )}
        {crop && (
          <BulletedText brandColor={brandColor}>
            <div className={styles.highlight}>Crop: {crop}</div>
          </BulletedText>
        )}
        {totalAcreage && (
          <BulletedText brandColor={brandColor}>
            <div className={styles.highlight}>
              Total Acreage: {Number.parseFloat(totalAcreage).toFixed(2)} ac.
            </div>
          </BulletedText>
        )}
        {productHeader && (
          <BulletedText brandColor={brandColor}>
            <div className={styles.highlight}>
              {productLabel}: {productHeader}
            </div>
          </BulletedText>
        )}
        {applicationDate && (
          <BulletedText brandColor={brandColor}>
            <div className={styles.highlight}>Application Date: {applicationDate}</div>
          </BulletedText>
        )}
        {regions && regions.length > 0 && (
          <React.Fragment>
            <BulletedText brandColor={brandColor}>
              <div className={styles.highlight}>Competitive Treatment(s): </div>
            </BulletedText>
            <TreatmentDetails regions={regions} />
            <TreatmentDetailsMap regions={regions} season={season} mapIsLoaded={mapIsLoaded} />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

StackedTrialFieldDetails.propTypes = {
  season: PropTypes.object.isRequired,
  regions: PropTypes.array,
  brandColor: PropTypes.string.isRequired,
  totalAcreage: PropTypes.number.isRequired,
  productHeader: PropTypes.string.isRequired,
  crop: PropTypes.string.isRequired,
  location: PropTypes.string,
  applicationDate: PropTypes.string,
}
