import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isFieldLoaded, getField, updateField, deleteField } from 'redux/modules/fields'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import swal from '@sweetalert/with-react'
import { SidePanel, ContextHeader } from '../_shared'
import EditFieldForm from './EditFieldForm'
import EditFieldMap from './EditFieldMap'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch, getState } = options.store
      const { params } = options

      if (params && params.fieldId) {
        if (!isFieldLoaded(getState(), params.fieldId)) {
          promises.push(dispatch(getField(params.fieldId)))
        }
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    field: state.fields.field.data,
  }),
  {
    updateField,
    deleteField,
  }
)

class EditFieldComponent extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    updateField: PropTypes.func.isRequired,
    deleteField: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleCloseEditForm = this.handleCloseEditForm.bind(this)
    this.handleEditPolygon = this.handleEditPolygon.bind(this)
    this.handleFieldDelete = this.handleFieldDelete.bind(this)
    this.fieldPolygon = {}
    this.state = {}
  }

  handleEditPolygon(fieldObj) {
    this.fieldPolygon = {
      ...fieldObj,
    }
  }

  handleCloseEditForm() {
    window.location = `/farm/${this.props.params.farmId}/field/${this.props.field.id}`
  }

  handleFieldSubmit = async data => {
    this.setState({
      isDisabled: true,
    })

    const requestParams = {
      fieldTitle: data.fieldTitle,
      polygon: this.fieldPolygon.polygon,
      bounds: this.fieldPolygon.bounds,
      size: this.fieldPolygon.size,
      fieldId: this.props.field.id,
    }

    try {
      await this.props.updateField(requestParams)
      this.setState({ isDisabled: false })

      await swal({
        title: 'Great, your field has been updated!',
        icon: 'success',
        closeModal: true,
      })

      this.handleCloseEditForm()
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeModal: true,
      })
      window.location.reload()
    }
  }

  handleFieldDelete = async () => {
    try {
      await this.props.deleteField(this.props.field.id)

      await swal({
        title: 'Great, your field has been deleted!',
        icon: 'success',
        closeModal: true,
      })
      window.location = `/farm/${this.props.params.farmId}`
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeModal: true,
      })
      window.location.reload()
    }
  }

  render() {
    const { field } = this.props
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Helmet title='Edit Field' />
        <div className='Sidebar'>
          <SidePanel background={false}>
            <ContextHeader text={field.title} handleBackEvent={this.handleCloseEditForm} />
            <EditFieldForm
              onSubmit={this.handleFieldSubmit}
              handleDelete={this.handleFieldDelete}
              isDisabled={this.state.isDisabled}
            />
          </SidePanel>
        </div>
        <div className='Map'>
          <EditFieldMap setEditFieldPolygon={this.handleEditPolygon} />
        </div>
      </div>
    )
  }
}

export default asyncConnectWrapper(connectWrapper(EditFieldComponent))
