import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Alert, Button } from 'react-bootstrap'
import { TextInput } from '_shared'
import styles from './Login.module.scss'

export function LoginForm({ onSubmit, formErrors, loggingIn, handleAlertDismiss }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const rightArrow = (
    <svg
      version='1.1'
      x='0px'
      y='0px'
      width='10px'
      height='15px'
      viewBox='0 0 10 15'
      enableBackground='new 0 0 10 15'
      xmlSpace='preserve'
    >
      <polyline fill='none' stroke='#FFFFFF' strokeMiterlimit='10' points='2,2 7.5,7.5 2,13  ' />
    </svg>
  )

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit({ email, password })
  }

  return (
    <form className={`${styles.loginForm} form-group`} onSubmit={handleSubmit}>
      <div className={styles.loginWindowBody}>
        {formErrors &&
          formErrors.map(error => {
            return (
              <Alert variant='danger' key={error.code} onDismiss={() => handleAlertDismiss(error)}>
                {error.message}
              </Alert>
            )
          })}
        <TextInput
          label='Email address'
          name='email'
          type='email'
          placeholder='Email address'
          disabled={loggingIn}
          required
          onChange={e => setEmail(e.target.value)}
        />
        <TextInput
          label='Password'
          name='password'
          type='password'
          placeholder='Password'
          disabled={loggingIn}
          required
          onChange={e => setPassword(e.target.value)}
        />

        <Link to='/forgot-password' className={styles.forgotPasswordLink}>
          Forgot password? {rightArrow}
        </Link>
      </div>

      <div className={styles.submitButtonWrapper}>
        <Button type='submit' block variant='primary' size='lg' disabled={loggingIn}>
          LOG IN
        </Button>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  formErrors: PropTypes.array,
  loggingIn: PropTypes.bool,
  handleAlertDismiss: PropTypes.func,
}
