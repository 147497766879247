import React from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'

import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { getTasksWithUnreviewedImages } from 'redux/modules/imagereview'

import { LoadingState } from '_shared'
import { CustomerAccountDropdown } from 'domains'
import { ImageReviewerSummary } from './ImageReviewerSummary'

import tableStyles from '_shared/Table/Table.module.scss'
import styles from '../ImageReviewer.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {},
  },
])

const connectWrapper = connect(state => ({
  user: state.auth.user,
  tasks: state.imagereview.tasks,
  dataErrors: state.imagereview.tasks.error,
}))

function ImageReviewerSummaryContainer({ tasks, dispatch }) {
  const onCustomerAccountChange = (customerAccountId = null) => {
    dispatch(getTasksWithUnreviewedImages(customerAccountId))
  }

  return (
    <div className={`container ${styles.imageReviewerContainer}`}>
      <Helmet title='Image Reviewer Summary' />
      <div className={`container ${styles.summaryWrapper}`}>
        <div className={tableStyles.tableTitle}>
          <h1>Image Reviewer Summary</h1>
          <CustomerAccountDropdown
            onCustomerAccountChange={onCustomerAccountChange}
            containerClassName={styles.customerAccountDropdownContainer}
            dropdownClassName={styles.customerAccountDropdown}
          />
        </div>
        {tasks.loaded && tasks.data ? (
          <ImageReviewerSummary tasks={tasks.data} />
        ) : (
          <LoadingState />
        )}
      </div>
    </div>
  )
}

export default asyncConnectWrapper(connectWrapper(ImageReviewerSummaryContainer))

ImageReviewerSummaryContainer.propTypes = {
  user: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
}
