/* eslint react/jsx-no-target-blank: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, change } from 'redux-form'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { ReduxFormInput } from '../_shared'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { FormattedNumber, FormattedDate } from 'react-intl'
import { DateUtils } from 'react-day-picker'
import { FaInfoCircle, FaSpinner } from 'react-icons/fa'
import { required } from 'helpers'
import { DayRangePicker } from './DayRangePicker'
import styles from './CreateOrderForm.module.scss'

const connectWrapper = connect(state => ({
  field: state.fields.field.data,
  services: state.services.services.data,
  activeSeason: state.seasons.activeSeason.data,
}))

export class CreateOrderFormComponent extends Component {
  constructor(props) {
    super(props)

    this.openServiceModal = this.openServiceModal.bind(this)
    this.closeServiceModal = this.closeServiceModal.bind(this)

    this.state = {
      showServiceModal: false,
      to: undefined,
      from: undefined,
    }
  }

  openServiceModal() {
    this.setState({
      showServiceModal: true,
    })
  }

  closeServiceModal() {
    this.setState({
      showServiceModal: false,
    })
  }

  handleDayClick = (day, modifiers) => {
    if (modifiers.disabled) {
      return
    }
    const { from, to } = DateUtils.addDayToRange(day, this.state)
    this.props.dispatch(change('CreateOrderForm', 'requestedStartDate', from))
    this.props.dispatch(change('CreateOrderForm', 'requestedEndDate', to))
    this.setState({ from, to })
  }

  beforeDate(day) {
    const today = new Date()
    const firstAvailableDay = new Date(today.setDate(today.getDate() + 8))
    return DateUtils.isDayBefore(day, firstAvailableDay)
  }

  render() {
    const {
      services,
      handleSubmit,
      helperText,
      previousStep,
      showSeasonInfo,
      activeSeason,
      isDisabled,
    } = this.props

    const { from, to } = this.state
    const modifiers = { start: from, end: to }

    const serviceOptions = services.map(service => {
      return (
        <option key={service.id} value={service.id}>
          {' '}
          {service.name}{' '}
        </option>
      )
    })

    const orderProducts = services.map(service => {
      const key = service.shortName
      const buttonProps = {}
      if (service.shortName === 'phc') {
        buttonProps.variant = 'primary'
      }

      const ListClass = classNames({
        productListItem: true,
        primaryListItem: service.shortName === 'phc',
      })

      return (
        <li className={ListClass} key={key}>
          <h5 className={styles.productTitle}>{service.name}</h5>
          <p className={styles.productDescription}>{service.description}</p>
          <p>
            <a href={service.descriptionUrl} target='_blank'>
              More information
            </a>
          </p>
          <Row>
            <Col xs={6}>
              <div>
                <dl className={styles.productMeta}>
                  <di className={styles.productMetaItem}>
                    <dt className={styles.productMetaKey}>Cost</dt>
                    <dd className={styles.productMetaValue}>
                      {service.cost ? (
                        <FormattedNumber value={service.cost} style={`currency`} currency='USD' />
                      ) : (
                        '-'
                      )}
                      /acre
                    </dd>
                  </di>
                </dl>
              </div>
            </Col>
          </Row>
        </li>
      )
    })

    return (
      <div className={styles.CreateOrderForm}>
        <div className={styles.headingWrapper}>
          <h1 className={styles.heading}>Create Order</h1>
        </div>

        <form className={classNames(styles.createOrderForm, 'form-horizontal')}>
          {helperText && <h6 className={styles.helperText}>{helperText}</h6>}
          {showSeasonInfo && (
            <div className={classNames('row', styles.seasonInfoWrapper)}>
              <div className={classNames('col-6', 'season-info')}>
                <b>Crop:</b> {activeSeason.crop.name}
              </div>
              <div className={classNames('col-6', 'plant-date')}>
                <b>Plant Date:</b>
                {activeSeason.plantDate ? <FormattedDate value={activeSeason.plantDate} /> : '-'}
              </div>
            </div>
          )}
          <div className={classNames(styles.inputWithServicesButtonContainer, 'col-12')}>
            <div className={classNames(styles.createOrderInputWrapper, 'col-8')}>
              <label htmlFor='serviceType' className={styles.inputLabel}>
                Select Imagery Service
              </label>
              <ReduxFormInput
                fieldName={'serviceType'}
                type={'select'}
                required={true}
                validate={required}
              >
                {({ errorClassName, ...reduxInput }) => (
                  <Form.Control
                    key='serviceType'
                    id='serviceType'
                    as='select'
                    disabled={false}
                    className={classNames(errorClassName, { [styles.createOrderInput]: true })}
                    {...reduxInput}
                  >
                    <option value={''}> Select Imagery Service </option>
                    {serviceOptions}
                  </Form.Control>
                )}
              </ReduxFormInput>
            </div>
            <div className={classNames(styles.servicesButtonContainer, 'col-4')}>
              <Button
                variant='outline-primary'
                className='servicesButton'
                onClick={this.openServiceModal}
              >
                <FaInfoCircle />
              </Button>
            </div>
          </div>
          <label htmlFor='specialInstructions' className={styles.inputLabel}>
            Special Instructions
          </label>
          <ReduxFormInput fieldName={'specialInstructions'} type={'textarea'}>
            {({ errorClassName, ...reduxInput }) => (
              <Form.Control
                as='textarea'
                key='specialInstructions'
                id='specialInstructions'
                placeholder='Special Instructions'
                className={styles.createOrderInput}
                {...reduxInput}
              />
            )}
          </ReduxFormInput>
          <div className={styles.requestedStartDateWrapper}>
            <label htmlFor='requestedStartDate' className={styles.inputLabel}>
              Schedule Date
            </label>
            <ReduxFormInput
              fieldName={'requestedStartDate'}
              type={'text'}
              required={true}
              validate={required}
            >
              {({ errorClassName, ...reduxInput }) => (
                <DayRangePicker
                  {...reduxInput}
                  key='requestedDates'
                  id='requestedDates'
                  beforeDate={this.beforeDate}
                  handleDayClick={this.handleDayClick}
                  modifiers={modifiers}
                  from={from}
                  to={to}
                />
              )}
            </ReduxFormInput>
          </div>
          <div className='row'>
            {previousStep && (
              <div className={classNames('col-4', 'previous-step')}>
                <Button
                  groupClassName={styles.createOrderFormGroup}
                  className={styles.button}
                  onClick={previousStep}
                >
                  Back
                </Button>
              </div>
            )}
            <div
              className={classNames('submit-container', {
                'col-8': previousStep,
                'col-12': !previousStep,
              })}
            >
              <Button
                type='submit'
                variant='primary'
                className={styles.button}
                onClick={handleSubmit}
                disabled={isDisabled}
              >
                {isDisabled && <FaSpinner />}
                Schedule
              </Button>
            </div>
          </div>
        </form>

        {services && (
          <Modal
            show={this.state.showServiceModal}
            onHide={this.closeServiceModal}
            onExited={this.closeServiceModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className={styles.orderServiceModalTitle}>Order a service</Modal.Title>
              <p className={styles.orderServiceModalDescription}>
                Once you place an order one of our Aker Agents will be in contact to assist you with
                scheduling. Rest easy your field is in good hands.
              </p>
            </Modal.Header>
            <Modal.Body>
              <Button onClick={this.closeServiceModal}>Go back to field list</Button>
            </Modal.Body>
            <Modal.Body className={styles.orderServiceModalBody}>
              <ul className={styles.productList}>{orderProducts}</ul>
            </Modal.Body>
          </Modal>
        )}
      </div>
    )
  }
}

CreateOrderFormComponent.propTypes = {
  field: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  previousStep: PropTypes.func,
  showSeasonInfo: PropTypes.bool,
  activeSeason: PropTypes.object,
  isDisabled: PropTypes.bool,
}

export default reduxForm({
  form: 'CreateOrderForm',
  touchOnBlur: true,
})(connectWrapper(CreateOrderFormComponent))
