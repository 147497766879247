import React from 'react'
import PropTypes from 'prop-types'
import { GeometryLayer } from './GeometryLayer'

export function FieldBoundaryLayer({ map, geometry, outlineColor }) {
  return <GeometryLayer map={map} geometry={geometry} outlineColor={outlineColor} />
}

FieldBoundaryLayer.propTypes = {
  map: PropTypes.object,
}
