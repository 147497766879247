import React from 'react'
import { Provider } from 'react-redux'
import { Router, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { ReduxAsyncConnect } from 'redux-connect'
// import 'babel/polyfill';
import useScroll from 'scroll-behavior/lib/useStandardScroll'
import ApiClient from './helpers/ApiClient'
import createStore from './redux/create'
import getRoutes from './routes'
import { CropsProvider, CustomerAccountsProvider, ErrorBoundary } from './domains'
import './App.scss'

require('leaflet/dist/leaflet.css')
require('leaflet-draw/dist/leaflet.draw.css')
require('react-day-picker/lib/style.css')

function App() {
  const _browserHistory = useScroll(() => browserHistory)()
  const client = new ApiClient()
  const store = createStore(_browserHistory, client, {})
  const history = syncHistoryWithStore(_browserHistory, store)

  
  return (
    <ErrorBoundary>
      <Provider store={store} key='provider'>
        <CropsProvider>
          <CustomerAccountsProvider>
            <Router
              history={history}
              render={props => (
                <ReduxAsyncConnect
                  {...props}
                  helpers={{ client }}
                  filter={item => !item.deferred}
                />
              )}
            >
              {getRoutes(store)}
            </Router>
          </CustomerAccountsProvider>
        </CropsProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
