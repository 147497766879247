import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'
import { ListItem } from '_shared'

export function FarmListItem({ farm }) {
  const onlyOneField = farm.fieldCount === 1 && farm.fields[0].id
  const url = onlyOneField
    ? `/farm/${farm.id}/field/${farm.fields[0].id}` // link directly to field
    : `/farm/${farm.id}` // Default

  return (
    <ListItem
      key={farm.id}
      id={farm.id}
      title={farm.title}
      info={farm.actionRequired ? <span /> : null}
      toUrl={url}
      text={
        <React.Fragment>
          <p>{farm.customerAccountName}</p>
          <span>
            <FormattedNumber value={farm.fieldCount || 0} />
            {farm.fieldCount === 1 ? ' field, ' : ' fields, '}
            <FormattedNumber value={farm.totalAcres || 0} /> acres
          </span>
        </React.Fragment>
      }
    />
  )
}

FarmListItem.propTypes = {
  farm: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    actionRequired: PropTypes.bool,
    customerAccountName: PropTypes.string.isRequired,
    fieldCount: PropTypes.number,
    totalAcres: PropTypes.number,
    url: PropTypes.string,
  }).isRequired,
}
