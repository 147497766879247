import { useState, useEffect } from 'react'
import Leaflet from 'leaflet'

const basePath = 'https://api.mapbox.com/styles/v1/'
const accessToken =
  'pk.eyJ1IjoiYWtlcmFnIiwiYSI6ImNqazczOTNqNzI1YXMzcW50MHF3NTg3dDMifQ.mQ7ADlqYrVVwJVO9cWqiKQ'
let layerUrl = `${basePath}{id}/{z}/{x}/{y}?access_token=${accessToken}`
if (window && window.devicePixelRatio && window.devicePixelRatio > 1) {
  layerUrl = `${basePath}{id}/{z}/{x}/{y}?access_token=${accessToken}`
}

export function StreetsLayer({ map, streetsEnabled }) {
  const [streets, setStreets] = useState(null)
  const streetsLayer = Leaflet.tileLayer(layerUrl, {
    id: 'akerag/ckg8g8f9c1pw119pdh5ugfbpu/tiles',
    maxZoom: 20,
    maxNativeZoom: 19,
    tileSize: 512,
    zoomOffset: -1
  })

  useEffect(() => {
    if (!map) return

    if (streetsEnabled) {
      try {
        map.addLayer(streetsLayer)
        streetsLayer.bringToBack()
        setStreets(streetsLayer)
      } catch (err) {
        console.log('LeafletMap addLayer Error:', err)
      }
    } else {
      try {
        if (!!streets) {
          map.removeLayer(streets)
        }
        setStreets(null)
      } catch (err) {
        console.log('LeafletMap removeLayer Error:', err)
      }
    }
  }, [map, streetsEnabled]) // eslint-disable-line react-hooks/exhaustive-deps
  return null
}
