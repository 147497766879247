import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { isCropsLoaded, getCrops } from 'redux/modules/crops'
import { isFieldLoaded, getField } from 'redux/modules/fields'
import { areServicesLoaded, getServices } from 'redux/modules/services'
import { createOrder } from 'redux/modules/farmsandfields'
import { SidePanel, ContextHeader } from '../_shared'
import CreateSeasonForm from '../CreateSeason/CreateSeasonForm'
import CreateOrderForm from './CreateOrderForm'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch, getState } = options.store
      const { params } = options
      if (params && params.fieldId) {
        if (!isFieldLoaded(getState(), params.fieldId)) {
          promises.push(dispatch(getField(params.fieldId)))
        }
      }

      if (!isCropsLoaded(getState())) {
        promises.push(dispatch(getCrops()))
      }

      // Add one call for the services select
      if (!areServicesLoaded(getState())) {
        promises.push(dispatch(getServices()))
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    field: state.fields.field.data,
    services: state.services.services,
    crops: state.crops.crops.data,
  }),
  { createOrder }
)
export class CreateOrderComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      layout: this.hasSeasons() ? 'createOrder' : 'createSeason',
      isNewSeason: false,
      isDisabled: false,
    }
  }

  handleOrderSubmit = async data => {
    const that = this // Wut.
    this.setState({ isDisabled: true })

    try {
      // TODO: remove hardcoded timing id once backoffice changes are made.
      // id 3 corresponds to the "Scheduled" FlightTiming instance
      await this.props.createOrder({
        fieldId: this.props.field.id,
        serviceId: data.serviceType,
        specialInstructions: data.specialInstructions,
        timing: '3',
        requestedStartDate: data.requestedStartDate,
        requestedEndDate: data.requestedEndDate,
        cropId: this.state.cropType,
        plantDate: this.state.plantDate,
        isNewSeason: this.state.isNewSeason,
      })

      const result = await swal({
        title: 'Great, your order is being processed!',
        type: 'success',
        closeOnConfirm: true,
      })
      result && that.handleCloseOrderForm()
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        type: 'error',
        closeOnConfirm: true,
      })
      window.location.reload()
    }
  }

  handleCloseOrderForm = () => {
    window.location = `${window.location.origin}/farms/${this.props.params.farmId}/field/${this.props.params.fieldId}`
  }

  handleSeasonSubmit = data => {
    this.setState({
      layout: 'createOrder',
      cropType: data.cropType,
      plantDate: data.plantDate,
      isNewSeason: true,
    })
  }

  navigateToCreateSeason = () => {
    this.setState({
      layout: 'createSeason',
    })
  }

  hasSeasons = () => {
    const { field } = this.props

    return field.seasons && field.seasons.length > 0
  }

  render() {
    const field = this.props.field || {}
    const { layout, isDisabled } = this.state

    const renderLayout = () => {
      if (this.hasSeasons()) {
        return (
          <CreateOrderForm
            showSeasonInfo
            onSubmit={this.handleOrderSubmit}
            isDisabled={isDisabled}
          />
        )
      }
      switch (layout) {
        case 'createSeason':
          return (
            <CreateSeasonForm
              onSubmit={this.handleSeasonSubmit}
              submitButtonText='Next Step'
              helperText='1) Create Season'
              headerText='Create Order'
            />
          )
        default:
          return (
            <CreateOrderForm
              onSubmit={this.handleOrderSubmit}
              helperText='2) Schedule Order'
              previousStep={this.navigateToCreateSeason}
              isDisabled={isDisabled}
            />
          )
      }
    }
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Helmet title='Create Order' />
        <SidePanel background={false}>
          <ContextHeader text={field.title} handleBackEvent={this.handleCloseOrderForm} />
          {renderLayout()}
        </SidePanel>
      </div>
    )
  }
}

CreateOrderComponent.propTypes = {
  field: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
  createOrder: PropTypes.func.isRequired,
}

export default asyncConnectWrapper(connectWrapper(CreateOrderComponent))
