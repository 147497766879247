const LOAD = 'aker/auth/LOAD'
export const LOAD_SUCCESS = 'aker/auth/LOAD_SUCCESS'
const LOAD_FAIL = 'aker/auth/LOAD_FAIL'

const LOGIN = 'aker/auth/LOGIN'
export const LOGIN_SUCCESS = 'aker/auth/LOGIN_SUCCESS'
export const LOGIN_FAIL = 'aker/auth/LOGIN_FAIL'

export const LOGOUT = 'aker/auth/LOGOUT'
export const LOGOUT_SUCCESS = 'aker/auth/LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'aker/auth/LOGOUT_FAIL'

const RESETPASSWORD = 'aker/auth/RESETPASSWORD'
const RESETPASSWORD_SUCCESS = 'aker/auth/RESETPASSWORD_SUCCESS'
const RESETPASSWORD_FAIL = 'aker/auth/RESETPASSWORD_FAIL'

const CHANGEPASSWORD = 'aker/auth/CHANGEPASSWORD'
const CHANGEPASSWORD_SUCCESS = 'aker/auth/CHANGEPASSWORD_SUCCESS'
const CHANGEPASSWORD_FAIL = 'aker/auth/CHANGEPASSWORD_FAIL'

const CLEAR_ALL_ERRORS = 'aker/auth/CLEAR_ALL_ERRORS'
const CLEAR_ERROR = 'aker/auth/CLEAR_ERROR'
const CLEAR_PASSWORD_SUCCESS = 'aker/auth/CLEAR_PASSWORD_SUCCESS'

const initialState = {
  user: null,
}

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        error: undefined, // Clear errors
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result.data,
      }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      }

    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        errors: undefined, // Clear errors
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        user: { ...action.result.data, confirmed: true }, // Mark confirmed if success
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        errors: action.error.errors,
      }

    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
        errors: undefined, // Clear errors
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null,
      }
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        user: null, // Ignore logout errors
        // errors: action.error.errors,
      }

    case RESETPASSWORD:
      return {
        ...state,
        resettingPassword: true,
        errors: undefined, // Clear errors
      }
    case RESETPASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        resetSuccess: action.result.data,
      }
    case RESETPASSWORD_FAIL:
      return {
        ...state,
        resettingPassword: false,
        errors: action.error.errors,
      }

    case CHANGEPASSWORD:
      return {
        ...state,
        changingPassword: true,
        errors: undefined, // Clear errors
      }
    case CHANGEPASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changeSuccess: action.result.data,
      }
    case CHANGEPASSWORD_FAIL:
      return {
        ...state,
        changingPassword: false,
        errors: action.error.errors,
      }

    case CLEAR_PASSWORD_SUCCESS:
      return {
        ...state,
        resetSuccess: undefined,
        changeSuccess: undefined,
      }
    case CLEAR_ALL_ERRORS:
      return {
        ...state,
        errors: [],
      }

    case CLEAR_ERROR:
      return {
        ...state,
        errors: state.errors && [...state.errors.filter(error => error.code !== action.error.code)],
      }

    case 'aker/auth/CHANGE_USER_ROLE':
      return {
        ...state,
        user: {
          ...state.user,
          role: action.role,
        },
      }
    default:
      return state
  }
}

export function isAuthLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded
}

export function loadAuth() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/authentication'),
  }
}

export function login(data) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: client =>
      client.post('/login', {
        data: {
          data,
        },
      }),
  }
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: client => client.get('/logout'),
  }
}

export function resetPassword(data) {
  return {
    types: [RESETPASSWORD, RESETPASSWORD_SUCCESS, RESETPASSWORD_FAIL],
    promise: client =>
      client.post('/password-reset-email', {
        data: {
          data,
        },
      }),
  }
}

export function changePassword(data) {
  return {
    types: [CHANGEPASSWORD, CHANGEPASSWORD_SUCCESS, CHANGEPASSWORD_FAIL],
    promise: client =>
      client.post('/change-password', {
        data: {
          data,
        },
      }),
  }
}

export function clearAllErrors() {
  return {
    type: CLEAR_ALL_ERRORS,
  }
}
export function clearError(error) {
  return {
    type: CLEAR_ERROR,
    error,
  }
}
export function clearPasswordSuccess() {
  return {
    type: CLEAR_PASSWORD_SUCCESS,
  }
}

export function signUp(data) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: client =>
      client.post('/users', {
        data: {
          data,
        },
      }),
  }
}
