const GET_CUSTOMER_ACCOUNTS = 'aker/CUSTOMER_ACCOUNTS/GET_CUSTOMER_ACCOUNTS'
const GET_CUSTOMER_ACCOUNTS_SUCCESS = 'aker/CUSTOMER_ACCOUNTS/GET_CUSTOMER_ACCOUNTS_SUCCESS'
const GET_CUSTOMER_ACCOUNTS_FAIL = 'aker/CUSTOMER_ACCOUNTS/GET_CUSTOMER_ACCOUNTS_FAIL'

const SET_SELECTED_CUSTOMER_ACCOUNT = 'aker/CUSTOMER_ACCOUNTS/SET_SELECTED_CUSTOMER_ACCOUNT'
const SET_SELECTED_CUSTOMER_ACCOUNT_SUCCESS =
  'aker/CUSTOMER_ACCOUNTS/SET_SELECTED_CUSTOMER_ACCOUNT_SUCCESS'
const SET_SELECTED_CUSTOMER_ACCOUNT_FAIL =
  'aker/CUSTOMER_ACCOUNTS/SET_SELECTED_CUSTOMER_ACCOUNT_FAIL'

const UPDATE_CUSTOMER_ACCOUNT_SETTINGS = 'aker/ACCOUNT/UPDATE_CUSTOMER_ACCOUNT_SETTINGS'
const UPDATE_CUSTOMER_ACCOUNT_SETTINGS_SUCCESS =
  'aker/ACCOUNT/UPDATE_CUSTOMER_ACCOUNT_SETTINGS_SUCCESS'
const UPDATE_CUSTOMER_ACCOUNT_SETTINGS_FAIL = 'aker/ACCOUNT/UPDATE_CUSTOMER_ACCOUNT_SETTINGS_FAIL'

const CLEAR_UPDATE_SUCCESS = 'aker/ACCOUNT/CLEAR_UPDATE_SUCCESS'
const CLEAR_ALL_ERRORS = 'aker/ACCOUNT/CLEAR_ALL_ERRORS'
const CLEAR_ERROR = 'aker/ACCOUNT/CLEAR_ERROR'

const initialState = {
  customerAccounts: {
    loaded: false,
  },
}

export default function customerAccounts(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CUSTOMER_ACCOUNTS:
      return {
        ...state,
        customerAccounts: {
          ...state.customerAccounts,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_CUSTOMER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        customerAccounts: {
          ...state.customerAccounts,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_CUSTOMER_ACCOUNTS_FAIL:
      return {
        ...state,
        customerAccounts: {
          ...state.customerAccounts,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case SET_SELECTED_CUSTOMER_ACCOUNT:
      return {
        ...state,
        selectedCustomerAccount: {},
      }
    case SET_SELECTED_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        selectedCustomerAccount: state.customerAccounts.data.find(ca => ca.id === action.result),
      }
    case SET_SELECTED_CUSTOMER_ACCOUNT_FAIL:
      return {
        ...state,
        selectedCustomerAccount: { ...state.selectedCustomerAccount },
      }
    case UPDATE_CUSTOMER_ACCOUNT_SETTINGS:
      return {
        ...state,
        updating: true,
        errors: undefined, // Clear errors
      }
    case UPDATE_CUSTOMER_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        updating: false,
        isUpdated: true,
        customerAccount: action.result.data,
      }
    case UPDATE_CUSTOMER_ACCOUNT_SETTINGS_FAIL:
      return {
        ...state,
        updating: false,
        isUpdated: false,
        errors: action.error.errors,
      }

    // for use on the CustomerAccountSettingsForm
    case CLEAR_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdated: false,
      }
    case CLEAR_ALL_ERRORS:
      return {
        ...state,
        errors: [],
        addErrors: [],
      }
    case CLEAR_ERROR:
      return {
        ...state,
        errors: state.errors && [...state.errors.filter(error => error.code !== action.error.code)],
        addErrors: state.addErrors && [
          ...state.addErrors.filter(error => error.code !== action.error.code),
        ],
      }
    default:
      return state
  }
}

export function getCustomerAccounts() {
  return {
    types: [GET_CUSTOMER_ACCOUNTS, GET_CUSTOMER_ACCOUNTS_SUCCESS, GET_CUSTOMER_ACCOUNTS_FAIL],
    promise: client => client.get('/customer_accounts'),
  }
}

export function setSelectedCustomerAccount(customerAccountId) {
  return {
    types: [
      SET_SELECTED_CUSTOMER_ACCOUNT,
      SET_SELECTED_CUSTOMER_ACCOUNT_SUCCESS,
      SET_SELECTED_CUSTOMER_ACCOUNT_FAIL,
    ],
    promise: () => Promise.resolve(customerAccountId),
  }
}

export function updateCustomerAccountSettings(customerAccountId, data) {
  return {
    types: [
      UPDATE_CUSTOMER_ACCOUNT_SETTINGS,
      UPDATE_CUSTOMER_ACCOUNT_SETTINGS_SUCCESS,
      UPDATE_CUSTOMER_ACCOUNT_SETTINGS_FAIL,
    ],
    promise: client =>
      client.put(`/customer_accounts/${customerAccountId}`, {
        data: {
          data,
        },
      }),
  }
}

export function clearUpdateSuccess() {
  return {
    type: CLEAR_UPDATE_SUCCESS,
  }
}
export function clearAllErrors() {
  return {
    type: CLEAR_ALL_ERRORS,
  }
}
export function clearError(error) {
  return {
    type: CLEAR_ERROR,
    error,
  }
}
