import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { ReportRows } from './ReportRows'
import { TrialRows } from './TrialRows'
import { TableGroupingSecondaryHeader, TableGroupingTernaryHeader } from '_shared'
import styles from './Reports.module.scss'

export function SeasonsReportsAndTrials({ seasonData, title, farmId, deleteReport, deleteTrial }) {
  const permissions = seasonData.permissions || {}
  const addReportLinkHref = `/farm/${farmId}/field/${seasonData.field.id}/season/${seasonData.id}/aerial-imagery-report/new`
  // const addTrialLinkHref = `/farm/${farmId}/field/${seasonData.field.id}/season/${seasonData.id}/as-applied-maps`
  const anchor = title.replace(/\W/g, '')

  return (
    <React.Fragment>
      <TableGroupingSecondaryHeader text={title} id={anchor} />
      <TableGroupingTernaryHeader text='Reports'>
        {permissions.canManage && (
          <Link to={addReportLinkHref} className={styles.addLink}>
            Add new report
          </Link>
        )}
      </TableGroupingTernaryHeader>
      <ReportRows season={seasonData} reports={seasonData.reports} deleteReport={deleteReport} />

      {seasonData.trials && seasonData.trials.length > 0 && (
        <TableGroupingTernaryHeader text='Applied Maps'>
          {/* {permissions.canManage && (
          <Link to={addTrialLinkHref} className={styles.addLink}>
            Add new applied map
          </Link>
        )} */}
        </TableGroupingTernaryHeader>
      )}
      <TrialRows trials={seasonData.trials} deleteTrial={deleteTrial} />
    </React.Fragment>
  )
}

SeasonsReportsAndTrials.propTypes = {
  farmId: PropTypes.string,
  seasonData: PropTypes.object,
  title: PropTypes.string,
  deleteReport: PropTypes.func,
  deleteTrial: PropTypes.func,
}
