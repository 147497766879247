const GET_FLIGHTS_BY_SEASON = 'aker/FLIGHTS/GET_FLIGHTS_BY_SEASON'
const GET_FLIGHTS_BY_SEASON_SUCCESS = 'aker/FLIGHTS/GET_FLIGHTS_BY_SEASON_SUCCESS'
const GET_FLIGHTS_BY_SEASON_FAIL = 'aker/FLIGHTS/GET_FLIGHTS_BY_SEASON_FAIL'

const GET_FLIGHT = 'aker/FLIGHTS/GET_FLIGHT'
const GET_FLIGHT_SUCCESS = 'aker/FLIGHTS/GET_FLIGHT_SUCCESS'
const GET_FLIGHT_FAIL = 'aker/FLIGHTS/GET_FLIGHT_FAIL'

const initialState = {
  flightsBySeason: {
    loaded: false,
  },
  flight: {
    loaded: false,
  },
}

export default function flights(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FLIGHTS_BY_SEASON:
      return {
        ...state,
        flightsBySeason: {
          ...state.flightsBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_FLIGHTS_BY_SEASON_SUCCESS:
      return {
        ...state,
        flightsBySeason: {
          ...state.flightsBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_FLIGHTS_BY_SEASON_FAIL:
      return {
        ...state,
        flightsBySeason: {
          ...state.flightsBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_FLIGHT:
      return {
        ...state,
        flight: {
          ...state.flight,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_FLIGHT_SUCCESS:
      return {
        ...state,
        flight: {
          ...state.flight,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_FLIGHT_FAIL:
      return {
        ...state,
        flight: {
          ...state.flight,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    default:
      return state
  }
}

export function getFlightsBySeasonId(seasonId) {
  return {
    types: [GET_FLIGHTS_BY_SEASON, GET_FLIGHTS_BY_SEASON_SUCCESS, GET_FLIGHTS_BY_SEASON_FAIL],
    promise: client => client.get(`/seasons/${seasonId}/flights`),
  }
}

export function getFlight(flightId) {
  return {
    types: [GET_FLIGHT, GET_FLIGHT_SUCCESS, GET_FLIGHT_FAIL],
    promise: client => client.get(`/flights/${flightId}`),
  }
}
