import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { SectionButtonContainer } from './SectionButtonContainer'
import { UserAccessList } from '../UserAccessList'

export const RemoveAccessSection = ({ close, list, handleRemoveSubscriptions }) => {
  const [subscriptionIdsToRemove, setSubscriptionIdsToRemove] = useState([])

  const handleCheckboxChange = (event, subscriptionId) => {
    const { checked } = event.target
    if (checked) {
      setSubscriptionIdsToRemove([...subscriptionIdsToRemove, subscriptionId])
    } else {
      let updatedSubscriptionIds = subscriptionIdsToRemove.filter(id => id !== subscriptionId)
      setSubscriptionIdsToRemove(updatedSubscriptionIds)
    }
  }

  const handleRemoveAccess = () => {
    handleRemoveSubscriptions(subscriptionIdsToRemove)
  }

  return (
    <React.Fragment>
      <UserAccessList onChange={handleCheckboxChange} list={list} editable></UserAccessList>
      <SectionButtonContainer>
        <Button variant='outline-dark' onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={handleRemoveAccess}
          disabled={subscriptionIdsToRemove.length === 0}
          variant='dark'
        >
          Remove Access
        </Button>
      </SectionButtonContainer>
    </React.Fragment>
  )
}

RemoveAccessSection.propTypes = {
  list: PropTypes.object,
  close: PropTypes.func,
  handleRemoveSubscriptions: PropTypes.func.isRequired,
}
