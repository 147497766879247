import React, { Component } from 'react'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'

/**
 * Use the @requireRole decorator to set role permissions to a React component.
 * When the roles are matching, the component is rendered, otherwise the
 * component won't render and the user is redirected to the 403 error page.
 *
 * @param  {Array, String} permissionRoles Allowed permission authentication roles.
 */

const connectWrapper = connect(
  state => ({
    user: state.auth.user,
  }),
  { replaceState: replace }
)

export default function requireRole(permissionRoles) {
  return function wrapWithAuthentication(WrappedComponent) {
    class RequireRole extends Component {
      constructor() {
        super()
        this.state = { shouldRender: false }
        this.componentWillMount = this.componentWillMount.bind(this)
      }

      componentWillMount() {
        const { user } = this.props
        if (
          user.permissions.canManage ||
          (permissionRoles.constructor === Array && permissionRoles.includes(user.role))
        ) {
          this.setState({ shouldRender: true })
        } else if (typeof permissionRoles === 'string' && permissionRoles === user.role) {
          this.setState({ shouldRender: true })
        } else {
          this.props.replaceState('/403')
        }
      }

      render() {
        return this.state.shouldRender ? <WrappedComponent {...this.props} /> : false
      }
    }

    return connectWrapper(RequireRole)
  }
}
