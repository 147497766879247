/* global google */
/* eslint array-callback-return: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MapTimeline from './MapTimeline'
import { TransparencySlider } from '../../domains/Map/TransparencySlider'
import { Button, Image, Modal } from 'react-bootstrap'
import { getNextKey } from 'helpers'
import { debounce } from 'lodash'
import { sld } from 'components/LegacyLeafletMap/sld'
import shallowCompare from 'react-addons-shallow-compare'
import moment from 'moment'
import L from 'leaflet'
import draw from 'leaflet-draw' // eslint-disable-line no-unused-vars
import * as turf from '@turf/turf'
import {
  MapLayerControl,
  TopLevelContainer,
  TopLevelCheckbox,
  BaseMapContainer,
  BaseMapRadioButton,
  DownloadButtonContainer,
} from 'domains'
import styles from './LeafletMap.module.scss'

const cluInactiveFillColor = '#d3d3d3'
const cluActiveFillColor = '#00a98b'

export default class LeafletMap extends Component {
  constructor(props) {
    super(props)
    this.switchBaseLayer = this.switchBaseLayer.bind(this)
    this.showMapLayerControl = this.showMapLayerControl.bind(this)
    this.hideMapLayerControl = this.hideMapLayerControl.bind(this)
    // this.handleLayerToggle = this.handleLayerToggle.bind(this);
    this.handleCluLayerToggle = this.handleCluLayerToggle.bind(this)
    this.handleObservationToggle = this.handleObservationToggle.bind(this)
    this.clearErrorMarkers = this.clearErrorMarkers.bind(this)
    this.checkForFarmOverview = this.checkForFarmOverview.bind(this)
    this.checkForReportOverview = this.checkForReportOverview.bind(this)
    this.setMapBounds = this.setMapBounds.bind(this)
    this.setMapState = this.setMapState.bind(this)
    this.clearFieldLayers = this.clearFieldLayers.bind(this)
    this.clearLayers = this.clearLayers.bind(this)
    this.currentlySelectedLayer = this.currentlySelectedLayer.bind(this)
    this.geolocate = this.geolocate.bind(this)
    this.isCreatingField = this.isCreatingField.bind(this)
    this.bindCreateFieldHandlers = this.bindCreateFieldHandlers.bind(this)
    this.bindAppliedMapHandlers = this.bindAppliedMapHandlers.bind(this)
    this.bindAnnotationHandlers = this.bindAnnotationHandlers.bind(this)
    this.updateAnnotationAreas = this.updateAnnotationAreas.bind(this)
    this.bindEditFieldHandlers = this.bindEditFieldHandlers.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.createEditFieldPolygons = this.createEditFieldPolygons.bind(this)
    this.addFieldBoundary = this.addFieldBoundary.bind(this)
    this.state = {
      LayerControlVisible: false,
      activeBaseLayerKey: 'satellite',
      activeImageLayerKey: null,
      LayersEnabled: true,
      ObservationsEnabled: this.props.displayScoutingZones,
      LayersTransparency: 100, // No initial transparency
      LegendEnabled: true,
      SoilsEnabled: false,
      clus: [],
      CluLayerEnabled: true,
      importLayerEnabled: false,
      isLoaded: false,
    }
    this.canFetchClus = true
  }

  componentDidMount() {
    const Leaflet = L

    const TileLayerSoils = L.TileLayer.WMS.extend({
      options: {
        sld_body: '',
      },
      _loadTile: function _loadTile(tile, tilePoint) {
        tile._layer = this
        tile.onload = this._tileOnLoad
        tile.onerror = this._tileOnError
        this._adjustTilePoint(tilePoint)
        const xhr = new XMLHttpRequest()
        const splitUrl = this.getTileUrl(tilePoint).split('?')
        xhr.open('POST', splitUrl[0])
        xhr.responseType = 'blob'
        xhr.onload = function xhrLoadCallback() {
          tile.src = window.URL.createObjectURL(this.response)
        }
        xhr.send(splitUrl[1])
        this.fire('tileloadstart', {
          tile: tile,
          url: tile.src,
        })
      },
      getTileUrl: function getTileUrl(tilePoint) {
        const map = this._map
        const tileSize = this.options.tileSize
        const nwPoint = tilePoint.multiplyBy(tileSize)
        const sePoint = nwPoint.add([tileSize, tileSize])
        const nw = this._crs.project(map.unproject(nwPoint, tilePoint.z))
        const se = this._crs.project(map.unproject(sePoint, tilePoint.z))
        const bbox =
          this._wmsVersion >= 1.3 && this._crs === L.CRS.EPSG4326
            ? [se.y, nw.x, nw.y, se.x].join(',')
            : [nw.x, se.y, se.x, nw.y].join(',')
        let url = L.Util.template(this._url, {
          s: this._getSubdomain(tilePoint),
        })
        const params = []
        for (const key in this.wmsParams) {
          if (Object.prototype.hasOwnProperty.call(this.wmsParams, key)) {
            params.push(key.toUpperCase() + '=' + this.wmsParams[key])
          }
        }
        url = url + (!url || url.indexOf('?') === -1 ? '?' : '&') + params.join('&')
        return url + '&BBOX=' + bbox + '&SLD_BODY=' + this.options.sld_body
      },
    })

    function tileLayerSoils(url, options) {
      return new TileLayerSoils(url, options)
    }

    const basePath = 'https://api.mapbox.com/styles/v1/'
    const accessToken =
      'pk.eyJ1IjoiYWtlcmFnIiwiYSI6ImNqazczOTNqNzI1YXMzcW50MHF3NTg3dDMifQ.mQ7ADlqYrVVwJVO9cWqiKQ'
      let layerUrl = `${basePath}{id}/{z}/{x}/{y}?access_token=${accessToken}`
      const soilUrl = 'https://sdmdataaccess.sc.egov.usda.gov/Spatial/SDM.wms'
    if (window && window.devicePixelRatio && window.devicePixelRatio > 1) {
      layerUrl = `${basePath}{id}/{z}/{x}/{y}?access_token=${accessToken}`
    }

    this.streets = Leaflet.tileLayer(layerUrl, {
      id: 'akerag/ckg8g8f9c1pw119pdh5ugfbpu/tiles',
      maxZoom: 20,
      maxNativeZoom: 19,
      tileSize: 512,
      zoomOffset: -1
    })
    this.satellite = Leaflet.tileLayer(layerUrl, {
      id: 'akerag/ckg8f9hmk06e819mospasxho4/tiles',
      maxZoom: 20,
      maxNativeZoom: 19,
      tileSize: 512,
      zoomOffset: -1
    })
    this.soils = tileLayerSoils(soilUrl, {
      maxZoom: 20,
      maxNativeZoom: 19,
      minZoom: 15,
      layers: 'surveyareapolyoutline,mapunitpoly',
      format: 'image/png',
      transparent: true,
      sld_body: sld,
    })

    this.activeBaseLayer = this.satellite
    this.activeClus = {}
    this.joinedPolygonId = null
    this.imageLayerGroup = new Leaflet.LayerGroup()
    this.observationAreaGroup = new Leaflet.LayerGroup()
    this.observationAreaLabelGroup = new Leaflet.LayerGroup()
    this.cluLayers = new Leaflet.LayerGroup()
    this.fieldGeometry = new Leaflet.LayerGroup()
    this.annotationLayer = new Leaflet.LayerGroup()

    this.map = Leaflet.map('mapContainer', {
      layers: [
        this.activeBaseLayer,
        this.imageLayerGroup,
        this.observationAreaGroup,
        this.observationAreaLabelGroup,
        this.cluLayers,
        this.fieldGeometry,
        this.annotationLayer,
      ],
      zoomControl: false,
    }).setView([37.8, -96], 4)

    this.zoomControls = new Leaflet.Control.Zoom({ position: 'topleft' }).addTo(this.map)

    this.baseMaps = {
      streets: this.streets,
      satellite: this.satellite,
    }

    // Limit number of map changes at a time
    this.slowMapStateUpdate = debounce(
      nextProps => {
        console.log('Refreshing map.')
        this.setMapState(nextProps)
      },
      150,
      {
        maxWait: 1000,
        trailing: true,
        leading: false,
      }
    )

    // var leafletDraw = require('leaflet-draw');
    // Initialise the FeatureGroup to store editable layers
    this.leafletDrawnItems = new Leaflet.FeatureGroup()
    this.map.addLayer(this.leafletDrawnItems)

    L.drawLocal.draw.toolbar.actions.text = 'Clear'
    L.drawLocal.draw.toolbar.actions.title = 'Clear'

    L.drawLocal.draw.handlers.polyline.tooltip.start = 'Click to start'
    L.drawLocal.draw.handlers.polyline.tooltip.cont = 'Click next point'
    L.drawLocal.draw.handlers.polyline.tooltip.end = 'Click last point to clear'

    L.drawLocal.draw.handlers.polygon.tooltip.start = 'Click to start'
    L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Click next point'
    if (this.isCreatingField()) {
      L.drawLocal.draw.handlers.polygon.tooltip.end = 'Click first point to complete'
    } else {
      L.drawLocal.draw.handlers.polygon.tooltip.end = 'Click first point to complete'
    }

    // Timeout needed for correct display in debug mode for some unknown reason
    // https://github.com/Leaflet/Leaflet/issues/941
    setTimeout(() => {
      this.map.invalidateSize()
      this.setMapState(this.props)
    }, 10)
    this.setState({ isLoaded: true })
  }

  componentWillReceiveProps(nextProps) {
    if (shallowCompare(this, nextProps, this.state) && !this.state.importLayerEnabled) {
      this.slowMapStateUpdate(nextProps)
    }

    if (window.location.pathname.includes('field/create')) {
      const importedFieldDataChanged = this.props.importedFieldData !== nextProps.importedFieldData
      importedFieldDataChanged && this.createImportedFieldLayer(nextProps)
      this.checkImportedFieldsMouseEvent(nextProps)

      const removeImportedFieldLayers =
        this.props.importedFieldData.length > 0 && nextProps.importedFieldData.length === 0
      if (removeImportedFieldLayers) {
        this.clearLayers()
        this.setSelectedPolygon({})
        this.clearMarkers()
      }
    }
  }

  checkImportedFieldsMouseEvent = nextProps => {
    const { mouseEvent } = nextProps.selectedImportedFieldId
    if (mouseEvent === 'click') {
      this.selectImportedFieldOnMap(nextProps)
    }
  }

  selectImportedFieldOnMap = nextProps => {
    const { importedFieldData } = nextProps
    const { id } = nextProps.selectedImportedFieldId
    const allFieldsCentroids = this.getAllFieldsCentroids(importedFieldData)

    allFieldsCentroids.filter(field => {
      if (id === +field.id) {
        const { coordinates } = field.center.geometry
        const zoomLevel = 15
        return this.map.setView([coordinates[1], coordinates[0]], zoomLevel)
      }
    })
    this.selectImportedFieldLayerFromSidePanel(id)
  }

  getAllFieldsCentroids = fields => {
    return fields.map(field => {
      const center = turf.centroid(turf.polygon(field.boundary))
      return { id: field.importedFieldId, center }
    })
  }

  selectImportedFieldLayerFromSidePanel = id => {
    this.props.passSelectedImportedFieldId(null, null)
    Object.keys(this.map._layers).map(key => {
      if (!this.map._layers[key]) return
      if (this.map._layers[key].importedFieldId === id) {
        const selectingNewLayer =
          this.props.selectedImportedFieldLayer._leaflet_id !==
          +Object.keys(this.map._layers[key]._layers)[0]
        const changingSelectedLayer =
          selectingNewLayer && this.props.selectedImportedFieldLayer._leaflet_id
        if (!selectingNewLayer || changingSelectedLayer) {
          this.selectDeselectImportedFieldLayer(this.props.selectedImportedFieldLayer)
        }
        if (selectingNewLayer) {
          const newSelectedLayerId = Object.keys(this.map._layers[key]._layers)[0]
          this.selectDeselectImportedFieldLayer(this.map._layers[key]._layers[newSelectedLayerId])
        }
      }
    })
  }

  selectDeselectImportedFieldLayer = layer => {
    this.handleCluClick({ target: layer })
  }

  clearMarkers = () => {
    Object.keys(this.map._layers).map(key => {
      if (this.map._layers[key]._icon) {
        this.map.removeLayer(this.map._layers[key])
      }
    })
    this.map.setView([37.8, -96], 4)
  }

  createImportedFieldLayer = nextProps => {
    if (
      this.state.isLoaded &&
      nextProps.hasFieldDataImported &&
      nextProps.importedFieldData &&
      !this.props.selectedImportedFieldLayer._leaflet_id
    ) {
      this.setState({
        clus: nextProps.importedFieldData,
        importLayerEnabled: true,
      })
      this.clearLayers()
      const importPolygonOptions = {
        clickable: true,
        onEachFeature: this.bindCluPolygon.bind(this),
        fill: true,
        fillColor: cluInactiveFillColor,
        fillOpacity: 0.2,
      }
      const importedFieldLength = nextProps.importedFieldData.length

      const centroidsOfAllFields = []
      nextProps.importedFieldData.forEach((field, i) => {
        let fixBoundary = true
        const fixBoundaryArray = arr => {
          if (arr.length === 1) {
            field.boundary[0] = field.boundary[0][0]
            fixBoundaryArray(field.boundary[0][0])
          } else {
            const isFieldWithCutOuts = field.boundary[0][0].length !== 2
            if (isFieldWithCutOuts) {
              // set main polygon as boundary for rendering the field marker
              field.boundary[0] = field.boundary[0][0]
            }
            fixBoundary = false
          }
        }
        if (fixBoundary) fixBoundaryArray(field.boundary[0])

        const centerOfField = turf.centroid(turf.polygon(field.boundary)).geometry.coordinates
        centroidsOfAllFields.push(centerOfField)
        const lastFieldInArr = i === importedFieldLength - 1
        if (lastFieldInArr) {
          const centerOfFirstField = turf.centroid(
            turf.polygon(nextProps.importedFieldData[0].boundary)
          ).geometry.coordinates
          centroidsOfAllFields.push(centerOfFirstField)
        }

        const createdCentroidsForAllFields = centroidsOfAllFields.length - 1 === importedFieldLength
        if (createdCentroidsForAllFields) {
          const features = turf.featureCollection(
            centroidsOfAllFields.map(point => turf.point([point[1], point[0]]))
          )
          const boundsOfFields = turf.envelope(features).geometry.coordinates
          this.updateObservationAreas(
            field.geojson,
            importPolygonOptions,
            'import',
            field.importedFieldId,
            boundsOfFields
          )
        } else {
          this.updateObservationAreas(
            field.geojson,
            importPolygonOptions,
            'import',
            field.importedFieldId
          )
        }
      })
    }
  }

  setMapState(nextProps) {
    const { imageLayers, bounds } = nextProps

    // Clear map
    this.clearErrorMarkers()
    this.clearFieldLayers()
    this.clearLayers()

    // // Clear any polygon shapes
    // if (this.leafletPolygonShape) {
    //   this.leafletDrawnItems.removeLayer(this.leafletPolygonShape);
    //   this.leafletPolygonShape = null;
    // }

    // Clear previous image layers
    this.imageLayers = null

    // Add the filed geometry polygon to the map. If edit add to the drawn items layer to edit.
    if (nextProps.geometry) {
      // Leaflet Draw does not support multipolygon so we have to split the polygons
      if (nextProps.editField) {
        this.createEditFieldPolygons(nextProps.geometry)
      } else {
        this.addFieldBoundary()
      }
    }

    if (this.isCreatingField()) {
      this.bindCreateFieldHandlers()
    } else if (nextProps.editField) {
      this.bindEditFieldHandlers()
    } else if (nextProps.asAppliedField) {
      this.bindAppliedMapHandlers()
    } else {
      this.intializeLeafletDraw()
    }

    if (nextProps.injectedPolygons && nextProps.injectedPolygons.length > 0) {
      nextProps.injectedPolygons.map(polygon => {
        this.updateObservationAreas(polygon.toGeoJSON(), {
          fill: true,
          fillColor: polygon.fillColor,
          fillOpacity: 0.8,
          onEachFeature: polygon.onEachFeature,
        })
      })
    }

    if (nextProps.displayDrawTool && !this.leafletDrawControl.added) {
      this.leafletDrawControl.added = true
      try {
        this.map.addControl(this.leafletDrawControl)
      } catch (err) {
        console.log('Error', err)
      }
    } else if (!nextProps.displayDrawTool && this.leafletDrawControl.added) {
      this.leafletDrawControl.added = false
      try {
        this.map.removeControl(this.leafletDrawControl)
      } catch (err) {
        console.log('Error', err)
      }
    }

    if (imageLayers && imageLayers.length > 0) {
      if (nextProps.activeWorkOrder) {
        this.setState({
          activeImageLayerKey: nextProps.activeWorkOrder,
        })
      }

      this.updateImageLayers(imageLayers, bounds)
    }

    if (nextProps.annotations && this.state.AnnotationsEnabled && nextProps.overviewField) {
      this.updateAnnotationAreas(nextProps.annotations)
    }

    this.checkForFarmOverview(nextProps)

    this.checkForReportOverview(nextProps)

    !this.state.importLayerEnabled && this.setMapBounds(nextProps)

    this.forceUpdate()
  }

  setMapBounds(nextProps) {
    // this.map.invalidateSize();

    const { bounds, uploadedShapes } = nextProps
    const Leaflet = L

    if (bounds && bounds.length > 0) {
      this.map.setMaxBounds(null).fitBounds(bounds, {
        padding: [0, 0],
        animated: false,
      })
    } else if (uploadedShapes) {
      const uploadedbounds = L.geoJson(uploadedShapes).getBounds()
      const formattedBounds = [
        [uploadedbounds._northEast.lat, uploadedbounds._northEast.lng],
        [uploadedbounds._southWest.lat, uploadedbounds._southWest.lng],
      ]
      this.map.setMaxBounds(null).fitBounds(formattedBounds, {
        padding: [0, 0],
        animated: false,
      })
    } else if ((nextProps.overviewFarm || this.isCreatingField()) && nextProps.fields) {
      // const Leaflet = require('leaflet');

      let overallBounds
      try {
        nextProps.fields.map(field => {
          const pinBound = Leaflet.latLngBounds(
            Leaflet.latLng(field.bounds[0][0], field.bounds[0][1]),
            Leaflet.latLng(field.bounds[1][0], field.bounds[1][1])
          )
          overallBounds = !overallBounds ? pinBound : overallBounds.extend(pinBound)
        })
      } catch (err) {
        // console.log('Could not calculate overall bounds', err)
      }

      if (overallBounds) {
        this.map.setMaxBounds(null).fitBounds(overallBounds, {
          padding: [0, 0],
          animate: false,
        })
      } else {
        // Reset the view to USA
        this.map.setMaxBounds(null).setView([37.8, -96], 4)
      }
    } else {
      // Reset the view to USA when you get back from a bound view
      this.map.setMaxBounds(null).setView([37.8, -96], 4)
    }

    // this.map.invalidateSize();
  }

  setSelectedPolygon(polygon) {
    this.activeClus = polygon
    this.props.setSelectedPolygon(this.activeClus)
  }

  intializeLeafletDraw(options = {}) {
    if (this.leafletDrawControl && this.leafletDrawControl.added) {
      return
    }
    const that = this
    const Leaflet = L
    // const pin = require('../../assets/annotation_pin.svg');
    const icon = Leaflet.divIcon({
      className: 'leaflet-issue-icon',
      html: `<div class='pinWrapper'><div class='annotationLabel'><i class="fas fa-binoculars" aria-hidden="true"></div><div class='pin'></div><div class='pulse'></div></div>`,
      iconSize: [30, 30],
    })

    const markerOptions = () => {
      if (that.props.displayAnnotationTool && that.props.hasSeasons) {
        return {
          icon: icon,
        }
      }

      return false
    }
    const polygonOptions = () => {
      if (that.props.displayDrawTool) {
        return {
          title: '',
          allowIntersection: false, // Windows does not allow clockwise selection because of this
          drawError: {
            color: '#b00b00',
            timeout: 1000,
          },
          shapeOptions: {
            color: '#FF4C5A',
            opacity: 0.75,
            fillOpacity: 0.5,
          },
          showArea: true,
          metric: false, // Whether to use the metric measurement system or imperial
        }
      }

      return false
    }

    const polylineOptions = () => {
      if (that.props.displayDrawTool) {
        return {
          shapeOptions: {
            color: '#FF4C5A',
            opacity: 0.75,
          },
          metric: false, // Whether to use the metric measurement system or imperial
          feet: true, // When not metric, to use feet instead of yards for display.
          showLength: true,
        }
      }

      return false
    }

    const defaultOptions = {
      position: 'topleft',
      draw: {
        circle: false,
        marker: markerOptions(),
        rectangle: false,

        polygon: polygonOptions(),
        polyline: polylineOptions(),
      },
      edit: false,
      delete: false,
    }

    if (this.props.overviewField) {
      this.bindAnnotationHandlers()
    }

    const mergedOptions = Object.assign({}, defaultOptions, options)

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    this.leafletDrawControl = new Leaflet.Control.Draw(mergedOptions)
  }

  addFieldBoundary() {
    const props = this.props
    const geoJson = L.geoJson(props.geometry, {
      color: 'white',
      fillColor: 'none',
      weight: 2,
      opacity: 1,
      lineJoin: 'round',
      // 'dashArray': '20,15',
    })
    this.fieldGeometry.addLayer(geoJson)
  }

  bindEditFieldHandlers() {
    const that = this
    const editDrawOptions = {
      draw: {
        polygon: false,
        polyline: false,
        circle: false,
        marker: false,
        rectangle: false,
      },
      edit: {
        featureGroup: that.leafletDrawnItems,
        remove: true,
        edit: true,
      },
    }

    that.intializeLeafletDraw(editDrawOptions)
    this.leafletDrawnItems.eachLayer(layer => {
      layer.editing.enable()
    })

    that.map.on('draw:editvertex', () => {
      let geoJson
      let unionTemp

      this.leafletDrawnItems.eachLayer(layer => {
        if (!unionTemp) {
          unionTemp = layer.toGeoJSON()
        } else {
          unionTemp = turf.union(unionTemp, layer.toGeoJSON())
        }
      })
      geoJson = L.geoJson(unionTemp)
      that.props.saveEditField(geoJson.getLayers()[0])
    })
  }

  createEditFieldPolygons(geometry) {
    if (geometry.type === 'MultiPolygon') {
      geometry.coordinates.forEach(shapeCoords => {
        const polygon = { type: 'Polygon', coordinates: shapeCoords }
        L.geoJson(polygon, {
          onEachFeature: (feature, layer) => {
            this.leafletDrawnItems.addLayer(layer)
          },
        })
      })
    } else {
      const layer = L.GeoJSON.geometryToLayer(geometry)
      this.leafletDrawnItems.addLayer(layer)
    }
  }

  bindAnnotationHandlers() {
    this.map.on('draw:created', event => {
      if (event.layer instanceof L.Marker) {
        const geoJson = event.layer.toGeoJSON()
        this.setState({
          AnnotationsEnabled: true,
        })
        this.annotationLayer.addLayer(event.layer)
        this.props.annotationMarkerAdded(geoJson)
      }
    })
  }

  bindCreateFieldHandlers() {
    const cluPolygonOptions = {
      clickable: true,
      onEachFeature: this.bindCluPolygon.bind(this),
      fill: true,
      fillColor: cluInactiveFillColor,
      fillOpacity: 0.2,
    }

    this.intializeLeafletDraw({
      draw: {
        circle: false,
        marker: false,
        rectangle: false,
        polyline: false,
        polygon: {
          title: '',
          allowIntersection: false, // Windows does not allow clockwise selection because of this
          drawError: {
            color: '#b00b00',
            timeout: 1000,
          },
          shapeOptions: {
            color: '#FF4C5A',
            opacity: 0.75,
            fillOpacity: 0.5,
          },
          showArea: true,
          metric: false, // Whether to use the metric measurement system or imperial
        },
      },
      edit: {
        featureGroup: this.leafletDrawnItems,
        edit: false,
      },
    })

    this.map.on('moveend', () => {
      if (this.map.getZoom() >= 14 && !this.state.importLayerEnabled) {
        const northEast = this.map.getBounds().getNorthEast()
        const southWest = this.map.getBounds().getSouthWest()
        this.props.fetchClus(northEast, southWest).then(data => {
          this.setState({ clus: data.data })
          if (this.canFetchClus) {
            this.clearLayers()
            this.setSelectedPolygon({})
            if (this.state.CluLayerEnabled) {
              this.state.clus.map(clu => {
                this.updateObservationAreas(clu.geojson, cluPolygonOptions, 'clus')
              })
            }
          }
        })
      }
    })

    this.map.on('draw:drawstart', () => {
      this.canFetchClus = false
      this.setState({
        CluLayerEnabled: false,
      })
      this.clearLayers('clus')
    })

    this.map.on('draw:created', event => {
      const layerObj = {}
      let geoJson
      let unionTemp
      this.leafletDrawnItems.addLayer(event.layer)

      this.leafletDrawnItems.eachLayer(layer => {
        if (!unionTemp) {
          unionTemp = layer.toGeoJSON()
        } else {
          unionTemp = turf.union(unionTemp, layer.toGeoJSON())
        }
      })
      geoJson = L.geoJson(unionTemp)

      layerObj[Object.keys(geoJson._layers)[0]] = geoJson._layers[Object.keys(geoJson._layers)[0]]
      this.setSelectedPolygon(layerObj)
    })

    this.map.on('draw:deleted', () => {
      if (this.leafletDrawnItems.getLayers().length === 0) {
        this.canFetchClus = true
        this.setState({
          CluLayerEnabled: true,
        })
        this.state.clus.map(clu => {
          this.updateObservationAreas(clu.geojson, cluPolygonOptions, 'clus')
        })
        this.setSelectedPolygon({})
      } else {
        let unionTemp
        let geoJson
        this.canFetchClus = false
        this.setState({
          CluLayerEnabled: false,
        })
        this.leafletDrawnItems.eachLayer(layer => {
          if (!unionTemp) {
            unionTemp = layer.toGeoJSON()
          } else {
            unionTemp = turf.union(unionTemp, layer.toGeoJSON())
          }
        })

        geoJson = L.geoJson(unionTemp)

        this.setSelectedPolygon({
          0: geoJson._layers[Object.keys(geoJson._layers)[0]],
        })
      }
    })

    this.map.on('draw:drawstop', () => {
      if (this.leafletDrawnItems.getLayers().length === 0) {
        this.canFetchClus = false
        this.state.clus.map(clu => {
          this.updateObservationAreas(clu.geojson, cluPolygonOptions, 'clus')
        })
        this.setSelectedPolygon({})
      }
    })

    this.autocomplete = new google.maps.places.Autocomplete(this.refs.Autocomplete)
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace()
      if (place && place.geometry) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        this.map.setView([lat, lng], 14)
      }
    })

    if (this.props.uploadedShapes) {
      this.clearLayers()
      this.createEditFieldPolygons(this.props.uploadedShapes)
      this.canFetchClus = false
      this.setState({
        CluLayerEnabled: false,
      })
    }
  }

  bindAppliedMapHandlers() {
    this.intializeLeafletDraw({
      draw: {
        circle: false,
        marker: false,
        rectangle: false,
        polyline: false,
        polygon: {
          title: '',
          allowIntersection: false, // Windows does not allow clockwise selection because of this
          drawError: {
            color: '#b00b00',
            timeout: 1000,
          },
          shapeOptions: {
            color: '#FF4C5A',
            opacity: 0.75,
            fillOpacity: 0.5,
          },
          showArea: true,
          metric: false, // Whether to use the metric measurement system or imperial
        },
      },
      edit: {
        featureGroup: this.leafletDrawnItems,
        edit: false,
      },
    })

    this.map.on('draw:created', event => {
      // const layerObj = {};
      // let geoJson;
      // this.leafletDrawnItems.addLayer(event.layer);
      const drawLayer = event.layer

      // layerObj[Object.keys(geoJson._layers)[0]] = geoJson._layers[Object.keys(geoJson._layers)[0]];
      this.setSelectedPolygon(drawLayer)
    })

    this.map.on('draw:deleted', () => {
      if (this.leafletDrawnItems.getLayers().length === 0) {
        this.setSelectedPolygon({})
      } else {
        let unionTemp
        let geoJson
        this.leafletDrawnItems.eachLayer(layer => {
          if (!unionTemp) {
            unionTemp = layer.toGeoJSON()
          } else {
            unionTemp = turf.union(unionTemp, layer.toGeoJSON())
          }
        })

        geoJson = L.geoJson(unionTemp)

        this.setSelectedPolygon({
          0: geoJson._layers[Object.keys(geoJson._layers)[0]],
        })
      }
    })
  }

  joinPolygons(polygons, type = 'observationAreas') {
    const activePolygonOptions = {
      color: 'white',
      weight: 2,
      opacity: 1,
      lineJoin: 'round',
      fill: true,
      fillColor: cluActiveFillColor,
      fillOpacity: 0.8,
      clickable: true,
      // 'dashArray': '20,15',
    }
    let unionTemp

    if (this.joinedPolygonId) {
      this.cluLayers.removeLayer(this.joinedPolygonId)
    }
    for (let int = 0; int < Object.keys(polygons).length; ++int) {
      const layer = polygons[Object.keys(polygons)[int]]
      if (int === 0) {
        unionTemp = layer.feature
      } else {
        unionTemp = turf.union(unionTemp, layer.feature)
      }
      this.inactivatePolygon(layer)
    }
    const joinedLayer = L.geoJson(unionTemp, activePolygonOptions)
    this.joinedPolygonId = joinedLayer._leaflet_id

    this.bindJoinedCluPolygon(joinedLayer)
    switch (type) {
      case 'clus':
        this.cluLayers.addLayer(joinedLayer)
        break
      default:
        this.observationAreaGroup.addLayer(joinedLayer)
        break
    }

    const activeClus = {}
    const key = Object.keys(joinedLayer._layers)[0]
    activeClus[joinedLayer._layers[key]._leaflet_id] = joinedLayer._layers[key]

    this.setSelectedPolygon(activeClus)
  }

  bindJoinedCluPolygon(layer) {
    layer.on({
      click: this.handleJoinedCluClick.bind(this),
    })
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        })
        this.autocomplete.setBounds(circle.getBounds())
      })
    }
  }

  handleJoinedCluClick(event) {
    this.cluLayers.removeLayer(event.target._leaflet_id)
    this.setSelectedPolygon({})
  }

  inactivatePolygon(layer) {
    layer.setStyle({ fillColor: cluInactiveFillColor, fillOpacity: 0.2 })
  }

  handleCluClick(event) {
    const layer = event.target
    const layerId = layer._leaflet_id
    const selectedImportedFieldIdFromSidePanel = this.props.selectedImportedFieldLayer._leaflet_id
    const unselectingLayer = selectedImportedFieldIdFromSidePanel === layerId
    unselectingLayer
      ? this.props.passSelectedImportedFieldLayer({ _leaflet_id: null })
      : this.props.passSelectedImportedFieldLayer(layer)
    const activeClus = this.activeClus
    // if (activeClus[selectedImportedFieldIdFromSidePanel] && !unselectingLayer) {
    //   this.inactivatePolygon(this.props.selectedImportedFieldLayer)
    //   delete activeClus[selectedImportedFieldIdFromSidePanel]
    // }
    if (activeClus[layerId]) {
      this.inactivatePolygon(event.target)
      delete activeClus[layerId]
    } else {
      event.target.setStyle({
        fillColor: cluActiveFillColor,
        fillOpacity: 0.8,
      })
      activeClus[layerId] = event.target
    }

    // const allowMultiSelect = this.props.importedFieldData.length > 0 ? false : true
    if (Object.keys(activeClus).length > 1) {
      this.joinPolygons(activeClus, 'clus')
    } else if (!unselectingLayer) {
      this.setSelectedPolygon(activeClus)
    }
  }

  bindCluPolygon(feature, layer) {
    layer.on({
      click: this.handleCluClick.bind(this),
    })
  }

  clearLayers() {
    try {
      this.cluLayers.clearLayers()
      this.observationAreaGroup.clearLayers()
      this.annotationLayer.clearLayers()
      this.leafletDrawnItems.clearLayers()
    } catch (err) {
      console.log('LeafletMap clearLayers Error:', err)
    }
    try {
      this.observationAreaLabelGroup.clearLayers()
    } catch (err) {
      console.log('LeafletMap clearLayers Error:', err)
    }
  }

  clearFieldLayers() {
    try {
      this.imageLayerGroup.clearLayers()
    } catch (err) {
      console.log('LeafletMap clearLayers Error:', err)

      try {
        this.map.removeLayer(this.imageLayerGroup)
      } catch (error) {
        console.log('Error', error)
      }

      this.imageLayerGroup = new L.LayerGroup()
      this.map.addLayer(this.imageLayerGroup)
    }

    this.setState({
      activeImageLayerKey: null,
    })
  }

  openLightbox(image) {
    this.setState({ showLightbox: true, image: image })
  }

  closeLightbox() {
    this.setState({ showLightbox: false })
  }

  checkForReportOverview(nextProps = this.props) {
    this.issuePins = this.issuePins || []

    const removePins = () => {
      if (this.issuePins && this.issuePins.length > 0) {
        this.issuePins.map(pin => {
          try {
            this.map.removeLayer(pin)
          } catch (err) {
            console.log('Error could not remove pins', err)
          }
        })
      }

      this.issuePins = []
    }

    const issueImages = issue => {
      const that = this
      const handleImageClick = image => {
        that.openLightbox(image)
      }

      return issue.media.map(image => {
        const img = document.createElement('img')
        img.src = image.url
        img.style.height = '115px'
        img.onclick = () => {
          handleImageClick(image)
        }
        return img
      })
    }

    if (nextProps.overviewReport) {
      removePins()
      const { issues } = nextProps
      const Leaflet = L

      if (issues.length > 0) {
        let counterKey = 'A'
        issues.map((issue, index) => {
          if (!issue.lat && !issue.lng) {
            return
          }

          if (index > 0) {
            counterKey = getNextKey(counterKey)
          }
          const latLng = Leaflet.latLng(issue.lat, issue.lng)
          const marker = Leaflet.marker(latLng, {
            clickable: true,
            keyboard: false,
            // title: field.title,
            // alt: field.title,
            riseOnHover: true,
            icon: Leaflet.divIcon({
              className: 'leaflet-issue-icon',
              html: `<div class='pinWrapper'><div class='pinLabel'>${counterKey}</div><div class='pin'></div><div class='pulse'></div></div>`,
              iconSize: [30, 30],
            }),
          })

          const popUpContainer = document.createElement('div')
          popUpContainer.innerHTML = `<b>${counterKey}. ${issue.cropStressItem.commonName}</b></br>`

          issueImages(issue).map(image => {
            popUpContainer.appendChild(image)
          })

          marker.bindPopup(popUpContainer, {
            offset: new Leaflet.Point(4, -20),
          })
          this.issuePins.push(marker)
          marker.addTo(this.map)
        })
      }
    } else {
      removePins()
    }
  }

  checkForFarmOverview(nextProps = this.props) {
    this.pins = this.pins || []

    const removePins = () => {
      if (this.pins && this.pins.length > 0) {
        this.pins.map(pin => {
          try {
            this.map.removeLayer(pin)
          } catch (err) {
            console.log('Error could not remove pins', err)
          }
        })
      }

      this.pins = []
    }

    if (nextProps.overviewFarm && nextProps.fields && nextProps.fields.length > 0) {
      // const Leaflet = require('leaflet');
      const Leaflet = L

      removePins()

      this.markerPopupOpen = null

      nextProps.fields.map(field => {
        try {
          const pinBound = Leaflet.latLngBounds(
            Leaflet.latLng(field.bounds[0][0], field.bounds[0][1]),
            Leaflet.latLng(field.bounds[1][0], field.bounds[1][1])
          )

          const marker = Leaflet.marker(pinBound.getCenter(), {
            clickable: true,
            keyboard: false,
            // title: field.title,
            // alt: field.title,
            riseOnHover: true,
            icon: Leaflet.divIcon({
              className: 'leaflet-div-field',
              iconSize: [24, 24],
            }),
          })
          marker.on('click', () => {
            this.props.openField(field)
          })
          marker.on('mouseover', () => {
            if (!this.markerPopupOpen) {
              marker.openPopup()
              this.markerPopupOpen = marker
            } else if (this.markerPopupOpen !== marker) {
              this.markerPopupOpen.closePopup()
              marker.openPopup()
              this.markerPopupOpen = marker
            }
          })
          marker.on('mouseout', () => {
            if (this.markerPopupOpen) {
              this.markerPopupOpen.closePopup()
              this.markerPopupOpen = false
            }
          })

          this.pins.push(marker)
          marker.addTo(this.map)
        } catch (err) {
          console.log('Error cound not add pins', err)
        }
      })
    } else {
      // Remove pins
      removePins()
    }
  }

  clearErrorMarkers() {
    // Remove marker
    if (this.errorMarker) {
      try {
        this.map.removeLayer(this.errorMarker)
      } catch (err) {
        console.log('Could not remove error marker', err)
      }
    }

    this.errorMarker = null
  }

  updateImageLayers(imageLayers, bounds) {
    if (!imageLayers || !bounds || bounds.length === 0) {
      // No layers need updating
      return
    }
    let activeImageLayerKey = this.state.activeImageLayerKey
    // const Leaflet = require('leaflet');
    const Leaflet = L
    this.imageLayers = {}
    imageLayers.forEach((layer, index) => {
      // Create a unique layer id
      layer.index = index

      const imageLayer = Leaflet.tileLayer(layer.tileUrl, {
        minZoom: 12,
        maxZoom: 20,
        maxNativeZoom: 20,
        // errorTileUrl: 'http://www.clipartbest.com/cliparts/Kcj/gn5/Kcjgn5Mji.png', // TODO: Load from our server
        bounds: bounds,
        opacity: this.state.LayersTransparency * 0.01, // Convert from 0-100 to 0-1 scale
      })

      imageLayer.on('tileerror', () => {
        if (!this.props.overviewField) {
          // Only display when showing field
          this.clearErrorMarkers()
          return
        }

        this.clearErrorMarkers()

        // This is called when the layer image does not exist. We display a pin instead
        try {
          const leafletBounds = Leaflet.latLngBounds(
            Leaflet.latLng(bounds[0][0], bounds[0][1]),
            Leaflet.latLng(bounds[1][0], bounds[1][1])
          )
          this.errorMarker = Leaflet.marker(leafletBounds.getCenter(), {
            clickable: false,
            keyboard: false,
            icon: Leaflet.divIcon({
              className: 'leaflet-text-label',
              html: '<h4>Zoom In</h4>',
              iconSize: [100, 35],
            }),
          })
          this.errorMarker.addTo(this.map)
        } catch (err) {
          console.log('Error could not add marker for layer', err)
        }
      })
      imageLayer.on('tileload', this.clearErrorMarkers)
      imageLayer.on('tileunload', this.clearErrorMarkers)
      imageLayer.originalLayer = layer // For reference

      this.imageLayers[layer.id] = imageLayer

      // Enable the first layer by default
      if (index === 0) {
        if (!activeImageLayerKey) {
          activeImageLayerKey = layer.id

          this.setState({
            activeImageLayerKey: layer.id,
          })
        }
      }
    })

    try {
      this.imageLayerGroup.clearLayers()
    } catch (err) {
      console.log('LeafletMap clearLayers Error:', err)
    }

    const activeLayer = this.imageLayers[activeImageLayerKey]
    if (this.state.LayersEnabled) {
      try {
        this.imageLayerGroup.addLayer(activeLayer)
      } catch (err) {
        console.log('LeafletMap addLayer Error:', err)
      }
    }
  }

  updateAnnotationAreas(annotations) {
    if (!annotations) {
      return
    }
    const that = this
    const Leaflet = L
    // const pin = require('assets/annotation_pin.svg');
    const markerOptions = Leaflet.divIcon({
      className: 'leaflet-issue-icon',
      html: `<div class='pinWrapper'><div class='annotationLabel'><i class="fas fa-binoculars" aria-hidden="true"></i></i></div><div class='pin'></div><div class='pulse'></div></div>`,
      iconSize: [30, 30],
    })

    const deleteAnnotation = annotation => {
      that.props.handleDeleteAnnotation(annotation.id)
    }

    const pointToLayer = (feature, latlng) => {
      return L.marker(latlng, {
        icon: markerOptions,
      })
    }
    /* eslint-disable */
    for (let index = annotations.length - 1; index >= 0; index--) {
      const annotation = annotations[index]
      const layer = Leaflet.geoJson(annotation.geometry, {
        pointToLayer: pointToLayer,
      })
      const popUpContainer = document.createElement('div')
      popUpContainer.innerHTML = `<b>${moment(annotation.createdAt).format(
        'MM/DD/YYYY'
      )}</b><br><div class='row'><div class='col-12'>${
        annotation.description
      }</div></div><br><div class='row'><div class='col-12'>`
      const button = document.createElement('button')
      button.className = 'trashCan btn btn-default'
      button.innerHTML = '<i class="fas fa-trash"></i>'
      button.onclick = () => {
        deleteAnnotation(annotation)
      }
      popUpContainer.appendChild(button)
      layer.bindPopup(popUpContainer).openPopup()
      this.annotationLayer.addLayer(layer)
    }
    /* eslint-enable */
  }

  updateObservationAreas(
    observationAreas,
    options = {},
    type = 'observationAreas',
    importedFieldId = null,
    boundsOfFields = []
  ) {
    // const Leaflet = require('leaflet');
    const Leaflet = L
    const defaultPolygonOptions = {
      color: 'white',
      fillColor: 'none',
      weight: 2,
      opacity: 1,
      lineJoin: 'round',
      // 'dashArray': '20,15',
    }
    const polygonOptions = Object.assign({}, defaultPolygonOptions, options)

    const layer = Leaflet.geoJson(observationAreas, polygonOptions)
    setTimeout(() => {
      try {
        switch (type) {
          case 'clus':
            this.cluLayers.addLayer(layer)
            break
          case 'import':
            {
              boundsOfFields.length > 0 && this.map.fitBounds(boundsOfFields)
              const singleFieldCenter = turf.centroid(turf.polygon(observationAreas.coordinates))
                .geometry.coordinates
              const markerIcon = L.divIcon({
                className: `imported-field-marker importMarker-${importedFieldId}`,
              })
              layer.options.icon = markerIcon
              L.marker([singleFieldCenter[1], singleFieldCenter[0]], { icon: markerIcon }).addTo(
                this.map
              )
              layer.importedFieldId = importedFieldId
              this.cluLayers.addLayer(layer)
            }
            break
          default:
            this.observationAreaGroup.addLayer(layer)
            break
        }
      } catch (err) {
        console.log('LeafletMap addLayer Error:', err)
      }
    }, 500)
  }

  updateObservationAreaLabels() {
    let labelKey = 'A'
    let index = 0

    // const Leaflet = require('leaflet');
    const Leaflet = L

    // Loop over the observation areas and add layers for all of them
    this.observationAreaGroup.eachLayer(layer => {
      layer.eachLayer(featureLayer => {
        if (index !== 0) {
          labelKey = getNextKey(labelKey)
        }
        index++
        const label = Leaflet.marker(featureLayer.getBounds().getCenter(), {
          clickable: false,
          keyboard: false,
          icon: Leaflet.divIcon({
            className: 'leaflet-text-label',
            html: index,
            iconSize: [28, 28],
          }),
        })
        try {
          this.observationAreaLabelGroup.addLayer(label)
        } catch (err) {
          console.log('LeafletMap addLayer Error:', err)
        }
      })
    })
  }

  toggleSoils = event => {
    this.setState({
      SoilsEnabled: event.target.checked,
    })
    if (event.target.checked) {
      try {
        this.map.addLayer(this.soils)
      } catch (err) {
        console.log('LeafletMap addLayer Error:', err)
      }
    } else {
      try {
        this.map.removeLayer(this.soils)
      } catch (err) {
        console.log('LeafletMap removeLayer Error:', err)
      }
    }
  }

  currentlySelectedLayer(optionalLayerName) {
    if (this.imageLayers && this.state.activeImageLayerKey) {
      const layer = this.imageLayers[optionalLayerName || this.state.activeImageLayerKey]

      return layer && layer.originalLayer
    }
    return null
  }

  handleCluLayerToggle(event) {
    this.clearLayers()

    const cluPolygonOptions = {
      clickable: true,
      onEachFeature: this.bindCluPolygon.bind(this),
      fill: true,
      fillColor: cluInactiveFillColor,
      fillOpacity: 0.2,
    }

    this.setState({
      CluLayerEnabled: event.target.checked,
    })

    this.canFetchClus = true

    if (event.target.checked && this.isCreatingField()) {
      this.state.clus.map(clu => {
        this.updateObservationAreas(clu.geojson, cluPolygonOptions, 'clus')
      })
    }
  }

  handleObservationToggle(event) {
    this.observationAreaGroup.clearLayers()
    this.observationAreaLabelGroup.clearLayers()

    const currentLayer = this.currentlySelectedLayer()

    this.setState({
      ObservationsEnabled: event.target.checked,
    })
    if (event.target.checked && currentLayer && currentLayer.scoutingZones) {
      this.updateObservationAreas(currentLayer.scoutingZones)
      this.updateObservationAreaLabels()
    }
  }

  handleAnnotationToggle = event => {
    const { annotations } = this.props
    this.annotationLayer.clearLayers()

    this.setState({
      AnnotationsEnabled: event.target.checked,
    })
    if (event.target.checked && annotations) {
      this.updateAnnotationAreas(annotations)
      // this.updateObservationAreaLabels();
    }
  }

  switchFeatureLayer(layer) {
    this.clearLayers()
    this.clearFieldLayers()
    this.clearErrorMarkers()

    try {
      this.imageLayerGroup.addLayer(this.imageLayers[layer.id])
    } catch (err) {
      console.log('LeafletMap addLayer Error:', err)
    }

    if (this.props.changeActiveWorkOrder) {
      this.props.changeActiveWorkOrder(layer.id)
    }
    this.setState({
      activeImageLayerKey: layer.id,
    })
    const currentLayer = this.currentlySelectedLayer(layer.id)

    if (this.props.injectedPolygons && this.props.injectedPolygons.length > 0) {
      this.props.injectedPolygons.map(polygon => {
        this.updateObservationAreas(polygon.toGeoJSON(), {
          fill: true,
          fillColor: polygon.fillColor,
          fillOpacity: 0.8,
          onEachFeature: polygon.onEachFeature,
        })
      })
    }

    if (currentLayer && currentLayer.scoutingZones && this.state.ObservationsEnabled) {
      this.updateObservationAreas(currentLayer.scoutingZones)
      this.updateObservationAreaLabels()
    }
  }

  showMapLayerControl() {
    this.setState({
      LayerControlVisible: true,
    })
  }

  hideMapLayerControl() {
    this.setState({
      LayerControlVisible: false,
    })
  }

  switchBaseLayer(val) {
    try {
      this.map.removeLayer(this.activeBaseLayer)
    } catch (err) {
      console.log('LeafletMap removeLayer Error:', err)
    }
    this.activeBaseLayer = this.baseMaps[val]
    try {
      this.map.addLayer(this.activeBaseLayer)
    } catch (err) {
      console.log('LeafletMap addLayer Error:', err)
    }
    this.activeBaseLayer.bringToBack()
    this.setState({
      activeBaseLayerKey: val,
    })
  }

  isCreatingField() {
    return this.props.createField
  }

  render() {
    const {
      showLayerControls,
      imageLayers,
      overviewField,
      overviewReport,
      bounds,
      annotations,
      displayTimeline,
      displayImageMetaDataLayers,
    } = this.props
    const currentLayer = this.currentlySelectedLayer()
    // console.log('map', this.map)
    return (
      <React.Fragment>
        <div className={styles.LeafletMap}>
          {this.isCreatingField() && (
            <div className={styles.placesContainer}>
              <input
                // id='places'  TODO: Confirm which id should be used
                className={styles.autocomplete}
                ref='Autocomplete'
                id='autocomplete'
                placeholder='Enter your address'
                type='text'
                onFocus={this.geolocate}
              ></input>
            </div>
          )}
          <div
            className={styles.LeafletMapInstance}
            id='mapContainer'
            ref='mapContainer'
            onClick={this.hideMapLayerControl}
          />
          {displayTimeline && imageLayers && imageLayers.length > 0 && (
            <div>
              <MapTimeline
                layers={imageLayers}
                selectedLayer={this.state.activeImageLayerKey}
                selectLayer={layer => this.switchFeatureLayer(layer)}
              />
              {this.state.LegendEnabled &&
                this.state.LayersEnabled &&
                currentLayer &&
                currentLayer.type &&
                currentLayer.type !== 'rgb' && (
                  <Image
                    src='data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7'
                    className={
                      currentLayer.type === 'thermal'
                        ? styles.layerLegendThermal
                        : styles.layerLegendNDVI
                    }
                  />
                )}
            </div>
          )}
          {showLayerControls && (
            <div className={styles.LeafletMapLayerControlToggleContainer}>
              <Button
                variant='secondary'
                className={styles.LeafletMapLayerControlToggle}
                onClick={this.showMapLayerControl}
              />
              {this.state.LayerControlVisible && (
                <MapLayerControl active hideMapLayerControl={this.hideMapLayerControl}>
                  <TopLevelContainer>
                    {((imageLayers && imageLayers.length > 0) ||
                      overviewField ||
                      overviewReport ||
                      this.isCreatingField()) && (
                      <div>
                        <p>Layers</p>
                        {annotations && (
                          <TopLevelCheckbox
                            onChange={this.handleAnnotationToggle}
                            defaultChecked={this.state.AnnotationsEnabled}
                            title='Annotations'
                          />
                        )}
                        {displayImageMetaDataLayers && imageLayers && imageLayers.length > 0 && (
                          <div>
                            {currentLayer && currentLayer.scoutingZones && (
                              <TopLevelCheckbox
                                onChange={this.handleObservationToggle}
                                defaultChecked={this.state.ObservationsEnabled}
                                title='Scout Zones'
                              />
                            )}
                            {currentLayer && (
                              <TopLevelCheckbox
                                onChange={event =>
                                  this.setState({
                                    LegendEnabled: event.target.checked,
                                  })
                                }
                                defaultChecked={this.state.LegendEnabled}
                                disabled={!this.state.LayersEnabled}
                                title='LEGEND'
                              />
                            )}
                            <hr />
                          </div>
                        )}
                        {(overviewField || overviewReport) && (
                          <TopLevelCheckbox
                            onChange={this.toggleSoils}
                            defaultChecked={this.state.soilsEnabled}
                            title='SOILS'
                          />
                        )}
                        {this.isCreatingField() && (
                          <TopLevelCheckbox
                            onChange={this.handleCluLayerToggle}
                            defaultChecked={this.state.CluLayerEnabled}
                            title='Common Land Units'
                          />
                        )}
                      </div>
                    )}
                  </TopLevelContainer>

                  {this.baseMaps && (
                    <BaseMapContainer>
                      {Object.keys(this.baseMaps).map(mapKey => {
                        return (
                          <BaseMapRadioButton
                            key={mapKey}
                            mapKey={mapKey}
                            onChange={() => this.switchBaseLayer(mapKey)}
                            defaultChecked={mapKey === this.state.activeBaseLayerKey}
                          />
                        )
                      })}
                    </BaseMapContainer>
                  )}

                  {currentLayer && currentLayer.mapDownloadUrl && (
                    <DownloadButtonContainer
                      disabled={!this.state.LayersEnabled}
                      image={currentLayer}
                      onClick={event => {
                        event.preventDefault()
                        window.open(event.target.value, '_blank')
                      }}
                    />
                  )}
                </MapLayerControl>
              )}
            </div>
          )}
          {overviewField && bounds && bounds.length === 0 && (
            <div className={styles.mapNoBounds}>
              <div className={styles.mapNoBoundsBackground} />
              <h3 className={styles.mapNoBoundsText}>No image available for this field</h3>
            </div>
          )}
          {this.state.image && (
            <Modal
              bsSize='large'
              dialogClassName='modalLightbox'
              show={this.state.showLightbox}
              onHide={this.closeLightbox}
            >
              <Modal.Header closeButton />
              <Image
                src={this.state.image.url}
                alt={this.state.image && this.state.image.alt ? this.state.image.alt : ''}
                responsive
              />
            </Modal>
          )}
        </div>
        <div className={styles.LeafletMapFooter}>
          <TransparencySlider
            handleLayerTransparency={value => {
              this.setState({ LayersTransparency: value })

              try {
                // Set all layers to the transparency level
                for (const key in this.imageLayers) {
                  if (this.imageLayers.hasOwnProperty(key)) {
                    const layer = this.imageLayers[key]
                    layer.setOpacity(value * 0.01) // Convert from 0-100 to 0-1 scale
                  }
                }
              } catch (err) {
                console.log('Could not change layer opacity', err)
              }
            }}
            LayersEnabled={this.state.LayersEnabled}
            value={this.state.LayersTransparency}
          />
        </div>
      </React.Fragment>
    )
  }
}

LeafletMap.propTypes = {
  showLayerControls: PropTypes.bool,
  imageLayers: PropTypes.array,
  bounds: PropTypes.array,
  fields: PropTypes.array,
  overviewCountry: PropTypes.bool,
  overviewFarm: PropTypes.bool,
  overviewField: PropTypes.bool,
  overviewReport: PropTypes.bool,
  openField: PropTypes.func,
  createField: PropTypes.bool,
  fetchClus: PropTypes.func,
  setSelectedPolygon: PropTypes.func,
  geometry: PropTypes.object,
  changeActiveWorkOrder: PropTypes.func,
  activeWorkOrder: PropTypes.string,
  issues: PropTypes.array,
  annotationMarkerAdded: PropTypes.func,
  handleDeleteAnnotation: PropTypes.func,
  annotations: PropTypes.array,
  displayAnnotationTool: PropTypes.bool,
  displayTimeline: PropTypes.bool,
  hasSeasons: PropTypes.bool,
  saveEditField: PropTypes.func,
  uploadedShapes: PropTypes.object,
  displayImageMetaDataLayers: PropTypes.bool,
  displayScoutingZones: PropTypes.bool,
  injectedPolygons: PropTypes.array,
}

LeafletMap.defaultProps = {
  displayAnnotationTool: false,
  displayTimeline: true,
  displayDrawTool: false,
  displayImageMetaDataLayers: true,
  displayScoutingZones: true,
}
