import { GET_FARM, GET_FARM_SUCCESS, GET_FARM_FAIL } from '../modules/farms'

const GET_FIELDS = 'aker/FIELDS/GET_FIELDS'
const GET_FIELDS_SUCCESS = 'aker/FIELDS/GET_FIELDS_SUCCESS'
const GET_FIELDS_FAIL = 'aker/FIELDS/GET_FIELDS_FAIL'

const GET_FIELD = 'aker/FIELDS/GET_FIELD'
const GET_FIELD_SUCCESS = 'aker/FIELDS/GET_FIELD_SUCCESS'
const GET_FIELD_FAIL = 'aker/FIELDS/GET_FIELD_FAIL'

const ADD_FIELD = 'aker/FIELDS/ADD_FIELD'
const ADD_FIELD_SUCCESS = 'aker/FIELDS/ADD_FIELD_SUCCESS'
const ADD_FIELD_FAIL = 'aker/FIELDS/ADD_FIELD_FAIL'

const initialState = {
  fields: {
    loaded: false,
  },
  field: {
    loaded: false,
  },
}

export default function fields(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_FIELDS_SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_FIELDS_FAIL:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_FIELD:
      return {
        ...state,
        field: {
          ...state.field,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case GET_FIELD_SUCCESS:
      return {
        ...state,
        field: {
          ...state.field,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_FIELD_FAIL:
      return {
        ...state,
        field: {
          ...state.field,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case ADD_FIELD:
      return {
        ...state,
        field: {
          ...state.field,
          loading: true,
        },
      }
    case ADD_FIELD_SUCCESS:
      return {
        ...state,
        farm: {
          ...state.farm,
          data: {
            ...state.farm.data,
            fields: [
              ...state.farm.data.fields,
              {
                title: action.result.data.title,
                id: action.result.data.id,
                issues: {
                  issueCount: 0,
                  damage: 0,
                },
              },
            ],
          },
        },
        field: {
          ...state.field,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        currentSeason: null,
        activeWorkOrder: null,
      }
    case ADD_FIELD_FAIL:
      const fieldErrors = action.error.errors.map(error => {
        return {
          method: 'add',
          ...error,
        }
      })
      return {
        ...state,
        field: {
          loaded: false,
          loading: false,
          error: fieldErrors,
          data: null,
        },
      }
    default:
      return state
  }
}

export function isFieldsLoaded(globalState, farmId) {
  return (
    globalState.fields &&
    globalState.fields.fields &&
    globalState.fields.fields.loaded &&
    globalState.fields.fields.data.every(field => field.farm.id === farmId)
  )
}

export function getFields(farmId = null) {
  let promise
  if (farmId) {
    promise = client => client.get(`/farm/${farmId}/fields`)
  } else {
    promise = client => client.get('/fields')
  }
  return {
    types: [GET_FIELDS, GET_FIELDS_SUCCESS, GET_FIELDS_FAIL],
    promise,
  }
}

export function isFieldLoaded(globalState, fieldId) {
  return (
    globalState.fields &&
    globalState.fields.field &&
    globalState.fields.field.loaded &&
    (fieldId ? globalState.fields.field.data.id === parseInt(fieldId, 10) : true)
  )
}

export function getField(fieldId) {
  return {
    types: [GET_FIELD, GET_FIELD_SUCCESS, GET_FIELD_FAIL],
    promise: client => client.get(`/field/${fieldId}`),
  }
}

export function updateField(requestParams) {
  return {
    types: [GET_FIELD, GET_FIELD_SUCCESS, GET_FIELD_FAIL],
    promise: client =>
      client.patch(`/field/${requestParams.fieldId}`, {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function deleteField(fieldId) {
  return {
    types: [GET_FARM, GET_FARM_SUCCESS, GET_FARM_FAIL],
    promise: client => client.del(`/field/${fieldId}`),
  }
}

export function addField(farmId, title, polygon, bounds, acres) {
  return {
    types: [ADD_FIELD, ADD_FIELD_SUCCESS, ADD_FIELD_FAIL],
    promise: client =>
      client.post('/field', {
        data: {
          data: {
            farmId,
            title,
            polygon,
            bounds,
            acres,
          },
        },
      }),
  }
}
