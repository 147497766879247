import Leaflet from 'leaflet'
import * as turf from '@turf/turf'

export function GeoJsonParser(drawnItems) {
  const layerObj = {}
  let geoJson
  let unionTemp

  drawnItems.eachLayer(layer => {
    if (!unionTemp) {
      unionTemp = layer.toGeoJSON()
    } else {
      unionTemp = turf.union(unionTemp, layer.toGeoJSON())
    }
  })
  geoJson = Leaflet.geoJson(unionTemp)

  const bounds = geoJson.getBounds()
  const formattedBounds = [
    [bounds._northEast.lat, bounds._northEast.lng],
    [bounds._southWest.lat, bounds._southWest.lng],
  ]

  const rawGeometry = geoJson._layers[Object.keys(geoJson._layers)[0]]
  const polygon = rawGeometry.toGeoJSON()
  const size = (turf.area(polygon) * 0.000247105).toFixed(2)

  return {
    ...layerObj,
    bounds: formattedBounds,
    polygon,
    size,
  }
}

export function PolygonToFeatureGeometry(polygon) {
  let geoJson = Leaflet.geoJson(polygon)
  const rawGeometry = geoJson._layers[Object.keys(geoJson._layers)[0]]
  return rawGeometry.toGeoJSON()
}

export function BoundsCenter(bounds) {
  return Leaflet.bounds(bounds).getCenter()
}
