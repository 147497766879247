import React from 'react'
import PropTypes from 'prop-types'
import styles from './RevXResultsToolShow.module.scss'

export function MapLegendComponent({ type, ...props }) {
  const thermalLegend = require('../../../../assets/legends/thermal-legend_reversed.png')
  const ndviLegend = require('../../../../assets/legends/NDVI-legend_reversed.png')

  return (
    <div className={`${styles.legendContainer}`}>
      <div className={styles.legendLeft}>Less Healthy</div>
      {type === 'thermal' && (
        <div className={styles.layerLegendThermal}>
          <img src={thermalLegend} alt='legend relating colors to damage for thermal Imagery' />
        </div>
      )}

      {type === 'ndvi' && (
        <div className={styles.layerLegendNDVI}>
          <img src={ndviLegend} alt='legend relating colors to damage for NVDI Imagery' />
        </div>
      )}
      <div className={styles.legendRight}>More Healthy</div>
    </div>
  )
}

MapLegendComponent.propTypes = {
  type: PropTypes.string.isRequired,
}
