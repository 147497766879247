// import axios from 'axios'
import * as turf from '@turf/turf'
import { getAlphabetLetterFromIndex } from 'helpers'

export const getFieldsFromAPI = type => {
  if (type === 'climate') {
    const redirect = `https://climate.com/static/app-login/?scope=${'fields:read'}&page=oidcauthn&response_type=code&redirect_uri=${
      window.location.href
    }&client_id=${process.env.REACT_APP_CLIMATE_CLIENT_ID}`
    window.location.assign(redirect)
  }
  if ((type = 'myjd')) {
  }
}

export const getFieldsFromSessionStorage = type => {
  if (type === 'climate') {
    return window.sessionStorage.importedClimateFields
  }
  if (type === 'myjd') {
    return window.sessionStorage.importedMyJDFields
  }
}

export const fixUnusualFieldCoords = fields => {
  const newFieldsArr = []
  fields.forEach(field => {
    try {
      if (typeof field.geojson.coordinates[0][0][0] === 'number') {
        return newFieldsArr.push(field)
      } else {
        createSubFieldsOfMultiPolygonFields(field).forEach(field => {
          newFieldsArr.push(field)
        })
      }
    } catch {
      return null
    }
  })
  return newFieldsArr
}

export const createSubFieldsOfMultiPolygonFields = field => {
  const numOfSubFields = field.geojson.coordinates.length
  const subFields = []
  for (let i = 0; i < numOfSubFields; i++) {
    const subFieldLetter = getAlphabetLetterFromIndex(i)
    const newField = JSON.parse(JSON.stringify(field))
    newField.name = `${newField.name} - ${subFieldLetter}`
    newField.geojson.coordinates = newField.geojson.coordinates[i]
    newField.boundary = [newField.geojson.coordinates]
    const sqMetersPerAcre = 4046.85642245
    newField.acres = (
      turf.area(turf.polygon(newField.geojson.coordinates)) / sqMetersPerAcre
    ).toFixed(2)
    subFields.push(newField)
  }
  return subFields
}
