import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { SectionButtonContainer } from './SectionButtonContainer'
import styles from './ModalSections.module.scss'

export const InvitationConfirmationSection = ({
  handleCloseConfirmation,
  handleAddMoreUsers,
  subscribers,
}) => {
  return (
    <React.Fragment>
      <h2>Notifications have been sent!</h2>
      <p>Notifications and invites have been sent to the following emails.</p>
      <ul className={styles.subscribersList}>
        {subscribers.map((subscriber, index) => {
          return (
            <li key={index}>
              <h3>{subscriber.email}</h3>
              <span>{subscriber.role}</span>
            </li>
          )
        })}
      </ul>
      <SectionButtonContainer>
        <Button variant='outline-dark' onClick={handleAddMoreUsers}>
          Add More Users
        </Button>
        <Button variant='dark' onClick={handleCloseConfirmation}>
          Close
        </Button>
      </SectionButtonContainer>
    </React.Fragment>
  )
}

InvitationConfirmationSection.propTypes = {
  handleCloseConfirmation: PropTypes.func.isRequired,
  handleAddMoreUsers: PropTypes.func.isRequired,
  subscribers: PropTypes.array.isRequired,
}
