/* eslint no-loop-func: 0*/
/* eslint array-callback-return: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import YieldAnalysisChart from '../YieldAnalysisChart/YieldAnalysisChart'
import { browserHistory } from 'react-router'
import moment from 'moment'
import L from 'leaflet'
import { FaCircle, FaArrowUp, FaArrowLeft } from 'react-icons/fa'
import classNames from 'classnames'
import styles from './TestPlotToolShow.module.scss'

const connectWrapper = connect(state => ({
  template: state.reports.report.data,
  activeSeason: state.seasons.activeSeason.data,
  account: state.account.data,
}))

class TestPlotToolShowComponent extends Component {
  constructor(props) {
    super(props)
    this.maps = {}

    const mappedWorkOrders = {}
    ;[0, 1].forEach(element => {
      if (props.template && props.template.workOrders && props.template.workOrders[element]) {
        const workOrder = props.template.workOrders[element]
        mappedWorkOrders[workOrder.position] = {
          id: workOrder.id,
          position: workOrder.position,
          dateFlown: moment(workOrder.dateFlown).format('MM/DD/YYYY'),
          growthStage: workOrder.growthStage,
          leafletId: `leafletmap${workOrder.position}`,
        }
      }
    })
    this.state = {
      title: props.template && props.template.title,
      //
      workOrders: mappedWorkOrders,
      //
      trialDetailsLocation: props.template && props.template.trialDetailsLocation,
      trialDetailsHybrid: props.template && props.template.trialDetailsHybrid,
      trialDetailsApplicationDate: props.template && props.template.trialDetailsApplicationDate,
      //
      contactPhone: props.template && props.template.contactPhone,
      contactName: props.template && props.template.contactName,
      //
      chemicalUsed: props.template && props.template.chemicalUsed,
      //
      trials: props.activeSeason.trials,
      // UI_Activate3rdWO: false
      primaryColor:
        props.primaryColor || (props.template && props.template.primaryColor) || '#0095FF',
      highlights: props.highlights || (props.template && props.template.highlights),
      commodityPrice: props.template.commodityPrice,
      logoUrl: props.template && props.template.logoUrl,
      footnote: props.template && props.template.footnote,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.initializeMaps()
    }, 100)
  }

  onNavigateTo(route) {
    browserHistory.push(route)
  }

  getCropType = () => {
    return this.props.activeSeason.crop
  }

  getMapLayerFromWorkOrderId(workOrderId) {
    const activeSeason = this.props.activeSeason
    if (activeSeason) {
      return activeSeason.mapLayers.find(layer => layer.workOrderId === parseInt(workOrderId, 10))
    }
  }

  initializeMaps = () => {
    const field = this.props.activeSeason.field

    for (const position in this.state.workOrders) {
      if (this.state.workOrders.hasOwnProperty(position)) {
        const map = L.map(`leafletmap${position}`, {
          center: [field.location[0], field.location[1]],
          zoomControl: false,
          attributionControl: false,
        }).fitBounds(field.bounds, {
          padding: [0, 0],
          animated: false,
        })

        map.touchZoom.disable()
        map.doubleClickZoom.disable()
        map.scrollWheelZoom.disable()
        map.boxZoom.disable()
        map.keyboard.disable()
        map.dragging.disable()

        this.maps[`leafletmap${position}`] = map
      }
    }

    if (this.props.activeSeason.trials && this.props.activeSeason.trials.length > 0) {
      const trialMap = L.map(`leafletTrials`, {
        center: [field.location[0], field.location[1]],
        zoomControl: false,
        attributionControl: false,
      }).fitBounds(field.bounds, {
        padding: [0, 0],
        animated: false,
      })
      trialMap.touchZoom.disable()
      trialMap.doubleClickZoom.disable()
      trialMap.scrollWheelZoom.disable()
      trialMap.boxZoom.disable()
      trialMap.keyboard.disable()
      trialMap.dragging.disable()

      if (field.geometry) {
        const fieldLayer = L.geoJson(field.geometry, {
          color: 'black',
          fillColor: 'none',
          weight: 2,
          opacity: 1,
          lineJoin: 'round',
          // 'dashArray': '20,15',
        })
        fieldLayer.addTo(trialMap)
      }
      this.props.activeSeason.trials.map((trial, index) => {
        const layer = L.geoJson(trial.polygon, {
          color: 'black',
          fillColor: 'none',
          weight: 1,
          opacity: 1,
          lineJoin: 'round',
          // 'dashArray': '20,15',
        })
        layer.addTo(trialMap)
        const label = L.marker(layer.getBounds().getCenter(), {
          clickable: false,
          keyboard: false,
          icon: L.divIcon({
            html: `(${index + 1})`,
            iconSize: [28, 28],
            className: 'leaflet-text-label',
          }),
        })

        label.addTo(trialMap)
      })
    }

    this.renderMaps()
  }

  renderMaps = () => {
    // TODO: Add the line drawings/polygons
    // const field = this.props.field;

    for (const position in this.state.workOrders) {
      if (this.state.workOrders.hasOwnProperty(position)) {
        const workOrder = this.state.workOrders[position]
        if (!workOrder.id) {
          return
        }

        const map = this.maps[workOrder.leafletId]

        if (map) {
          map.eachLayer(layer => {
            map.removeLayer(layer)
          })
        }
        const mapLayer = this.getMapLayerFromWorkOrderId(workOrder.id)
        L.tileLayer(mapLayer.tileUrl).addTo(map)

        if (this.props.activeSeason.trials && this.props.activeSeason.trials.length > 0) {
          this.props.activeSeason.trials.map(trial => {
            const layer = L.geoJson(trial.polygon, {
              color: 'black',
              fillColor: 'none',
              weight: 2,
              opacity: 1,
              lineJoin: 'round',
              // 'dashArray': '20,15',
            })
            layer.addTo(map)
          })
        }
      }
    }
  }

  render() {
    const akerLogo = require('assets/logo-aker-white.svg')
    const brandColor = this.state.primaryColor
    // add some transparency to the brand color to make it 'lighter'.
    const brandColorLight = this.state.primaryColor + '2e'
    const highlights = this.state.highlights
    const activeSeason = this.props.activeSeason
    const productHeader = this.props.template.productHeader
    const template = this.props.template

    const shouldShowLegend = wo => {
      if (!wo) {
        return false
      }

      if (wo.type === 'thermal' || wo.type === 'ndvi') {
        return true
      }
    }

    const renderWorkOrders = () => {
      const workOrdersComponent = []
      const workOrders = template.workOrders
      const renderTimePassage = (workOrder, comparerWorkOrder) => {
        if (workOrder && comparerWorkOrder) {
          const diff = Math.floor(
            (new Date(workOrder.dateFlown) - new Date(comparerWorkOrder.dateFlown)) / 86400000
          )
          if (diff && diff > 0) {
            return (
              <div>
                Time Passage: <b>{diff} Days</b>
              </div>
            )
          }
        }
        return (
          <div>
            Time Passage: <b>-</b>
          </div>
        )
      }
      for (let position = 0; position < workOrders.length; position++) {
        const wo = workOrders[position]
        const thermalLegend = require('../../../assets/legends/thermal-legend.png')
        const ndviLegend = require('../../../assets/legends/NDVI-legend.png')

        workOrdersComponent.push(
          <div
            key={position}
            className={classNames(null, {
              [styles.center]: workOrders.length === 1,
              'col-12': workOrders.length === 1,
              'col-6': workOrders.length > 1,
            })}
          >
            <div className={styles.mapContainer}>
              <div id={`leafletmap${position}`} style={{ width: '100%', height: 300 }}></div>
            </div>
            <div>
              <div className={styles.h2} style={{ color: brandColor }}>
                {workOrders.length > 1 &&
                  (position > 0 ? 'After Application' : 'Before Application')}
                {workOrders.length === 1 && 'Application'}
              </div>
              <div>
                Date: <b>{moment.utc(wo.dateFlown).format('MM/DD/YYYY')}</b>
              </div>
              <div>
                Crop Stage: <b>{wo.growthStage ? wo.growthStage : '-'}</b>
              </div>
              {renderTimePassage(wo, workOrders[0])}
            </div>
            {shouldShowLegend(wo) && (
              <div className={`${styles.legendContainer}`}>
                {wo.type === 'thermal' && (
                  <div className={styles.layerLegendThermal}>
                    <img src={thermalLegend} alt={'legend for thermal imagery'} />
                  </div>
                )}

                {wo.type === 'ndvi' && (
                  <div className={styles.layerLegendNDVI}>
                    <img src={ndviLegend} alt={'legend for ndvi imagery'} />
                  </div>
                )}
                <div className={styles.legendLeft}>Healthy</div>
                <div className={styles.legendRight}>Less Healthy</div>
              </div>
            )}
          </div>
        )
      }
      return workOrdersComponent
    }

    const contactInfoComponent = () => {
      if (this.state.contactName || this.state.contactPhone) {
        return (
          <div className={styles.testPlotContainer}>
            <div className={styles.h2} style={{ color: brandColor }}>
              Questions?
            </div>
            {this.state.contactName && (
              <div>
                <span>
                  Name: <b>{this.state.contactName}</b>
                </span>
              </div>
            )}
            {this.state.contactPhone && (
              <div>
                <span>
                  Phone: <b>{this.state.contactPhone}</b>
                </span>
              </div>
            )}
          </div>
        )
      }
    }

    const calculateYieldAdvantage = () => {
      const yieldValues = this.state.trials.reduce((result, trial) => {
        if (trial.yield) {
          result.push(trial.yield)
        }
        return result
      }, [])
      yieldValues.sort((first, second) => {
        return first - second
      })

      if (yieldValues.length === 0) {
        return null
      } else if (yieldValues.length === 1) {
        return yieldValues[0].toFixed(1)
      }
      return (yieldValues[yieldValues.length - 1] - yieldValues[0]).toFixed(1)
    }
    const yieldAdvantage = calculateYieldAdvantage()

    const calculateRevenueAdvantage = () => {
      if (yieldAdvantage && this.state.commodityPrice) {
        return (yieldAdvantage * this.state.commodityPrice).toFixed(2)
      }
    }
    const revenueAdvantage = calculateRevenueAdvantage()

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Helmet title='View Test Plot' />
        <section className={styles.TestPlotTool + ' ' + styles.print}>
          <style
            dangerouslySetInnerHTML={{
              __html: `
                @media print {
                  .fa-circle:before,
                  .fa-arrow-up:before{
                    color: ${brandColor};
                  }
                }
            `,
            }}
          />

          <button
            type='button'
            className={`btn btn-link ${styles.tptBackBtn}`}
            onClick={() =>
              this.onNavigateTo(
                `/farm/${activeSeason.farmId}/field/${activeSeason.field.id}/season/${activeSeason.id}/reports`
              )
            }
          >
            <FaArrowLeft />
            &nbsp;Back
          </button>

          <div className='row'>
            <div className='col-8'></div>
            <div className='col-4' style={{ overflow: 'hidden', height: 40 }}>
              <div
                className={styles.brandColorBorderHax}
                style={{
                  borderRight: '600px solid ' + brandColorLight,
                  transform: 'translate(-30px,0px)',
                }}
              ></div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12' style={{ overflow: 'hidden' }}>
              <div
                className={styles.brandColorBorderHax}
                style={{ borderRight: '1390px solid ' + brandColor }}
              ></div>
              <div className={styles.brandedHeaderBar}>
                <img
                  className={styles.brandLogo}
                  alt='Company Logo'
                  src={this.props.account.companyLogo ? this.props.account.companyLogo : akerLogo}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-8 main-content'>
              <div
                data-section='header-main'
                className={styles.pageTitle}
                style={{ color: brandColor }}
              >
                {template.title}
              </div>

              <div className={styles.testPlotContainer}>
                <div className={styles.h1}>Plant Health Imagery</div>
                <div className='row'>{renderWorkOrders()}</div>
              </div>

              <div data-section='analysis' className={styles.testPlotContainer}>
                <div className={styles.h1}>Yield Analysis</div>
                <div className='chart'>
                  <YieldAnalysisChart trials={this.state.trials} primaryColor={brandColor} />
                </div>
              </div>
              <div data-section='yield-revenue' className={styles.testPlotContainer}>
                <div className='row'>
                  <div className='col-6'>
                    <div className={styles.h1}>Yield Advantage</div>
                    <div>
                      <FaArrowUp
                        style={{
                          color: brandColor,
                          fontSize: '2em',
                        }}
                      />
                      <span className={styles.yieldValue} style={{ color: brandColor }}>
                        {yieldAdvantage ? `${yieldAdvantage} bu/acre` : 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className={styles.h1}>Revenue Advantage</div>
                    <div>
                      <FaArrowUp
                        style={{
                          color: brandColor,
                          fontSize: '2em',
                        }}
                      />
                      <span className={styles.yieldValue} style={{ color: brandColor }}>
                        {revenueAdvantage ? `$${revenueAdvantage} USD/acre` : 'N/A'}
                      </span>
                      {this.state.commodityPrice && (
                        <div data-section='smallprint' className={styles.smallPrint}>
                          <div className={styles.smallText}>
                            *based on ${this.state.commodityPrice.toFixed(2)}
                            /bu
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`row ${styles.footer}`}>
                <div className={`col-12 ${styles.small}`}>{this.state.footnote}</div>
              </div>
              <div className={`row ${styles.footer}`}>
                <div className={`col-12 ${styles.small}`}>
                  Aerial imagery and document template is copyright of Aker Technologies, Inc. All
                  Rights Reserved.
                </div>
              </div>
            </div>

            <div className={'col-4 ' + styles.testPlotSidebar} style={{ overflow: 'hidden' }}>
              <div
                className={styles.brandColorBorderHax}
                style={{
                  borderRight: '600px solid ' + brandColorLight,
                  transform: 'translate(-30px,-30px)',
                }}
              ></div>
              <div style={{ zIndex: 3 }}>
                <div className={styles.testPlotContainer}>
                  {highlights && highlights.length > 0 && (
                    <div className={styles.testPlotContainer}>
                      <div className={styles.h2} style={{ color: brandColor }}>
                        Why Aker&trade; Ag Drone Imagery?
                      </div>
                      <div>
                        {highlights.map((highlight, index) => {
                          return (
                            <div key={index} className={`${styles.highlightWrapper}`}>
                              <div className={`${styles.bullet}`}>
                                <FaCircle
                                  style={{
                                    color: brandColor,
                                  }}
                                />
                              </div>
                              <div className={styles.highlight}>{highlight}</div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.testPlotContainer}>
                  <div className={styles.h1}>{productHeader}</div>
                  <div className={styles.h2} style={{ color: brandColor }}>
                    On Farm Trial Details
                  </div>
                  <div>
                    <div>
                      Location: <b>{template.location}</b>
                    </div>
                    <div>
                      Crop: <b>{template.crop}</b>
                    </div>
                    <div>
                      Hybrid/Variety: <b>{template.seedType ? template.seedType : '-'}</b>
                    </div>
                    <div>
                      Planting Date:{' '}
                      <b>
                        {activeSeason.plantDate &&
                          moment.utc(activeSeason.plantDate).format('MM/DD/YYYY')}
                      </b>
                    </div>
                    <div>
                      Application Date:{' '}
                      <b>
                        {template.applicationDate &&
                          moment.utc(template.applicationDate).format('MM/DD/YYYY')}
                      </b>
                    </div>
                  </div>
                </div>

                <div className={styles.testPlotContainer}>
                  {this.state.trials && this.state.trials.length !== 0 && (
                    <div className={styles.h2} style={{ color: brandColor }}>
                      Treatment Details
                    </div>
                  )}
                  {this.state.trials &&
                    this.state.trials.length !== 0 &&
                    this.state.trials.map((trial, index) => {
                      return (
                        <div key={trial.id} className={styles.treatmentDetail}>
                          <div>
                            ({index + 1}){' '}
                            {trial.isUntreated
                              ? 'No Fungicide Applied'
                              : `${trial.productName.charAt(0).toUpperCase() +
                                  trial.productName.slice(1)}`}
                          </div>
                          <div>
                            <div className={styles.treatmentDetailsList}>
                              {trial.appliedComments &&
                                trial.appliedComments.map((comment, commentIndex) => {
                                  return (
                                    <li key={commentIndex} className={styles.appliedComment}>
                                      <span> {comment} </span>
                                    </li>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  {this.state.trials && this.state.trials.length > 0 && (
                    <div className={styles.trialsMapContainer}>
                      <div id={`leafletTrials`} style={{ width: '100%', height: 200 }}></div>
                    </div>
                  )}
                </div>
                {contactInfoComponent()}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

TestPlotToolShowComponent.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  primaryColor: PropTypes.string,
  highlights: PropTypes.array,
  account: PropTypes.object.isRequired,
}

export default connectWrapper(TestPlotToolShowComponent)
