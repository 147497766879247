import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Dropdown } from '_shared'
import { CropsContext, LeafletMap } from 'domains'
import styles from '../AerialImageryReport.module.scss'

export function FlightPicker({
  season,
  flights,
  regions,
  showGrowthStage,
  selectedFlightId,
  selectedGrowthStage,
  selectedCropId,
  onFlightChange,
  onGrowthStageChange,
  label,
  name,
}) {
  const { crops } = React.useContext(CropsContext)

  const [selectedFlight, setSelectedFlight] = useState(flights.find(f => f.id === selectedFlightId))
  const [cropStageOptions, setCropStageOptions] = useState([])

  useEffect(() => {
    if (showGrowthStage) {
      const selectedCrop = crops.find(c => c.id === selectedCropId)
      if (selectedCrop) {
        const options = selectedCrop.growthStages.map(gs => ({ value: gs, label: gs }))
        setCropStageOptions(options)
      }
    }
  }, [showGrowthStage, selectedCropId, crops])

  const flightOptions = flights.map(f => ({
    value: f.id,
    label: `${moment.utc(f.dateFlown).format('MM/DD/YYYY')} - ${f.type.toUpperCase()}`,
  }))

  const handleFlightChange = selectedOption => {
    const flight = flights.find(f => f.id === selectedOption.value)
    setSelectedFlight(flight)
    onFlightChange(flight)
  }

  return (
    <div className={styles.flightDataPicker}>
      <div className={styles.aerialImageryMap}>
        <LeafletMap
          hideLayerControls
          hideTransparencySlider
          hideZoomControls
          zoomDisabled
          panDisabled
          hideLegend
          hideBaseLayers
          bounds={season.field.bounds}
          boundaryGeometry={season.field.geometry}
          innerGeometries={
            regions &&
            selectedFlight &&
            regions.map(r => ({ id: r.id, geometry: r.geometry, display: true }))
          }
          innerGeometriesOutlineOnly
          activeImage={selectedFlight}
        />
      </div>
      <Dropdown
        name={`flightDropdown${name}`}
        label={label}
        options={flightOptions}
        value={flightOptions.find(opt => opt.value === selectedFlightId)}
        onChange={handleFlightChange}
        placeholder='Please select a flight date'
      />
      {showGrowthStage && (
        <Dropdown
          name={`growthStageDropdown${name}`}
          label='Crop stage'
          options={cropStageOptions}
          value={cropStageOptions.find(opt => opt.value === selectedGrowthStage)}
          onChange={selectedOption => onGrowthStageChange(selectedOption.value)}
          placeholder='Please select a crop stage'
        />
      )}
    </div>
  )
}

FlightPicker.propTypes = {
  season: PropTypes.object,
  flights: PropTypes.array,
  regions: PropTypes.array,
  showGrowthStage: PropTypes.bool,
  selectedFlightId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedCropId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFlightChange: PropTypes.func,
  onGrowthStageChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
}
