import { createValidator, email, required, match, minLength } from 'helpers'

/**
 * Applied rules to all possible form fields.
 *
 * @type {Object}   Field names and applieds rule
 */
const validationRules = {
  name: [required],
  farmTitle: [required],
  email: [email, required],
  zipCode: [required],
  password: [required, minLength(8)],
  passwordConfirmation: [required, match('password')],
}

/**
 * Active fields are dynamicly added based on the the fields passed as arugment.
 * @param  {Array} fields List of fields active inside the form
 * @return {Object}       Object with invalid fields and the error message
 */
const signUpFormValidation = createValidator(validationRules)

export default signUpFormValidation
