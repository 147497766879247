import React from 'react'
import PropTypes from 'prop-types'
import styles from './ImageReviewer.module.scss'

export const TaggedFindings = ({ findings, handleRemoveClick }) => {
  return (
    <div className={styles.taggedFindingsContainer}>
      <div className={styles.findingPills}>
        {findings.map(tag => {
          return (
            <div key={tag.id}>
              <p>
                {tag.name}{' '}
                <button
                  className={styles.removeTaggedFindingIcon}
                  onClick={() => handleRemoveClick(tag.id)}
                >
                  X
                </button>
              </p>
              {tag.taggedBy.email && <em>{tag.taggedBy.email}</em>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

TaggedFindings.propTypes = {
  findings: PropTypes.array.isRequired,
}
