import React from 'react'
import styles from './AerialImageryDownload.module.scss'

export const AerialImageryDownload = ({
  label,
  name,
  taskUUID,
  customerId,
  selectedDownload,
  setSelectedDownload,
}) => {
  const toggleDownloadModal = e => {
    e && e.stopPropagation()
    let newName = null
    if (selectedDownload !== name) newName = name
    setSelectedDownload(newName)
  }

  const downloadFiles = async fileTypes => {
    const bucket =
      process.env.REACT_APP_ENV === 'production' ? 'aker-flights' : 'aker-flights-staging'
    for await (let fileType of fileTypes) {
      window.open(
        `https://s3.amazonaws.com/${bucket}/workorders/${customerId}/${taskUUID}${fileType}`
      )
    }
    toggleDownloadModal()
  }

  const renderDownloadOption = (title, downloadTypes) => {
    return (
      <div className={styles.optionsRow}>
        <span>{title}</span>
        <i className='fas fa-cloud-download-alt' onClick={() => downloadFiles(downloadTypes)} />
      </div>
    )
  }

  return (
    <>
      <div
        className={`${styles.downloadIcon} ${selectedDownload === name && styles.buttonClicked}`}
        onClick={e => toggleDownloadModal(e)}
      >
        <i className='fas fa-cloud-download-alt' />
      </div>
      {selectedDownload === name && (
        <div className={styles.downloadModal}>
          <h3>Download Files</h3>
          <div className={styles.options}>
            {renderDownloadOption('Index tif', ['_index.tif'])}
            {label.toLowerCase() !== 'rgb' &&
              (label.toLowerCase() !== 'thermal' &&
                renderDownloadOption('Color tif', ['.tif', '.pdf']))}
          </div>
        </div>
      )}
    </>
  )
}
