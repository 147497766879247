import React from 'react'
import styles from './LeafletMap.module.scss'

export function NoImageAvailableForField() {
  return (
    <div className={styles.mapNoBounds}>
      <div className={styles.mapNoBoundsBackground} />
      <h3 className={styles.mapNoBoundsText}>No image available for this field</h3>
    </div>
  )
}
