import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useRect } from '@reach/rect'
import { FaCalendar, FaLeaf, FaClock } from 'react-icons/fa'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportModernImageDetails({ flight, compareDate, brandColor, label }) {
  const labelRef = useRef()
  const rect = useRect(labelRef)

  const renderTimePassage = () => {
    let diff = 0
    if (compareDate) {
      diff = moment.utc(flight.dateFlown).diff(moment.utc(compareDate), 'days')
    }

    return (
      <React.Fragment>
        Time Passage: <strong>{diff > 0 ? `${diff} Days` : '-'}</strong>
      </React.Fragment>
    )
  }

  const renderLabel = () => {
    // This is needed to get the correct width for the rotated

    return (
      <div
        ref={labelRef}
        className={styles.rotatedText}
        style={{
          color: brandColor,
          marginLeft: rect && rect.width * 2 * -1,
          marginTop: rect && rect.width * 2 * -1,
        }}
      >
        {label}
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderLabel()}
      <div className={styles.iconContainer}>
        <div>
          <span className={styles.iconWrapper}>
            <FaCalendar style={{ color: brandColor }} />
          </span>
          Date: <strong>{moment.utc(flight.dateFlown).format('MM/DD/YYYY')}</strong>
        </div>
        {flight.growthStage && (
          <div>
            <span className={styles.iconWrapper}>
              <FaLeaf style={{ color: brandColor }} />
            </span>
            Crop Stage: <strong>{flight.growthStage}</strong>
          </div>
        )}

        <div>
          <span className={styles.iconWrapper}>
            <FaClock style={{ color: brandColor }} />
          </span>
          {renderTimePassage()}
        </div>
      </div>
    </React.Fragment>
  )
}

AerialImageryReportModernImageDetails.propTypes = {
  compareDate: PropTypes.string,
  flight: PropTypes.shape({ dateFlown: PropTypes.string }).isRequired,
  brandColor: PropTypes.string.isRequired,
  label: PropTypes.string,
}
