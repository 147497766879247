import React from 'react'
import PropTypes from 'prop-types'
import { LeafletMap } from 'domains'
import styles from '../AerialImageryReport.module.scss'

export function TreatmentDetailsMap({ season, regions, mapIsLoaded }) {
  if (!regions || regions.length === 0) return null
  return (
    <div className={styles.trialsMapContainer}>
      <div className={styles.regionsMap}>
        <LeafletMap
          hideLayerControls
          hideTransparencySlider
          hideZoomControls
          zoomDisabled
          panDisabled
          hideLegend
          hideBaseLayers
          bounds={season ? season.field.bounds : []}
          boundaryGeometry={season ? season.field.geometry : {}}
          boundaryOutlineColor='#000'
          innerGeometries={
            regions && regions.map(r => ({ id: r.id, geometry: r.geometry, display: true }))
          }
          innerGeometriesOutlineOnly
          innerGeometriesOutlineColor='#000'
          mapIsLoaded={mapIsLoaded}
        />
      </div>
    </div>
  )
}

TreatmentDetailsMap.propTypes = {
  season: PropTypes.object,
  regions: PropTypes.array,
}
