import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from '../AerialImageryReport.module.scss'

export function StackedTrialImageDetails({ flight, applicationDate, brandColor }) {
  const getDaysSinceApplication = () => {
    const recentFlightDate = moment.utc(flight.dateFlown)
    let days

    if (applicationDate) {
      const applDate = moment.utc(applicationDate)
      days = Math.floor(moment.duration(recentFlightDate.diff(applDate)).asDays())
    }
    return days
  }

  const daysSinceApplication = getDaysSinceApplication()

  return (
    <div className={styles.center}>
      <h2 className={styles.subheader} style={{ color: brandColor }}>
        <span>
          {flight.type.toUpperCase()} Date: {moment.utc(flight.dateFlown).format('MM/DD/YYYY')}
        </span>{' '}
        {daysSinceApplication &&
          (daysSinceApplication >= 0 ? (
            <span>{daysSinceApplication} Days After Treatment</span>
          ) : (
            <span>{daysSinceApplication * -1} Days Before Treatment</span>
          ))}
      </h2>
    </div>
  )
}

StackedTrialImageDetails.propTypes = {
  flight: PropTypes.shape({
    dateFlown: PropTypes.string,
  }).isRequired,
  applicationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  brandColor: PropTypes.string.isRequired,
}
