import React from 'react'
import PropTypes from 'prop-types'
import { SeasonsReportsAndTrials } from './SeasonsReportsAndTrials'
import { TableGroupingPrimaryHeader } from '_shared'

export function FarmsReportsAndTrials({ farmId, farmData, title, deleteReport, deleteTrial }) {
  const seasons = farmData.seasons

  const getDisplayName = season => {
    return `${season.field.title} - ${season.title}`
  }

  const sortBySeasonTitleAlphabetically = (seasonIdA, seasonIdB) => {
    var nameA = getDisplayName(seasons[seasonIdA]).toLowerCase() // ignore upper and lowercase
    var nameB = getDisplayName(seasons[seasonIdB]).toLowerCase() // ignore upper and lowercase
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    // names must be equal
    return 0
  }

  const getSortedSeasonIds = () => {
    const allSeasonIds = Object.keys(seasons)
    return allSeasonIds.sort(sortBySeasonTitleAlphabetically)
  }

  const sortedSeasonIds = getSortedSeasonIds()

  return (
    <React.Fragment>
      <TableGroupingPrimaryHeader text={title} />
      {sortedSeasonIds.map(seasonId => {
        const season = seasons[seasonId]

        return (
          <SeasonsReportsAndTrials
            key={seasonId}
            seasonData={season}
            farmId={farmId}
            title={getDisplayName(season)}
            deleteReport={deleteReport}
            deleteTrial={deleteTrial}
          />
        )
      })}
    </React.Fragment>
  )
}

FarmsReportsAndTrials.propTypes = {
  farmId: PropTypes.string,
  farmData: PropTypes.object,
  title: PropTypes.string,
  deleteReport: PropTypes.func,
  deleteTrial: PropTypes.func,
}
