const GET_TRIALS_BY_FIELD = 'aker/TRIALS/GET_TRIALS_BY_FIELD'
const GET_TRIALS_BY_FIELD_SUCCESS = 'aker/TRIALS/GET_TRIALS_BY_FIELD_SUCCESS'
const GET_TRIALS_BY_FIELD_FAIL = 'aker/TRIALS/GET_TRIALS_BY_FIELD_FAIL'

const GET_TRIALS_BY_SEASON = 'aker/TRIALS/GET_TRIALS_BY_SEASON'
const GET_TRIALS_BY_SEASON_SUCCESS = 'aker/TRIALS/GET_TRIALS_BY_SEASON_SUCCESS'
const GET_TRIALS_BY_SEASON_FAIL = 'aker/TRIALS/GET_TRIALS_BY_SEASON_FAIL'

const GET_TRIALS_BY_CUSTOMER_ACCOUNT = 'aker/TRIALS/GET_TRIALS_BY_CUSTOMER_ACCOUNT'
const GET_TRIALS_BY_CUSTOMER_ACCOUNT_SUCCESS = 'aker/TRIALS/GET_TRIALS_BY_CUSTOMER_ACCOUNT_SUCCESS'
const GET_TRIALS_BY_CUSTOMER_ACCOUNT_FAIL = 'aker/TRIALS/GET_TRIALS_BY_CUSTOMER_ACCOUNT_FAIL'

const DELETE_TRIAL = 'aker/TRIALS/DELETE_TRIAL'
const DELETE_TRIAL_SUCCESS = 'aker/TRIALS/DELETE_TRIAL_SUCCESS'
const DELETE_TRIAL_FAIL = 'aker/TRIALS/DELETE_TRIAL_FAIL'

const initialState = {
  trialsByField: {
    loaded: false,
  },
  trialsBySeason: {
    loaded: false,
  },
  trialsByCustomerAccount: {
    loaded: false,
  },
}

export default function trials(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TRIALS_BY_FIELD:
      return {
        ...state,
        trialsByField: {
          ...state.trialsByField,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_TRIALS_BY_FIELD_SUCCESS:
      return {
        ...state,
        trialsByField: {
          ...state.trialsByField,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_TRIALS_BY_FIELD_FAIL:
      return {
        ...state,
        trialsByField: {
          ...state.trialsByField,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_TRIALS_BY_SEASON:
      return {
        ...state,
        trialsBySeason: {
          ...state.trialsBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_TRIALS_BY_SEASON_SUCCESS:
      return {
        ...state,
        trialsBySeason: {
          ...state.trialsBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_TRIALS_BY_SEASON_FAIL:
      return {
        ...state,
        trialsBySeason: {
          ...state.trialsBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_TRIALS_BY_CUSTOMER_ACCOUNT:
      return {
        ...state,
        trialsByCustomerAccount: {
          ...state.trialsByCustomerAccount,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_TRIALS_BY_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        trialsByCustomerAccount: {
          ...state.trialsByCustomerAccount,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_TRIALS_BY_CUSTOMER_ACCOUNT_FAIL:
      return {
        ...state,
        trialsByCustomerAccount: {
          ...state.trialsByCustomerAccount,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case DELETE_TRIAL:
      return {
        ...state,
      }
    case DELETE_TRIAL_SUCCESS:
      return {
        ...state,
      }
    case DELETE_TRIAL_FAIL:
      return {
        ...state,
      }

    default:
      return state
  }
}

export function isTrialsByFieldLoaded(globalState) {
  return (
    globalState.trials &&
    globalState.trials.trialsByField &&
    globalState.trials.trialsByField.loaded
  )
}

export function getTrialsByFieldId(fieldId) {
  return {
    types: [GET_TRIALS_BY_FIELD, GET_TRIALS_BY_FIELD_SUCCESS, GET_TRIALS_BY_FIELD_FAIL],
    promise: client => client.get(`/field/${fieldId}/trials`),
  }
}

export function isTrialsBySeasonLoaded(globalState) {
  return (
    globalState.trials &&
    globalState.trials.trialsBySeason &&
    globalState.trials.trialsBySeason.loaded
  )
}

export function getTrialsBySeasonId(seasonId) {
  return {
    types: [GET_TRIALS_BY_SEASON, GET_TRIALS_BY_SEASON_SUCCESS, GET_TRIALS_BY_SEASON_FAIL],
    promise: client => client.get(`/seasons/${seasonId}/trials`),
  }
}

export function getTrialsByCustomerAccount(customerAccountId) {
  let promise
  if (!!customerAccountId) {
    promise = client => client.get(`/customer_accounts/${customerAccountId}/trials`)
  } else {
    promise = client => client.get(`/trials`)
  }
  return {
    types: [
      GET_TRIALS_BY_CUSTOMER_ACCOUNT,
      GET_TRIALS_BY_CUSTOMER_ACCOUNT_SUCCESS,
      GET_TRIALS_BY_CUSTOMER_ACCOUNT_FAIL,
    ],
    promise,
  }
}

export function deleteTrial(id) {
  return {
    types: [DELETE_TRIAL, DELETE_TRIAL_SUCCESS, DELETE_TRIAL_FAIL],
    promise: client => client.del(`/trials/${id}`),
  }
}
