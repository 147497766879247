import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import { getSeason } from 'redux/modules/seasons'
import { getFlightsBySeasonId } from 'redux/modules/flights'
import { getRegionsBySeasonId } from 'redux/modules/regions'
import { getFlaggedProbeObservationsBySeasonId } from 'redux/modules/probeData'
import { getReport, updateReport } from 'redux/modules/aerialImageryReports'
import { LoadingState } from '_shared'
import { AerialImageryReportForm } from './Form/AerialImageryReportForm'
import styles from './AerialImageryReport.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch } = options.store
      const { params } = options
      if (params && params.seasonId) {
        promises.push(dispatch(getSeason(params.seasonId)))
        promises.push(dispatch(getFlightsBySeasonId(params.seasonId)))
        promises.push(dispatch(getRegionsBySeasonId(params.seasonId)))
      }

      if (params && params.reportId) {
        promises.push(dispatch(getReport(params.reportId)))
      }
    },
  },
])

const connectWrapper = connect(
  state => ({
    season: state.seasons.activeSeason,
    flights: state.flights.flightsBySeason,
    report: state.aerialImageryReports.report,
    regions: state.regions.regionsBySeason,
    observationsByDate: state.probeData.flaggedProbeObservationsBySeason,
  }),
  {
    updateReport,
  }
)

export function EditAerialImageryReportContainerComponent({
  season,
  report,
  flights,
  regions,
  observationsByDate,
  updateReport,
  dispatch,
}) {
  const [observationsFetched, setObservationsFetched] = useState(false)

  const onTemplateChange = template => {
    if (!template.hideObservationData && !observationsFetched) {
      let observations = dispatch(getFlaggedProbeObservationsBySeasonId(season.data.id))
      setObservationsFetched(true)
      return observations
    }
  }

  const handleSubmit = async params => {
    try {
      const updatedReport = await updateReport(report.data.id, params)
      return updatedReport.data
    } catch {
      return
    }
  }

  if (!season.data || !report.data) {
    return <LoadingState />
  } else {
    return (
      <div className={styles.aerialImageryReportFormContainer}>
        <h1 className='h2'>Edit report</h1>
        <AerialImageryReportForm
          season={season.data}
          flights={flights.data}
          regions={regions.data}
          report={report.data || {}}
          observationsByDate={observationsByDate.data || []}
          onSave={handleSubmit}
          onTemplateChange={onTemplateChange}
        />
      </div>
    )
  }
}

const EditAerialImageryReportContainer = asyncConnectWrapper(
  connectWrapper(EditAerialImageryReportContainerComponent)
)
export default EditAerialImageryReportContainer

EditAerialImageryReportContainerComponent.propTypes = {
  season: PropTypes.shape({ data: PropTypes.object }),
  flights: PropTypes.shape({ data: PropTypes.array }),
  regions: PropTypes.shape({ data: PropTypes.array }),
  report: PropTypes.shape({ data: PropTypes.object }),
  updateReport: PropTypes.func,
}
