import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { TimelyReportsAndTrials } from './TimelyReportsAndTrials'

export class ReportsAndTrials extends Component {
  setUpNestedTimelyFarmSeasonData = (season, shapedObject) => {
    const currentYear = moment().year()
    const year = parseInt(season.year, 10)
    const isCurrentYear = year === currentYear
    const yearKey = isCurrentYear ? 'Current' : 'Archived'
    const farmId = season.farm.id
    const seasonId = season.id

    shapedObject[yearKey] = shapedObject[yearKey] || {}

    shapedObject[yearKey][farmId] = shapedObject[yearKey][farmId] || {}
    shapedObject[yearKey][farmId].title = season.farm.title

    shapedObject[yearKey][farmId].seasons = shapedObject[yearKey][farmId].seasons || {}

    shapedObject[yearKey][farmId].seasons[seasonId] =
      shapedObject[yearKey][farmId].seasons[seasonId] || {}
    shapedObject[yearKey][farmId].seasons[seasonId].id = season.id
    shapedObject[yearKey][farmId].seasons[seasonId].title = season.title
    shapedObject[yearKey][farmId].seasons[seasonId].field = season.field
    shapedObject[yearKey][farmId].seasons[seasonId].farm = season.farm
    shapedObject[yearKey][farmId].seasons[seasonId].permissions = season.permissions

    return shapedObject[yearKey][farmId].seasons[seasonId]
  }

  putDataInCorrectShape = (reportsBySeason, trials) => {
    let data = reportsBySeason.reduce((shapedObject, currentItem) => {
      let seasonObject = this.setUpNestedTimelyFarmSeasonData(currentItem, shapedObject)
      seasonObject.reports = seasonObject.reports || []
      seasonObject.reports = seasonObject.reports.concat(currentItem.reports)

      return shapedObject
    }, {})

    data = trials.reduce((shapedObject, currentItem) => {
      let seasonObject = this.setUpNestedTimelyFarmSeasonData(currentItem.season, shapedObject)
      seasonObject.trials = seasonObject.trials || []
      seasonObject.trials.push(currentItem)

      return shapedObject
    }, data)

    return data
  }

  render() {
    const { reportsBySeason, trials, deleteReport, deleteTrial } = this.props
    const timelyData = this.putDataInCorrectShape(reportsBySeason, trials)

    return (
      <React.Fragment>
        {Object.keys(timelyData).length === 0 &&
          'You have no current seasons to add reports or applied maps to'}
        {Object.entries(timelyData).map(([key, value]) => {
          return (
            <TimelyReportsAndTrials
              key={key}
              timelyData={value}
              title={`${key} Reports`}
              deleteReport={deleteReport}
              deleteTrial={deleteTrial}
            />
          )
        })}
      </React.Fragment>
    )
  }
}

ReportsAndTrials.propTypes = {
  reportsBySeason: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      field: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
      }),
      farm: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
      }),
      permissions: PropTypes.shape({ canManage: PropTypes.bool }),
      reports: PropTypes.array,
    })
  ),
  trials: PropTypes.array,
  deleteReport: PropTypes.func,
  deleteTrial: PropTypes.func,
}
