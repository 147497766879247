import React from 'react'
import PropTypes from 'prop-types'
import { TopLevelCheckbox } from './MapLayerControl'

export function SoilsLayerControls({ shouldDisplayControls, soilsEnabled, setSoilsEnabled }) {
  return shouldDisplayControls ? (
    <TopLevelCheckbox
      onChange={event => setSoilsEnabled(event.target.checked)}
      defaultChecked={soilsEnabled}
      title='SOILS'
    />
  ) : null
}

SoilsLayerControls.propTypes = {
  shouldDisplayControls: PropTypes.bool,
  soils: PropTypes.object,
  map: PropTypes.object,
}
