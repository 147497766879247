import React from 'react'
import PropTypes from 'prop-types'
import styles from '../AerialImageryReport.module.scss'

export function InfoGridHeader({ brandColor, header }) {
  return (
    <div className={styles.infoRow}>
      <div className={styles.subheader} style={{ color: brandColor }}>
        {header}:
      </div>
    </div>
  )
}

InfoGridHeader.propTypes = {
  brandColor: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}
