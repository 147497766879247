import React, { useState } from 'react'
import PropTypes from 'prop-types'
import swal from '@sweetalert/with-react'
import { FaPencilAlt, FaTrashAlt, FaTimes, FaSave } from 'react-icons/fa'
import { FormButtonContainer, CheckboxInput, TextInput, TextAreaInput } from '_shared'
import { getAlphabetLetterFromIndex } from 'helpers'
import styles from './RegionListNew.module.scss'

export const RegionsListNew = ({
  regions,
  deleteRegion,
  handleSingleRegionToggle,
  editRegion,
  cancelEditRegion,
  saveEditRegion,
  saveNewRegion,
  toggleAddNewRegion,
  handleRegionInfoUpdate,
  regionToEdit,
}) => {
  const [hideMenu, setHideMenu] = useState(false)

  const onDeleteClick = async regionId => {
    if (regionId === 'new') {
      cancelEditRegion()
      return
    }
    const result = await swal({
      title: 'Are you sure you want to delete this region?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          className: '',
          closeModal: false,
        },
      },
    })

    result && deleteRegion(regionId)
  }

  const toggleRegionsMenu = () => {
    setHideMenu(!hideMenu)
  }

  return (
    <div id='regionsContainer' className={`${styles.regions} ${hideMenu && styles.regionsHide}`}>
      <button className={styles.regionsHeader} onClick={toggleRegionsMenu}>
        <label htmlFor='seasonDropdown'>Regions</label>
        <i className={`fas fa-chevron-down ${hideMenu && styles.rotateIcon}`} />
      </button>
      <div className={styles.regionsBody}>
        <div className={styles.regionList}>
          {regions && regions.length > 0 ? (
            regions.map((region, index) => (
              <div className={styles.regionListItems} key={region.id}>
                <div className={styles.regionListItemRow}>
                  <div className={styles.regionListItemCheckboxContainer}>
                    <CheckboxInput
                      name={region.id.toString()}
                      onChange={e => handleSingleRegionToggle(region.id, e.target.checked)}
                      value={region.display}
                      label={`${getAlphabetLetterFromIndex(index)}. ${region.name}`}
                      className={styles.regionName}
                    />
                  </div>
                  <div className={styles.regionsButtons}>
                    <button
                      className='btn'
                      style={!regionToEdit.id ? {} : { visibility: 'hidden' }}
                      onClick={e => editRegion(region, e.target.checked)}
                    >
                      <FaPencilAlt color='#007cc0' />
                    </button>
                    {regionToEdit.id === region.id && (
                      <>
                        <button className={`btn ${styles.undo}`} onClick={cancelEditRegion}>
                          <FaTimes color='#efa700' />
                        </button>
                        <button
                          className={`btn ${styles.save}`}
                          onClick={region.id === 'new' ? saveNewRegion : saveEditRegion}
                        >
                          <FaSave color='#00b898' />
                        </button>
                      </>
                    )}
                    <button
                      className='btn'
                      style={
                        !regionToEdit.id || regionToEdit.id === region.id
                          ? {}
                          : { visibility: 'hidden' }
                      }
                      onClick={() => onDeleteClick(region.id)}
                    >
                      <FaTrashAlt color='#ff4c5a' />
                    </button>
                  </div>
                </div>
                {regionToEdit.id === region.id && (
                  <div className={styles.editRegionInfoContainer}>
                    <TextInput
                      name='name'
                      label='Region name'
                      value={regionToEdit.name}
                      required
                      onChange={e => handleRegionInfoUpdate('name', e.target.value)}
                    />
                    <CheckboxInput
                      name='exclusion'
                      label='Exclude from reporting?'
                      value={regionToEdit.exclusion}
                      onChange={e => handleRegionInfoUpdate('exclusion', e.target.checked)}
                    />
                    <CheckboxInput
                      name='untreated'
                      label='Untreated?'
                      value={regionToEdit.untreated}
                      onChange={e => handleRegionInfoUpdate('untreated', e.target.checked)}
                    />
                    <TextInput
                      name='yield'
                      label='Yield'
                      value={regionToEdit.yield}
                      onChange={e => handleRegionInfoUpdate('yield', e.target.value)}
                    />
                    <TextAreaInput
                      name='notes'
                      label='Notes'
                      value={regionToEdit.notes}
                      onChange={e => handleRegionInfoUpdate('notes', e.target.value)}
                    />
                  </div>
                )}
              </div>
            ))
          ) : (
            <span className={styles.regionListItem}>No Regions</span>
          )}
        </div>
        {regions && !regionToEdit.id && (
          <div className={styles.addRegionBtn}>
            <FormButtonContainer>
              <button className={`btn btn-primary`} onClick={toggleAddNewRegion}>
                Add region
              </button>
            </FormButtonContainer>
          </div>
        )}
      </div>
    </div>
  )
}

RegionsListNew.propTypes = {
  regions: PropTypes.array,
}
