const GET_SERVICES = 'aker/services/GET_SERVICES'
const GET_SERVICES_SUCCESS = 'aker/services/GET_SERVICES_SUCCESS'
const GET_SERVICES_FAIL = 'aker/services/GET_SERVICES_FAIL'

const initialState = {
  services: {
    loaded: false,
  },
}

export default function services(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        services: {
          ...state.services,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_SERVICES_FAIL:
      return {
        ...state,
        services: {
          ...state.services,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    default:
      return state
  }
}

export function areServicesLoaded(globalState) {
  return (
    globalState.services && globalState.services.services && globalState.services.services.loaded
  )
}

export function getServices() {
  return {
    types: [GET_SERVICES, GET_SERVICES_SUCCESS, GET_SERVICES_FAIL],
    promise: client => client.get('/services'),
  }
}
