import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { logout } from 'redux/modules/auth'
import { push } from 'react-router-redux'
import { ReactComponent as LogoImage } from '../../assets/safari-pinned-tab.svg'
import styles from './Logout.module.scss'

/**
 * Login
 * Displaying a logout form and handle submitting the form by changing the state
 * by a logout action.
 */
const connectWrapper = connect(
  state => ({
    loggingOut: state.auth.loggingOut,
    logoutErrors: state.auth.errors,
    user: state.auth.user,
  }),
  {
    logout,
    pushState: push,
  }
)

class LogoutComponent extends Component {
  componentWillMount() {
    this.handleLogout()
  }

  handleLogout = () => {
    const { user, logout, pushState } = this.props

    if (user) {
      try {
        logout()
      } catch (e) {
        console.log(e)
      }
    } else {
      pushState('/login')
    }
  }

  render() {
    return (
      <div className={styles.siteWrapper}>
        <Helmet title='Signed Out' />
        <div className={styles.logoutWindowWrapper}>
          <div className={styles.akerInformation}>
            <div className={styles.logoWrapper}>
              <LogoImage className={styles.logo} />
              <div className={styles.slogan}>
                Capture in-season threats to protect your profits.
              </div>
            </div>
            <div className={styles.descriptionWrapper}>
              <ul>
                <li className={styles.listItem}>Aker is free and mobile</li>
                <li className={styles.listItem}>
                  Download all your data and collaborate with your team
                </li>
                <li className={styles.listItem}>Scout faster</li>
                <li className={styles.listItem}>Visualize and prioritize in-season threats</li>
              </ul>
            </div>
          </div>
          <div className={styles.logoutWindow}></div>
        </div>
      </div>
    )
  }
}

LogoutComponent.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  logoutErrors: PropTypes.array,
  loggingOut: PropTypes.bool,
  pushState: PropTypes.func.isRequired,
}

export default connectWrapper(LogoutComponent)
