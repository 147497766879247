const TOGGLE_FAVORITE_PROBE_IMAGE = 'aker/IMAGE_REVIEW/TOGGLE_FAVORITE_PROBE_IMAGE'
const TOGGLE_FAVORITE_PROBE_IMAGE_SUCCESS = 'aker/IMAGE_REVIEW/TOGGLE_FAVORITE_PROBE_IMAGE_SUCCESS'
const TOGGLE_FAVORITE_PROBE_IMAGE_FAIL = 'aker/IMAGE_REVIEW/TOGGLE_FAVORITE_PROBE_IMAGE_FAIL'

const initialState = {
  toggleFavoriteProbeImageData: {
    loaded: false,
  },
}

export default function favoriteimages(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_FAVORITE_PROBE_IMAGE:
      return {
        ...state,
        toggleFavoriteProbeImageData: {
          ...state.toggleFavoriteProbeImageData,
          loading: true,
          error: undefined,
        },
      }

    case TOGGLE_FAVORITE_PROBE_IMAGE_SUCCESS:
      return {
        ...state,
        toggleFavoriteProbeImageData: {
          ...state.toggleFavoriteProbeImageData,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }

    case TOGGLE_FAVORITE_PROBE_IMAGE_FAIL:
      return {
        ...state,
        toggleFavoriteProbeImageData: {
          ...state.toggleFavoriteProbeImageData,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }
    default:
      return state
  }
}

export function toggleFavoriteProbeImage(imagePath) {
  return {
    types: [
      TOGGLE_FAVORITE_PROBE_IMAGE,
      TOGGLE_FAVORITE_PROBE_IMAGE_SUCCESS,
      TOGGLE_FAVORITE_PROBE_IMAGE_FAIL,
    ],
    promise: client =>
      client.post(`/favorite_images/toggle_probe_image`, {
        data: {
          data: {
            url: imagePath,
          },
        },
      }),
  }
}
