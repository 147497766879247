import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './SidePanel.module.scss'

/**
 * SidePanel
 * Use this component to make a quick start!
 */
export default class SidePanel extends Component {
  static propTypes = {
    children: PropTypes.node,
    background: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    background: true,
  }

  render() {
    const { background } = this.props
    return (
      <div
        className={styles.SidePanel + ' ' + (background ? styles.background : styles.noBackground)}
      >
        {this.props.children}
      </div>
    )
  }
}
