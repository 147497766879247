import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Leaflet from 'leaflet'
import { GeoJsonParser } from './GeoJsonParser'

function DrawingControlsInitializer(isInEditMode, drawnItems) {
  Leaflet.drawLocal.draw.toolbar.actions.text = 'Clear'
  Leaflet.drawLocal.draw.toolbar.actions.title = 'Clear'

  Leaflet.drawLocal.draw.handlers.polygon.tooltip.start = 'Click to start'
  Leaflet.drawLocal.draw.handlers.polygon.tooltip.cont = 'Click next point'
  Leaflet.drawLocal.draw.handlers.polygon.tooltip.end = 'Click first point to complete'

  const polygonOptions = {
    title: '',
    allowIntersection: false, // Windows does not allow clockwise selection because of this
    drawError: {
      color: '#b00b00',
      timeout: 1000,
    },
    shapeOptions: {
      color: '#FF4C5A',
      opacity: 0.75,
      fillOpacity: 0.5,
    },
    showArea: true,
    metric: false, // Whether to use the metric measurement system or imperial
  }

  const defaultOptions = {
    position: 'topleft',
    draw: {
      circle: false,
      marker: false,
      rectangle: false,
      polyline: false,
      polygon: isInEditMode ? false : polygonOptions,
    },
    edit: {
      featureGroup: drawnItems,
      edit: false,
      remove: true,
    },
    delete: false,
  }

  // Initialise the draw control and pass it the FeatureGroup of editable layers
  return new Leaflet.Control.Draw(defaultOptions)
}

function MakeGeometryEditable(geometry, drawnItems) {
  if (geometry.type === 'MultiPolygon') {
    geometry.coordinates.forEach(shapeCoords => {
      const polygon = { type: 'Polygon', coordinates: shapeCoords }
      Leaflet.geoJson(polygon, {
        onEachFeature: (feature, layer) => {
          drawnItems.addLayer(layer)
        },
      })
    })
  } else {
    const layer = Leaflet.GeoJSON.geometryToLayer(geometry)
    drawnItems.addLayer(layer)
  }

  drawnItems.eachLayer(layer => {
    layer.editing.enable()
  })
}

export function DrawingControls({ map, drawingEnabled, geometryToEdit, setDrawnGeometry }) {
  const [drawingControls, setDrawingControls] = useState(null)
  const [drawnItems, setDrawnItems] = useState(null)
  const [isInEditMode, setIsInEditMode] = useState(!!geometryToEdit)

  useEffect(() => {
    setIsInEditMode(!!geometryToEdit)
  }, [geometryToEdit])

  useEffect(() => {
    if (!map) return

    if (drawingEnabled) {
      let leafletDrawnItems = drawnItems

      if (!drawnItems) {
        leafletDrawnItems = new Leaflet.FeatureGroup()
        map.addLayer(leafletDrawnItems)
        setDrawnItems(leafletDrawnItems)
      }

      if (!drawingControls) {
        const drawControl = DrawingControlsInitializer(isInEditMode, leafletDrawnItems)
        map.addControl(drawControl)
        setDrawingControls(drawControl)
      }

      if (isInEditMode) {
        MakeGeometryEditable(geometryToEdit.geometry, leafletDrawnItems)
        bindEditDrawingEventHandlers(leafletDrawnItems)
      } else {
        bindCreateDrawingEventHandlers(leafletDrawnItems)
      }
    } else {
      if (!!drawingControls) {
        try {
          map.removeControl(drawingControls)
        } catch (err) {
          console.log('LeafletMap removeLayer Error:', err)
        }
      }

      if (!!drawnItems) {
        try {
          map.removeLayer(drawnItems)
        } catch (err) {
          console.log('LeafletMap removeLayer Error:', err)
        }
      }

      setDrawnItems(null)
      setDrawingControls(null)
    }

    // Cleanup to run when component unmounts
    return function cleanup() {
      try {
        map.removeLayer(drawingControls)
        map.removeLayer(drawnItems)
      } catch {}
    }
  }, [map, drawingEnabled, isInEditMode]) //eslint-disable-line react-hooks/exhaustive-deps

  const bindCreateDrawingEventHandlers = drawnItems => {
    map.on('draw:created', event => {
      drawnItems.addLayer(event.layer) // add layer for union logic of multi-polygon areas
      const geometryObj = GeoJsonParser(drawnItems)
      drawnItems.clearLayers() // remove layer so we can re-add in an editable state
      MakeGeometryEditable(geometryObj.polygon.geometry, drawnItems)
      setDrawnGeometry(geometryObj)
    })

    map.on('draw:deleted', () => {
      if (drawnItems.getLayers().length > 0) {
        const geometryObj = GeoJsonParser(drawnItems)
        setDrawnGeometry(geometryObj)
      }
    })
  }

  const bindEditDrawingEventHandlers = drawnItems => {
    map.on('draw:editvertex', () => {
      const geometryObj = GeoJsonParser(drawnItems)
      setDrawnGeometry(geometryObj)
    })
  }

  return null
}

DrawingControls.propTypes = {
  map: PropTypes.object,
}
