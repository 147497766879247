import React, { Component } from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ImageReviewerByTask } from './ImageReviewerByTask'
import { DEFAULT_STATUS_QUERY } from './ImageReviewerFilter'

import {
  getImagesDataByTask,
  getCropStressItems,
  updateImageReview,
  deleteImages,
} from 'redux/modules/imagereview'
import { updateProbeImage } from 'redux/modules/probeImages'

import { toggleFavoriteProbeImage } from 'redux/modules/favoriteImages'
import styles from './ImageReviewer.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { params, store } = options
      const { dispatch } = store
      if (params && params.taskId) {
        promises.push(dispatch(getImagesDataByTask(params.taskId, DEFAULT_STATUS_QUERY)))
        promises.push(dispatch(getCropStressItems(params.taskId)))
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    user: state.auth.user,
    cropStressItems: state.imagereview.cropStressItems.data,
    imageDataByTask: state.imagereview.imageDataByTask.data,
    imageDataByTaskErrors: state.imagereview.imageDataByTask.error,
    favoriteImageData: state.favoriteimages.toggleFavoriteProbeImageData.data,
  }),
  { updateImageReview, toggleFavoriteProbeImage, getImagesDataByTask, deleteImages, updateProbeImage }
)

class ImageReviewerByTaskContainer extends Component {
  update = (taskId, data, status, pageNumber) => {
    this.props.dispatch(updateImageReview(taskId, data, status, pageNumber))
  }

  updateMlReview = (fileName, data) => {
    this.props.dispatch(updateProbeImage(fileName, data))
  }

  toggleFavorite = imagePath => {
    this.props.dispatch(toggleFavoriteProbeImage(imagePath))
  }

  fetchImagesForPage = (status, pageNumber) => {
    this.props.dispatch(getImagesDataByTask(this.props.imageDataByTask.id, status, pageNumber))
  }

  handleDeleteImages = async imagesToDelete => {
    await this.props.dispatch(deleteImages(imagesToDelete))
  }

  render() {
    const { imageDataByTask, cropStressItems, user, favoriteImageData } = this.props
    return (
      <div className={styles.imageReviewerContainer}>
        <Helmet title='Image Reviewer Summary' />
        <ImageReviewerByTask
          user={user}
          task={imageDataByTask}
          cropStressItems={cropStressItems}
          updateImageTags={this.update}
          toggleFavoriteImage={this.toggleFavorite}
          favoriteImageData={favoriteImageData}
          fetchImagesForPage={this.fetchImagesForPage}
          deleteImages={this.handleDeleteImages}
          updateMlReview={this.updateMlReview}
        />
      </div>
    )
  }
}

export default asyncConnectWrapper(connectWrapper(ImageReviewerByTaskContainer))

ImageReviewerByTaskContainer.propTypes = {
  user: PropTypes.object.isRequired,
  imageDataByTask: PropTypes.object.isRequired,
}
