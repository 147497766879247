/* eslint array-callback-return: 0*/
import superagent from 'superagent'
import config from '../config'
import cookie from 'react-cookie'
const methods = ['get', 'post', 'put', 'patch', 'del']

/*
 * This silly underscore is here to avoid a mysterious "ReferenceError: ApiClient is not defined" error.
 * See Issue #14. https://github.com/erikras/react-redux-universal-hot-example/issues/14
 *
 * Remove it at your own risk.
 */
class _ApiClient {
  constructor(req) {
    methods.forEach(
      method =>
        (this[method] = (path, { params, data, preferredStatusResponse } = {}) =>
          new Promise((resolve, reject) => {
            const requestPath = config.endpoint + path
            const request = superagent[method](requestPath)

            if (cookie && cookie.load('authToken')) {
              request.set('Authorization', 'Token ' + cookie.load('authToken'))
            }

            if (params) {
              request.query(params)
            }

            // Used to get a specific response from Apiary when
            // using the mock server
            if (preferredStatusResponse) {
              request.set('Preferred', preferredStatusResponse)
            }

            // Only accept JSON results
            request.set('Accept', 'application/json')

            // Attach files to the request if we send them
            // @TODO: This only works for the file uploaded in /settings now
            // If we want to re-use this we need to make this more dynamic
            if (data && data.data && data.data.companyLogo) {
              // Make a copy of the data without the file in it
              const newData = {
                ...data.data,
              }
              delete newData.companyLogo

              // Add the field form data
              Object.keys(newData).map(key => {
                request.field(key, newData[key])
              })

              // Add the files
              // Make sure it is a file object and not a url
              if (typeof data.data.companyLogo === 'object') {
                request.attach('companyLogo', data.data.companyLogo, data.data.companyLogo.name)
              } else {
                console.log('Invalid company logo string', data.data.companyLogo)
              }
            } else if (data) {
              request.send(data)
            }

            request.end((err, res) => {
              if (err) {
                reject({ ...(res.body || err), statusCode: res.statusCode }) // reject only accepts 1 param!
              } else {
                resolve(res.body)
              }
            })
          }))
    )
  }
}

const ApiClient = _ApiClient

export default ApiClient
