import { useState, useEffect } from 'react'
import Leaflet from 'leaflet'
import { sld } from './sld'

const soilUrl = 'https://sdmdataaccess.sc.egov.usda.gov/Spatial/SDM.wms'

const TileLayerSoils = Leaflet.TileLayer.WMS.extend({
  options: {
    sld_body: '',
  },
  _loadTile: function _loadTile(tile, tilePoint) {
    tile._layer = this
    tile.onload = this._tileOnLoad
    tile.onerror = this._tileOnError
    this._adjustTilePoint(tilePoint)
    const xhr = new XMLHttpRequest()
    const splitUrl = this.getTileUrl(tilePoint).split('?')
    xhr.open('POST', splitUrl[0])
    xhr.responseType = 'blob'
    xhr.onload = function xhrLoadCallback() {
      tile.src = window.URL.createObjectURL(this.response)
    }
    xhr.send(splitUrl[1])
    this.fire('tileloadstart', {
      tile: tile,
      url: tile.src,
    })
  },
  getTileUrl: function getTileUrl(tilePoint) {
    const map = this._map
    const tileSize = this.options.tileSize
    const nwPoint = tilePoint.multiplyBy(tileSize)
    const sePoint = nwPoint.add([tileSize, tileSize])
    const nw = this._crs.project(map.unproject(nwPoint, tilePoint.z))
    const se = this._crs.project(map.unproject(sePoint, tilePoint.z))
    const bbox =
      this._wmsVersion >= 1.3 && this._crs === Leaflet.CRS.EPSG4326
        ? [se.y, nw.x, nw.y, se.x].join(',')
        : [nw.x, se.y, se.x, nw.y].join(',')
    let url = Leaflet.Util.template(this._url, {
      s: this._getSubdomain(tilePoint),
    })
    const params = []
    for (const key in this.wmsParams) {
      if (Object.prototype.hasOwnProperty.call(this.wmsParams, key)) {
        params.push(key.toUpperCase() + '=' + this.wmsParams[key])
      }
    }
    url = url + (!url || url.indexOf('?') === -1 ? '?' : '&') + params.join('&')
    return url + '&BBOX=' + bbox + '&SLD_BODY=' + this.options.sld_body
  },
})

export function SoilsLayer({ soilsEnabled, map }) {
  const [soils, setSoils] = useState(null)
  const soilsLayer = new TileLayerSoils(soilUrl, {
    maxZoom: 20,
    maxNativeZoom: 19,
    minZoom: 15,
    layers: 'surveyareapolyoutline,mapunitpoly',
    format: 'image/png',
    transparent: true,
    sld_body: sld,
  })

  useEffect(() => {
    if (!map) return

    if (soilsEnabled) {
      try {
        map.addLayer(soilsLayer)
        setSoils(soilsLayer)
      } catch (err) {
        console.log('LeafletMap addLayer Error:', err)
      }
    } else {
      try {
        if (!!soils) {
          map.removeLayer(soils)
        }
        setSoils(null)
      } catch (err) {
        console.log('LeafletMap removeLayer Error:', err)
      }
    }
  }, [map, soilsEnabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
