import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './MapTimeline.module.scss'

export default class MapTimeline extends Component {
  static propTypes = {
    selectLayer: PropTypes.func.isRequired,
    layers: PropTypes.array,
    selectedLayer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  render() {
    const { layers } = this.props

    // // NOTE: For debug testing only!!!
    // layers = [
    //   {
    //     name: 'layerthree',
    //     type: 'thermal',
    //     date: new Date('2016/1/15'),
    //   },
    //   {
    //     name: 'layerone',
    //     type: 'thermal',
    //     date: new Date('2016/4/15'),
    //   },
    //   {
    //     name: 'layertwo',
    //     type: 'thermal',
    //     date: new Date('2016/6/15'),
    //   },
    //   {
    //     name: 'layerthree',
    //     type: 'thermal',
    //     date: new Date('2016/10/15'),
    //   },
    // ];

    // Make sure to use April through Oct as the season!
    let layersSorted = []
    // Check to make sure layers have dates first
    if (layers && layers.length > 0 && layers[0].dateFlown) {
      try {
        // Sort layers by date
        let sorted = layers.map(layer => ({
          ...layer,
          date: new Date(layer.dateFlown),
        }))
        sorted = sorted.sort(
          (layerOne, layerTwo) => layerOne.date.getTime() - layerTwo.date.getTime()
        )
        // Get the first layers year as reference
        const year = sorted[0].date ? sorted[0].date.getFullYear() : new Date().getFullYear()

        let layersPointer = 0
        let currentLayer = sorted[layersPointer++]

        // Add layers before April
        while (currentLayer && currentLayer.date && currentLayer.date.getMonth() < 3) {
          layersSorted.push(currentLayer)
          currentLayer = sorted[layersPointer++]
        }

        // Add layers in relative order between April and October
        for (let month = 3; month < 10; month++) {
          let found = false
          while (
            currentLayer &&
            currentLayer.date &&
            currentLayer.date.getMonth() === month &&
            currentLayer.date.getFullYear() === year
          ) {
            layersSorted.push(currentLayer)
            currentLayer = sorted[layersPointer++]
            found = true
          }

          if (!found) {
            // Add empty spacer between months
            layersSorted.push({})
          }
        }
        // Add the rest of the layers at the end
        while (currentLayer && currentLayer.date) {
          layersSorted.push(currentLayer)
          currentLayer = sorted[layersPointer++]
        }
      } catch (err) {
        console.log('MapTimeline Error:', err)
        // Fall back to displaying layers with equal spacing
        layersSorted = layers
      }
    } else {
      // Layers do not have dates. Just add them all equally spaced
      layersSorted = layers
    }

    return (
      <div className={styles.timelineBase}>
        <span className={styles.timelineBar} />
        <div className={styles.timelineRow}>
          {layersSorted &&
            layersSorted.map((layer, index) => {
              if (!layer || !layer.dateFlown) {
                // Used as a spacer to show relative month spacing
                return (
                  <div key={index} className={styles.timelineColumn}>
                    <div className={styles.timelinePointEmpty} />
                  </div>
                )
              }

              const style = {}
              if (layer.type === 'thermal') {
                style.background = '#FF4C5A'
              }

              if (layer.type === 'rgb') {
                style.background = '#d1c436'
              }
              if (layer.id === this.props.selectedLayer) {
                style.borderWidth = '4px'
              }

              return (
                <div key={index} className={styles.timelineColumn}>
                  <p className={styles.timelineText}></p>
                  <div
                    className={styles.timelinePoint}
                    style={style}
                    onClick={() => this.props.selectLayer(layer)}
                  />
                  {layer.date && (
                    <p className={styles.timelineText}>{`${layer.date.getMonth() +
                      1}/${layer.date.getDate()}`}</p>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}
