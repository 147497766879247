import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { ReduxFormInput } from '../_shared'
import classNames from 'classnames'
import styles from './WrappedInputField.module.scss'

export default function WrappedInputField({
  columnWidth,
  name,
  type,
  placeholder,
  title,
  required,
  disabled,
  horizontal,
  validate,
  ...props
}) {
  const labelStyles = classNames({ [styles.topLabel]: !horizontal, [styles.leftLabel]: horizontal })
  const inputStyles = classNames({ [styles.rightAlignedInput]: horizontal })
  const formGroupStyles = classNames(`col-${columnWidth}`, {
    [styles.horizontalFieldGroup]: horizontal,
  })
  return (
    <div className={formGroupStyles}>
      <div className={labelStyles}>{title}</div>
      <ReduxFormInput fieldName={name} type={type} validate={validate}>
        {({ errorClassName, ...reduxInput }) => (
          <Form.Control
            {...reduxInput}
            key={name}
            type={type}
            id={name}
            placeholder={placeholder}
            className={classNames(errorClassName, inputStyles)}
            disabled={disabled}
            required={required}
          />
        )}
      </ReduxFormInput>
    </div>
  )
}

WrappedInputField.propTypes = {
  columnWidth: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  validate: PropTypes.any, //This can be a function, or an array of functions
}
