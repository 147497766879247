import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LoadingState } from '_shared'
import { NewImageryByDate } from './NewImageryByDate'
import styles from './ProbeData.module.scss'

export const NEW_IMAGERY_TAB_ACTION = 'NewImageryTab/GET_PROBE_IMAGERY'

export function DateOpenStatusParser(observations, selectedObservationDate) {
  return observations.reduce((openStatuses, observation) => {
    openStatuses[observation.observedAt] = selectedObservationDate === observation.observedAt
    return openStatuses
  }, {})
}

export function NewImageryTab({ activeSeason, onDataChange, selectedObservationDate, ...props }) {
  const [dateOpenStatuses, setDateOpenStatuses] = useState({})
  const [probeCycles, setProbeCycles] = useState([])
  const [probeCyclesLoaded, setProbeCyclesLoaded] = useState(false)
  const [hasProbeCycles, setHasProbeCycles] = useState(false)

  // Due to a "delay", we are intentionally NOT using the global store here
  // We're using the raw response instead
  // Using the redux store, the user sees a 'flash' of old data before the dispatch for new data is sent
  useEffect(() => {
    onDataChange(NEW_IMAGERY_TAB_ACTION, activeSeason.id).then(response => {
      setProbeCyclesLoaded(true)
      setProbeCycles(response.data)
      setHasProbeCycles(response.data && response.data.length > 0)
    })
  }, [activeSeason, onDataChange])

  useEffect(() => {
    if (probeCycles && probeCycles.length > 0) {
      setDateOpenStatuses(DateOpenStatusParser(probeCycles, selectedObservationDate))
    }
  }, [probeCycles, selectedObservationDate])

  return (
    <div className='container'>
      <h2 className='h3'>TrueCause Imagery</h2>
      {!probeCyclesLoaded && (
        <div className={styles.loadingContainer}>
          <LoadingState />
        </div>
      )}
      {probeCyclesLoaded && !hasProbeCycles && (
        <h3 className='h5'>
          There is no new imagery to show. Check the TrueCause tab for reviewed images.
        </h3>
      )}
      {hasProbeCycles &&
        probeCycles.map((date, index) => {
          return (
            <NewImageryByDate
              key={date.observedAt.toString()}
              dateData={date}
              open={dateOpenStatuses[date.observedAt] || false}
              {...props}
            />
          )
        })}
    </div>
  )
}

NewImageryTab.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  selectedObservationDate: PropTypes.string,
  onDataChange: PropTypes.func.isRequired,
}
