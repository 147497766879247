const GET_CROPS = 'aker/CROPS/GET_CROPS'
const GET_CROPS_SUCCESS = 'aker/CROPS/GET_CROPS_SUCCESS'
const GET_CROPS_FAIL = 'aker/CROPS/GET_CROPS_FAIL'

const initialState = {
  crops: {
    loaded: false,
  },
}

export default function crops(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CROPS:
      return {
        ...state,
        crops: {
          ...state.crops,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_CROPS_SUCCESS:
      return {
        ...state,
        crops: {
          ...state.crops,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_CROPS_FAIL:
      return {
        ...state,
        crops: {
          ...state.crops,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    default:
      return state
  }
}

export function isCropsLoaded(globalState) {
  return globalState.crops && globalState.crops.crops && globalState.crops.crops.loaded
}

export function getCrops() {
  let promise
  promise = client => client.get('/crops')
  return {
    types: [GET_CROPS, GET_CROPS_SUCCESS, GET_CROPS_FAIL],
    promise,
  }
}
