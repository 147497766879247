import React, { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import NotificationSystem from 'react-notification-system'

import { deleteAppliedMap } from 'redux/modules/farmsandfields'
import { getReportsBySeason, deleteReport } from 'redux/modules/aerialImageryReports'
import {
  getReportsBySeasonId as getLegacyReportsBySeason,
  deleteReport as deleteLegacyReport,
} from 'redux/modules/reports'
import { getTrialsBySeasonId } from 'redux/modules/trials'

import { LoadingState } from '_shared'
import { ReportsAndTrials } from './ReportsAndTrials'

// We are resolving an empty promise here because we need
// access to props.dispatch in componentDidMount
const asyncConnectWrapper = asyncConnect([
  {
    promise: () => {
      return Promise.all([])
    },
  },
])

class ReportsBySeasonContainer extends Component {
  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem
    const { params } = this.props
    if (params && params.seasonId) {
      this.props.dispatch(getLegacyReportsBySeason(params.seasonId))
      this.props.dispatch(getReportsBySeason(params.seasonId))
      this.props.dispatch(getTrialsBySeasonId(params.seasonId))
    }
  }

  handleDeleteReport = async reportToDelete => {
    const result = await swal({
      title: 'Are you sure you want to delete this report?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          className: '',
          closeModal: true,
        },
      },
    })

    if (result === true) {
      try {
        if (reportToDelete && reportToDelete.type === 'LegacyReport') {
          await this.props.deleteLegacyReport(reportToDelete.id)
        } else if (reportToDelete && reportToDelete.type === 'AerialImageryReport') {
          await this.props.deleteReport(reportToDelete.id)
        }
        this.notificationSystem.addNotification({
          message: 'Deleted Successfully',
          level: 'success',
          autoDismiss: 3,
        })
        this.props.getLegacyReportsBySeason(this.props.params.seasonId)
        this.props.getReportsBySeason(this.props.params.seasonId)
      } catch {
        this.notificationSystem.addNotification({
          message: 'Could not save. Please contact us at support@aker.ag',
          level: 'error',
          autoDismiss: 3,
        })
      }
    }
  }

  handleDeleteTrial = async trialId => {
    await swal({
      title: 'Are you sure you want to delete this trial?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
      closeOnConfirm: true,
    })

    try {
      await this.props.deleteAppliedMap(trialId)
      this.notificationSystem.addNotification({
        message: 'Deleted Successfully',
        level: 'success',
        autoDismiss: 3,
      })

      this.props.getTrialsBySeasonId(this.props.params.seasonId)
    } catch (err) {
      console.log(err)
      this.notificationSystem.addNotification({
        message: 'Could not save. Please contact us at support@aker.ag',
        level: 'error',
        autoDismiss: 3,
      })
    }
  }

  isDataLoaded = collection => {
    return collection.loaded && collection.data
  }

  // merge the legacy reports with aerial imagery reports into 1 collection
  getAllReportsBySeason = () => {
    const { reportsBySeason, legacyReportsBySeason } = this.props
    const allReportsBySeason = { ...reportsBySeason.data }
    allReportsBySeason.reports = legacyReportsBySeason.data.reports.concat(
      reportsBySeason.data.reports
    )
    return [allReportsBySeason]
  }

  render() {
    const { trialsBySeason, reportsBySeason, legacyReportsBySeason } = this.props

    return (
      <React.Fragment>
        <NotificationSystem ref='notificationSystem' />
        <div className='container'>
          {this.isDataLoaded(legacyReportsBySeason) &&
          this.isDataLoaded(reportsBySeason) &&
          this.isDataLoaded(trialsBySeason) ? (
            <ReportsAndTrials
              reportsBySeason={this.getAllReportsBySeason()}
              trials={trialsBySeason.data || []}
              deleteReport={this.handleDeleteReport}
              deleteTrial={this.handleDeleteTrial}
            />
          ) : (
            <LoadingState />
          )}
        </div>
      </React.Fragment>
    )
  }
}

const connectWrapper = connect(
  state => ({
    legacyReportsBySeason: state.reports.reportsBySeason,
    reportsBySeason: state.aerialImageryReports.reportsBySeason,
    trialsBySeason: state.trials.trialsBySeason,
  }),
  {
    getLegacyReportsBySeason,
    getReportsBySeason,
    deleteReport,
    deleteLegacyReport,
    deleteAppliedMap,
  }
)

export default withRouter(asyncConnectWrapper(connectWrapper(ReportsBySeasonContainer)))

ReportsBySeasonContainer.propTypes = {
  legacyReportsBySeason: PropTypes.object,
  reportsBySeason: PropTypes.object,
  trialsBySeason: PropTypes.object,
  getLegacyReportsBySeason: PropTypes.func.isRequired,
  getReportsBySeason: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  deleteLegacyReport: PropTypes.func.isRequired,
  deleteAppliedMap: PropTypes.func.isRequired,
}
