import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import axios from 'axios'
import { browserHistory } from 'react-router'
import { Button } from 'react-bootstrap'
import swal from '@sweetalert/with-react'
import {
  TextInput,
  TextAreaInput,
  DatePicker,
  OnOffSwitch,
  Dropdown,
  FileInput,
  ColorPickerInput,
  LoadingState,
} from '_shared'
import { CustomerAccountsContext } from 'domains'
import { AerialImageryReportTemplatePicker } from './AerialImageryReportTemplatePicker'
import { FieldRegionDisplay } from './FieldRegionDisplay'
import { BeforeAndAfterFlightPicker } from './BeforeAndAfterFlightPicker'
import { NthFlightPicker } from './NthFlightPicker'
import { ObservationPicker } from './ObservationPicker'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportForm({
  onSave,
  season,
  flights,
  regions,
  observationsByDate,
  report,
  onTemplateChange,
}) {
  let defaultLocation = report ? report.location : ''
  if (!defaultLocation && (season.field.city || season.field.state)) {
    defaultLocation = `${season.field.city}, ${season.field.state}`
  }

  const { customerAccounts } = React.useContext(CustomerAccountsContext)
  const [template, setTemplate] = useState(report ? report.template : null)
  const [title, setTitle] = useState(report ? report.title : '')
  const [brandColor, setBrandColor] = useState('#0095FF')
  const [imgSrc, setImgSrc] = useState('')
  const logoInput = useRef(null)

  const [chemicalUsed, setChemicalUsed] = useState(report ? report.productHeader : '')
  const [cropId] = useState(season ? season.crop.id : '')
  const [hybrid, setHybrid] = useState(report ? report.seedType : '')
  const [location, setLocation] = useState(defaultLocation)
  const [applicationDate, setApplicationDate] = useState(
    report && report.applicationDate ? moment.utc(report.applicationDate).format('MM/DD/YYYY') : ''
  )

  const [selectedFlights, setSelectedFlights] = useState(report ? report.flights : [])
  const [commodityPrice, setCommodityPrice] = useState(report ? report.commodityPrice : '')
  const [termsAndConditions, setTermsAndConditions] = useState(report ? report.footnote : '')
  const [contactName, setContactName] = useState(report ? report.contactName : '')
  const [contactPhone, setContactPhone] = useState(report ? report.contactPhone : '')
  const [contactEmail, setContactEmail] = useState(report ? report.contactEmail : '')
  const [selectedObservations, setSelectedObservations] = useState(
    report ? report.observations : []
  )

  const [showFieldRegions, setShowFieldRegions] = useState(true)
  const [showPlantHealthyImagery, setShowPlantHealthyImagery] = useState(true)
  const [showProbeObservations, setShowProbeObservations] = useState(true)

  const [observationDateOptions, setObservationDateOptions] = useState([])
  const [selectedObservationDate, setSelectedObservationDate] = useState(
    report && report.observationDate ? moment.utc(report.observationDate).format('MM/DD/YYYY') : ''
  )
  const [observationsToDisplay, setObservationsToDisplay] = useState([])

  const [waitingOnPdfGeneration, setWaitingOnPdfGeneration] = useState(false)

  useEffect(() => {
    if (!season) return

    let selectedCustomerAccount = customerAccounts.find(c => c.id === season.customerAccount.id)
    if (selectedCustomerAccount) {
      let color =
        report && report.brandColor ? report.brandColor : selectedCustomerAccount.brandColor
      setBrandColor(color || '#0095FF')

      let logoUrl = report && report.logoUrl ? report.logoUrl : selectedCustomerAccount.logoUrl
      setImgSrc(logoUrl || '')

      let caContactName =
        report && report.contactName ? report.contactName : selectedCustomerAccount.contactName
      setContactName(caContactName || '')
      let caContactPhone =
        report && report.contactPhone ? report.contactPhone : selectedCustomerAccount.contactPhone
      setContactPhone(caContactPhone || '')
      let caContactEmail =
        report && report.contactEmail ? report.contactEmail : selectedCustomerAccount.contactEmail
      setContactEmail(caContactEmail || '')

      let footnote = report ? report.footnote : selectedCustomerAccount.copyright
      setTermsAndConditions(footnote || selectedCustomerAccount.copyright)
    }
  }, [season]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (template) {
      onTemplateChange(template)
      if (!template.hideObservationData) {
        setTitle('Defend your fields with TrueCause™')
      }
    }
  }, [template]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let obsDateOptions = observationsByDate
      ? observationsByDate.map(obsDate => ({
        value: moment.utc(obsDate.observedAt).format('MM/DD/YYYY'),
        label: moment.utc(obsDate.observedAt).format('MM/DD/YYYY'),
      }))
      : []
    let obsToDisplay = []

    if (report && report.observationDate) {
      const observationDate = observationsByDate.find(
        obsDate =>
          moment.utc(obsDate.observedAt).format('MM/DD/YYYY') ===
          moment.utc(report.observationDate).format('MM/DD/YYYY')
      )
      obsToDisplay = observationDate ? observationDate.cropStressTypes : []
    }

    setObservationDateOptions(obsDateOptions)
    setObservationsToDisplay(obsToDisplay)
  }, [observationsByDate]) //eslint-disable-line react-hooks/exhaustive-deps

  const updateImageSource = () => {
    const logoImg = logoInput.current.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(logoImg)
    reader.onloadend = () => setImgSrc(reader.result)
  }

  const onSubmit = async e => {
    e.preventDefault()
    let logoImg

    if (logoInput.current.files) {
      logoImg = logoInput.current.files[0]
      if (logoImg && !logoImg.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        swal({
          title: 'File must be an image.',
          icon: 'warning',
          showCancelButton: false,
          dangerMode: true,
          confirmButtonText: 'OK',
          closeOnConfirm: true,
        })
        return
      }
    }

    if (template.requireAerialImagery && (!selectedFlights || selectedFlights.length === 0)) {
      swal({
        title: 'Please select aerial imagery.',
        icon: 'warning',
        showCancelButton: false,
        dangerMode: true,
        confirmButtonText: 'OK',
        closeOnConfirm: true,
      })
      return
    }

    const params = {
      seasonId: season.id,
      title,
      brandColor,
      templateId: template.id,
      productHeader: chemicalUsed,
      commodityPrice,
      location,
      seedType: hybrid,
      applicationDate,
      footnote: termsAndConditions,
      contactName,
      contactPhone,
      contactEmail,
      flights: selectedFlights.filter(f => !!f.id),
      cropStressFindings: selectedObservations,
      observationDate: selectedObservationDate,
      companyLogo: logoImg,
    }

    setWaitingOnPdfGeneration(true)
    const report = await onSave(params)
    if (report) {
      await generateReport(report.season.id, report.id, report.uuid)
      setWaitingOnPdfGeneration(false)
      await swal({
        title: 'Great, your report has been saved!',
        icon: 'success',
        closeModal: true,
      })
      browserHistory.push(
        `/farms/${season.farm.id}/field/${season.field.id}/season/${season.id}/aerial-imagery-report/${report.id}`
      )
    } else {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeModal: true,
      })
    }
  }

  const generateReport = async (seasonId, reportId, uuid) => {
    const envUrl = process.env.REACT_APP_ENV === 'production' ? 'https://discover.aker.ag' : 'https://discover.staging.aker.ag'
    const generateReportURL = () => {
      let url = `${envUrl}/farm/1234/field/1234/season/`;
      url += seasonId;
      url += "/aerial-imagery-report/";
      url += reportId;
      return url;
    };

    try {
      const customer = season.customerAccount.id
      const bucket =
        process.env.REACT_APP_ENV === 'production' ? "aker-reports" : "aker-reports-staging"
      await axios({
        method: 'POST',
        url: "https://nodeutil.aker.ag:4001/generatePdfFromUrl",
        data: {
          reportName: uuid,
          customer,
          username: process.env.REACT_APP_PUPPETEER_LOGIN,
          password: process.env.REACT_APP_PUPPETEER_PASSWORD,
          url: generateReportURL(),
          bucket,
          envUrl
        }
      })
    } catch {
      await swal({
        title: "Error generating report PDF.",
        text: 'Please contact info@aker.ag for more information.',
        icon: 'error',
        closeModal: true,
      })
    }
  }

  const handleObservationDateChange = opt => {
    if (opt.value === selectedObservationDate) return

    setSelectedObservationDate(opt.value)
    setSelectedObservations([])
    const selectedObservationDateData = observationsByDate.find(
      obsDate => moment.utc(obsDate.observedAt).format('MM/DD/YYYY') === opt.value
    )
    if (!!selectedObservationDateData) {
      setObservationsToDisplay(selectedObservationDateData.cropStressTypes)
    }
  }

  const flightPickersByName = {
    BeforeAndAfterFlightPicker,
    NthFlightPicker,
  }
  const FlightPicker =
    template && template.flightPicker
      ? flightPickersByName[template.flightPicker]
      : flightPickersByName[0]

  return (
    <form onSubmit={onSubmit}>
      <section className={styles.aerialImageryReportFormSection}>
        <AerialImageryReportTemplatePicker
          onChange={setTemplate}
          selectedTemplate={template}
          customerAccountId={season.customerAccount.id}
        />
      </section>
      {template && (
        <React.Fragment>
          <section className={styles.aerialImageryReportFormSection}>
            <TextInput
              name='title'
              label='Title (required)'
              value={title}
              onChange={e => setTitle(e.target.value)}
              required={true}
            />
            <FileInput
              name='companyLogo'
              label='Company Logo'
              inputRef={logoInput}
              onChange={updateImageSource}
            />
            {imgSrc && (
              <img className={styles.previewImage} src={imgSrc} alt='File upload preview' />
            )}
            <ColorPickerInput
              name='brandColor'
              label='Brand Color'
              value={brandColor}
              onChange={setBrandColor}
            />
          </section>
          {!template.hideProductHeader &&
            !template.hideLocation &&
            !template.hideSeedType &&
            !template.hideApplicationDate && (
              <section className={styles.aerialImageryReportFormSection}>
                <h2 className={`h3 ${styles.sectionLabel}`}>Enter Field and Test Details</h2>
                {!template.hideProductHeader && (
                  <TextInput
                    name='chemicalUsed'
                    label='Chemical used'
                    value={chemicalUsed}
                    onChange={e => setChemicalUsed(e.target.value)}
                    placeholder='Aker Fungicide'
                  />
                )}
                {!template.hideLocation && (
                  <TextInput
                    name='location'
                    label='Location'
                    value={location}
                    onChange={e => setLocation(e.target.value)}
                    placeholder='County, State'
                  />
                )}
                {!template.hideSeedType && (
                  <TextInput
                    name='hybrid'
                    label='Hybrid / Variety'
                    value={hybrid}
                    onChange={e => setHybrid(e.target.value)}
                    placeholder='Green 54-38'
                  />
                )}
                {!template.hideApplicationDate && (
                  <DatePicker
                    name='applicationDate'
                    label='Application Date'
                    value={applicationDate}
                    onChange={date => setApplicationDate(date)}
                  />
                )}
              </section>
            )}

          <section className={styles.aerialImageryReportFormSection}>
            <OnOffSwitch
              name='fieldRegions'
              label='Add Field Regions'
              value={showFieldRegions}
              onChange={() => setShowFieldRegions(!showFieldRegions)}
              className={styles.sectionLabel}
              labelClassName='h3'
            />
            {showFieldRegions && (
              <React.Fragment>
                <FieldRegionDisplay regions={regions} season={season} />
                {!template ||
                  (!template.hideCommodityPrice && (
                    <TextInput
                      name='commodityPrice'
                      label='Commodity Price'
                      value={commodityPrice}
                      onChange={e => setCommodityPrice(e.target.value)}
                      placeholder='8.60'
                    >
                      <span className={styles.bushelsPerAcre}>bu/acre</span>
                    </TextInput>
                  ))}
              </React.Fragment>
            )}
          </section>
          {!template.hideAerialImageryData && (
            <section className={styles.aerialImageryReportFormSection}>
              <OnOffSwitch
                name='plantHealthyImagery'
                label='Add Plant Health Imagery'
                value={showPlantHealthyImagery}
                onChange={() => setShowPlantHealthyImagery(!showPlantHealthyImagery)}
                className={styles.sectionLabel}
                labelClassName='h3'
              />
              {showPlantHealthyImagery && (
                <FlightPicker
                  report={report}
                  season={season || { field: {} }}
                  flights={flights}
                  regions={regions}
                  cropId={cropId}
                  flightLimit={template.flightLimit}
                  setFlights={setSelectedFlights}
                />
              )}
            </section>
          )}
          {!template.hideObservationData && (
            <section className={styles.aerialImageryReportFormSection}>
              <OnOffSwitch
                name='probeObservations'
                label='Include TrueCause Issues'
                value={showProbeObservations}
                onChange={() => setShowProbeObservations(!showProbeObservations)}
                className={styles.sectionLabel}
                labelClassName='h3'
              />
              {!observationsByDate && showProbeObservations && (
                <div className={styles.loadingContainer}>
                  <LoadingState />
                </div>
              )}
              {observationsByDate && showProbeObservations && (
                <React.Fragment>
                  <Dropdown
                    name={`trueCauseDate`}
                    label='Date of TrueCause Observation'
                    options={observationDateOptions}
                    value={observationDateOptions.find(
                      opt => opt.value === selectedObservationDate
                    )}
                    onChange={handleObservationDateChange}
                    placeholder='Please select a date that TrueCause imagery was taken'
                  />
                  {!!selectedObservationDate && (
                    <ObservationPicker
                      observationsGroupedByStressType={observationsToDisplay}
                      selectedObservations={selectedObservations}
                      setSelectedObservations={setSelectedObservations}
                      observationLimit={template.observationLimit}
                    />
                  )}
                </React.Fragment>
              )}
            </section>
          )}
          <section className={styles.aerialImageryReportFormSection}>
            <h2 className={`h3 ${styles.sectionLabel}`}>Enter Personal Information</h2>
            {!template.hideContactData && (
              <React.Fragment>
                <TextInput
                  name='contactName'
                  label='Contact Name'
                  value={contactName}
                  onChange={e => setContactName(e.target.value)}
                  placeholder='Johnny Appleseed'
                />
                <TextInput
                  name='contactPhone'
                  label='Contact Phone'
                  value={contactPhone}
                  onChange={e => setContactPhone(e.target.value)}
                  placeholder='(555) 555-5555'
                />
                <TextInput
                  name='contactEmail'
                  label='Contact Email'
                  value={contactEmail}
                  onChange={e => setContactEmail(e.target.value)}
                  placeholder='jappleseed@aker.ag'
                />
              </React.Fragment>
            )}

            <TextAreaInput
              name='termsAndConditions'
              label='Terms & Conditions'
              value={termsAndConditions}
              onChange={e => setTermsAndConditions(e.target.value)}
              placeholder='Terms & Conditions'
            />
          </section>
          <div className={styles.buttonContainer}>
            {!waitingOnPdfGeneration ? <Button type='submit' variant='primary'>
              Save Report
            </Button> : <Button>Saving...</Button>}
          </div>
        </React.Fragment>
      )}
    </form>
  )
}

AerialImageryReportForm.propTypes = {
  onSave: PropTypes.func,
  season: PropTypes.object,
  flights: PropTypes.array,
  regions: PropTypes.array,
  observationsByDate: PropTypes.arrayOf(
    PropTypes.shape({
      observedAt: PropTypes.string,
      cropStressTypes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          cropStressItems: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              issues: PropTypes.arrayOf(
                PropTypes.shape({
                  images: PropTypes.array,
                })
              ).isRequired,
            })
          ).isRequired,
        })
      ),
    })
  ),
  report: PropTypes.shape({
    template: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    productHeader: PropTypes.string,
    seedType: PropTypes.string,
    location: PropTypes.string,
    applicationDate: PropTypes.string,
    contactPhone: PropTypes.string,
    contactName: PropTypes.string,
    contactEmail: PropTypes.string,
    commodityPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    footnote: PropTypes.string,
    flights: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        growthStage: PropTypes.string,
      })
    ),
  }),
}
