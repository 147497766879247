import React from 'react'
import PropTypes from 'prop-types'
import { UserAccessListItem } from './UserAccessListItem'

export const UserAccessList = ({ list, editable, onChange }) => {
  if (!list) return null
  const renderCustomerAccountManagers = () => {
    return list.customerAccountManagers.map(user => (
      <UserAccessListItem key={user.id} user={user} />
    ))
  }

  const renderSubscribers = () => {
    return list.subscribers.map(user => (
      <UserAccessListItem onChange={onChange} key={user.id} user={user} editable={editable} />
    ))
  }

  return (
    <React.Fragment>
      {renderCustomerAccountManagers()}
      {renderSubscribers()}
    </React.Fragment>
  )
}

UserAccessList.propTypes = {
  list: PropTypes.object,
}
