import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Helmet from 'react-helmet'
import ChangePasswordForm from './ChangePasswordForm'
import {
  changePassword,
  clearAllErrors,
  clearError,
  clearPasswordSuccess,
} from 'redux/modules/auth'
import config from '../../config'
import styles from './ChangePassword.module.scss'

/**
 * ChangePassword
 * Change users password after clicking the email link.
 */
const enhance = compose(
  connect(
    state => ({
      changeSuccess: state.auth.changeSuccess,
      changeErrors: state.auth.errors,
      changingPassword: state.auth.changingPassword,
    }),
    {
      changePassword,
      clearAllErrors,
      clearError,
      clearPasswordSuccess,
    }
  )
)

export default enhance(
  class ChangePassword extends Component {
    static propTypes = {
      changePassword: PropTypes.func.isRequired,
      changingPassword: PropTypes.bool,
      changeSuccess: PropTypes.object,
      changeErrors: PropTypes.array,
      clearAllErrors: PropTypes.func.isRequired,
      clearError: PropTypes.func.isRequired,
      clearPasswordSuccess: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
    }

    constructor(props) {
      super(props)
      this.handleAlertDismiss = this.handleAlertDismiss.bind(this)
    }

    componentWillUnmount() {
      this.props.clearAllErrors()
      this.props.clearPasswordSuccess()
    }

    handleSubmit = data => {
      data.token = this.props.params.emailToken
      this.props.changePassword(data)
    }

    handleAlertDismiss(alertItem) {
      this.props.clearError(alertItem)
    }

    render() {
      const { changeSuccess, changeErrors, changingPassword } = this.props
      const logoImage = require('assets/logo-aker-white.svg')
      const shouldDisableForm = typeof resetSuccess !== 'undefined' || changingPassword || false

      return (
        <div className={styles.siteWrapper}>
          <Helmet title='Change password' />

          <div className={styles.passwordForgotWindow}>
            <div className={styles.passwordForgotWindowBody}>
              <img className={styles.logo} src={logoImage} alt={config.app.title} />

              <h2 className={styles.heading}>Change your password</h2>
            </div>

            <ChangePasswordForm
              onSubmit={this.handleSubmit}
              formSuccess={changeSuccess}
              formErrors={changeErrors}
              handleAlertDismiss={this.handleAlertDismiss}
              isDisabled={shouldDisableForm}
            />
          </div>
        </div>
      )
    }
  }
)
