import React from 'react'
import PropTypes from 'prop-types'
import { FaArrowUp } from 'react-icons/fa'
import styles from '../AerialImageryReport.module.scss'

export function RevenueAdvantage({ brandColor, revenueAdvantage, commodityPrice }) {
  return (
    <React.Fragment>
      <h2 className={`h4 ${styles.advantageHeader}`}>Revenue Advantage</h2>
      <div className={styles.advantageValueContainer}>
        <FaArrowUp
          className={styles.advantageArrow}
          style={{
            color: brandColor,
          }}
        />
        <div className={styles.advantageValue} style={{ color: brandColor }}>
          {revenueAdvantage ? `$${revenueAdvantage} USD/acre` : 'N/A'}
        </div>
      </div>
      {commodityPrice && (
        <div className={styles.smallText}>
          *based on ${commodityPrice.toFixed(2)}
          /bu
        </div>
      )}
    </React.Fragment>
  )
}

RevenueAdvantage.propTypes = {
  brandColor: PropTypes.string.isRequired,
  revenueAdvantage: PropTypes.string,
  commodityPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
