import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router'
import { Button, Alert } from 'react-bootstrap'
import { WrappedInputField } from '../_shared'
import { required } from 'helpers'
import styles from './ChangePasswordForm.module.scss'

/**
 * ChangePasswordForm
 * Redux form to set a new password request to the API.
 */

class ChangePasswordFormComponent extends Component {
  render() {
    const { handleSubmit, formSuccess, formErrors, handleAlertDismiss, isDisabled } = this.props

    const renderLeftArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#FFFFFF' strokeMiterlimit='10' points='7,13 1.5,7.5 7,2  ' />
      </svg>
    )

    return (
      <form className='form-inverse' onSubmit={handleSubmit}>
        <div className={styles.passwordForgotWindowBody}>
          {formSuccess && <Alert variant='success'>You have set a new password!</Alert>}
          {formErrors &&
            formErrors.map(error => {
              return (
                <Alert
                  variant='danger'
                  key={error.code}
                  onDismiss={() => handleAlertDismiss(error)}
                >
                  {error.message}
                </Alert>
              )
            })}

          {!formSuccess && (
            <WrappedInputField
              name={'password'}
              title={'Password'}
              placeholder={'New Password'}
              columnWidth={12}
              type={'text'}
              required={true}
              validate={required}
              disabled={false}
              horizontal={true}
            />
          )}

          <Link to='/login' className={styles.forgotPasswordLink}>
            {renderLeftArrow} Return to login page
          </Link>
        </div>

        <Button
          type='submit'
          variant='primary'
          bsSize='large'
          block
          groupClassName={styles.submitButton}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Change password
        </Button>
      </form>
    )
  }
}

ChangePasswordFormComponent.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formSuccess: PropTypes.object,
  formErrors: PropTypes.array,
  handleAlertDismiss: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'changePassword',
  touchOnBlur: false,
})(ChangePasswordFormComponent)
