const GET_PROBE_OBSERVATIONS_BY_SEASON = 'aker/TRIALS/GET_PROBE_OBSERVATIONS_BY_SEASON'
const GET_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS =
  'aker/TRIALS/GET_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS'
const GET_PROBE_OBSERVATIONS_BY_SEASON_FAIL = 'aker/TRIALS/GET_PROBE_OBSERVATIONS_BY_SEASON_FAIL'

const GET_PROBE_IMAGERY_BY_SEASON = 'aker/TRIALS/GET_PROBE_IMAGERY_BY_SEASON'
const GET_PROBE_IMAGERY_BY_SEASON_SUCCESS = 'aker/TRIALS/GET_PROBE_IMAGERY_BY_SEASON_SUCCESS'
const GET_PROBE_IMAGERY_BY_SEASON_FAIL = 'aker/TRIALS/GET_PROBE_IMAGERY_BY_SEASON_FAIL'

const GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON =
  'aker/TRIALS/GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON'
const GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS =
  'aker/TRIALS/GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS'
const GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_FAIL =
  'aker/TRIALS/GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_FAIL'

const initialState = {
  probeObservationsBySeason: {
    loaded: false,
  },
  probeCyclesBySeason: {
    loaded: false,
  },
  flaggedProbeObservationsBySeason: {
    loaded: false,
  },
}

export default function probeData(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PROBE_OBSERVATIONS_BY_SEASON:
      return {
        ...state,
        probeObservationsBySeason: {
          ...state.probeObservationsBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS:
      return {
        ...state,
        probeObservationsBySeason: {
          ...state.probeObservationsBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_PROBE_OBSERVATIONS_BY_SEASON_FAIL:
      return {
        ...state,
        probeObservationsBySeason: {
          ...state.probeObservationsBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_PROBE_IMAGERY_BY_SEASON:
      return {
        ...state,
        probeCyclesBySeason: {
          ...state.probeCyclesBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_PROBE_IMAGERY_BY_SEASON_SUCCESS:
      return {
        ...state,
        probeCyclesBySeason: {
          ...state.probeCyclesBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_PROBE_IMAGERY_BY_SEASON_FAIL:
      return {
        ...state,
        probeCyclesBySeason: {
          ...state.probeCyclesBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON:
      return {
        ...state,
        flaggedProbeObservationsBySeason: {
          ...state.flaggedProbeObservationsBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS:
      return {
        ...state,
        flaggedProbeObservationsBySeason: {
          ...state.flaggedProbeObservationsBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_FAIL:
      return {
        ...state,
        flaggedProbeObservationsBySeason: {
          ...state.flaggedProbeObservationsBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    default:
      return state
  }
}

export function getProbeObservationsBySeasonId(seasonId) {
  return {
    types: [
      GET_PROBE_OBSERVATIONS_BY_SEASON,
      GET_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS,
      GET_PROBE_OBSERVATIONS_BY_SEASON_FAIL,
    ],
    promise: client => client.get(`/seasons/${seasonId}/probe_observations`),
  }
}

export function getProbeImageryBySeasonId(seasonId) {
  return {
    types: [
      GET_PROBE_IMAGERY_BY_SEASON,
      GET_PROBE_IMAGERY_BY_SEASON_SUCCESS,
      GET_PROBE_IMAGERY_BY_SEASON_FAIL,
    ],
    promise: client => client.get(`/seasons/${seasonId}/probe_cycles`),
  }
}

export function getFlaggedProbeObservationsBySeasonId(seasonId) {
  return {
    types: [
      GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON,
      GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_SUCCESS,
      GET_FLAGGED_PROBE_OBSERVATIONS_BY_SEASON_FAIL,
    ],
    promise: client => client.get(`/seasons/${seasonId}/flagged_probe_observations`),
  }
}
