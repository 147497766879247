/* global shp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import L from 'leaflet'
import swal from '@sweetalert/with-react'
import multiply from '../LegacyLeafletMap/multiply'
import classNames from 'classnames'
import { required } from 'helpers'
import { ReduxFormInput } from '../_shared'
import styles from './CreateFieldForm.module.scss'
import { ImportFieldFromThirdParty } from './ImportFieldFromThirdParty/ImportFieldFromThirdParty'
import ImportFieldInstructions from './ImportFieldInstructions'

class CreateFieldFormComponent extends Component {
  constructor() {
    super()

    this.showShapeFileInput = this.showShapeFileInput.bind(this)
    this.state = {
      showShapeFileInput: false,
      fieldNameInputFromSidePanel: '',
    }
  }

  showShapeFileInput() {
    this.setState({
      showShapeFileInput: true,
    })
  }

  renderShapeFileInput = () => {
    const { setUploadedPolygon } = this.props

    return (
      <div>
        <div className={styles.shapeHelperText}>Only zip shapefiles are supported</div>
        <input
          type='file'
          className={styles.shapeFileInput}
          onChange={event => {
            if (!event.target && !event.target.files) {
              return
            }
            if (event.target.files[0].name.slice(-3) !== 'zip') {
              swal({
                title: 'Error!',
                text:
                  'Please make sure you are uploading a zip shapefile. Contact support@aker.ag for more questions.',
                icon: 'error',
                dangerMode: true,
                confirmButtonText: 'OK',
                closeModal: true,
              })
              event.target.value = ''
              return
            }
            const zipFile = event.target.files[0]
            const reader = new FileReader()
            const Leaflet = L
            reader.onload = buffer => {
              shp(buffer.target.result).then(
                geoJSON => {
                  const mergedJSON = multiply(geoJSON)
                  const layer = Leaflet.geoJson(mergedJSON)
                  console.log('layer', layer)
                  setUploadedPolygon(layer)
                },
                error => {
                  console.log(error)
                  swal({
                    title: 'Error!',
                    text: `${error}`,
                    icon: 'error',
                    dangerMode: true,
                    confirmButtonText: 'OK',
                    closeModal: true,
                  })
                }
              )
            }
            reader.readAsArrayBuffer(zipFile)
          }}
        />
      </div>
    )
  }

  setFieldNameInputFromSidePanel = prop => {
    this.setState({ fieldNameInputFromSidePanel: prop })
  }

  render() {
    const {
      handleSubmit,
      acresSelected,
      toggleImportFieldComponent,
      showImportFieldComponent,
      hasFieldsImported,
      updateImportedFieldData,
      setSelectedPolygon,
      selectedImportedFieldId,
      passSelectedImportedFieldId,
      importedFieldData,
      clearFieldSelection,
    } = this.props

    return (
      <div className={styles.createFieldWrapper}>
        <div className={styles.headingWrapper}>
          <h1 className={styles.heading}>Create Field</h1>
        </div>
        <form
          className={styles.createFieldForm + ' form-horizontal'}
          style={showImportFieldComponent ? { paddingBottom: 0 } : {}}
        >
          <Button
            type='button'
            value='Import Field'
            variant='secondary'
            groupClassName={styles.createFieldFormGroup}
            className={styles.button}
            onClick={() => toggleImportFieldComponent('toggle')}
          >
            Import Field
          </Button>
          <ReduxFormInput fieldName={'fieldName'} type={'text'} validate={required}>
            {({ errorClassName, ...reduxInput }) => {
              if (this.state.fieldNameInputFromSidePanel !== '') {
                reduxInput.value = this.state.fieldNameInputFromSidePanel
              }
              return (
                <Form.Control
                  {...reduxInput}
                  key={'fieldName'}
                  type={'text'}
                  id={'fieldName'}
                  placeholder={'Field Name'}
                  className={classNames(errorClassName, {
                    [styles.createFieldInput]: true,
                  })}
                  disabled={false}
                  required={true}
                  validate={required}
                />
              )
            }}
          </ReduxFormInput>

          <Button
            type='submit'
            value='Create'
            variant='primary'
            groupClassName={styles.createFieldFormGroup}
            className={styles.button}
            onClick={handleSubmit}
          >
            Create
          </Button>

          {acresSelected >= 0 && <div>Acres: {acresSelected}</div>}

          {showImportFieldComponent ? (
            <ImportFieldFromThirdParty
              updateImportedFieldData={updateImportedFieldData}
              toggleImportFieldComponent={toggleImportFieldComponent}
              hasFieldsImported={hasFieldsImported}
              setSelectedPolygon={setSelectedPolygon}
              selectedImportedFieldId={selectedImportedFieldId}
              passSelectedImportedFieldId={passSelectedImportedFieldId}
              importedFieldData={importedFieldData}
              setFieldNameInputFromSidePanel={this.setFieldNameInputFromSidePanel}
              clearFieldSelection={clearFieldSelection}
            />
          ) : (
            <ImportFieldInstructions />
          )}
        </form>
      </div>
    )
  }
}

CreateFieldFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  acresSelected: PropTypes.number,
  setUploadedPolygon: PropTypes.func,
}

export default reduxForm({
  form: 'CreateFieldForm',
  touchOnBlur: true,
})(CreateFieldFormComponent)
