import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { getFarms } from 'redux/modules/farms'
import { getUserAccessList } from 'redux/modules/useraccess'
import { addSubscriptions, removeSubscriptions } from 'redux/modules/subscriptions'
import { FarmFieldAccess } from './FarmFieldAccess'
import { LoadingState } from '_shared'
import Helmet from 'react-helmet'
import requireRole from '../../helpers/requireRole'
import { SideMenu } from 'components'
import { FARM_USER_ACCESS_ACTION, FIELD_USER_ACCESS_ACTION } from './FarmFieldAccess'
import { ADD_SUBSCRIPTIONS_ACTION } from './UserAccessModal'
import style from './UserAccess.module.scss'
// We are resolving an empty promise here because we need
// access to props.dispatch in componentDidMount
const asyncConnectWrapper = asyncConnect([
  {
    promise: ({ store }) => {
      return Promise.all([])
    },
  },
])

const connectWrapper = connect(
  state => ({
    farms: state.farms.farms,
    userAccessList: state.useraccess.userAccessList.data,
    addErrors: state.account.addErrors,
    subscribers: state.subscriptions.subscribers.data,
    removedSubscribers: state.subscriptions.removedSubscribers.data,
  }),
  {
    pushState: push,
    getUserAccessList,
    getFarms,
    addSubscriptions,
    removeSubscriptions,
  }
)

class FarmFieldAccessContainer extends Component {
  onResourceSelect = (type, resourceId) => {
    switch (type) {
      case FARM_USER_ACCESS_ACTION:
        this.props.dispatch(getUserAccessList('farm', resourceId))
        break
      case FIELD_USER_ACCESS_ACTION:
        this.props.dispatch(getUserAccessList('field', resourceId))
        break
      default:
        break
    }
  }

  componentDidMount() {
    this.props.dispatch(getFarms())
  }

  refreshUserAccess = () => {
    this.props.dispatch(getFarms())
  }

  handleRemoveSubscriptions = subscriptionIds => {
    this.props.dispatch(removeSubscriptions(subscriptionIds))
  }

  handleAddSubscriptions = (type, params) => {
    const { resourceType, resourceId, subscriberParams } = params
    switch (type) {
      case ADD_SUBSCRIPTIONS_ACTION:
        this.props.dispatch(addSubscriptions(resourceType, resourceId, subscriberParams))
        break
      default:
        break
    }
  }

  areFarmsLoaded = () => {
    const { farms } = this.props
    return farms.loaded && farms.data
  }

  render() {
    const { user, farms, userAccessList, subscribers, removedSubscribers } = this.props

    return this.areFarmsLoaded() ? (
      <div className={style.userAccessContainer}>
        <Helmet title='Manage users' />
        <SideMenu user={user} />
        <div className={`container ${style.userAccessWrapper}`}>
          <FarmFieldAccess
            farms={farms.data}
            subscribers={subscribers}
            removedSubscribers={removedSubscribers}
            refreshUserAccess={this.refreshUserAccess}
            userAccessList={userAccessList}
            onResourceSelect={this.onResourceSelect}
            handleAddSubscriptions={this.handleAddSubscriptions}
            handleRemoveSubscriptions={this.handleRemoveSubscriptions}
          />
        </div>
      </div>
    ) : (
      <LoadingState />
    )
  }
}

export default withRouter(
  asyncConnectWrapper(connectWrapper(requireRole([])(FarmFieldAccessContainer)))
)

FarmFieldAccessContainer.propTypes = {
  user: PropTypes.object.isRequired,
}
