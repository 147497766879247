// Multiply single type geojsons into a multi type geojson

const multiply = (geojsons, options) => {
  if (!geojsons) {
    return null
  }
  /* eslint-disable */
  if (geojsons.type === 'FeatureCollection') {
    geojsons = geojsons.features
  }

  if (Array.isArray(geojsons) && geojsons.length === 0) {
    return null
  }

  if (!Array.isArray(geojsons)) {
    geojsons = [geojsons]
  }
  /* eslint-enable */

  let properties
  let onEachFeature
  if (options) {
    properties = options.properties || {}
    onEachFeature = options.onEachFeature
  } else {
    properties = {}
  }

  const geomType = geojsons[0].geometry.type
  const result = {
    type: 'Feature',
    geometry: { coordinates: [] },
    properties: properties,
  }

  switch (geomType) {
    case 'Point':
    case 'LineString':
    case 'Polygon':
      result.geometry.type = 'Multi' + geomType
      break
    default:
      return null
  }

  for (let step = 0, length = geojsons.length; step < length; step++) {
    const geojson = geojsons[step]

    if (geojson.geometry.type !== geomType) {
      return null
    }

    result.geometry.coordinates.push(geojson.geometry.coordinates)

    if (onEachFeature) {
      result.properties = onEachFeature(properties, geojson.properties, step, geojsons)
    }
  }

  return result
}

export default multiply
