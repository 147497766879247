import React from 'react'
import PropTypes from 'prop-types'
import styles from '../AerialImageryReport.module.scss'

export function InfoGridRow({ label, value }) {
  return (
    <div className={styles.infoRow}>
      <div className={styles.infoLabel}>{label}:</div>
      <div className={styles.infoValue}>
        <span>{value}</span>
      </div>
    </div>
  )
}

InfoGridRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}
