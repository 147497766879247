const GET_REGIONS_BY_SEASON = 'aker/REGIONS/GET_REGIONS_BY_SEASON'
const GET_REGIONS_BY_SEASON_SUCCESS = 'aker/REGIONS/GET_REGIONS_BY_SEASON_SUCCESS'
const GET_REGIONS_BY_SEASON_FAIL = 'aker/REGIONS/GET_REGIONS_BY_SEASON_FAIL'

const GET_REGION = 'aker/REGIONS/GET_REGION'
const GET_REGION_SUCCESS = 'aker/REGIONS/GET_REGION_SUCCESS'
const GET_REGION_FAIL = 'aker/REGIONS/GET_REGION_FAIL'

const CREATE_REGION = 'aker/REGIONS/CREATE_REGION'
const CREATE_REGION_SUCCESS = 'aker/REGIONS/CREATE_REGION_SUCCESS'
const CREATE_REGION_FAIL = 'aker/REGIONS/CREATE_REGION_FAIL'

const UPDATE_REGION = 'aker/REGIONS/UPDATE_REGION'
const UPDATE_REGION_SUCCESS = 'aker/REGIONS/UPDATE_REGION_SUCCESS'
const UPDATE_REGION_FAIL = 'aker/REGIONS/UPDATE_REGION_FAIL'

const DELETE_REGION = 'aker/REGIONS/DELETE_REGION'
const DELETE_REGION_SUCCESS = 'aker/REGIONS/DELETE_REGION_SUCCESS'
const DELETE_REGION_FAIL = 'aker/REGIONS/DELETE_REGION_FAIL'

const initialState = {
  regionsBySeason: {
    loaded: false,
  },
  region: {
    loaded: false,
  },
}

export default function regions(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REGIONS_BY_SEASON:
      return {
        ...state,
        regionsBySeason: {
          ...state.regionsBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REGIONS_BY_SEASON_SUCCESS:
      return {
        ...state,
        regionsBySeason: {
          ...state.regionsBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REGIONS_BY_SEASON_FAIL:
      return {
        ...state,
        regionsBySeason: {
          ...state.regionsBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_REGION:
      return {
        ...state,
        region: {
          ...state.region,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REGION_SUCCESS:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REGION_FAIL:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case CREATE_REGION:
      return {
        ...state,
        region: {
          ...state.region,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case CREATE_REGION_SUCCESS:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case CREATE_REGION_FAIL:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case UPDATE_REGION:
      return {
        ...state,
        region: {
          ...state.region,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case UPDATE_REGION_SUCCESS:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case UPDATE_REGION_FAIL:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case DELETE_REGION:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case DELETE_REGION_SUCCESS:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case DELETE_REGION_FAIL:
      return {
        ...state,
        regions: {
          ...state.regions,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    default:
      return state
  }
}

export function getRegionsBySeasonId(seasonId) {
  return {
    types: [GET_REGIONS_BY_SEASON, GET_REGIONS_BY_SEASON_SUCCESS, GET_REGIONS_BY_SEASON_FAIL],
    promise: client => client.get(`/seasons/${seasonId}/regions`),
  }
}

export function getRegion(regionId) {
  return {
    types: [GET_REGION, GET_REGION_SUCCESS, GET_REGION_FAIL],
    promise: client => client.get(`/regions/${regionId}`),
  }
}

export function createRegion(requestParams) {
  return {
    types: [CREATE_REGION, CREATE_REGION_SUCCESS, CREATE_REGION_FAIL],
    promise: client =>
      client.post('/regions', {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function updateRegion(requestParams) {
  return {
    types: [UPDATE_REGION, UPDATE_REGION_SUCCESS, UPDATE_REGION_FAIL],
    promise: client =>
      client.put(`/regions/${requestParams.regionId}`, {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function deleteRegion(regionId) {
  return {
    types: [DELETE_REGION, DELETE_REGION_SUCCESS, DELETE_REGION_FAIL],
    promise: client => client.del(`/regions/${regionId}`),
  }
}
