import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import { createReport } from 'redux/modules/aerialImageryReports'
import { getSeason } from 'redux/modules/seasons'
import { getFlightsBySeasonId } from 'redux/modules/flights'
import { getRegionsBySeasonId } from 'redux/modules/regions'
import { getFlaggedProbeObservationsBySeasonId } from 'redux/modules/probeData'
import { AerialImageryReportForm } from './Form/AerialImageryReportForm'
import styles from './AerialImageryReport.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { params, store } = options
      const { dispatch } = store
      if (params && params.seasonId) {
        promises.push(dispatch(getSeason(params.seasonId)))
        promises.push(dispatch(getFlightsBySeasonId(params.seasonId)))
        promises.push(dispatch(getRegionsBySeasonId(params.seasonId)))
      }
      return Promise.all(promises)
    },
  },
])
const connectWrapper = connect(
  state => ({
    season: state.seasons.activeSeason,
    flights: state.flights.flightsBySeason,
    regions: state.regions.regionsBySeason,
    observationsByDate: state.probeData.flaggedProbeObservationsBySeason,
  }),
  {
    createReport,
  }
)

export function CreateAerialImageryReportContainerComponent({
  season,
  flights,
  regions,
  observationsByDate,
  createReport,
  dispatch,
}) {
  const [observationsFetched, setObservationsFetched] = useState(false)

  const onTemplateChange = template => {
    if (!template.hideObservationData && !observationsFetched) {
      let observations = dispatch(getFlaggedProbeObservationsBySeasonId(season.data.id))
      setObservationsFetched(true)
      return observations
    }
  }

  const handleSubmit = async params => {
    try {
      const newReport = await createReport(params)
      return newReport.data
    } catch {
      return
    }
  }

  return (
    <div className={styles.aerialImageryReportFormContainer}>
      <h1 className='h2'>Create new report</h1>
      <AerialImageryReportForm
        season={season.data}
        flights={flights.data}
        regions={regions.data}
        observationsByDate={observationsByDate.data || []}
        onSave={handleSubmit}
        onTemplateChange={onTemplateChange}
      />
    </div>
  )
}

const CreateAerialImageryReportContainer = asyncConnectWrapper(
  connectWrapper(CreateAerialImageryReportContainerComponent)
)
export default CreateAerialImageryReportContainer

CreateAerialImageryReportContainerComponent.propTypes = {
  season: PropTypes.shape({ data: PropTypes.object }),
  flights: PropTypes.shape({ data: PropTypes.array }),
  regions: PropTypes.shape({ data: PropTypes.array }),
  createReport: PropTypes.func,
}
