/* eslint no-loop-func: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getReport } from 'redux/modules/reports'
import { getSeason } from 'redux/modules/seasons'
import { loadAccount } from 'redux/modules/account'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import TestPlotToolShow from './Templates/TestPlotToolShow'
import ModernShow from './Templates/ModernShow'
import RevXResultsToolShow from './Templates/RevXResultsToolShow'
// import Helmet from 'react-helmet'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch, getState } = options.store
      const { params } = options
      const state = getState()
      if (params && params.testPlotToolId) {
        promises.push(dispatch(getReport(params.testPlotToolId)))
      }

      if (params && params.seasonId) {
        promises.push(dispatch(getSeason(params.seasonId)))
      }

      promises.push(dispatch(loadAccount(state.auth.user.id)))
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(state => ({
  report: state.reports.report.data,
}))

class TestPlotToolShowComponent extends Component {
  render() {
    const renderForm = () => {
      let jsx = null
      switch (this.props.report.theme) {
        case 'original':
          jsx = <TestPlotToolShow />
          break
        case 'modern':
          jsx = <ModernShow />
          break
        case 'revx':
          jsx = <RevXResultsToolShow />
          break
        default:
          break
      }
      return jsx
    }

    return renderForm()
  }
}

TestPlotToolShowComponent.propTypes = {
  report: PropTypes.object.isRequired,
}

export default asyncConnectWrapper(connectWrapper(TestPlotToolShowComponent))
