import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import styles from './RevXResultsToolShow.module.scss'
import { MapLegendComponent } from './MapLegendComponent'

export function WorkOrderComponent({
  brandColor,
  index,
  timeSinceApplication,
  workOrder,
  workOrdersCount,
  ...props
}) {
  const shouldShowLegend = () => {
    if (!workOrder) {
      return false
    } else if (workOrdersCount > 1 && index === 0) {
      return false
    } else if (workOrder.type === undefined) {
      return false
    } else {
      return true
    }
  }

  return (
    <div className={classNames(styles.center, 'col-12')}>
      {shouldShowLegend() && <MapLegendComponent type={workOrder.type} isRevX={true} />}
      <div className={styles.mapContainer}>
        <div
          id={`leafletmap${index}`}
          key={`leafletmap${index}`}
          style={{ width: '100%', height: 300 }}
        ></div>
      </div>
      <div>
        <h2 className={styles.h2} style={{ color: brandColor }}>
          <span>Date: {moment(workOrder.dateFlown).format('MM/DD/YYYY')}</span>{' '}
          <span>Days After Treatment: {timeSinceApplication}</span>
        </h2>
      </div>
    </div>
  )
}

WorkOrderComponent.propTypes = {
  brandColor: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  timeSinceApplication: PropTypes.string.isRequired,
  workOrder: PropTypes.object.isRequired,
  workOrdersCount: PropTypes.number.isRequired,
}
