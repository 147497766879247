import { useState, useEffect } from 'react'
import Leaflet from 'leaflet'

const basePath = 'https://api.mapbox.com/styles/v1/'
const accessToken =
  'pk.eyJ1IjoiYWtlcmFnIiwiYSI6ImNqazczOTNqNzI1YXMzcW50MHF3NTg3dDMifQ.mQ7ADlqYrVVwJVO9cWqiKQ'
let layerUrl = `${basePath}{id}/{z}/{x}/{y}?access_token=${accessToken}`
if (window && window.devicePixelRatio && window.devicePixelRatio > 1) {
  layerUrl = `${basePath}{id}/{z}/{x}/{y}?access_token=${accessToken}`
}

export function SatelliteLayer({ map, satelliteEnabled }) {
  const [satellite, setSatellite] = useState(null)
  const satelliteLayer = Leaflet.tileLayer(layerUrl, {
    id: 'akerag/ckg8f9hmk06e819mospasxho4/tiles',
    maxZoom: 20,
    maxNativeZoom: 19,
    tileSize: 512,
    zoomOffset: -1
  })

  useEffect(() => {
    if (!map) return

    if (satelliteEnabled) {
      try {
        map.addLayer(satelliteLayer)
        satelliteLayer.bringToBack()
        setSatellite(satelliteLayer)
      } catch (err) {
        console.log('LeafletMap addLayer Error:', err)
      }
    } else {
      try {
        map.removeLayer(satellite)
        setSatellite(null)
      } catch (err) {
        console.log('LeafletMap removeLayer Error:', err)
      }
    }
  }, [map, satelliteEnabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
