import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './YieldAnalysisChart.module.scss'

/**
 * YieldAnalysisChart
 * graphic chart used in TestPlotTool.
 */
export default class YieldAnalysisChart extends Component {
  static propTypes = {
    trials: PropTypes.array.isRequired,
    primaryColor: PropTypes.string,
    variation: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      trials: this.props.trials,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      trials: nextProps.trials,
    })
  }

  setMax = max => {
    this.max = max
  }

  setMin = min => {
    this.min = min
  }

  calculateIntervals = () => {
    const trials = this.state.trials
    let intervals = []
    const linspace = (from, to, len) => {
      const arr = new Array(len)
      const step = (to - from) / (len - 1)
      let index = 0
      for (; index < len; ++index) {
        arr[index] = Math.round(from + index * step)
      }
      return arr
    }

    const yieldValues = trials.reduce((result, trial) => {
      if (trial.yield) {
        result.push(trial.yield)
      }
      return result
    }, [])
    yieldValues.sort((first, second) => {
      return first - second
    })
    if (yieldValues[0]) {
      const theoreticalMin = yieldValues[0] - 20 < 0 ? 0 : yieldValues[0] - 20
      this.setMax(yieldValues[yieldValues.length - 1] + 10)
      this.setMin(theoreticalMin)
      intervals = linspace(this.min, this.max, 6)
    }

    return intervals
  }

  render() {
    const intervals = this.calculateIntervals()
    const variation = this.props.variation
    const sortedTrials = this.state.trials.sort((trial1, trial2) => {
      return trial2.yield - trial1.yield
    })
    const treatmentRows = () => {
      const trials = sortedTrials.map((trial, index) => {
        let widthPercentage = 0
        if (trial.yield) {
          widthPercentage = (trial.yield - this.min) / (this.max - this.min)
        }

        const barBackgroundColor = index !== 0 ? '#ccc' : this.props.primaryColor

        return (
          <div key={trial.id} className={`${styles.yacRow} ${trial.yield ? '' : styles.noYield}`}>
            <div
              className={styles.yacRowColor}
              style={{
                width: trial.yield ? `${widthPercentage * 100}%` : 'auto',
                backgroundColor: barBackgroundColor,
              }}
            >
              {trial.isUntreated ? 'Untreated' : trial.productName}
            </div>
            <div className={styles.yacRowNum}>{trial.yield ? trial.yield : 'N/A'}</div>
          </div>
        )
      })

      return trials
    }

    return (
      <div className={`${styles.YieldAnalysisChart} ${variation === 'B' && styles.variation}`}>
        <div className={styles.topLegend}>
          {sortedTrials &&
            sortedTrials.map((trial, index) => {
              return (
                <div
                  key={trial.id}
                  style={{
                    display: 'inline-block',
                    width: `${100 / sortedTrials.length}%`,
                    textAlign: 'center',
                  }}
                >
                  <div>
                    <span
                      style={{
                        width: 15,
                        height: 15,
                        display: 'inline-block',
                        marginRight: 4,
                        boxShadow: `inset 0 0 0 1000px ${
                          index !== 0 ? '#ccc' : this.props.primaryColor
                        } !important`,
                      }}
                    ></span>
                    <div
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                      }}
                    >
                      {trial.isUntreated ? 'Untreated' : trial.productName}
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        <div className={styles.yacTop}>
          <div className={styles.yacName1}>Treatment</div>
          {treatmentRows()}
        </div>
        <div className={styles.yacBottom} id='yacBottom'>
          {intervals.map((interval, index) => {
            if (intervals.length - 1 === index) {
              return null
            }
            return (
              <div key={interval} className={styles.yacBottomLegendRow}>
                <div className={styles.value}>{interval}</div>
              </div>
            )
          })}
        </div>
        <div className={styles.yacName2}>Yield (bushels/acre)</div>
      </div>
    )
  }
}
