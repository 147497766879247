import React from 'react'
import PropTypes from 'prop-types'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryLegend({ type }) {
  const legendSrc = require('assets/legends/NDVI-legend_reversed.png')
  const altText = 'legend relating colors to damage for imagery'

  if (type !== 'thermal' && type !== 'ndvi' && type !== 'ndre') {
    return null
  }

  return (
    <div className={`${styles.legendContainer}`}>
      <div className={styles.legendImageContainer}>
        <img src={legendSrc} alt={altText} />
      </div>
      <div className={styles.legendLeft}>Less Healthy</div>
      <div className={styles.legendRight}>Healthy</div>
    </div>
  )
}

AerialImageryLegend.propTypes = {
  type: PropTypes.string,
}
