import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Alert, Button, Row, Col } from 'react-bootstrap'
import { Dropdown } from '_shared'
import styles from './ModalForm.module.scss'

/**
 * ModalForm
 * Form to add a new farm or field to a list with validation passed as props
 * to check if all fields are. provided. Form submission is handled by the
 * FarmsAndFields container.Basically all logic is placed outside of this component,
 * so you can re-use this whenever you want to place a form in a modal window.
 */

class FarmsModalFormComponent extends Component {
  state = {
    selectedCustomerAccountId: '',
    farmTitle: '',
  }

  handleDropdownChange = selectedOption => {
    this.setState({ selectedCustomerAccountId: selectedOption.value })
  }

  customerAccountOptions = () => {
    return this.props.customerAccounts.map(account => ({ label: account.name, value: account.id }))
  }

  handleInputChange = event => {
    const { value } = event.target
    this.setState({ farmTitle: value })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { farmTitle, selectedCustomerAccountId } = this.state
    const data = {
      farmTitle,
      customerAccountId: selectedCustomerAccountId,
    }
    this.props.onSubmit(data)
  }

  render() {
    const { addButtonType, handleDismiss, handleSubmit, handleCancel, modalErrors } = this.props
    const { selectedCustomerAccountId, farmTitle } = this.state
    return (
      <div>
        {modalErrors &&
          modalErrors.map(error => {
            return (
              <Alert onDismiss={() => handleDismiss(error)} variant='danger' key={error.code}>
                {error.message}
              </Alert>
            )
          })}

        <form className='form-horizontal' handleSubmit={handleSubmit}>
          <Dropdown
            placeholder='Select Customer Account'
            options={this.customerAccountOptions()}
            onChange={selectedOption => this.handleDropdownChange(selectedOption)}
            value={this.customerAccountOptions().find(
              opt => opt.value === this.state.selectedCustomerAccountId
            )}
            inputId={`customerAccountDropdown`}
          />
          <label>
            Farm Name
            <input
              className={styles.farmTitleInput}
              value={this.state.farmTitle}
              onChange={this.handleInputChange}
              name='farmTitle'
            />
          </label>

          <div className={styles.formButtonRow}>
            <Row>
              <Col xs={6} className='text-left'>
                {handleCancel && (
                  <Button variant='outline-primary' onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xs={6} className='text-right'>
                <Button
                  type='submit'
                  variant='primary'
                  onClick={this.handleSubmit}
                  disabled={!selectedCustomerAccountId || !farmTitle}
                >
                  Add {addButtonType}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    )
  }
}

FarmsModalFormComponent.propTypes = {
  addButtonType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  handleDismiss: PropTypes.func,
  postInProgress: PropTypes.bool,
  modalErrors: PropTypes.array,
}

export default reduxForm({
  form: 'farmsModalForm',
  touchOnBlur: false,
})(FarmsModalFormComponent)
