import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import styles from './List.module.scss'
import classNames from 'classnames'

// This is the top-level container that holds all of the components of a sidebar list
export function ListContainer(props) {
  const { children, className } = props

  return <div className={`${styles.listContainer} ${className}`}>{children}</div>
}

// This is the large title that appears at the top of the sidebar, it is only clickable if 'onClick' is passed in
export function ListTitleHeader({ title, selectedSection, onClick, children, className }) {
  const labelStyles = classNames(styles.listTitleHeader, className, {
    [styles.deselected]: !selectedSection,
  })

  const titleText = title ? <h1 className={styles.listTitleText}>{title}</h1> : null

  return (
    <div className={labelStyles}>
      {onClick ? (
        <button onClick={onClick} className={styles.headerButton}>
          {titleText}
          {children}
        </button>
      ) : (
        <React.Fragment>
          {titleText}
          {children}
        </React.Fragment>
      )}
    </div>
  )
}
ListTitleHeader.propTypes = {
  onClick: PropTypes.func,
  selectedSection: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

export function ListTitleHeaderWithContextMenu({
  title,
  selectedSection,
  children,
  onClick,
  className,
}) {
  return (
    <div className={`${styles.headerWithEdit} ${className}`}>
      <ListTitleHeader title={title} selectedSection={selectedSection} onClick={onClick} />
      <div className={`${styles.editButton} col-1`}>{children}</div>
    </div>
  )
}
ListTitleHeaderWithContextMenu.propTypes = {
  onClick: PropTypes.func,
  selectedSection: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

// This is a section of a larger list (i.e. Most Recent Reports)
export function ListSection(props) {
  const { children, className } = props
  return <div className={`${styles.listSection} ${className}`}>{children}</div>
}

// Title for a list section
export function ListSectionHeader(props) {
  const { header, children, className } = props

  return (
    <div className={`${styles.listSectionHeader} ${className}`}>
      <h2>{header}</h2>
      {children}
    </div>
  )
}

ListSectionHeader.propTypes = {
  header: PropTypes.string.isRequired,
}

// Holds the individual items for a list section
export function ListItemsContainer(props, hideEndOfListText) {
  const { children } = props
  const shouldDisplayEndOfListText = !hideEndOfListText && children && children.length > 0
  const endOfListText = props.itemType ? `End of ${props.itemType} list.` : 'End of list.'

  return (
    <div className={styles.listItems}>
      {children}
      {shouldDisplayEndOfListText && <div className={styles.endOfListText}>{endOfListText}</div>}
    </div>
  )
}

// Each item within a list
export function ListItem(props) {
  const { id, title, text, info, toUrl, onClick } = props

  const itemContent = (
    <div className={styles.listItemBorderContainer}>
      <div className={styles.listItemTopContainer}>
        <h2 className={`h5 ${styles.listItemHeader}`}>{title}</h2>
        {info && <div className={styles.listItemInfo}>{info}</div>}
      </div>
      <div className={styles.listItemText}>{text}</div>
    </div>
  )

  const shouldRenderButton = onClick && !toUrl
  const shouldRenderLink = !shouldRenderButton && toUrl
  const shouldRenderDiv = !shouldRenderButton && !shouldRenderLink

  if (shouldRenderButton) {
    return (
      <button key={id} to={toUrl} className={styles.listItem} onClick={onClick}>
        {itemContent}
      </button>
    )
  }
  if (shouldRenderLink) {
    return (
      <Link key={id} to={toUrl} className={styles.listItem}>
        {itemContent}
      </Link>
    )
  }
  if (shouldRenderDiv) {
    return (
      <div key={id} className={styles.listItem}>
        {itemContent}
      </div>
    )
  }
}

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.element.isRequired,
  info: PropTypes.element,
  toUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

// Placeholder for missing list content
export function EmptyListItems(props) {
  const { itemType } = props

  return (
    <div className={`${styles.listItem} ${styles.emptyListItem}`}>
      <h3 className='h4'>{`No ${itemType}`}</h3>
    </div>
  )
}

EmptyListItems.propTypes = {
  itemType: PropTypes.string.isRequired,
}
