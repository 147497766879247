import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LinkContainer } from 'react-router-bootstrap'
import Nav from 'react-bootstrap/Nav'
import styles from './SideMenu.module.scss'

/**
 * SideMenu
 * Left sidebar with navigation
 */

export function SideMenuNavItem(props) {
  const { title, toUrl } = props
  return (
    <LinkContainer to={toUrl}>
      {/* This element is styled in the custom bootstrap file */}
      <Nav.Link eventKey={toUrl.substr(1)}>{title}</Nav.Link>
    </LinkContainer>
  )
}

export default class SideMenu extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  }

  render() {
    const { user } = this.props

    return (
      <div className={styles.SideMenu}>
        <Nav className='flex-column'>
          <SideMenuNavItem title='My Settings' toUrl='/account' />
          {user.isCustomerAccountManager && (
            <React.Fragment>
              <SideMenuNavItem title='Customer Settings' toUrl='/settings' />
              <SideMenuNavItem title='Image Reviewer Access' toUrl='/image-reviewer-access' />
            </React.Fragment>
          )}
          <SideMenuNavItem title='Farm/Field Access' toUrl='/user-access' />
        </Nav>
      </div>
    )
  }
}
