import { find } from 'lodash'

const LOAD_FARMS = 'aker/FARMSANDFIELDS/LOAD_FARMS'
const LOAD_FARMS_SUCCESS = 'aker/FARMSANDFIELDS/LOAD_FARMS_SUCCESS'
const LOAD_FARMS_FAIL = 'aker/FARMSANDFIELDS/LOAD_FARMS_FAIL'

const LOAD_FARM = 'aker/FARMSANDFIELDS/LOAD_FARM'
const LOAD_FARM_SUCCESS = 'aker/FARMSANDFIELDS/LOAD_FARM_SUCCESS'
const LOAD_FARM_FAIL = 'aker/FARMSANDFIELDS/LOAD_FARM_FAIL'

const ADD_FARM = 'aker/FARMSANDFIELDS/ADD_FARM'
const ADD_FARM_SUCCESS = 'aker/FARMSANDFIELDS/ADD_FARM_SUCCESS'
const ADD_FARM_FAIL = 'aker/FARMSANDFIELDS/ADD_FARM_FAIL'

const LOAD_FIELDS = 'aker/FARMSANDFIELDS/LOAD_FIELDS'
const LOAD_FIELDS_SUCCESS = 'aker/FARMSANDFIELDS/LOAD_FIELDS_SUCCESS'
const LOAD_FIELDS_FAIL = 'aker/FARMSANDFIELDS/LOAD_FIELDS_FAIL'

const LOAD_FIELD = 'aker/FARMSANDFIELDS/LOAD_FIELD'
const LOAD_FIELD_SUCCESS = 'aker/FARMSANDFIELDS/LOAD_FIELD_SUCCESS'
const LOAD_FIELD_FAIL = 'aker/FARMSANDFIELDS/LOAD_FIELD_FAIL'

const ADD_FIELD = 'aker/FARMSANDFIELDS/ADD_FIELD'
const ADD_FIELD_SUCCESS = 'aker/FARMSANDFIELDS/ADD_FIELD_SUCCESS'
const ADD_FIELD_FAIL = 'aker/FARMSANDFIELDS/ADD_FIELD_FAIL'

const LOAD_SCOUTS = 'aker/FARMSANDFIELDS/LOAD_SCOUTS'
const LOAD_SCOUTS_SUCCESS = 'aker/FARMSANDFIELDS/LOAD_SCOUTS_SUCCESS'
const LOAD_SCOUTS_FAIL = 'aker/FARMSANDFIELDS/LOAD_SCOUTS_FAIL'

const CREATE_WORK_ORDER = 'aker/FARMSANDFIELDS/CREATE_WORK_ORDER'
const CREATE_WORK_ORDER_SUCCESS = 'aker/FARMSANDFIELDS/CREATE_WORK_ORDER_SUCCESS'
const CREATE_WORK_ORDER_FAIL = 'aker/FARMSANDFIELDS/CREATE_WORK_ORDER_FAIL'

const CLEAR_ALL_ERRORS = 'aker/FARMSANDFIELDS/CLEAR_ALL_ERRORS'
const CLEAR_ERROR = 'aker/FARMSANDFIELDS/CLEAR_ERROR'

const CHANGE_ACTIVE_WORK_ORDER = 'aker/FARMSANDFIELDS/CHANGE_ACTIVE_WORK_ORDER'

// Seasons
const CHANGE_CURRENT_SEASON_SUCCESS = 'aker/FARMSANDFIELDS/CHANGE_CURRENT_SEASON_SUCCESS'
const CREATE_SEASON = 'aker/FARMSANDFIELDS/CREATE_SEASON'
const CREATE_SEASON_SUCCESS = 'aker/FARMSANDFIELDS/CREATE_SEASON_SUCCESS'
const CREATE_SEASON_FAIL = 'aker/FARMSANDFIELDS/CREATE_SEASON_FAIL'
const EDIT_SEASON = 'aker/FARMSANDFIELDS/EDIT_SEASON'
const EDIT_SEASON_SUCCESS = 'aker/FARMSANDFIELDS/EDIT_SEASON_SUCCESS'
const EDIT_SEASON_FAIL = 'aker/FARMSANDFIELDS/EDIT_SEASON_FAIL'
const DELETE_SEASON = 'aker/FARMSANDFIELDS/DELETE_SEASON'
const DELETE_SEASON_SUCCESS = 'aker/FARMSANDFIELDS/DELETE_SEASON_SUCCESS'
const DELETE_SEASON_FAIL = 'aker/FARMSANDFIELDS/DELETE_SEASON_FAIL'

// Annotations
const CREATE_ANNOTATION = 'aker/MAPLAYERS/CREATE_ANNOTATION'
const CREATE_ANNOTATION_SUCCESS = 'aker/MAPLAYERS/CREATE_ANNOTATION_SUCCESS'
const CREATE_ANNOTATION_FAIL = 'aker/MAPLAYERS/CREATE_ANNOTATION_FAIL'
const DELETE_ANNOTATION = 'aker/MAPLAYERS/DELETE_ANNOTATION'
const DELETE_ANNOTATION_SUCCESS = 'aker/MAPLAYERS/DELETE_ANNOTATION_SUCCESS'
const DELETE_ANNOTATION_FAIL = 'aker/MAPLAYERS/DELETE_ANNOTATION_FAIL'

// Applied Maps
const CREATE_APPLIED_MAPS = 'aker/APPLIED_MAPS/CREATE_APPLIED_MAPS'
const CREATE_APPLIED_MAPS_SUCCESS = 'aker/APPLIED_MAPS/CREATE_APPLIED_MAPS_SUCCESS'
const CREATE_APPLIED_MAPS_FAIL = 'aker/APPLIED_MAPS/CREATE_APPLIED_MAPS_FAIL'
const GET_ALL_APPLIED_MAPS = 'aker/GET_ALL_APPLIED_MAPS/GET_ALL_APPLIED_MAPS'
const GET_ALL_APPLIED_MAPS_SUCCESS =
  'aker/GET_ALL_APPLIED_MAPS_SUCCESS/GET_ALL_APPLIED_MAPS_SUCCESS'
const GET_ALL_APPLIED_MAPS_FAIL = 'aker/GET_ALL_APPLIED_MAPS_FAIL/GET_ALL_APPLIED_MAPS_FAIL'
const GET_APPLIED_MAPS = 'aker/GET_APPLIED_MAPS/GET_APPLIED_MAPS'
const GET_APPLIED_MAPS_SUCCESS = 'aker/GET_APPLIED_MAPS_SUCCESS/GET_APPLIED_MAPS_SUCCESS'
const GET_APPLIED_MAPS_FAIL = 'aker/GET_APPLIED_MAPS_FAIL/GET_APPLIED_MAPS_FAIL'
const DELETE_APPLIED_MAP = 'aker/DELETE_APPLIED_MAP/DELETE_APPLIED_MAP'
const DELETE_APPLIED_MAP_SUCCESS = 'aker/DELETE_APPLIED_MAP_SUCCESS/DELETE_APPLIED_MAP_SUCCESS'
const DELETE_APPLIED_MAP_FAIL = 'aker/DELETE_APPLIED_MAP_FAIL/DELETE_APPLIED_MAP_FAIL'

const GET_SEASON = 'aker/GET_SEASON/GET_SEASON'
const GET_SEASON_SUCCESS = 'aker/GET_SEASON_SUCCESS/GET_SEASON_SUCCESS'
const GET_SEASON_FAIL = 'aker/GET_SEASON_FAIL/GET_SEASON_FAIL'

const initialState = {
  farms: {
    loaded: false,
  },
  farm: {
    loaded: false,
  },
  fields: {
    loaded: false,
  },
  field: {
    loaded: false,
  },
  scouts: {
    loaded: false,
  },
  currentSeason: {
    loaded: false,
  },
  activeWorkOrder: null,
  appliedMaps: {
    loaded: false,
  },
}

function getCurrentSeason(seasons) {
  const currentSeason = find(seasons, season => {
    return season.current
  })

  if (currentSeason) {
    currentSeason.loaded = true
    return currentSeason
  }

  return {}
}

//  For Changing between Seasons
function getActiveWorkOrder(season = {}) {
  if (season.mapLayers && season.mapLayers.length > 0) {
    return season.mapLayers[0].id
  }
}

//  For Initial Page Load
function getDefaultActiveWorkOrder(seasons) {
  const season = getCurrentSeason(seasons)
  return getActiveWorkOrder(season)
}

export default function farmsandfields(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_FARMS:
      return {
        ...state,
        farms: {
          ...state.farms,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case LOAD_FARMS_SUCCESS:
      return {
        ...state,
        farms: {
          ...state.farms,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case LOAD_FARMS_FAIL:
      return {
        ...state,
        farms: {
          ...state.farms,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case LOAD_FARM:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case LOAD_FARM_SUCCESS:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case LOAD_FARM_FAIL:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case ADD_FARM:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case ADD_FARM_SUCCESS:
      return {
        ...state,
        farms: {
          ...state.farms,
          data: [...state.farms.data, action.result.data],
        },
        farm: {
          ...state.farm,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case ADD_FARM_FAIL:
      const farmErrors = action.error.errors.map(error => {
        return {
          method: 'add',
          ...error,
        }
      })
      return {
        ...state,
        farm: {
          loaded: false,
          loading: false,
          error: farmErrors,
          data: null,
        },
      }

    case LOAD_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case LOAD_FIELDS_SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case LOAD_FIELDS_FAIL:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case CREATE_ANNOTATION:
    case LOAD_FIELD:
      return {
        ...state,
        field: {
          ...state.field,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case DELETE_SEASON_SUCCESS:
    case EDIT_SEASON_SUCCESS:
    case DELETE_ANNOTATION_SUCCESS:
    case CREATE_ANNOTATION_SUCCESS:
    case LOAD_FIELD_SUCCESS:
      return {
        ...state,
        field: {
          ...state.field,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        currentSeason: getCurrentSeason(action.result.data.seasons),
        activeWorkOrder: getDefaultActiveWorkOrder(action.result.data.seasons),
      }
    case DELETE_ANNOTATION_FAIL:
    case CREATE_ANNOTATION_FAIL:
    case LOAD_FIELD_FAIL:
      return {
        ...state,
        field: {
          ...state.field,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case ADD_FIELD:
      return {
        ...state,
        field: {
          ...state.field,
          loading: true,
        },
      }
    case ADD_FIELD_SUCCESS:
      return {
        ...state,
        farm: {
          ...state.farm,
          data: {
            ...state.farm.data,
            fields: [
              ...state.farm.data.fields,
              {
                title: action.result.data.title,
                id: action.result.data.id,
                issues: {
                  issueCount: 0,
                  damage: 0,
                },
              },
            ],
          },
        },
        field: {
          ...state.field,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        currentSeason: null,
        activeWorkOrder: null,
      }
    case ADD_FIELD_FAIL:
      const fieldErrors = action.error.errors.map(error => {
        return {
          method: 'add',
          ...error,
        }
      })
      return {
        ...state,
        field: {
          loaded: false,
          loading: false,
          error: fieldErrors,
          data: null,
        },
      }
    case LOAD_SCOUTS:
      return {
        ...state,
        scouts: {
          ...state.scouts,
          loading: true,
        },
      }
    case LOAD_SCOUTS_SUCCESS:
      return {
        ...state,
        scouts: {
          ...state.scouts,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case LOAD_SCOUTS_FAIL:
      return {
        ...state,
        scouts: {
          ...state.scouts,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case CLEAR_ALL_ERRORS:
      return {
        ...state,
        farms: {
          ...state.farms,
          error: [],
        },
        farm: {
          ...state.farm,
          error: [],
        },
        fields: {
          ...state.fields,
          error: [],
        },
        field: {
          ...state.field,
          error: [],
        },
        scouts: {
          ...state.scouts,
          error: [],
        },
        appliedMaps: {
          ...state.appliedMaps,
          error: [],
        },
      }

    case CLEAR_ERROR:
      return {
        ...state,
        farms: {
          ...state.farms,
          error: state.farms.error && [
            ...state.farms.error.filter(error => error.code !== action.error.code),
          ],
        },
        farm: {
          ...state.farm,
          error: state.farm.error && [
            ...state.farm.error.filter(error => error.code !== action.error.code),
          ],
        },
        fields: {
          ...state.fields,
          error: state.fields.error && [
            ...state.fields.error.filter(error => error.code !== action.error.code),
          ],
        },
        field: {
          ...state.field,
          error: state.field.error && [
            ...state.field.error.filter(error => error.code !== action.error.code),
          ],
        },
        scouts: {
          ...state.scouts,
          error: state.scouts.error && [
            ...state.scouts.error.filter(error => error.code !== action.error.code),
          ],
        },
        appliedMaps: {
          ...state.appliedMaps,
          error: state.appliedMaps.error && [
            ...state.appliedMaps.error.filter(error => error.code !== action.error.code),
          ],
        },
      }

    case CHANGE_ACTIVE_WORK_ORDER:
      return {
        ...state,
        activeWorkOrder: action.workOrderId,
      }

    case CREATE_SEASON_SUCCESS:
      return {
        ...state,
        currentSeason: action.result.season,
        activeWorkOrder: null,
      }
    case CHANGE_CURRENT_SEASON_SUCCESS:
      return {
        ...state,
        currentSeason: action.currentSeason,
        activeWorkOrder: getActiveWorkOrder(action.currentSeason),
      }
    case GET_SEASON:
      return {
        ...state,
        currentSeason: {
          loading: true,
          loaded: false,
        },
      }
    case GET_SEASON_SUCCESS:
      return {
        ...state,
        currentSeason: {
          loaded: true,
          loading: false,
          ...action.result.season,
        },
      }
    case GET_SEASON_FAIL:
      return {
        ...state,
        currentSeason: {
          loading: false,
          loaded: false,
        },
      }
    case CREATE_APPLIED_MAPS:
    case GET_ALL_APPLIED_MAPS:
    case GET_APPLIED_MAPS:
      return {
        ...state,
        appliedMaps: {
          loading: true,
          loaded: false,
          data: {},
        },
      }
    case CREATE_APPLIED_MAPS_SUCCESS:
    case GET_ALL_APPLIED_MAPS_SUCCESS:
    case GET_APPLIED_MAPS_SUCCESS:
      return {
        ...state,
        appliedMaps: {
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case CREATE_APPLIED_MAPS_FAIL:
    case GET_ALL_APPLIED_MAPS_FAIL:
    case GET_APPLIED_MAPS_FAIL:
      return {
        ...state,
        appliedMaps: {
          loading: false,
          loaded: false,
          data: {},
        },
        errors: action.error.errors,
      }
    case DELETE_APPLIED_MAP:
      return {
        ...state,
        appliedMaps: {
          loading: true,
          loaded: false,
          data: {},
        },
      }
    case DELETE_APPLIED_MAP_SUCCESS:
      return {
        ...state,
        appliedMaps: {
          loading: false,
          loaded: true,
        },
      }
    case DELETE_APPLIED_MAP_FAIL:
      return {
        ...state,
        appliedMaps: {
          loading: false,
          loaded: false,
          data: {},
        },
        errors: action.error.errors,
      }
    default:
      return state
  }
}

export function isFarmsLoaded(globalState) {
  return (
    globalState.farmsandfields &&
    globalState.farmsandfields.farms &&
    globalState.farmsandfields.farms.loaded
  )
}
export function loadFarms() {
  return {
    types: [LOAD_FARMS, LOAD_FARMS_SUCCESS, LOAD_FARMS_FAIL],
    promise: client => client.get('/farms'),
  }
}

export function isFarmLoaded(globalState, farmId) {
  return (
    globalState.farmsandfields &&
    globalState.farmsandfields.farm &&
    globalState.farmsandfields.farm.loaded &&
    (farmId ? globalState.farmsandfields.farm.data.id === parseInt(farmId, 10) : true)
  )
}
export function loadFarm(farmId) {
  return {
    types: [LOAD_FARM, LOAD_FARM_SUCCESS, LOAD_FARM_FAIL],
    promise: client => client.get(`/farm/${farmId}`),
  }
}
export function updateFarm(requestParams) {
  return {
    types: [LOAD_FARM, LOAD_FARM_SUCCESS, LOAD_FARM_FAIL],
    promise: client =>
      client.patch(`/farm/${requestParams.farmId}`, {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}
export function addFarm(data) {
  return {
    types: [ADD_FARM, ADD_FARM_SUCCESS, ADD_FARM_FAIL],
    promise: client =>
      client.post('/farm', {
        data: {
          data,
        },
      }),
  }
}
export function deleteFarm(farmId) {
  return {
    types: [LOAD_FARMS, LOAD_FARMS_SUCCESS, LOAD_FARMS_FAIL],
    promise: client => client.del(`/farm/${farmId}/`),
  }
}

export function isFieldsLoaded(globalState) {
  return (
    globalState.farmsandfields &&
    globalState.farmsandfields.fields &&
    globalState.farmsandfields.fields.loaded
  )
}
export function loadFields() {
  return {
    types: [LOAD_FIELDS, LOAD_FIELDS_SUCCESS, LOAD_FIELDS_FAIL],
    promise: client => client.get('/fields'),
  }
}

export function isFieldLoaded(globalState, fieldId) {
  return (
    globalState.farmsandfields &&
    globalState.farmsandfields.field &&
    globalState.farmsandfields.field.loaded &&
    (fieldId ? globalState.farmsandfields.field.data.id === parseInt(fieldId, 10) : true)
  )
}
export function loadField(fieldId) {
  return {
    types: [LOAD_FIELD, LOAD_FIELD_SUCCESS, LOAD_FIELD_FAIL],
    promise: client => client.get(`/field/${fieldId}`),
  }
}
export function updateField(requestParams) {
  return {
    types: [LOAD_FIELD, LOAD_FIELD_SUCCESS, LOAD_FIELD_FAIL],
    promise: client =>
      client.patch(`/field/${requestParams.fieldId}`, {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}
export function deleteField(fieldId) {
  return {
    types: [LOAD_FARM, LOAD_FARM_SUCCESS, LOAD_FARM_FAIL],
    promise: client => client.del(`/field/${fieldId}`),
  }
}

export function addField(farmId, title, polygon, bounds, acres) {
  return {
    types: [ADD_FIELD, ADD_FIELD_SUCCESS, ADD_FIELD_FAIL],
    promise: client =>
      client.post('/field', {
        data: {
          data: {
            farmId,
            title,
            polygon,
            bounds,
            acres,
          },
        },
      }),
  }
}

/**
  requestParams = {
    fieldId: 1,
    scoutId: 1,
    cropId: 1,
    plantDate: Date,
    workOrderId: 1
  }
**/

export function isScoutsLoaded(globalState) {
  return (
    globalState.farmsandfields &&
    globalState.farmsandfields.scouts &&
    globalState.farmsandfields.scouts.loaded
  )
}
export function loadScouts() {
  return {
    types: [LOAD_SCOUTS, LOAD_SCOUTS_SUCCESS, LOAD_SCOUTS_FAIL],
    promise: client => client.get('/scouts'),
  }
}

/* Order
params: {
  fieldId: int,
  serviceId: int
  timing: int,
  requestedStartDate: Date,
  requestedEndDate: Date,
  specialIntstructions: string,
  cropId: int,
  plantDate: Date,
  isNewSeason: bool
} */
export function createOrder(requestParams) {
  return {
    types: [CREATE_WORK_ORDER, CREATE_WORK_ORDER_SUCCESS, CREATE_WORK_ORDER_FAIL],
    promise: client =>
      client.post('/order', {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function clearAllErrors() {
  return {
    type: CLEAR_ALL_ERRORS,
  }
}
export function clearError(error) {
  return {
    type: CLEAR_ERROR,
    error,
  }
}

export function changeActiveWorkOrder(workOrderId) {
  return {
    type: CHANGE_ACTIVE_WORK_ORDER,
    workOrderId: workOrderId,
  }
}

/* Seasons
Params: {
  fieldId: [int]
  cropId: [int]
  plantDate: [Date]
} */
export function createSeason(requestParams) {
  return {
    types: [CREATE_SEASON, CREATE_SEASON_SUCCESS, CREATE_SEASON_FAIL],
    promise: client =>
      client.post('/seasons', {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}
export function editSeason(requestParams) {
  return {
    types: [EDIT_SEASON, EDIT_SEASON_SUCCESS, EDIT_SEASON_FAIL],
    promise: client =>
      client.patch(`/seasons/${requestParams.seasonId}`, {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}
export function deleteSeason(seasonId) {
  return {
    types: [DELETE_SEASON, DELETE_SEASON_SUCCESS, DELETE_SEASON_FAIL],
    promise: client => client.del(`/seasons/${seasonId}`),
  }
}

export function changeActiveSeason(field, index) {
  const currentSeason = field.seasons[index]
  return {
    type: CHANGE_CURRENT_SEASON_SUCCESS,
    currentSeason: currentSeason,
  }
}

export function createAnnotation(requestParams) {
  return {
    types: [CREATE_ANNOTATION, CREATE_ANNOTATION_SUCCESS, CREATE_ANNOTATION_FAIL],
    promise: client =>
      client.post('/annotations', {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function deleteAnnotation(annotationId) {
  return {
    types: [DELETE_ANNOTATION, DELETE_ANNOTATION_SUCCESS, DELETE_ANNOTATION_FAIL],
    promise: client => client.del('/annotations/' + annotationId),
  }
}

// As Applied Maps
export function createAppliedMaps(requestParams) {
  return {
    types: [CREATE_APPLIED_MAPS, CREATE_APPLIED_MAPS_SUCCESS, CREATE_APPLIED_MAPS_FAIL],
    promise: client =>
      client.post('/trials', {
        data: {
          ...requestParams,
        },
      }),
  }
}

export function getAllAppliedMaps(seasonId) {
  return {
    types: [GET_ALL_APPLIED_MAPS, GET_ALL_APPLIED_MAPS_SUCCESS, GET_ALL_APPLIED_MAPS_FAIL],
    promise: client => client.get('/trials'),
  }
}

export function getAppliedMaps(seasonId) {
  return {
    types: [GET_APPLIED_MAPS, GET_APPLIED_MAPS_SUCCESS, GET_APPLIED_MAPS_FAIL],
    promise: client => client.get(`/trials/${seasonId}`),
  }
}

export function isAppliedMapsLoaded(globalState) {
  return (
    globalState.farmsandfields &&
    globalState.farmsandfields.appliedMaps &&
    globalState.farmsandfields.appliedMaps.loaded
  )
}
export function deleteAppliedMap(id) {
  return {
    types: [DELETE_APPLIED_MAP, DELETE_APPLIED_MAP_SUCCESS, DELETE_APPLIED_MAP_FAIL],
    promise: client => client.del(`/trials/${id}`),
  }
}

export function getSeason(seasonId) {
  return {
    types: [GET_SEASON, GET_SEASON_SUCCESS, GET_SEASON_FAIL],
    promise: client => client.get(`/seasons/${seasonId}`),
  }
}
