import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { OnOffSwitch } from '_shared'
import { ProbeDataByStressItem } from './ProbeDataByStressItem'
import styles from './ProbeData.module.scss'

export function StressItemSelectionParser(cropStressItems, active) {
  return cropStressItems.reduce((state, stressItem) => {
    state[stressItem.id] = active
    return state
  }, {})
}

export function ProbeDataByStressType({
  stressTypeData,
  active,
  updateSelectedStressItems,
  ...props
}) {
  const [isActive, setIsActive] = useState(active)
  const [stressItemSelections, setStressItemSelections] = useState({})

  useEffect(() => setIsActive(active), [active], [])
  useEffect(() => {
    setStressItemSelections(StressItemSelectionParser(stressTypeData.cropStressItems, active))
  }, [stressTypeData.cropStressItems, active])

  function updateStressItemSelections(id, isSelected) {
    let newChildSelections = { ...stressItemSelections }
    newChildSelections[id] = isSelected
    setStressItemSelections(newChildSelections)
    if (Object.values(newChildSelections).every(val => !!val)) {
      setIsActive(true)
    } else if (Object.values(newChildSelections).every(val => !val)) {
      setIsActive(false)
    }

    if (!!updateSelectedStressItems) {
      updateSelectedStressItems(id, isSelected)
    }
  }

  function handleClick() {
    setIsActive(!isActive)
    setStressItemSelections(StressItemSelectionParser(stressTypeData.cropStressItems, !isActive))
    stressTypeData.cropStressItems.forEach(stressItem => {
      if (!!updateSelectedStressItems) {
        updateSelectedStressItems(stressItem.id, !isActive)
      }
    })
  }

  return (
    <div className={styles.stressTypeContainer}>
      <OnOffSwitch
        name={stressTypeData.name}
        value={!!isActive}
        label={stressTypeData.name}
        onChange={handleClick}
      />
      {isActive &&
        stressTypeData.cropStressItems.map((stressItem, index) => {
          return (
            <ProbeDataByStressItem
              key={stressItem.name}
              stressItemData={stressItem}
              updateStressItemSelections={updateStressItemSelections}
              active={stressItemSelections[stressItem.id] || false}
              {...props}
            />
          )
        })}
    </div>
  )
}

ProbeDataByStressType.propTypes = {
  stressTypeData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cropStressItems: PropTypes.array.isRequired,
  }),
  active: PropTypes.bool,
  updateSelectedStressItems: PropTypes.func,
}
