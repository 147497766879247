import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {
  FlightDisplay,
  RevenueAdvantage,
  TreatmentDetails,
  TreatmentDetailsMap,
  YieldAdvantage,
  YieldAnalysisChart,
} from '../_shared'
import { AerialImageryReportModernImageDetails } from './AerialImageryReportModernImageDetails'
import { InfoGridRow } from './InfoGridRow'
import { InfoGridHeader } from './InfoGridHeader'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportModern({
  season,
  regions,
  flights,
  brandColor,
  brandLogo,
  title,
  commodityPrice,
  yieldAdvantage,
  revenueAdvantage,
  footnote,
  crop,
  seedType,
  plantDate,
  applicationDate,
  displayYieldAnalysis,
  displayYieldAdvantage,
  displayReveneueAdvantage,
}) {
  return (
    <React.Fragment>
      <Helmet title='View Aerial Imagery Report' />
      <section
        className={`${styles.aerialImageryReport} ${styles.print} ${styles.aerialImageryReportModern}`}
      >
        <div className='row'>
          <div className='col-7'>
            <h1 className={styles.pageTitle} style={{ color: brandColor }}>
              {title}
            </h1>
            <div className={styles.infoGrid}>
              <InfoGridHeader header='On Farm Trial Details' brandColor={brandColor} />
              {crop && <InfoGridRow label='Crop' value={crop} />}
              {seedType && <InfoGridRow label='Hybrid/Variety' value={seedType} />}
              {plantDate && <InfoGridRow label='Planting Date' value={plantDate} />}
              {applicationDate && <InfoGridRow label='Application Date' value={applicationDate} />}
              {regions && regions.length > 0 && (
                <div>
                  <InfoGridHeader header='Treatment Details' brandColor={brandColor} />
                  <div className={styles.infoRow}>
                    <div
                      className={styles.infoContent}
                      style={{
                        width: '33%',
                      }}
                    >
                      <TreatmentDetails regions={regions} />
                    </div>
                    <div
                      className={styles.infoContent}
                      style={{
                        width: '67%',
                        borderLeft: '1px solid #e3e3e3',
                      }}
                    >
                      <TreatmentDetailsMap
                        regions={regions}
                        season={season}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-5'>
            {brandLogo && <img className={styles.brandLogo} src={brandLogo} alt='Company Logo' />}
          </div>
        </div>
        <div className={`row ${styles.plantHealthImagery}`}>
          <h2
            className={styles.pageTitle}
            style={{
              color: brandColor,
              textAlign: 'center',
              marginTop: 5,
            }}
          >
            Plant Health Imagery
          </h2>
          <div className='row'>
            {flights.map((flight, index) => {
              return (
                <div className='col-6' key={flight.id}>
                  <FlightDisplay flight={flight} season={season} regions={regions} hideBaseLayers>
                    <AerialImageryReportModernImageDetails
                      flight={flight}
                      compareDate={flights[0].dateFlown}
                      brandColor={brandColor}
                      label={index === 0 ? 'Before Application' : 'After Application'}
                    />
                  </FlightDisplay>
                </div>
              )
            })}
          </div>
        </div>
        {displayYieldAnalysis && (
          <div className={`row ${styles.yieldWrapper} ${styles.withBottomMargin}`}>
            <div className='col-6'>
              <YieldAnalysisChart regions={regions} brandColor={brandColor} variation='B' />
            </div>
            <div className='col-6'>
              {displayYieldAdvantage && (
                <YieldAdvantage yieldAdvantage={yieldAdvantage} brandColor={brandColor} />
              )}
              {displayReveneueAdvantage && (
                <RevenueAdvantage
                  revenueAdvantage={revenueAdvantage}
                  commodityPrice={commodityPrice}
                  brandColor={brandColor}
                />
              )}
            </div>
          </div>
        )}
        <div className={styles.footerPrint}>
          {footnote && <div className={`row ${styles.footer}`}>{footnote}</div>}
          <div className={`row ${styles.footer}`}>
            Aerial imagery and document template is copyright of Aker Technologies, Inc. All Rights
            Reserved.
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

AerialImageryReportModern.propTypes = {
  season: PropTypes.object,
  regions: PropTypes.array,
  flights: PropTypes.array,
  brandColor: PropTypes.string.isRequired,
  brandColorLight: PropTypes.string,
  brandLogo: PropTypes.string,
  title: PropTypes.string,
  commodityPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yieldAdvantage: PropTypes.string,
  revenueAdvantage: PropTypes.string,
  footnote: PropTypes.string,
  highlights: PropTypes.array,
  productHeader: PropTypes.string,
  crop: PropTypes.string,
  seedType: PropTypes.string,
  location: PropTypes.string,
  plantDate: PropTypes.string,
  applicationDate: PropTypes.string,
}
