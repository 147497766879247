import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'
import { ListItem } from '_shared'

export function FieldListItem({ field, onSelectField }) {
  const url = `/farm/${field.farm.id}/field/${field.id}`

  return (
    <ListItem
      key={field.id}
      id={field.id}
      title={field.title}
      info={field.actionRequired ? <span /> : null}
      toUrl={url}
      text={
        <span>
          <FormattedNumber value={field.size || 0} />
          {field.size === 1 ? ' acre, ' : ' acres, '}
          <FormattedNumber value={field.flightCount || 0} /> {'aerial, '}
          <FormattedNumber value={field.issueCount || 0} /> {'TrueCause, '}
          <FormattedNumber value={field.reportCount || 0} />
          {field.reportCount === 1 ? ' report ' : ' reports '}
        </span>
      }
      onClick={() => onSelectField(field.id)}
    />
  )
}

FieldListItem.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    actionRequired: PropTypes.bool,
    flightCount: PropTypes.number,
    issueCount: PropTypes.number,
    reportCount: PropTypes.number,
    url: PropTypes.string,
  }).isRequired,
  onSelectField: PropTypes.func,
}
