const ADD_SUBSCRIPTIONS = 'aker/SUBSCRIPTIONS/ADD_SUBSCRIPTIONS'
const ADD_SUBSCRIPTIONS_SUCCESS = 'aker/SUBSCRIPTIONS/ADD_SUBSCRIPTIONS_SUCCESS'
const ADD_SUBSCRIPTIONS_FAIL = 'aker/SUBSCRIPTIONS/ADD_SUBSCRIPTIONS_FAIL'
const REMOVE_SUBSCRIPTIONS = 'aker/SUBSCRIPTIONS/REMOVE_SUBSCRIPTIONS'
const REMOVE_SUBSCRIPTIONS_SUCCESS = 'aker/SUBSCRIPTIONS/REMOVE_SUBSCRIPTIONS_SUCCESS'
const REMOVE_SUBSCRIPTIONS_FAIL = 'aker/SUBSCRIPTIONS/REMOVE_SUBSCRIPTIONS_FAIL'

const initialState = {
  subscribers: {
    loaded: false,
  },
  removedSubscribers: {
    loaded: false,
  },
}

export default function subscriptions(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_SUBSCRIPTIONS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case ADD_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case ADD_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case REMOVE_SUBSCRIPTIONS:
      return {
        ...state,
        removedSubscribers: {
          ...state.removedSubscribers,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case REMOVE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        removedSubscribers: {
          ...state.removedSubscribers,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case REMOVE_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        removedSubscribers: {
          ...state.removedSubscribers,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    default:
      return state
  }
}

export function addSubscriptions(resourceType, resourceId, subscriberParams) {
  return {
    types: [ADD_SUBSCRIPTIONS, ADD_SUBSCRIPTIONS_SUCCESS, ADD_SUBSCRIPTIONS_FAIL],
    promise: client =>
      client.post('/subscriptions/', {
        data: {
          data: {
            resourceType,
            resourceId,
            subscribers: subscriberParams,
          },
        },
      }),
  }
}

export function removeSubscriptions(subscriptionIds) {
  return {
    types: [REMOVE_SUBSCRIPTIONS, REMOVE_SUBSCRIPTIONS_SUCCESS, REMOVE_SUBSCRIPTIONS_FAIL],
    promise: client =>
      client.del('/subscriptions/remove', {
        data: {
          data: {
            subscriptionIds: subscriptionIds,
          },
        },
      }),
  }
}
