import React from 'react'
import PropTypes from 'prop-types'
import { GeometryLayer } from './GeometryLayer'

export function InnerGeometriesLayer({ map, geometries, outlineOnly, outlineColor }) {
  const mapColors = [
    '#58bfe1',
    '#f26464',
    '#63bf93',
    '#f1bb3d',
    '#4c6686',
    '#aecc53',
    '#b674b6',
    '#00b0a9',
    '#d26937',
    '#84a230',
    '#f4a968',
    '#706ab8',
  ]

  // in case there are more geometries than there are colors we need to repeat colors
  const getColorIndex = index => {
    let colorNumber
    for (let i = mapColors.length; i > 0; i--) {
      if (index % i === 0) {
        colorNumber = i
        break
      }
    }

    return colorNumber
  }

  const CHAR_CODE_TO_START_UPPERCASE_ALPHABET = 65

  if (geometries && geometries.length > 0) {
    return geometries.map((g, index) => {
      if (g.display) {
        return (
          <GeometryLayer
            key={g.id}
            map={map}
            geometry={g.geometry}
            label={String.fromCharCode(CHAR_CODE_TO_START_UPPERCASE_ALPHABET + index)}
            fillColor={outlineOnly ? null : mapColors[getColorIndex(index + 1)]}
            outlineColor={outlineColor ? outlineColor : mapColors[getColorIndex(index + 1)]}
          />
        )
      } else {
        return null
      }
    })
  } else {
    return null
  }
}

InnerGeometriesLayer.propTypes = {
  map: PropTypes.object,
  geometries: PropTypes.array,
}
