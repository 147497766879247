import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router'
import moment from 'moment'
import { TableWithCustomData, TableRow } from '_shared'
import styles from '../ImageReviewer.module.scss'
import { FaSortUp, FaSortDown } from 'react-icons/fa'

export const CONDENSED_LIMIT = 5

export const ImageReviewerSummaryComponent = ({ tasks, isCondensed, tableTitleChildren }) => {
  const [currentSort, setCurrentSort] = useState('workOrderId')
  const [sortAscending, setSortAscending] = useState(true)

  const tasksCells = (task = null) => {
    return (
      <React.Fragment>
        <div className={styles.cell}>
          <Link to={imageReviewerByTaskUrl(task.id)}>{task.workOrderId}</Link>
        </div>
        <div className={styles.cell}>{task.fieldName}</div>
        <div className={styles.cell}>{task.totalImageCount}</div>
        <div className={styles.cell}>{task.percentComplete}%</div>
        <div className={styles.cell}>{task.updatedOn.format('MM/DD/YYYY')}</div>
        {!isCondensed && task.permissions.canDelete && (
          <div className={styles.cell}>
            <Link to={quickReviewByTaskUrl(task.id)}>Bulk image review</Link>
          </div>
        )}
      </React.Fragment>
    )
  }

  const imageReviewerByTaskUrl = taskId => {
    return `/image-reviewer/${taskId}`
  }

  const quickReviewByTaskUrl = taskId => {
    return `/image-reviewer/${taskId}/bulk-review`
  }

  const tasksToMap = () => {
    let tasksToMap = isCondensed ? tasks.slice(0, CONDENSED_LIMIT) : tasks
    tasksToMap = tasksToMap.map(t => ({
      ...t,
      percentComplete: (
        ((t.totalImageCount - t.unreviewedImageCount) / t.totalImageCount) *
        100
      ).toFixed(),
      updatedOn: moment.utc(t.recentProbeBatchDate),
    }))

    tasksToMap.sort((a, b) => (a[currentSort] > b[currentSort] ? 1 : -1))
    if (!sortAscending) {
      tasksToMap.reverse()
    }
    return tasksToMap
  }

  const handleSortButtonClick = sort => {
    if (currentSort === sort) {
      setSortAscending(!sortAscending)
    } else {
      setSortAscending(true)
    }
    setCurrentSort(sort)
  }

  const displaySortButton = sort => {
    const isCurrentSort = sort === currentSort

    return (
      !isCondensed && (
        <button className={styles.sortButton} onClick={e => handleSortButtonClick(sort)}>
          <div className={styles.sortIconContainer}>
            {isCurrentSort && sortAscending && <FaSortUp />}
            {isCurrentSort && !sortAscending && <FaSortDown />}
            {!isCurrentSort && (
              <React.Fragment>
                <FaSortUp />
                <FaSortDown />
              </React.Fragment>
            )}
          </div>
        </button>
      )
    )
  }

  return (
    <React.Fragment>
      <TableWithCustomData>
        <div className={`${styles.tableHeaders} ${styles.headerSpacing}`}>
          <h2 className='h4'>Work Order ID {displaySortButton('workOrderId')}</h2>
          <h2 className='h4'>Field {displaySortButton('fieldName')}</h2>
          <h2 className='h4'>Total # of Images {displaySortButton('totalImageCount')}</h2>
          <h2 className='h4'>% complete {displaySortButton('percentComplete')}</h2>
          <h2 className='h4'>Updated on {displaySortButton('updatedOn')}</h2>
          {!isCondensed && tasks.some(t => t.permissions.canDelete) && <div></div>}
        </div>
        {!tasks.length ? (
          <span>No Images to be Reviewed</span>
        ) : (
          tasksToMap().map(task => (
            <TableRow
              key={task.id}
              tableCells={() => tasksCells(task)}
              primary
              noBorder
              withHighlights
            />
          ))
        )}
        {isCondensed && (
          <div className={styles.seeMoreLinkContainer}>
            <Link to='/image-reviewer'>See More...</Link>
          </div>
        )}
      </TableWithCustomData>
    </React.Fragment>
  )
}

export const ImageReviewerSummary = withRouter(ImageReviewerSummaryComponent)

ImageReviewerSummary.propTypes = {
  tasks: PropTypes.array.isRequired,
  isCondensed: PropTypes.bool,
}
