import React from 'react'
import { FormattedNumber, FormattedDate } from 'react-intl'
import PropTypes from 'prop-types'
import styles from './Timeline.module.scss'
import {
  FaLeaf,
  FaCheckCircle,
  FaTimesCircle,
  FaCalendarAlt,
  FaClipboardCheck,
  FaClock,
  FaImages,
  FaSearchLocation,
} from 'react-icons/fa'

function PlantEvent({ event }) {
  const description = (
    <React.Fragment>
      <FormattedNumber value={event.eventData.acres || 0} /> acres of {event.eventData.crop}{' '}
      planted.
    </React.Fragment>
  )

  return <TimelineEvent event={event} icon={FaLeaf} description={description} />
}

function OrderRequestedEvent({ event }) {
  return <TimelineEvent event={event} icon={FaSearchLocation} description='Order submitted.' />
}

function OrderAcceptedEvent({ event }) {
  return <TimelineEvent event={event} icon={FaCheckCircle} description='Order accepted by Aker.' />
}

function OrderRejectedEvent({ event }) {
  return (
    <TimelineEvent
      event={event}
      icon={FaTimesCircle}
      description='Order rejected by Aker. Please contact Aker for more support.'
    />
  )
}

function OrderScheduledEvent({ event }) {
  return (
    <TimelineEvent event={event} icon={FaCalendarAlt} description='Scheduled to be fulfilled.' />
  )
}

function OrderDataCollectedEvent({ event }) {
  return (
    <TimelineEvent event={event} icon={FaClipboardCheck} description='Data has been uploaded.' />
  )
}

function OrderDataProcessingEvent({ event }) {
  return <TimelineEvent event={event} icon={FaClock} description='Data has begun processing.' />
}

function OrderCompleteEvent({ event }) {
  return <TimelineEvent event={event} icon={FaImages} description='Order complete.' />
}

function TimelineEvent({ event, icon, description }) {
  const IconComponent = icon

  return (
    <React.Fragment>
      <div className={styles.iconWrapper}>
        <IconComponent color='#00b898' />
      </div>
      <div className={styles.title}>{description}</div>
      <div className={styles.date}>{event.date && <FormattedDate value={event.date} />}</div>
    </React.Fragment>
  )
}

export function TimelineItem({ event }) {
  const { type } = event

  switch (type) {
    case 'plant':
      return PlantEvent({ event })
    case 'orderRequested':
    case 'flightOrderRequested':
      return OrderRequestedEvent({ event })
    case 'orderAccepted':
    case 'flightOrderAccepted':
      return OrderAcceptedEvent({ event })
    case 'orderRejected':
    case 'flightOrderRejected':
      return OrderRejectedEvent({ event })
    case 'orderScheduled':
      return OrderScheduledEvent({ event })
    case 'orderDataCollected':
      return OrderDataCollectedEvent({ event })
    case 'orderDataProcessing':
    case 'flightDataProcessing':
      return OrderDataProcessingEvent({ event })
    case 'orderComplete':
      return OrderCompleteEvent({ event })
    default:
      return null
  }
}

export function Timeline({ events }) {
  return (
    <div className={styles.FieldTimeline}>
      <div className={styles.padding}>
        <ul className={styles.Timeline}>
          {events.length > 0 &&
            events.map(event => (
              <li className={`${styles.TimelineItem} ${styles.success}`} key={event.id}>
                <TimelineItem event={event} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

Timeline.propTypes = {
  events: PropTypes.array.isRequired,
}
