import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FullScreenImage, FavoriteProbeImageButton } from '_shared'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import styles from './ProbeData.module.scss'

export const FavoriteImageUpdater = ({ url, isFavorited }, images) => {
  const imageToUpdate = images.find(image => image.imagePath === url)
  if (imageToUpdate) {
    imageToUpdate.isFavorited = isFavorited
  }

  return images
}

export function ImageCarousel({
  images,
  observationNumber,
  activeIndex,
  setActiveIndex,
  displayFavoriteToggle,
  favoriteImageData,
  toggleFavoriteImage,
}) {
  const [showFullScreenImage, setShowFullScreenImage] = useState(false)
  const [imagesToDisplay, setImagesToDisplay] = useState(images)

  useEffect(() => {
    if (!favoriteImageData) {
      return
    }

    setImagesToDisplay(FavoriteImageUpdater(favoriteImageData, [...imagesToDisplay]))
  }, [favoriteImageData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setImagesToDisplay(images)
  }, [images])

  function renderImage(image, index) {
    const imgClassName = classNames(styles.stressFindingImage, {
      [styles.active]: index === activeIndex,
    })

    return (
      <div className={imgClassName} key={image.id}>
        <img
          onClick={() => setShowFullScreenImage(true)}
          src={image.thumbnailPath}
          alt={`Observation ${observationNumber}`}
        />
        {displayFavoriteToggle && (
          <FavoriteProbeImageButton
            imagePath={image.imagePath}
            isFavorited={image.isFavorited}
            toggleFavorite={toggleFavoriteImage}
          />
        )}
      </div>
    )
  }

  function handlePrevClick() {
    let newIndex = activeIndex - 1
    if (newIndex < 0) {
      newIndex = imagesToDisplay.length - 1
    }
    setActiveIndex(newIndex)
  }

  function handleNextClick() {
    let newIndex = activeIndex + 1
    if (newIndex >= imagesToDisplay.length) {
      newIndex = newIndex % imagesToDisplay.length
    }
    setActiveIndex(newIndex)
  }

  return (
    <div className={styles.stressFindingCarousel}>
      <button onClick={handlePrevClick} className={styles.prevButton}>
        <FaChevronLeft />
      </button>
      <div className={styles.stressFindingImageContainer}>
        {imagesToDisplay.map((image, index) => renderImage(image, index))}
      </div>
      <button onClick={handleNextClick} className={styles.nextButton}>
        <FaChevronRight />
      </button>
      {showFullScreenImage && (
        <FullScreenImage
          src={imagesToDisplay[activeIndex].imagePath}
          onClose={() => setShowFullScreenImage(false)}
        />
      )}
    </div>
  )
}

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imagePath: PropTypes.string.isRequired,
      thumbnailPath: PropTypes.string.isRequired,
    })
  ),
  setActiveIndex: PropTypes.func,
  activeIndex: PropTypes.number,
  observationNumber: PropTypes.number,
  displayFavoriteToggle: PropTypes.bool,
  favoriteImageData: PropTypes.object,
  toggleFavoriteImage: PropTypes.func,
}
