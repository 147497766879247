import React from 'react'
import PropTypes from 'prop-types'
import { DownloadButtonContainer } from './MapLayerControl'

export function DownloadMapControls({ shouldDisplayControls, layersEnabled, activeImage }) {
  return shouldDisplayControls ? (
    <DownloadButtonContainer
      disabled={!layersEnabled}
      image={activeImage}
      onClick={event => {
        event.preventDefault()
        window.open(event.target.value, '_blank')
      }}
    />
  ) : null
}

DownloadMapControls.propTypes = {
  shouldDisplayControls: PropTypes.bool,
  layersEnabled: PropTypes.bool,
  activeImage: PropTypes.object,
}
