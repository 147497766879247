import React, { useState } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import './ImportFieldFromThirdParty.scss'
import { ImportClimateFieldData } from './ImportClimateFieldData'
import { ImportMyJDFieldData } from './ImportMyJDFieldData'
import ImportKMLField from './ImportKMLField'
import parser from 'fast-xml-parser'
import * as turf from '@turf/turf'
import swal from 'sweetalert'

const ImportFieldFromThirdPartyComponent = props => {
  const importedFieldsInSession = JSON.parse(window.sessionStorage.getItem('importedClimateFields'))
    ? 'climate'
    : JSON.parse(window.sessionStorage.getItem('importedMyJDFields'))
    ? 'myjd'
    : props.location.query.code
    ? 'climate'
    : 'select'
  const [view, setView] = useState(importedFieldsInSession)
  const [loading, setLoading] = useState(props.location.query.code ? true : false)
  const [kmlFileUploaded, setKmlFileUploaded] = useState(false)
  const { origin, pathname } = window.location

  const cancelImport = () => {
    clearUsersSelection()
    clearSessionStorage()
  }

  const clearSessionStorage = () => {
    window.sessionStorage.removeItem('importedClimateFields')
    window.sessionStorage.removeItem('importedMyJDFields')
  }

  const clearUsersSelection = () => {
    props.toggleImportFieldComponent('cancel')
    if (window.location.search) {
      window.location.assign(`${origin}${pathname}`)
    }
  }

  const uploadKMLFileInput = ev => {
    const fileInput = ev.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const fileAsText = e.target.result
      const outerBoundaryStart = fileAsText.indexOf('<outerBoundaryIs>')
      const outerBoundaryEnd = fileAsText.indexOf('</outerBoundaryIs>')
      const outerBoundaryText = fileAsText
        .slice(outerBoundaryStart, outerBoundaryEnd)
        .replace('<outerBoundaryIs>', '')
      const firstIndex = outerBoundaryText.indexOf('<coordinates>')
      const lastIndex = outerBoundaryText.indexOf('</coordinates>')
      const coordsAsText = outerBoundaryText
        .slice(firstIndex, lastIndex)
        .replace('<coordinates>', '')
      const parsedData = parser.parse(fileAsText)
      // check what the format is... some have altitudes, some don't
      var coordinates = {}
      if (coordsAsText.includes(',0 ')) {
        coordinates = coordsAsText
          .split('0 ')
          .join('')
          .split(',')
      } else {
        coordinates = coordsAsText.replace(/\s/g, ',').split(',')
      }
      if (!coordsAsText || coordinates.length < 4) {
        return swal({
          title: 'Error!',
          text:
            'Please make sure you are uploading a KML file of an entire field boundary. Contact support@aker.ag for more questions.',
          icon: 'error',
          dangerMode: true,
          confirmButtonText: 'OK',
          closeModal: true,
        })
      }
      const fieldName =
        parsedData.kml && parsedData.kml.Document ? parsedData.kml.Document.name : 'Unknown'

      const formatedCoordinates = []
      coordinates.forEach(point => {
        const length = formatedCoordinates.length
        const indx = length - 1
        if (+point === 0) return
        if (length === 0) {
          formatedCoordinates.push([+point])
        } else {
          if (formatedCoordinates[indx].length === 1) {
            formatedCoordinates[indx].push(+point)
          } else if (formatedCoordinates[indx].length === 2) {
            formatedCoordinates.push([+point])
          }
        }
      })
      const fieldData = {}
      fieldData.boundary = [formatedCoordinates]
      const sqMetersPerAcre = 4046.85642245
      fieldData.acres = (turf.area(turf.polygon([formatedCoordinates])) / sqMetersPerAcre).toFixed(
        2
      )
      fieldData.geojson = {
        type: 'Polygon',
        coordinates: [formatedCoordinates],
      }
      fieldData.importedFieldId = 0
      fieldData.name = fieldName.replace('.kml', '').replace(/_|-/g, ' ')
      props.updateImportedFieldData([fieldData])
      setView('kml')
    }

    reader.readAsText(fileInput)
  }

  return (
    <div className='create-field-import'>
      {view === 'select' && (
        <>
          <h2>Import field data from:</h2>
          <div className='create-field-import-body'>
            <button onClick={() => setView('climate')}>Climate</button>
            <button onClick={() => setView('myjd')}>MyJD</button>
            <div className='field-data-upload'>
              <button>KML file</button>
              <input type='file' id='kml-upload' onChange={ev => uploadKMLFileInput(ev)} />
            </div>
          </div>
        </>
      )}
      {view === 'climate' && (
        <ImportClimateFieldData
          loading={loading}
          updateImportedFieldData={props.updateImportedFieldData}
          importedFieldData={props.importedFieldData}
          setSelectedPolygon={props.setSelectedPolygon}
          selectedImportedFieldId={props.selectedImportedFieldId}
          passSelectedImportedFieldId={props.passSelectedImportedFieldId}
          setLoading={setLoading}
          setFieldNameInputFromSidePanel={props.setFieldNameInputFromSidePanel}
        />
      )}
      {view === 'myjd' && (
        <ImportMyJDFieldData
          loading={loading}
          updateImportedFieldData={props.updateImportedFieldData}
          importedFieldData={props.importedFieldData}
          setSelectedPolygon={props.setSelectedPolygon}
          selectedImportedFieldId={props.selectedImportedFieldId}
          passSelectedImportedFieldId={props.passSelectedImportedFieldId}
          setLoading={setLoading}
        />
      )}
      {view === 'kml' && (
        <ImportKMLField
          kmlFileUploaded={kmlFileUploaded}
          setKmlFileUploaded={setKmlFileUploaded}
          loading={loading}
          updateImportedFieldData={props.updateImportedFieldData}
          importedFieldData={props.importedFieldData}
          setSelectedPolygon={props.setSelectedPolygon}
          selectedImportedFieldId={props.selectedImportedFieldId}
          passSelectedImportedFieldId={props.passSelectedImportedFieldId}
          setLoading={setLoading}
        />
      )}
      {view !== 'select' && (
        <div className='create-field-import-footer'>
          <button className='clear-btn' onClick={() => props.clearFieldSelection()}>
            Clear Selections
          </button>
          <button className='cancel-btn' onClick={cancelImport}>
            Cancel
          </button>
        </div>
      )}
    </div>
  )
}

export const ImportFieldFromThirdParty = withRouter(ImportFieldFromThirdPartyComponent)

ImportFieldFromThirdParty.propTypes = {
  updateImportedFieldData: PropTypes.func.isRequired,
  toggleImportFieldComponent: PropTypes.func.isRequired,
  hasFieldsImported: PropTypes.bool,
}
