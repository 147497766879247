import React from 'react'
import PropTypes from 'prop-types'
import { TopLevelCheckbox } from './MapLayerControl'

export function LegendLayerControls({ shouldDisplayControls, legendEnabled, setLegendEnabled }) {
  return (
    <React.Fragment>
      {shouldDisplayControls && (
        <div>
          <TopLevelCheckbox
            onChange={event => setLegendEnabled(event.target.checked)}
            defaultChecked={legendEnabled}
            title='LEGEND'
          />
        </div>
      )}
      <hr />
    </React.Fragment>
  )
}

LegendLayerControls.propTypes = {
  shouldDisplayControls: PropTypes.bool,
  legendEnabled: PropTypes.bool,
  setLegendEnabled: PropTypes.func,
}
