import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import swal from '@sweetalert/with-react'
import moment from 'moment'
import { flatten } from 'helpers'
import { FullScreenImage, Toggle } from '_shared'
import { DeleteSelection } from './_shared'
import { ActiveImage } from './ActiveImage'
import { ImageMetadata } from './ImageMetadata'
import { ImageMLMetadataContainer } from './ImageMLMetadataContainer'
import { ImageReviewerFilter, FILTER_OPTIONS } from './ImageReviewerFilter'
import { ImageReviewerCarousel } from './ImageReviewerCarousel'
import { StressItemsTagging } from './StressItemsTagging'
import { TaggedFindings } from './TaggedFindings'
import styles from './ImageReviewer.module.scss'

export const ImageReviewerByTaskComponent = ({
  task,
  cropStressItems,
  user,
  updateImageTags,
  favoriteImageData,
  toggleFavoriteImage,
  fetchImagesForPage,
  deleteImages,
  updateMlReview,
}) => {
  const { probeImages, permissions } = task
  const [activeImage, setActiveImage] = useState(null)
  const [taggedFindings, setTaggedFindings] = useState([])
  const [showFullScreenImage, setShowFullScreenImage] = useState(false)
  const [filteredStatusOption, setFilteredStatusOption] = useState(FILTER_OPTIONS[0])
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [showMlMetadata, setShowMlMetadata] = useState(false)
  const [agronomyRereviewModeOn, setAgronomyRereviewModeOn] = useState(false)
  const [imagesToDelete, setImagesToDelete] = useState([])
  const [mlBoxLocations, setMlBoxLocations] = useState([])
  const activeImageElement = useRef(null)
  const canvasElement = useRef(null)

  const rereviewModeOnToggle = { id: 'rereviewModeOn', label: 'Rereview Mode' }

  useEffect(() => {
    // set the stress items we should use in child components based on current review mode
    // child components will consume `cropStressItems` and not care about the mode
    probeImages.forEach(image => {
      image.cropStressItems = agronomyRereviewModeOn
        ? image.agronomyReviewStressItems
        : image.customerReviewStressItems

      image.isHealthy = agronomyRereviewModeOn ? image.isHealthyAgronomyRereview : image.isHealthy
    })
  }, [agronomyRereviewModeOn, probeImages])

  useEffect(() => {
    // Maintain state after an image is updated
    // unless filter changed and active image no longer exists in probe images
    let image = activeImage && probeImages.find(image => image.id === activeImage.id)
    if (!image) {
      image = task.probeImages.length > 0 ? task.probeImages[0] : null
    }
    if (!!image) {
      setActiveImage(image)
      if (image.mlReview) {
        setMlBoxLocations(image.mlReview.ml_box_locations)
      }
    }
  }, [task]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeImage) setTaggedFindings([...activeImage.cropStressItems])
  }, [activeImage, agronomyRereviewModeOn])

  const handleThumbnailSelect = selectedImageId => {
    const image = probeImages.find(image => image.id === selectedImageId)
    setActiveImage(image)

    const imageBoxes = doesImageHaveMlBox(image)

    if (imageBoxes) {
      setMlBoxLocations(image.mlReview.ml_box_locations)
    } else {
      setMlBoxLocations([])
    }
  }

  const save = async observedCropId => {
    const cropStressFindingAttributes = taggedFindings.map(finding => ({
      cropStressItemId: finding.id,
      taggedById: finding.taggedBy.id,
    }))
    const data = {
      probeImageId: activeImage.id,
      observedCropId,
      cropStressFindingAttributes,
      rereview: agronomyRereviewModeOn,
    }
    await updateMlReview(activeImage.fileName, { mlReview: { mlBoxLocations } })
    updateImageTags(task.id, data, filteredStatusOption.id, currentPageNumber)
  }

  const saveAndContinue = (e, observedCropId) => {
    e.preventDefault()
    const updatedActiveImage = probeImages.find(image => image.id === activeImage.id)
    const currentIndex = probeImages.indexOf(updatedActiveImage)
    save(observedCropId)
    let nextImage =
      currentIndex + 1 < probeImages.length ? probeImages[currentIndex + 1] : updatedActiveImage
    setActiveImage(nextImage)
    const imageBoxes = doesImageHaveMlBox(nextImage)
    if (imageBoxes) {
      setMlBoxLocations(imageBoxes)
    } else {
      setMlBoxLocations([])
    }
  }

  const doesImageHaveMlBox = image => {
    return image.mlReview && image.mlReview.ml_box_locations
  }

  const removeTaggedFindings = tagId => {
    setTaggedFindings(taggedFindings.filter(finding => finding.id !== tagId))
  }

  const updateTaggedFindings = (observedCropId, selectedStressItemIds) => {
    const { crops } = cropStressItems
    const observedCrop = crops.find(crop => crop.id === observedCropId)

    const selectedItems = flatten(
      observedCrop.cropStressTypes.map(stressType => stressType.cropStressItems)
    ).filter(item => selectedStressItemIds.includes(item.id))

    const findings = createFindingsFromSelectedItems(selectedItems)

    setTaggedFindings(findings)
  }

  const createFindingsFromSelectedItems = items => {
    let updatedFindings = []
    items.forEach(item => {
      const existingFinding = taggedFindings.find(finding => finding.id === item.id)
      if (existingFinding) {
        updatedFindings.push(existingFinding)
      } else {
        const newFinding = {
          id: item.id,
          name: item.name,
          taggedBy: user,
        }
        updatedFindings.push(newFinding)
      }
    })
    return updatedFindings
  }

  const fetchNewPageOfImages = (statusOption, newPageNumber = 1) => {
    const statusQuery = statusOption ? statusOption.id : null

    setFilteredStatusOption(statusOption)
    fetchImagesForPage(statusQuery, newPageNumber)
    setCurrentPageNumber(newPageNumber)
  }

  const handleSelectAllToDelete = allSelected => {
    if (allSelected) {
      setImagesToDelete(task.probeImages.map(img => img.id))
    } else {
      setImagesToDelete([])
    }
  }

  const onImageCheckboxClick = imageId => {
    let updatedImagesToDelete = [...imagesToDelete]
    const existingImageId = updatedImagesToDelete.find(id => id === imageId)
    if (existingImageId) {
      updatedImagesToDelete = updatedImagesToDelete.filter(id => id !== imageId)
    } else {
      updatedImagesToDelete.push(imageId)
    }
    setImagesToDelete(updatedImagesToDelete)
  }

  const handleDeleteImages = async () => {
    try {
      await deleteImages(imagesToDelete)
      await swal({
        title: 'Great, your images have been deleted!',
        icon: 'success',
        type: 'success',
        closeOnConfirm: true,
      })

      const statusQuery = filteredStatusOption ? filteredStatusOption.id : null
      fetchImagesForPage(statusQuery, currentPageNumber)
    } catch (err) {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeOnConfirm: true,
      })
    }
  }

  return (
    <React.Fragment>
      <div className={styles.taggingWrapper}>
        <ActiveImage
          image={activeImage}
          showFullScreen={() => setShowFullScreenImage(true)}
          activeImageRef={activeImageElement}
          canvasRef={canvasElement}
        />
        <div className={styles.imageMetadata}>
          <ImageMetadata
            task={task}
            notes={activeImage ? activeImage.notes : ''}
            temperature={activeImage ? activeImage.environmentalData.tipCelsius : ''}
            humidity={activeImage ? activeImage.environmentalData.externalHumidityPercentage : ''}
            carbonDioxide={activeImage ? activeImage.environmentalData.carbonDioxidePpm : ''}
            date={activeImage ? moment(activeImage.acquisitionDate).format('MM/DD/YYYY') : ''}
          />
          <TaggedFindings findings={taggedFindings} handleRemoveClick={removeTaggedFindings} />
          <ImageMLMetadataContainer
            image={activeImage}
            activeImageRef={activeImageElement}
            canvasRef={canvasElement}
            showMlMetadata={showMlMetadata}
            setShowMlMetadata={setShowMlMetadata}
            mlBoxLocations={mlBoxLocations}
            setMlBoxLocations={setMlBoxLocations}
          />
          {!!activeImage && !!activeImage.agronomyReviewStressItems && (
            <div style={{ paddingTop: '1rem' }}>
              <Toggle
                items={[rereviewModeOnToggle]}
                selectedItem={agronomyRereviewModeOn ? rereviewModeOnToggle : null}
                offItemSelected={!agronomyRereviewModeOn}
                updateSelectedItem={() => setAgronomyRereviewModeOn(!agronomyRereviewModeOn)}
              />
            </div>
          )}
        </div>
        {activeImage ? (
          <StressItemsTagging
            updateTaggedFindings={updateTaggedFindings}
            cropStressItems={cropStressItems}
            taggedFindings={taggedFindings}
            handleSubmit={saveAndContinue}
            activeImage={activeImage}
          />
        ) : (
          <div className={styles.noActiveImage}>
            <h3 className='h5'>No image selected to tag</h3>
          </div>
        )}
      </div>
      <div className={styles.carouselContainer}>
        <div className={styles.filterContainer}>
          <ImageReviewerFilter
            selectedToggleItem={filteredStatusOption}
            setSelectedToggleItem={fetchNewPageOfImages}
          />
          {permissions.canDelete && (
            <DeleteSelection
              inCarousel
              imagesToDelete={imagesToDelete}
              onSelectAllClick={handleSelectAllToDelete}
              deleteImages={handleDeleteImages}
            />
          )}
        </div>
        <ImageReviewerCarousel
          onThumbnailSelect={handleThumbnailSelect}
          activeImageId={activeImage ? activeImage.id : null}
          images={probeImages || []}
          imagesToDelete={imagesToDelete}
          favoriteImageData={favoriteImageData}
          toggleFavoriteImage={toggleFavoriteImage}
          currentPageNumber={currentPageNumber}
          updateCurrentPage={pageNumber => fetchNewPageOfImages(filteredStatusOption, pageNumber)}
          totalPageCount={task.totalImagePageCount}
          onImageCheckboxClick={onImageCheckboxClick}
          canDelete={permissions.canDelete}
        />
      </div>
      {showFullScreenImage && (
        <FullScreenImage
          onClose={() => setShowFullScreenImage(false)}
          src={activeImage.imagePath}
          image={activeImage}
          showMlMetadata={showMlMetadata}
          mlBoxLocations={mlBoxLocations}
          setMlBoxLocations={setMlBoxLocations}
        />
      )}
    </React.Fragment>
  )
}

export const ImageReviewerByTask = withRouter(ImageReviewerByTaskComponent)

ImageReviewerByTask.propTypes = {
  task: PropTypes.object.isRequired,
  updateImageTags: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  toggleFavoriteImage: PropTypes.func.isRequired,
  deleteImages: PropTypes.func.isRequired,
  favoriteImageData: PropTypes.object,
  cropStressItems: PropTypes.object,
}
