import { cloneDeep } from 'lodash'

export function sortResourcesAlphabetically(resources) {
  return resources.sort((resourceA, resourceB) => {
    if (resourceA.title > resourceB.title) return 1
    if (resourceA.title < resourceB.title) return -1

    return 0
  })
}

export const matchesFilter = (title, filterString) => {
  return title.toLowerCase().indexOf(filterString.toLowerCase()) !== -1
}

export const filteredFields = (farm, filterString) => {
  return farm.fields.filter(field => {
    return matchesFilter(field.title, filterString)
  })
}

export const filterFarmsAndFields = (farms, filterString) => {
  let farmsToFilter = cloneDeep(farms)
  return farmsToFilter.filter(farm => {
    if (matchesFilter(farm.title, filterString)) {
      return true
    } else {
      farm.fields = filteredFields(farm, filterString)
      return farm.fields.length > 0
    }
  })
}

export const getAlphabetLetterFromIndex = index => {
  const uppercaseCharCodeStart = 65
  return String.fromCharCode(uppercaseCharCodeStart + index)
}
