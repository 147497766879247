import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FaCheckCircle } from 'react-icons/fa'
import { FavoriteProbeImageButton, CheckboxInput } from '_shared'
import styles from '../ImageReviewer.module.scss'

export function ProbeImageDisplay({
  image,
  active,
  selected,
  disabled,
  showIssuesBadge,
  showFavoriteButton,
  showSelectionCheckbox,
  onThumbnailSelect,
  onCheckboxClick,
  toggleFavoriteImage,
  className,
}) {
  const imageClasses = image => {
    return classNames({
      [styles.active]: active,
      [styles.blurry]: image.isBlurry,
    })
  }

  const displayIssuesBadge = image => {
    return image.isHealthy ? (
      <FaCheckCircle className={styles.healthy} />
    ) : (
      <span className={styles.issueCount}>{image.cropStressItems.length}</span>
    )
  }

  return (
    <div
      className={`${styles.imageContainer} ${className} ${disabled ? styles.disabled : ''}`}
      key={image.id}
    >
      <img
        onClick={() => !disabled && onThumbnailSelect(image.id)}
        src={image.thumbnailPath}
        alt={image.thumbnailPath}
        className={imageClasses(image)}
      />
      {showIssuesBadge && image.reviewed && displayIssuesBadge(image)}
      {showFavoriteButton && (
        <FavoriteProbeImageButton
          imagePath={image.imagePath}
          isFavorited={image.isFavorited}
          toggleFavorite={toggleFavoriteImage}
        />
      )}
      {showSelectionCheckbox && (
        <CheckboxInput
          name={`probeImage-${image.id}`}
          label=''
          onChange={() => !disabled && onCheckboxClick(image.id)}
          value={selected}
          className={styles.imgCheckbox}
          disabled={disabled}
        />
      )}
    </div>
  )
}

ProbeImageDisplay.propTypes = {
  image: PropTypes.object,
  active: PropTypes.bool,
  showFavoriteButton: PropTypes.bool,
  onThumbnailSelect: PropTypes.func,
  toggleFavoriteImage: PropTypes.func,
}
