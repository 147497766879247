import React, { useState } from 'react'
import { browserHistory } from 'react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import swal from '@sweetalert/with-react'
import { FaSpinner } from 'react-icons/fa'
import { DatePicker, Dropdown, FormButtonContainer } from '_shared'

export function SeasonForm({ season, crops, onSave, deleteSeason }) {
  const cropOptions = crops.map(crop => ({ label: crop.name, value: crop.id }))

  const [saveDisabled, setSaveDisabled] = useState(false)
  const [cropType, setCropType] = useState(season.crop.id)
  const [plantDate, setPlantDate] = useState(
    season ? moment.utc(season.plantDate).format('MM/DD/YYYY') : ''
  )

  const onDeleteClick = async () => {
    const result = await swal({
      title: 'Are you sure you want to delete this season?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          className: '',
          closeModal: false,
        },
      },
    })
    result && handleDeleteSeason()
  }

  const handleDeleteSeason = async () => {
    setSaveDisabled(true)
    try {
      let seasons = await deleteSeason(season.id) //eslint-disable-line no-unused-vars
      setSaveDisabled(false)

      await swal({
        title: 'Great, your season has been deleted!',
        icon: 'success',
        closeOnConfirm: true,
      })
      browserHistory.push(`/farms/${season.farmId}/field/${season.field.id}`)
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeOnConfirm: true,
      })
    }
  }

  const handleSubmit = async data => {
    setSaveDisabled(true)

    const requestParams = {
      cropType,
      plantDate: moment.utc(data.plantDate).format('YYYY/MM/DD'),
      seasonId: season.id,
    }

    try {
      await onSave(requestParams)
      setSaveDisabled(false)

      await swal({
        title: 'Great, your field has been updated!',
        icon: 'success',
        closeModal: true,
      })
      browserHistory.push(`/farms/${season.farmId}/field/${season.field.id}`)
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeModal: true,
      })
    }
  }

  return (
    <React.Fragment>
      <h2>{season.title}</h2>
      <form onSubmit={handleSubmit}>
        <Dropdown
          name='cropType'
          inputId='cropType'
          label='Crop Type'
          placeholder='Select Crop Type'
          value={cropOptions.find(opt => opt.value === cropType)}
          options={cropOptions}
          onChange={selectedOption => setCropType(selectedOption.value)}
        />
        <DatePicker
          name='plantDate'
          label='What is your approximate planting date?'
          value={plantDate}
          onChange={date => setPlantDate(date)}
        />
        <FormButtonContainer>
          <Button variant='outline-danger' onClick={onDeleteClick}>
            Delete
          </Button>

          <button className='btn btn-primary' type='submit' disabled={saveDisabled}>
            {saveDisabled && <FaSpinner />}
            Save
          </button>
        </FormButtonContainer>
      </form>
    </React.Fragment>
  )
}

SeasonForm.propTypes = {
  season: PropTypes.object.isRequired,
  crops: PropTypes.array.isRequired,
  onSave: PropTypes.func,
  deleteSeason: PropTypes.func,
}
