import React from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import PropTypes from 'prop-types'
import { getCrops } from 'redux/modules/crops'
import { getSeason, editSeason, deleteSeason } from 'redux/modules/seasons'
import { getRegionsBySeasonId, deleteRegion } from 'redux/modules/regions'
import { EditSeasonFormAndMap } from './EditSeasonFormAndMap'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { params, store } = options
      const { dispatch } = store
      if (params && params.seasonId) {
        promises.push(dispatch(getSeason(params.seasonId)))
        promises.push(dispatch(getRegionsBySeasonId(params.seasonId)))
      }
      promises.push(dispatch(getCrops()))

      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    crops: state.crops.crops.data,
    season: state.seasons.activeSeason.data,
    regions: state.regions.regionsBySeason.data,
  }),
  {
    editSeason,
    deleteSeason,
    deleteRegion,
  }
)

function EditSeasonContainer({
  season,
  crops,
  regions,
  editSeason,
  deleteSeason,
  deleteRegion,
  dispatch,
}) {
  const updateRegions = () => {
    dispatch(getRegionsBySeasonId(season.id))
  }

  return (
    <EditSeasonFormAndMap
      season={season}
      crops={crops}
      regions={regions}
      editSeason={editSeason}
      deleteSeason={deleteSeason}
      updateRegions={updateRegions}
      deleteRegion={deleteRegion}
    />
  )
}

export default asyncConnectWrapper(connectWrapper(EditSeasonContainer))

EditSeasonContainer.propTypes = {
  season: PropTypes.object.isRequired,
  crops: PropTypes.array.isRequired,
  editSeason: PropTypes.func,
  deleteSeason: PropTypes.func,
  deleteRegion: PropTypes.func,
}
