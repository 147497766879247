import React from 'react'
import { IndexRoute, Route } from 'react-router'
import { isAuthLoaded, loadAuth } from './redux/modules/auth'

import {
  Account,
  AccountSetup,
  App,
  ChangePassword,
  CreateField,
  CreateOrder,
  CreateSeason,
  Dashboard,
  EditFarm,
  EditField,
  ForgotPassword,
  LoginWithToken,
  Logout,
  NotFound,
  NotAllowed,
  TestPlotToolShowWrapper,
} from './components'

import {
  CustomerAccountSettingsContainer,
  FarmListContainer,
  FieldListContainer,
  FieldsListMap,
  FieldViewContainer,
  FieldViewMap,
  EditRegionContainer,
  EditSeasonContainer,
  ImageReviewerSummaryContainer,
  ImageReviewerByTaskContainer,
  ImageReviewerAccessContainer,
  BulkImageReviewContainer,
  LeafletMap,
  LoginContainer,
  ReportsIndexContainer,
  ReportsBySeasonContainer,
  FarmFieldAccessContainer,
  CreateRegionContainer,
  AerialImageryReportContainer,
  CreateAerialImageryReportContainer,
  EditAerialImageryReportContainer,
} from 'domains'

export default store => {
  const requireLogin = (nextState, replace, cb) => {
    if (!isAuthLoaded(store.getState())) {
      store
        .dispatch(loadAuth())
        .then(() => {
          cb()
        })
        .catch(err => {
          console.log('Route Error', err)
          replace('/login')

          cb()
        })
    } else {
      cb()
    }
  }

  const requireNotLoggedIn = (nextState, replace, cb) => {
    if (isAuthLoaded(store.getState())) {
      replace('/')
    }

    cb()
  }

  return (
    <Route path='/' component={App}>
      {/* Routes requiring login */}
      <Route onEnter={requireLogin}>
        <IndexRoute component={Dashboard} />
        {/* farm group */}
        <Route path='farm/:farmId/field/create' component={CreateField} action='create' />
        {/* Reports */}
        <Route path='report(s)'>
          <IndexRoute component={ReportsIndexContainer} />
        </Route>
        <Route
          path='farm(s)/:farmId/field/:fieldId/season/:seasonId/reports'
          component={ReportsBySeasonContainer}
        />
        <Route
          path='/farm(s)/:farmId/field/:fieldId/season/:seasonId/aerial-imagery-report/new'
          component={CreateAerialImageryReportContainer}
        />
        <Route
          path='/farm(s)/:farmId/field/:fieldId/season/:seasonId/aerial-imagery-report/:reportId'
          component={AerialImageryReportContainer}
        />
        <Route
          path='/farm(s)/:farmId/field/:fieldId/season/:seasonId/aerial-imagery-report/:reportId?download'
          component={AerialImageryReportContainer}
        />
        <Route
          path='/farm(s)/:farmId/field/:fieldId/season/:seasonId/aerial-imagery-report/:reportId/edit'
          component={EditAerialImageryReportContainer}
        />
        {/* Image Reviewer */}
        <Route path='image-reviewer' component={ImageReviewerSummaryContainer} />
        <Route path='image-reviewer/:taskId/bulk-review' component={BulkImageReviewContainer} />
        <Route path='image-reviewer/:taskId' component={ImageReviewerByTaskContainer} />
        <Route path='image-reviewer-access' component={ImageReviewerAccessContainer} />
        {/* Deprecated route */}
        <Route
          path='farm(s)/:farmId/field/:fieldId/season/:seasonId/test-plot-tool/:testPlotToolId/show'
          component={TestPlotToolShowWrapper}
        />
        <Route path='farm(s)' components={{ sidebar: FarmListContainer, map: LeafletMap }} />
        <Route
          path='farm(s)/:farmId'
          components={{
            sidebar: FieldListContainer,
            map: FieldsListMap,
            wideSidebar: true,
          }}
        />
        <Route
          path='fields'
          components={{
            sidebar: FieldListContainer,
            map: FieldsListMap,
            wideSidebar: true,
          }}
        />
        <Route path='farm(s)/:farmId/edit' component={EditFarm} />
        <Route path='farm(s)/:farmId/field/:fieldId' component={FieldViewContainer} />
        <Route path='farm(s)/:farmId/field/:fieldId/edit' component={EditField} />
        <Route
          path='farm(s)/:farmId/field/:fieldId/create-season'
          components={{ sidebar: CreateSeason, map: FieldViewMap }}
        />
        <Route
          path='farm(s)/:farmId/field/:fieldId/create-order'
          components={{ sidebar: CreateOrder, map: FieldViewMap }}
        />
        <Route
          path='farm(s)/:farmId/field/:fieldId/season/:seasonId/edit'
          component={EditSeasonContainer}
        />
        <Route
          path='farm(s)/:farmId/field/:fieldId/season/:seasonId/create-region'
          component={CreateRegionContainer}
        />
        <Route
          path='farm(s)/:farmId/field/:fieldId/season/:seasonId/region/:regionId'
          component={EditRegionContainer}
        />
        {/* user admin group */}
        <Route path='account' component={Account} />
        <Route path='settings' component={CustomerAccountSettingsContainer} />
        <Route path='user-access' component={FarmFieldAccessContainer} />
      </Route>

      <Route path='logout' component={Logout} noNav='true' />

      {/* Auth routes where users can not be logged in */}
      <Route onEnter={requireNotLoggedIn}>
        <Route path='login' component={LoginContainer} noNav='true' />
        <Route path='login/:authToken' component={LoginWithToken} />
      </Route>

      <Route path='change-password/:emailToken' component={ChangePassword} noNav='true' />
      <Route path='forgot-password' component={ForgotPassword} noNav='true' />
      <Route path='account-setup/:inviteToken' component={AccountSetup} noNav='true' />

      {/* Not allowed */}
      <Route path='403' component={NotAllowed} status={403} noNav='true' />
      <Route path='*' component={NotFound} status={404} noNav='true' />
    </Route>
  )
}
