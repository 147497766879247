import { useState, useEffect } from 'react'
import Leaflet from 'leaflet'
import PropTypes from 'prop-types'

export function ImageErrorHandler(map, imageLayer, errorMarker) {
  imageLayer.addEventListener('tileerror', () => {
    map.removeLayer(errorMarker)
    // This is called when the layer image does not exist. We display a pin instead
    try {
      errorMarker.addTo(map)
    } catch (err) {
      console.log('Error could not add marker for layer', err)
    }
  })

  imageLayer.addEventListener('tileload', () => map.removeLayer(errorMarker))
  imageLayer.addEventListener('tileunload', () => map.removeLayer(errorMarker))
}

export function ImagePlacer(map, imageLayer) {
  map.addLayer(imageLayer)
}

export function ImageRemover(map, imageLayer, errorMarker) {
  map.removeLayer(imageLayer)
  map.removeLayer(errorMarker)
}

export function TransparencyChanger(imageLayer, layersTransparency) {
  try {
    imageLayer.setOpacity(layersTransparency * 0.01) // Convert from 0-100 to 0-1 scale
  } catch (err) {
    console.log('Could not change layer opacity', err)
  }
}

export function ImageLayerLeafletObjectBuilder(activeImage, bounds, layersTransparency) {
  const imageLayer = Leaflet.tileLayer(activeImage.tileUrl, {
    minZoom: 12,
    maxZoom: 20,
    maxNativeZoom: 20,
    bounds: bounds,
    opacity: layersTransparency * 0.01, // Convert from 0-100 to 0-1 scale
  })
  imageLayer.originalLayer = activeImage // for reference

  const leafletBounds = Leaflet.latLngBounds(
    Leaflet.latLng(bounds[0][0], bounds[0][1]),
    Leaflet.latLng(bounds[1][0], bounds[1][1])
  )

  const errorMarkerLayer = Leaflet.marker(leafletBounds.getCenter(), {
    clickable: false,
    keyboard: false,
    icon: Leaflet.divIcon({
      className: 'leaflet-text-label',
      html: '<h4>Zoom In</h4>',
      iconSize: [100, 35],
    }),
  })

  return { imageLayer, errorMarkerLayer }
}

export function ImageLayer({ map, activeImage, bounds, layersTransparency }) {
  const [image, setImage] = useState(null)
  const [errorMarker, setErrorMarker] = useState(null) //eslint-disable-line no-unused-vars

  useEffect(() => {
    if (!activeImage || !bounds || bounds.length === 0) return
    if (!map) return

    try {
      const { imageLayer, errorMarkerLayer } = ImageLayerLeafletObjectBuilder(
        activeImage,
        bounds,
        layersTransparency
      )

      ImageErrorHandler(map, imageLayer, errorMarkerLayer)
      ImagePlacer(map, imageLayer)
      setImage(imageLayer)
      setErrorMarker(errorMarkerLayer)

      // Cleanup to run when component unmounts
      return function cleanup() {
        ImageRemover(map, imageLayer, errorMarkerLayer)
      }
    } catch (err) {
      console.log('LeafletMap addLayer Error:', err)
    }
  }, [activeImage, map, bounds]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!image) return
    TransparencyChanger(image, layersTransparency)
  }, [layersTransparency]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

ImageLayer.propTypes = {
  map: PropTypes.object,
  activeImage: PropTypes.object,
  bounds: PropTypes.array,
  layersTransparency: PropTypes.number,
}
