/* eslint array-callback-return: 0*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { MapLayerControl, TopLevelContainer } from './MapLayerControl'
import styles from './LeafletMap.module.scss'

export function LayerControls({ children }) {
  const [layerControlVisible, setLayerControlVisible] = useState(false)

  return (
    <div className={styles.LeafletMapLayerControlToggleContainer}>
      <Button
        variant='secondary'
        className={styles.LeafletMapLayerControlToggle}
        onClick={() => setLayerControlVisible(true)}
      />
      <MapLayerControl
        hideMapLayerControl={() => setLayerControlVisible(false)}
        active={layerControlVisible}
      >
        <TopLevelContainer>
          <React.Fragment>
            <p>Layers</p>
            {children}
          </React.Fragment>
        </TopLevelContainer>
      </MapLayerControl>
    </div>
  )
}

LayerControls.propTypes = {
  hasLayers: PropTypes.bool,
}
