import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Alert } from 'react-bootstrap'
import swal from '@sweetalert/with-react'
import { TextInput, TextAreaInput, FileInput, ColorPickerInput } from '_shared'
import { CustomerAccountsContext, CustomerAccountDropdown } from 'domains'
import { HighlightsEditor } from './HighlightsEditor'
import styles from './CustomerAccountSettings.module.scss'

export function CustomerAccountSettingsForm({
  isUpdated,
  onSubmit,
  handleAlertSuccessDismiss,
  formErrors,
  handleAlertDismiss,
  onCustomerAccountChange,
}) {
  const { customerAccounts, selectedCustomerAccountId, refreshCustomerAccounts } = React.useContext(
    CustomerAccountsContext
  )

  const [canManage, setCanManage] = useState(false)
  const [name, setName] = useState('')
  const [brandColor, setBrandColor] = useState('#0095FF')
  const [highlightsHeader, setHighlightsHeader] = useState('')
  const [highlights, setHighlights] = useState([])
  const [copyright, setCopyright] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [imgSrc, setImgSrc] = useState('')
  const logoInput = useRef(null)

  useEffect(() => {
    let selectedCustomerAccount = customerAccounts.find(c => c.id === selectedCustomerAccountId)
    if (selectedCustomerAccount) {
      setCanManage(selectedCustomerAccount.permissions.canUpdate)
      setName(selectedCustomerAccount.name)
      setBrandColor(selectedCustomerAccount.brandColor || '#0095FF')
      setHighlightsHeader(selectedCustomerAccount.highlightsHeader || '')
      setHighlights(selectedCustomerAccount.highlights || [])
      setCopyright(selectedCustomerAccount.copyright || '')
      setContactName(selectedCustomerAccount.contactName || '')
      setContactPhone(selectedCustomerAccount.contactPhone || '')
      setContactEmail(selectedCustomerAccount.contactEmail || '')
      setImgSrc(selectedCustomerAccount.logoUrl || '')
    }
  }, [selectedCustomerAccountId]) //eslint-disable-line react-hooks/exhaustive-deps

  const updateImageSource = () => {
    const logoImg = logoInput.current.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(logoImg)
    reader.onloadend = () => setImgSrc(reader.result)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    let logoImg

    if (logoInput.current.files) {
      logoImg = logoInput.current.files[0]
      if (logoImg && !logoImg.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        swal({
          title: 'File must be an image.',
          icon: 'warning',
          showCancelButton: false,
          dangerMode: true,
          confirmButtonText: 'OK',
          closeOnConfirm: true,
        })
        return
      }
    }

    await onSubmit(selectedCustomerAccountId, {
      name,
      brandColor,
      highlightsHeader,
      highlights,
      copyright,
      contactName,
      contactPhone,
      contactEmail,
      companyLogo: logoImg,
    })

    refreshCustomerAccounts()
  }

  return (
    <div>
      <div className={styles.customerAccountDropdown}>
        <CustomerAccountDropdown onCustomerAccountChange={onCustomerAccountChange} />
      </div>
      {!selectedCustomerAccountId && 'Please choose a customer account'}
      {!canManage &&
        selectedCustomerAccountId &&
        "Sorry, you don't have the permissions to update this customer account's settings"}
      {canManage && selectedCustomerAccountId && (
        <React.Fragment>
          <p>
            Customize your company name and logo. We will use it for reports and communication to
            your growers.
          </p>
          <form
            className={styles.customerSettingsForm}
            onSubmit={handleSubmit}
            encType='multipart/form-data'
          >
            {formErrors &&
              formErrors.map(error => (
                <Alert
                  key={error.code}
                  variant='danger'
                  onDismiss={() => handleAlertDismiss(error)}
                >
                  {error.message}
                </Alert>
              ))}
            {isUpdated && (
              <Alert variant='success' onDismiss={handleAlertSuccessDismiss} dismissAfter={2000}>
                Your info is updated
              </Alert>
            )}
            <TextInput
              name='name'
              label='Name'
              value={name}
              onChange={e => setName(e.target.value)}
              required={true}
            />
            <FileInput
              name='companyLogo'
              label='Company Logo'
              inputRef={logoInput}
              onChange={updateImageSource}
            />
            {imgSrc && (
              <img className={styles.previewImage} src={imgSrc} alt='File upload preview' />
            )}
            <ColorPickerInput
              name='brandColor'
              label='Brand Color'
              value={brandColor}
              onChange={setBrandColor}
            />
            <TextInput
              name='highlightsHeader'
              label='Report highlights header'
              value={highlightsHeader}
              onChange={e => setHighlightsHeader(e.target.value)}
            />
            <HighlightsEditor highlights={highlights} setHighlights={setHighlights} />
            <TextInput
              name='contactName'
              label='Default Contact Name'
              value={contactName}
              onChange={e => setContactName(e.target.value)}
            />
            <TextInput
              name='contactPhone'
              label='Default Contact Phone'
              value={contactPhone}
              onChange={e => setContactPhone(e.target.value)}
            />
            <TextInput
              name='contactEmail'
              label='Default Contact Email'
              value={contactEmail}
              onChange={e => setContactEmail(e.target.value)}
            />
            <TextAreaInput
              name='copyright'
              label='Terms and Conditions Template'
              value={copyright}
              onChange={e => setCopyright(e.target.value)}
            />
            <div className={styles.buttonContainer}>
              <Button type='submit' variant='primary' className='col-2'>
                Save
              </Button>
            </div>
          </form>
        </React.Fragment>
      )}
    </div>
  )
}

CustomerAccountSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdated: PropTypes.bool,
  handleAlertSuccessDismiss: PropTypes.func.isRequired,
  formErrors: PropTypes.array,
  handleAlertDismiss: PropTypes.func.isRequired,
  onCustomerAccountChange: PropTypes.func,
}
