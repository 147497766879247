import React from 'react'
import PropTypes from 'prop-types'
import styles from './Toggle.module.scss'

export function Toggle({
  selectedItem,
  items,
  updateSelectedItem,
  offItemSelected,
  offLabel,
  className,
}) {
  const offToggleLabel = offLabel || 'Off'
  return (
    <div className={`${styles.toggleContainer} ${className}`}>
      <button
        className={`${styles.toggle} ${offItemSelected ? styles.active : ''}`}
        onClick={() => updateSelectedItem(0)}
      >
        <div>{offToggleLabel}</div>
      </button>
      {items.map(item => (
        <button
          key={item.id}
          className={`${styles.toggle} ${
            selectedItem && selectedItem.id === item.id ? styles.active : ''
          }`}
          onClick={() => updateSelectedItem(item.id)}
        >
          <div>{item.label}</div>
        </button>
      ))}
    </div>
  )
}

Toggle.propTypes = {
  selectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  offLabel: PropTypes.string,
  className: PropTypes.string,
  updateSelectedItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
}
