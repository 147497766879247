/* eslint react/jsx-no-target-blank: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import { push } from 'react-router-redux'
import {
  loadAccountByToken,
  updateAccount,
  clearAllErrors,
  clearUpdateSuccess,
} from 'redux/modules/account'
import config from '../../config'
import { LinkContainer } from 'react-router-bootstrap'
import { Button } from 'react-bootstrap'
import classNames from 'classnames/bind'
import styles from './AccountSetup.module.scss'

import AccountSetupForm from './AccountSetupForm'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const { dispatch } = options.store
      const { params } = options
      return dispatch(loadAccountByToken(params.inviteToken))
    },
  },
])

const connectWrapper = connect(
  state => ({
    account: state.account.data,
    updateErrors: state.account.errors,
    tokenErrors: state.account.tokenErrors,
  }),
  {
    loadAccountByToken,
    updateAccount,
    clearAllErrors,
    clearUpdateSuccess,
    pushState: push,
  }
)

class AccountSetupComponent extends Component {
  constructor() {
    super()
    this.state = { step: 1 }
    // Make sure we have access to the correct this reference
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.previousStep = this.previousStep.bind(this)
  }

  componentWillUnmount() {
    this.props.clearAllErrors()
    this.props.clearUpdateSuccess()
  }

  /**
   * TODO: Handle success response with logging in the user by the returning
   * session from the API.
   */
  onSubmit = data => {
    this.props.updateAccount(this.props.account.id, data).then(() => {
      this.props.pushState('/')
    })
  }

  nextStep() {
    this.setState({ step: this.state.step + 1 })
  }

  previousStep() {
    this.setState({ step: this.state.step - 1 })
  }

  render() {
    const logoImage = require('assets/logo-aker.svg')
    const rightArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#0095ff' strokeMiterlimit='10' points='2,2 7.5,7.5 2,13  ' />
      </svg>
    )

    const { account, tokenErrors, updateErrors } = this.props

    /**
     * Variables for each form based on the role of an account and the current step.
     */
    let totalSteps = 2
    const { step } = this.state
    const formTitle = 'Setup your account'
    const showPreviousStep = step > 1

    let validator
    let nextButtonLabel = 'Next'

    /**
     * Handle the click and based on the current step it wil show the next form
     * or submit it.
     * @param  {object} data Data provided by submitting the form.
     */
    const handleButtonClick = data => {
      if (step === totalSteps) {
        this.onSubmit(data)
      } else {
        this.nextStep()
      }
    }

    const formStep = !tokenErrors ? `(step ${step}/${totalSteps})` : false
    const cx = classNames.bind(styles)
    const windowName = cx({
      setupWindow: true,
      errorWindow: tokenErrors,
    })

    return (
      <div className={styles.siteWrapper}>
        <Helmet title={formTitle} />

        <div className={styles.siteWrapperInner}>
          <div className={windowName}>
            <div className={styles.leftColumn}>
              <img className={styles.logo} src={logoImage} alt={config.app.title} />

              <div className={styles.diver}>
                <a className={styles.link} href='https://aker.ag/' target='_blank'>
                  Get Aker {rightArrow}
                </a>
                <br />
                <a className={styles.link} href='https://aker.ag/retailer' target='_blank'>
                  Find a retailer {rightArrow}
                </a>
              </div>
            </div>

            <div className={styles.rightColumn}>
              {tokenErrors &&
                [tokenErrors].map(error => (
                  <div>
                    <h2>Something went wrong</h2>
                    {error.message && <p>{error.message}</p>}
                    <p>
                      If you think this is an error, please contact{' '}
                      {config.app.contactInfo.companyName} at:
                    </p>
                    <address>
                      <a href={`mailto:${config.app.contactInfo.serviceEmail}`}>
                        {config.app.contactInfo.serviceEmail}
                      </a>
                      <br />
                      {config.app.contactInfo.servicePhone}
                    </address>
                    <LinkContainer to='/login'>
                      <Button variant='primary'>Go to login page</Button>
                    </LinkContainer>
                  </div>
                ))}
              {!tokenErrors && (
                <AccountSetupForm
                  title={formTitle}
                  formStep={formStep}
                  initialValues={account}
                  validate={validator}
                  nextButtonLabel={nextButtonLabel}
                  onSubmit={handleButtonClick}
                  showPreviousStep={showPreviousStep}
                  previousStep={this.previousStep}
                  formErrors={updateErrors}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AccountSetupComponent.propTypes = {
  account: PropTypes.object,
  updateErrors: PropTypes.array,
  tokenErrors: PropTypes.array,
  updateAccount: PropTypes.func.isRequired,
  clearAllErrors: PropTypes.func.isRequired,
  clearUpdateSuccess: PropTypes.func.isRequired,
  pushState: PropTypes.func.isRequired,
}

export default asyncConnectWrapper(connectWrapper(AccountSetupComponent))
