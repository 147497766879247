import { createStore as _createStore, applyMiddleware, compose } from 'redux'
import createMiddleware from './middleware/clientMiddleware'
import { routerMiddleware } from 'react-router-redux'
import rootReducer from './modules/reducer'

export default function createStore(history, client, data) {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = routerMiddleware(history)
  const middleware = [createMiddleware(client), reduxRouterMiddleware]

  let finalCreateStore
  if (process.env.NODE_ENV === 'development') {
    const createLogger = require(`redux-logger`)
    // Only log 'aker/*' redux actions
    const logger = createLogger({
      diff: true,
      predicate: (getState, action) => action.type.search('aker/') === 0,
    })

    finalCreateStore = compose(applyMiddleware(...middleware, logger))(_createStore)
  } else {
    finalCreateStore = applyMiddleware(...middleware)(_createStore)
  }

  const reducer = rootReducer
  const store = finalCreateStore(reducer, data)

  // if (process.env.NODE_ENV === "development" && module.hot) {
  //   module.hot.accept("./modules/reducer", () => {
  //     store.replaceReducer(require("./modules/reducer"));
  //   });
  // }

  return store
}
