import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { Row, Button, Alert } from 'react-bootstrap'
import { WrappedInputField } from '../../_shared'
import ContactFormValidation from './ContactFormValidation'
import { ResetPassword } from './ResetPassword'
import styles from './ContactInfoForm.module.scss'

class InnerForm extends Component {
  static propTypes = {
    formDescription: PropTypes.string,
    formErrors: PropTypes.array,
    handleAlertDismiss: PropTypes.func.isRequired,
    handleAlertSuccessDismiss: PropTypes.func.isRequired,
    handlePasswordReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isFormUpdated: PropTypes.bool,
    resetErrors: PropTypes.object,
    resetSuccess: PropTypes.object,
    resettingPassword: PropTypes.bool,
  }

  render() {
    const {
      formErrors,
      handleAlertDismiss,
      handleAlertSuccessDismiss,
      handlePasswordReset,
      handleSubmit,
      isFormUpdated,
      resetErrors,
      resetSuccess,
      resettingPassword,
    } = this.props

    return (
      <form className='form-horizontal' onSubmit={handleSubmit}>
        <p>The address is used for billing and other communication between you and Aker.</p>
        {/* Contact Info Form */}
        {isFormUpdated && (
          <Alert variant='success' onDismiss={handleAlertSuccessDismiss} dismissAfter={2000}>
            Your info is updated
          </Alert>
        )}
        {formErrors &&
          formErrors.map(error => {
            return (
              <Alert key={error.code} variant='danger' onDismiss={() => handleAlertDismiss(error)}>
                {error.message}
              </Alert>
            )
          })}
        <div>
          <Row>
            <WrappedInputField
              name={'name'}
              columnWidth={12}
              required={true}
              title={'Name'}
              type={'text'}
              placeholder={''}
              disabled={false}
            />
          </Row>
          <Row>
            <WrappedInputField
              name={'email'}
              columnWidth={12}
              required={true}
              title={'Email'}
              type={'text'}
              placeholder={''}
              disabled={true}
            />
          </Row>
          <Row>
            <WrappedInputField
              name={'address1'}
              columnWidth={12}
              required={true}
              title='Address'
              type={'text'}
              placeholder={''}
              disabled={false}
            />
          </Row>
          <Row>
            <WrappedInputField
              name={'address2'}
              columnWidth={12}
              required={false}
              title={''}
              type={'text'}
              placeholder={'Optional'}
              disabled={false}
            />
          </Row>
          <Row>
            <WrappedInputField
              name={'city'}
              columnWidth={12}
              required={true}
              title={'Town / City'}
              type={'text'}
              placeholder={''}
              disabled={false}
            />
          </Row>
          <Row>
            <WrappedInputField
              name={'state'}
              columnWidth={6}
              required={true}
              title={'State'}
              type={'text'}
              placeholder={''}
              disabled={false}
            />
            <WrappedInputField
              name={'zipCode'}
              columnWidth={6}
              required={true}
              title={'ZIP Code'}
              type={'text'}
              placeholder={''}
              disabled={false}
            />
          </Row>
          <Row>
            <WrappedInputField
              name={'phone'}
              columnWidth={12}
              required={true}
              title={'Phone number'}
              type={'tel'}
              placeholder={''}
              disabled={false}
            />
            <WrappedInputField
              name={'mobile'}
              columnWidth={12}
              required={false}
              title={'Mobile'}
              type={'tel'}
              placeholder={'Optional'}
              disabled={false}
            />
          </Row>
        </div>
        {/* Reset Password button */}
        <ResetPassword
          resetHandler={handlePasswordReset}
          resetErrors={resetErrors}
          resetSuccess={resetSuccess}
          resettingPassword={resettingPassword}
        />
        {/* Submit form button */}
        <div className={styles.buttonContainer}>
          <Button type='submit' variant='primary' className='col-3'>
            Save
          </Button>
        </div>
      </form>
    )
  }
}

const Form = reduxForm({
  form: 'ContactInfoForm',
  validate: ContactFormValidation,
  touchOnBlur: false,
})(InnerForm)

/**
 * ContactInfoForm
 * Showing a contact detail information form.
 */
export default class ContactInfoForm extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    description: PropTypes.string,

    onSubmit: PropTypes.func.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    formErrors: PropTypes.array,
    handleAlertDismiss: PropTypes.func.isRequired,
    handleAlertSuccessDismiss: PropTypes.func.isRequired,

    handlePasswordReset: PropTypes.func.isRequired,
    resettingPassword: PropTypes.bool,
    resetSuccess: PropTypes.object,
    resetErrors: PropTypes.object,
  }

  render() {
    const {
      account,
      description,
      onSubmit,
      isUpdated,
      formErrors,
      handleAlertDismiss,
      handleAlertSuccessDismiss,
      handlePasswordReset,
      resettingPassword,
      resetSuccess,
      resetErrors,
    } = this.props

    return (
      <div>
        <Form
          formDescription={description}
          onSubmit={onSubmit}
          initialValues={account}
          isFormUpdated={isUpdated}
          formErrors={formErrors}
          handleAlertDismiss={handleAlertDismiss}
          handleAlertSuccessDismiss={handleAlertSuccessDismiss}
          handlePasswordReset={handlePasswordReset}
          resettingPassword={resettingPassword}
          resetSuccess={resetSuccess}
          resetErrors={resetErrors}
        />
      </div>
    )
  }
}
