import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from '_shared'
import { CustomerAccountsContext } from 'domains'
import styles from './CustomerAccount.module.scss'

export default function CustomerAccountDropdown({
  onCustomerAccountChange,
  containerClassName,
  dropdownClassName,
}) {
  const {
    customerAccounts,
    selectedCustomerAccountId,
    setSelectedCustomerAccountId,
  } = React.useContext(CustomerAccountsContext)

  useEffect(() => {
    if (onCustomerAccountChange) onCustomerAccountChange(selectedCustomerAccountId || null)
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const ALL_OPTION_VALUE = 'all'
  const customerAccountOptions = [
    { value: ALL_OPTION_VALUE, label: 'All customer accounts' },
    ...customerAccounts.map(ca => ({ value: ca.id, label: ca.name })),
  ]
  const dropdownValue = selectedCustomerAccountId
    ? customerAccountOptions.find(opt => opt.value === selectedCustomerAccountId)
    : customerAccountOptions[0]

  const onDropdownChange = selectedOption => {
    const customerAccountId =
      selectedOption.value === ALL_OPTION_VALUE ? null : selectedOption.value
    setSelectedCustomerAccountId(customerAccountId)
    if (onCustomerAccountChange) {
      onCustomerAccountChange(customerAccountId)
    }
  }

  return (
    <div className={`${containerClassName} ${styles.customerAccountDropdown}`}>
      <Dropdown
        name='customerAccountDropdown'
        label='Customer Account'
        className={dropdownClassName}
        options={customerAccountOptions}
        value={dropdownValue}
        onChange={onDropdownChange}
      />
    </div>
  )
}

CustomerAccountDropdown.propTypes = {
  onCustomerAccountChange: PropTypes.func,
}
