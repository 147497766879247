import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Leaflet from 'leaflet'
import { MapPinsLayer } from './MapPinsLayer'

export function PinsForBoundaries({ map, boundaries }) {
  const [pinLocations, setPinLocations] = useState([])

  useEffect(() => {
    const pins = boundaries.map(b => ({
      // get center of field boundary (object with lat & lng)
      ...Leaflet.latLngBounds(
        Leaflet.latLng(b.bounds[0][0], b.bounds[0][1]),
        Leaflet.latLng(b.bounds[1][0], b.bounds[1][1])
      ).getCenter(),
      ...b,
    }))
    setPinLocations(pins)
  }, [map, boundaries])

  return (
    <MapPinsLayer
      pinLocations={pinLocations.map(pin => ({
        active: true,
        ...pin,
      }))}
      map={map}
    />
  )
}

PinsForBoundaries.propTypes = {
  map: PropTypes.object,
  boundaries: PropTypes.arrayOf(
    PropTypes.shape({ bounds: PropTypes.array.isRequired, onClick: PropTypes.func })
  ),
}
