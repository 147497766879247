import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import { isFarmLoaded, getFarm } from 'redux/modules/farms'
import { addField } from 'redux/modules/fields'
import * as turf from '@turf/turf'
import swal from '@sweetalert/with-react'
import { push } from 'react-router-redux'
import Helmet from 'react-helmet'
import { SidePanel, ContextHeader } from '../_shared'
import LegacyLeafletMap from 'components/LegacyLeafletMap/LegacyLeafletMap'
import CreateFieldForm from './CreateFieldForm'
import { fetchClus } from 'redux/modules/maplayers'
import styles from './CreateField.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch, getState } = options.store
      const { params } = options

      if (!isFarmLoaded(getState(), params.farmId)) {
        promises.push(dispatch(getFarm(params.farmId)))
      }

      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    farm: state.farms.farm.data,
    fields: state.farms.farm.data.fields,
  }),
  {
    fetchClus,
    addField,
    pushState: push,
  }
)

class CreateFieldComponent extends Component {
  constructor(props) {
    super(props)
    // Make sure we have access to the correct this reference
    this.handleFetchClus = this.handleFetchClus.bind(this)
    this.setSelectedPolygon = this.setSelectedPolygon.bind(this)
    this.setUploadedPolygon = this.setUploadedPolygon.bind(this)
    this.handleFieldSubmit = this.handleFieldSubmit.bind(this)
    this.handleBackEvent = this.handleBackEvent.bind(this)
    this.state = {
      selectedAcres: 0,
      uploadedShapes: null,
      mapDisabled: false,
      showImportFieldComponent: !!props.location.query.code,
      importedFieldData: [],
      fieldsImported: false,
      selectedImportedFieldId: { id: null, mouseEvent: null },
      selectedImportedFieldLayer: { _leaflet_id: null },
      origin: window.location.origin,
      pathname: window.location.pathname,
    }
  }

  componentDidMount() {
    const hasImportedFields =
      JSON.parse(window.sessionStorage.getItem('importedClimateFields')) ||
      JSON.parse(window.sessionStorage.getItem('importedMyJDFields'))
    if (hasImportedFields) {
      this.setState({ showImportFieldComponent: true })
    }
  }

  setUploadedPolygon(leafletLayer = null) {
    if (!leafletLayer) {
      this.selectedPolygon = null
      this.setState({
        uploadedShapes: null,
      })
      return
    }

    this.selectedPolygon = leafletLayer._layers[Object.keys(leafletLayer._layers)[0]]

    this.setState({
      selectedAcres: (turf.area(leafletLayer.toGeoJSON()) * 0.000247105).toFixed(2),
      uploadedShapes: this.selectedPolygon.toGeoJSON(),
      mapDisabled: true,
    })
  }

  setSelectedPolygon(polygon) {
    if (Object.keys(polygon).length > 0) {
      this.selectedPolygon = polygon[Object.keys(polygon)[0]]
      this.setState({
        selectedAcres: (turf.area(this.selectedPolygon.toGeoJSON()) * 0.000247105).toFixed(2),
      })
    } else {
      this.selectedPolygon = null
      this.setState({
        selectedAcres: 0,
      })
    }
  }

  getSelectedImportedFieldId = (id, mouseEvent) => {
    this.setState({ selectedImportedFieldId: { id, mouseEvent } })
  }
  getSelectedImportedFieldLayer = layer => {
    this.setState({ selectedImportedFieldLayer: layer })
  }

  handleFetchClus(northEast, southWest) {
    return this.props.fetchClus(northEast, southWest)
  }

  handleFieldSubmit(data) {
    const bounds = this.selectedPolygon.getBounds()
    const formattedBounds = [
      [bounds._northEast.lat, bounds._northEast.lng],
      [bounds._southWest.lat, bounds._southWest.lng],
    ]
    const acres = (turf.area(this.selectedPolygon.toGeoJSON()) * 0.000247105).toFixed(2)
    this.props
      .addField(
        this.props.params.farmId,
        data.fieldName,
        JSON.stringify(this.selectedPolygon.toGeoJSON()),
        JSON.stringify(formattedBounds),
        acres
      )
      .then(res => {
        window.location = `/farm/${this.props.params.farmId}/field/${res.data.id}`
      })
  }

  handleBackEvent() {
    this.props.pushState(`/farm/${this.props.params.farmId}`)
  }

  toggleImportFieldComponent = event => {
    if (event === 'back' || event === 'cancel')
      this.setState({ importedFieldData: [], selectedImportedFieldLayer: { _leaflet_id: null } })
    if (event === 'cancel') this.setState({ showImportFieldComponent: false })
    if (event === 'toggle')
      this.setState({ showImportFieldComponent: !this.state.showImportFieldComponent })
  }

  clearFieldSelection = () => {
    const { origin, pathname } = window.location
    if (window.location.search) {
      window.location.assign(`${origin}${pathname}`)
    }
  }

  render() {
    const acresSelectedAsNumber = eval(this.state.selectedAcres) // eslint-disable-line no-eval
    const handleSubmit = data => {
      if (!this.selectedPolygon) {
        swal({
          title: 'Error!',
          text: 'You must select or create a polygon in the map',
          icon: 'error',
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'OK',
          closeModal: true,
        })
      } else {
        this.handleFieldSubmit(data)
      }
    }
    const mapProps = {}

    const { farm } = this.props

    const hasFieldDataImported =
      this.state.importedFieldData && this.state.importedFieldData.length > 0
    mapProps.createField = true
    mapProps.setSelectedPolygon = this.setSelectedPolygon
    mapProps.fetchClus = this.handleFetchClus
    mapProps.fields = this.props.fields
    mapProps.uploadedShapes = this.state.uploadedShapes
    mapProps.displayDrawTool = true
    mapProps.props = this.props
    mapProps.importedFieldData = this.state.importedFieldData
    mapProps.hasFieldDataImported = hasFieldDataImported
    mapProps.showImportFieldComponent = this.state.showImportFieldComponent
    mapProps.passSelectedImportedFieldId = this.getSelectedImportedFieldId
    mapProps.passSelectedImportedFieldLayer = this.getSelectedImportedFieldLayer
    mapProps.selectedImportedFieldId = this.state.selectedImportedFieldId
    mapProps.selectedImportedFieldLayer = this.state.selectedImportedFieldLayer

    return (
      <div className='create-field'>
        <Helmet title='Create Field' />
        <div className='Sidebar'>
          <SidePanel>
            <ContextHeader text={farm.title} handleBackEvent={this.handleBackEvent} />
            <CreateFieldForm
              onSubmit={handleSubmit}
              acresSelected={acresSelectedAsNumber}
              setUploadedPolygon={this.setUploadedPolygon}
              toggleImportFieldComponent={this.toggleImportFieldComponent}
              showImportFieldComponent={this.state.showImportFieldComponent}
              updateImportedFieldData={data => this.setState({ importedFieldData: data })}
              importedFieldData={this.state.importedFieldData}
              fieldsImported={hasFieldDataImported}
              setSelectedPolygon={this.setSelectedPolygon}
              selectedImportedFieldId={this.state.selectedImportedFieldId}
              passSelectedImportedFieldId={this.getSelectedImportedFieldId}
              clearFieldSelection={this.clearFieldSelection}
            />
          </SidePanel>
        </div>
        <div className='Map'>
          <LegacyLeafletMap showLayerControls {...mapProps} />
        </div>
        {this.state.mapDisabled && <div className={`Map ${styles.disabled}`}></div>}
      </div>
    )
  }
}

CreateFieldComponent.propTypes = {
  farm: PropTypes.object,
  fields: PropTypes.array,
  fetchClus: PropTypes.func,
  pushState: PropTypes.func.isRequired,
  route: PropTypes.object,
  addField: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
}
export default asyncConnectWrapper(connectWrapper(CreateFieldComponent))
