import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Button } from 'react-bootstrap'
import { TableWithCustomData, TableRow } from '_shared'
import styles from '../ImageReviewer/ImageReviewer.module.scss'
import userAccessStyles from './UserAccess.module.scss'
import { UserAccessModal } from '../UserAccess/UserAccessModal'

export const ImageReviewerAccessComponent = ({
  customerAccounts,
  userAccessList,
  onCustomerAccountSelect,
  ...props
}) => {
  const customerAccountCells = customerAccount => {
    return (
      <div className={styles.row}>
        <div className={styles.cell}>
          <Button variant='link' onClick={() => displayAccessFor(customerAccount)}>
            {customerAccount.reviewerCount}
          </Button>
        </div>
      </div>
    )
  }

  const [showAccessModal, setAccessModal] = useState(false)
  const [resourceTitle, setResourceTitle] = useState(null)
  const [resourceId, setResourceId] = useState(null)

  const closeAccessModal = () => {
    setAccessModal(false)
  }

  const handleCloseConfirmation = () => {
    props.refreshUserAccess()

    setAccessModal(false)
  }

  const displayAccessFor = customerAccount => {
    onCustomerAccountSelect(customerAccount.id)
    setResourceTitle(customerAccount.name)
    setResourceId(customerAccount.id)
    setAccessModal(true)
  }

  return (
    <React.Fragment>
      <TableWithCustomData title='Image Reviewer Access'>
        <div className={`${userAccessStyles.tableHeaders} ${styles.headerSpacing}`}>
          <h2 className='h3'>Customer Account</h2>
          <h2 className='h3'># of Image Reviewers</h2>
        </div>
        {!customerAccounts.length ? (
          <span>No Customer Accounts with Image Reviewers</span>
        ) : (
          customerAccounts.map(customerAccount => (
            <TableRow
              key={customerAccount.id}
              tableCells={() => customerAccountCells(customerAccount)}
              primary
              title={
                <Button variant='link' onClick={() => displayAccessFor(customerAccount)}>
                  {customerAccount.name}
                </Button>
              }
              noBorder
              withHighlights
            />
          ))
        )}
      </TableWithCustomData>
      <UserAccessModal
        resourceTitle={resourceTitle}
        resourceType='CustomerAccount'
        resourceId={resourceId}
        userAccessList={userAccessList}
        handleCloseConfirmation={handleCloseConfirmation}
        show={showAccessModal}
        close={closeAccessModal}
        {...props}
      />
    </React.Fragment>
  )
}

export const ImageReviewerAccess = withRouter(ImageReviewerAccessComponent)

ImageReviewerAccess.propTypes = {
  customerAccounts: PropTypes.array.isRequired,
}
