import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { SectionButtonContainer } from './SectionButtonContainer'
import styles from './ModalSections.module.scss'

export const FindUserForm = ({
  emailAddresses,
  emailAddress,
  handleKeyUp,
  removeEmailAddress,
  handleInputChange,
  isInvalidEmailFormat,
  handleSubmit,
  cancel,
}) => {
  return (
    <div className={styles.findUserForm}>
      <label>
        User's Email
        <input
          type='email'
          value={emailAddress}
          onChange={handleInputChange}
          onKeyUp={handleKeyUp}
          name='email'
        />
        {isInvalidEmailFormat && <p className={styles.emailFormatError}>Invalid email format</p>}
      </label>
      <div className={styles.emailAddressPills}>
        {emailAddresses.map((email, index) => {
          return (
            <React.Fragment key={index}>
              <h3>
                {email}{' '}
                <span className={styles.removeEmailIcon} onClick={() => removeEmailAddress(email)}>
                  X
                </span>
              </h3>
            </React.Fragment>
          )
        })}
      </div>
      <SectionButtonContainer>
        <Button variant='outline-dark' onClick={cancel}>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='dark'
          onClick={handleSubmit}
          disabled={!emailAddress && emailAddresses.length === 0}
        >
          Find Users
        </Button>
      </SectionButtonContainer>
    </div>
  )
}

FindUserForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  emailAddresses: PropTypes.array,
  emailAddress: PropTypes.string,
  handleKeyUp: PropTypes.func.isRequired,
  removeEmailAddress: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}
