import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import ForgotPasswordForm from './ForgotPasswordForm'
import { resetPassword, clearAllErrors, clearError, clearPasswordSuccess } from 'redux/modules/auth'
import config from '../../config'
import styles from './ForgotPassword.module.scss'

/**
 * ForgotPassword
 * Request a password reset to the API for an email address.
 */
const connectWrapper = connect(
  state => ({
    resetSuccess: state.auth.resetSuccess,
    resetErrors: state.auth.errors,
    resettingPassword: state.auth.resettingPassword,
  }),
  {
    resetPassword,
    clearAllErrors,
    clearError,
    clearPasswordSuccess,
  }
)

class ForgotPasswordComponent extends Component {
  constructor() {
    super()
    // Make sure we have access to the correct this reference
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this)
  }

  componentWillUnmount() {
    this.props.clearAllErrors()
    this.props.clearPasswordSuccess()
  }

  handleSubmit = data => {
    this.props.resetPassword(data)
  }

  handleAlertDismiss(alertItem) {
    this.props.clearError(alertItem)
  }

  render() {
    const { resetSuccess, resetErrors, resettingPassword } = this.props
    const logoImage = require('assets/logo-aker-white.svg')
    const shouldDisableForm = typeof resetSuccess !== 'undefined' || resettingPassword || false

    return (
      <div className={styles.siteWrapper}>
        <Helmet title='Forgot password' />

        <div className={styles.passwordForgotWindow}>
          <div className={styles.passwordForgotWindowBody}>
            <img className={styles.logo} src={logoImage} alt={config.app.title} />

            <h2 className={styles.heading}>Forgot password?</h2>
            <p>Fill in your email address to reset your password.</p>
          </div>

          <ForgotPasswordForm
            onSubmit={this.handleSubmit}
            formSuccess={resetSuccess}
            formErrors={resetErrors}
            handleAlertDismiss={this.handleAlertDismiss}
            isDisabled={shouldDisableForm}
          />
        </div>
      </div>
    )
  }
}

export default connectWrapper(ForgotPasswordComponent)
