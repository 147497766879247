import React from 'react'
import PropTypes from 'prop-types'
import { FaArrowUp } from 'react-icons/fa'
import styles from '../AerialImageryReport.module.scss'

export function YieldAdvantage({ brandColor, yieldAdvantage }) {
  return (
    <React.Fragment>
      <h2 className={`h4 ${styles.advantageHeader}`}>Yield Advantage</h2>
      <div className={styles.advantageValueContainer}>
        <FaArrowUp
          className={styles.advantageArrow}
          style={{
            color: brandColor,
          }}
        />
        <div className={styles.advantageValue} style={{ color: brandColor }}>
          {yieldAdvantage ? `${yieldAdvantage} bu/acre` : 'N/A'}
        </div>
      </div>
    </React.Fragment>
  )
}

YieldAdvantage.propTypes = {
  brandColor: PropTypes.string.isRequired,
  yieldAdvantage: PropTypes.string,
}
