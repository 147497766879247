import { createValidator, required } from 'helpers'

/**
 * Applied rules to all possible form fields.
 *
 * @type {Object}   Field names and applieds rule
 */
const validationRules = {
  farmTitle: [required],
}

/**
 * Active fields are dynamicly added based on the the fields passed as arugment.
 * @param  {Array} fields List of fields active inside the form
 * @return {Object}       Object with invalid fields and the error message
 */
const editFarmFormValidation = createValidator(validationRules)

export default editFarmFormValidation
