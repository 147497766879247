import React from 'react'
import PropTypes from 'prop-types'
import { FindUserFormContainer } from './FindUserFormContainer'

export const FindUserSection = ({ cancel, onFindUsers }) => {
  return (
    <React.Fragment>
      <p>
        If this person has a user email under this customer account, they will be notified that they
        have been given access. Otherwise, an email will be sent inviting them to join Aker.
      </p>
      <FindUserFormContainer onSubmit={onFindUsers} cancel={cancel} />
    </React.Fragment>
  )
}

FindUserSection.propTypes = {
  cancel: PropTypes.func.isRequired,
}
