import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import { flatten } from 'helpers'
import { ProbeDataFlightToggle } from './ProbeDataFlightToggle'
import { ProbeDataByStressType } from './ProbeDataByStressType'
import styles from './ProbeData.module.scss'

export function ProbeDataByDate({ dateData, open, updateSelectedObservationDate, ...props }) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open, dateData])

  const handleClick = open => {
    updateSelectedObservationDate(open ? dateData.observedAt : null)
    setIsOpen(open)
  }

  function countOfStressItems() {
    return dateData.cropStressTypes.reduce((count, stressType) => {
      count += stressType.cropStressItems.length
      return count
    }, 0)
  }

  const className = classNames(styles.observationDateContainer, {
    [styles.open]: isOpen,
  })
  
  return (
    <div className={className}>
      <h3 className={`h5 ${styles.dateHeader}`}>
        {isOpen ? (
          <button onClick={() => handleClick(false)}>
            <FaChevronUp />
          </button>
        ) : (
          <button onClick={() => handleClick(true)}>
            <FaChevronDown />
          </button>
        )}
        {moment(dateData.observedAt).format('MM/DD/YYYY')}:{' '}
        <span className={styles.stressCount}>{countOfStressItems()} stresses found</span>
      </h3>
      {dateData.flights && dateData.flights.length > 0 && (
        <div className={styles.flightsContainer}>
          <h4 className='h5'>Aerial imagery</h4>
          <ProbeDataFlightToggle flights={dateData.flights} {...props} />
        </div>
      )}
      <div className={styles.stressTypesContainer}>
        <div className={styles.stressTypesHeader}>
          <h4 className='h5'>Stress categories</h4>
          {
            flatten(
              flatten(dateData.cropStressTypes.map(c => c.cropStressItems)).map(c => c.issues)
            ).length
          }{' '}
          total observations
        </div>
        {isOpen &&
          dateData.cropStressTypes.map(stressType => {
            return (
              <ProbeDataByStressType
                key={stressType.name}
                stressTypeData={stressType}
                active={isOpen}
                {...props}
              />
            )
          })}
      </div>
    </div>
  )
}

ProbeDataByDate.propTypes = {
  dateData: PropTypes.object,
  open: PropTypes.bool,
  updateSelectedObservationDate: PropTypes.func.isRequired,
}
