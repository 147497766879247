import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Helmet from 'react-helmet'
import { resetPassword } from 'redux/modules/auth'
import {
  isLoaded as isAccountLoaded,
  loadAccount,
  updateAccount,
  clearAllErrors,
  clearError,
  clearUpdateSuccess,
} from 'redux/modules/account'
import {
  clearError as clearErrorAuth,
  clearAllErrors as clearAllErrorsAuth,
} from 'redux/modules/auth'
import { clearPasswordSuccess } from 'redux/modules/auth'
import ContactForm from './ContactInfoForm/ContactForm'
import SideMenu from './SideMenu/SideMenu'
import { asyncConnect } from 'redux-connect'
import styles from './Account.module.scss'
import { pick } from 'lodash'

/**
 * Account
 * Show settings panel for an user based on its role and handle submit responses.
 */

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: ({ store: { dispatch, getState } }) => {
      const state = getState()
      const promises = []
      if (!isAccountLoaded(state)) {
        promises.push(dispatch(loadAccount(state.auth.user.id)))
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    user: state.auth.user,
    account: state.account.data,
    isUpdated: state.account.isUpdated,
    errorsAccount: state.account.errors,
    errorsAuth: state.auth.errors,
    resetSuccess: state.auth.resetSuccess,
    resetErrors: state.auth.resetError,
  }),
  {
    resetPassword,
    loadAccount,
    updateAccount,
    clearAllErrors,
    clearError,
    clearAllErrorsAuth,
    clearErrorAuth,
    clearUpdateSuccess,
    clearPasswordSuccess,
    pushState: push,
  }
)

class Account extends Component {
  // Prevents errors when logging out
  shouldComponentUpdate = nextProps => {
    return this.props.user && !nextProps.user ? false : true
  }

  componentWillUnmount = () => {
    this.props.clearAllErrors()
    this.props.clearAllErrorsAuth()
    this.props.clearUpdateSuccess()
    this.props.clearPasswordSuccess()
  }

  handleSubmit = data => {
    //TODO: only send the _changed_ data
    const accountData = pick(data, [
      'name',
      'address1',
      'address2',
      'city',
      'zipCode',
      'state',
      'phone',
      'mobile',
    ])
    this.props.updateAccount(this.props.user.id, accountData)
  }

  handleAlertDismiss = alertItem => {
    this.props.clearError(alertItem)
    this.props.clearErrorAuth(alertItem)
  }

  handleAlertSuccessDismiss = () => {
    this.props.clearUpdateSuccess()
  }

  handlePasswordReset = event => {
    event.preventDefault()
    this.props.resetPassword({ email: this.props.user.email })
  }

  returnForm = role => {
    const {
      account,
      isUpdated,
      resetSuccess,
      resetErrors,
      resettingPassword,
      errorsAccount,
      errorsAuth,
    } = this.props
    const errors = [...(errorsAuth || []), ...(errorsAccount || [])]

    return (
      <ContactForm
        account={account}
        onSubmit={this.handleSubmit}
        isUpdated={isUpdated}
        formErrors={errors}
        handleAlertDismiss={this.handleAlertDismiss}
        handleAlertSuccessDismiss={this.handleAlertSuccessDismiss}
        handlePasswordReset={this.handlePasswordReset}
        resetSuccess={resetSuccess}
        resetErrors={resetErrors}
        resettingInProgress={resettingPassword}
      />
    )
  }

  render() {
    const { user } = this.props
    const role = user && user.role

    return (
      <div className={styles.myAccount}>
        <Helmet title='My account' />
        <SideMenu user={user} />

        <div className={styles.content}>{this.returnForm(role)}</div>
      </div>
    )
  }
}

Account.propTypes = {
  user: PropTypes.object,
  account: PropTypes.object,
  loadAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  errorsAccount: PropTypes.array,
  errorsAuth: PropTypes.array,
  clearAllErrors: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  clearAllErrorsAuth: PropTypes.func.isRequired,
  clearErrorAuth: PropTypes.func.isRequired,
  clearUpdateSuccess: PropTypes.func.isRequired,
  clearPasswordSuccess: PropTypes.func.isRequired,

  resetPassword: PropTypes.func,
  resettingPassword: PropTypes.bool,
  resetSuccess: PropTypes.object,
  resetErrors: PropTypes.object,

  pushState: PropTypes.func.isRequired,
}

export default asyncConnectWrapper(connectWrapper(Account))
