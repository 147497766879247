import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Leaflet from 'leaflet'

function GeometryDrawer(map, geometry, fillColor = null, outlineColor = null) {
  if (!geometry) return

  const geoJson = Leaflet.geoJson(geometry, {
    color: outlineColor || 'white',
    fillColor: fillColor || 'none',
    fillOpacity: fillColor ? 0.6 : 0,
    weight: 2,
    opacity: 1,
    lineJoin: 'round',
  })
  map.addLayer(geoJson)
  return geoJson
}

function LabelDrawer(map, label, layer) {
  const labelMarker = Leaflet.marker(layer.getBounds().getCenter(), {
    icon: Leaflet.divIcon({
      clickable: false,
      keyboard: false,
      className: 'leaflet-text-label',
      // className: 'label',
      // html: `<span>${label}</span>`,
      html: `<div>${label}</div>`,
      iconSize: [28, 28],
    }),
  })

  map.addLayer(labelMarker)
  return labelMarker
}

export function GeometryLayer({ map, geometry, label, fillColor, outlineColor }) {
  useEffect(() => {
    if (!map) return

    let addedLayer = GeometryDrawer(map, geometry, fillColor, outlineColor)
    let addedLabel
    if (!!label) {
      addedLabel = LabelDrawer(map, label, addedLayer)
    }

    // Cleanup to run when component unmounts
    return function cleanup() {
      try {
        map.removeLayer(addedLayer)
        if (!!label) {
          map.removeLayer(addedLabel)
        }
      } catch {}
    }
  }, [map, geometry]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

GeometryLayer.propTypes = {
  map: PropTypes.object,
  geometry: PropTypes.object,
  label: PropTypes.string,
  fillColor: PropTypes.string,
  outlineColor: PropTypes.string,
}
