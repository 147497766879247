import React from 'react'
import PropTypes from 'prop-types'
import styles from './Form.module.scss'

export function FileInput({ name, label, inputRef, onChange }) {
  return (
    <div className={styles.formInput}>
      <label htmlFor={name}>{label}</label>
      <input name={name} id={name} type='file' ref={inputRef} onChange={onChange} />
    </div>
  )
}

FileInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.object,
  onChange: PropTypes.func,
}
