import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { Dropdown } from '_shared'
import { SectionButtonContainer } from './SectionButtonContainer'
import styles from './ModalSections.module.scss'

export const UsersToInviteList = ({
  cancel,
  emailAddresses,
  roleOptions,
  allRolesSet,
  getUserRole,
  onRoleDropdownChange,
  handleUsersToInvite,
}) => {
  return (
    <div className={styles.Container}>
      <ul className={styles.usersToInviteList}>
        {emailAddresses.map((emailAddress, index) => {
          return (
            <li key={index}>
              <span>{emailAddress}</span>
              {roleOptions && (
                <Dropdown
                  placeholder='Select User Role'
                  className={styles.userRoleDropdown}
                  options={roleOptions}
                  onChange={selectedOption =>
                    onRoleDropdownChange(selectedOption, index, emailAddress)
                  }
                  value={getUserRole(emailAddress)}
                  inputId={`userRoleDropdown${index}`}
                />
              )}
            </li>
          )
        })}
      </ul>
      <SectionButtonContainer>
        <Button variant='outline-dark' onClick={cancel}>
          Cancel
        </Button>
        <Button disabled={!allRolesSet} variant='dark' onClick={() => handleUsersToInvite()}>
          Send
        </Button>
      </SectionButtonContainer>
    </div>
  )
}

UsersToInviteList.propTypes = {
  cancel: PropTypes.func.isRequired,
  emailAddresses: PropTypes.array.isRequired,
  roleOptions: PropTypes.array,
  getUserRole: PropTypes.func.isRequired,
  onRoleDropdownChange: PropTypes.func.isRequired,
  handleUsersToInvite: PropTypes.func.isRequired,
  allRolesSet: PropTypes.bool.isRequired,
}
