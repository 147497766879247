import React from 'react'
import PropTypes from 'prop-types'
import styles from './Form.module.scss'

export function TextAreaInput({ name, label, value, onChange, placeholder, className }) {
  return (
    <div className={`${styles.formInput} ${className}`}>
      <label htmlFor={name}>{label}</label>
      <textarea id={name} name={name} value={value} placeholder={placeholder} onChange={onChange} />
    </div>
  )
}

TextAreaInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
}
