import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import styles from './FarmFieldAccess.module.scss'

export const FarmFieldAccessSearch = ({ handleInputChange, handleKeyUp, handleSearchClick }) => {
  return (
    <div className={styles.searchBox}>
      <label>Search for Farm or Field</label>
      <div className={styles.searchBoxInput}>
        <input
          onKeyUp={handleKeyUp}
          onChange={handleInputChange}
          type='text'
          placeholder='Farm or Field'
        />
        <Button variant='dark' onClick={handleSearchClick}>
          Search
        </Button>
      </div>
    </div>
  )
}

FarmFieldAccessSearch.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleKeyUp: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
}
