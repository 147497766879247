import { useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Leaflet from 'leaflet'
import { debounce } from 'lodash'
import { compact } from 'helpers'
import { OverlappingPinSpiderfierContext } from './OverlappingPinSpiderfierContext'

export function PinRemover(pins, map) {
  pins.forEach(pin => {
    try {
      map.removeLayer(pin.marker)
    } catch (err) {
      console.log(`Error: Could not remove pin at [${pin.lat}, ${pin.lng}]`, err)
    }
  })
}

export function PinPlacer(pinLocations, hoveredMapPinId, map, overlappingMarkerSpiderfier) {
  return compact(
    pinLocations.map((pin, index) => {
      if (!pin.lat && !pin.lng) {
        console.log('Error: Could not place pin, missing lat or lng')
        return null
      }

      if (!pin.active) return null

      const latLng = Leaflet.latLng(pin.lat, pin.lng)
      const pinMarker = Leaflet.marker(latLng, {
        clickable: true,
        keyboard: false,
        riseOnHover: true,
        icon: Leaflet.divIcon({
          className: 'leaflet-issue-icon',
          html: `<div class='pinWrapper'><div class='pinLabel'>${pin.pinNumber ||
            index + 1}</div><div class='pin ${
            hoveredMapPinId === pin.id ? 'hovered' : ''
          }'></div></div>`,
          iconSize: [30, 30],
        }),
      })
      if (pin.onClick) {
        pinMarker.on('click', () => {
          pin.onClick()
        })
      }

      pinMarker.addTo(map)
      if (overlappingMarkerSpiderfier) {
        overlappingMarkerSpiderfier.addMarker(pinMarker)
      }
      return { id: pin.id, lat: pin.lat, lng: pin.lng, marker: pinMarker }
    })
  )
}

export function PopupBinder(
  pinLocations,
  placedPins,
  popupContent,
  hoveredMapPinId,
  updateHoveredMapPinId,
  overlappingMarkerSpiderfier
) {
  function createPopup(pin) {
    const popUpContainer = document.createElement('div', { class: 'pinMarkerPopup' })
    ReactDOM.render(popupContent(pin), popUpContainer)
    return popUpContainer
  }

  if (!popupContent) return

  // need to iterate over all provided pin locations to ensure index matches expected index
  pinLocations.forEach((pin, index) => {
    const placedPin = placedPins.find(pp => pp.id === pin.id)
    if (!placedPin) return

    const pinMarker = placedPin.marker

    const popup = pinMarker.bindPopup(createPopup(pin, index + 1), {
      offset: new Leaflet.Point(10, -20),
    })

    pinMarker.addEventListener(
      'mouseover',
      debounce(function(e) {
        updateHoveredMapPinId(pin.id, true)
      }, 100)
    )

    popup.on('popupclose', function(e) {
      updateHoveredMapPinId(pin.id, false)
    })

    // pinMarker.addEventListener(
    //   'mouseout',
    //   debounce(function(e) {
    //     updateHoveredMapPinId(pin.id, false)
    //   }, 100)
    // )

    if (hoveredMapPinId === pin.id) {
      if (overlappingMarkerSpiderfier) {
        overlappingMarkerSpiderfier.unspiderfy()
      }
      popup.openPopup()
      pinMarker.setZIndexOffset(100)
    }
  })
}

export function MapPinsLayer({
  pinLocations,
  map,
  popupContent,
  hoveredMapPinId,
  updateHoveredMapPinId,
}) {
  const [currentPins, setCurrentPins] = useState([])
  const overlappingMarkerSpiderfier = useContext(OverlappingPinSpiderfierContext)

  useEffect(() => {
    if (!map) return

    PinRemover(currentPins, map)
    let newlyPlacedPins = PinPlacer(pinLocations, hoveredMapPinId, map, overlappingMarkerSpiderfier)
    PopupBinder(
      pinLocations,
      newlyPlacedPins,
      popupContent,
      hoveredMapPinId,
      updateHoveredMapPinId,
      overlappingMarkerSpiderfier
    )
    setCurrentPins(newlyPlacedPins)

    // Cleanup to run when component unmounts
    return function cleanup() {
      PinRemover(newlyPlacedPins, map)
    }
  }, [pinLocations, hoveredMapPinId, overlappingMarkerSpiderfier]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

MapPinsLayer.propTypes = {
  pinLocations: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  map: PropTypes.object,
  popupContent: PropTypes.func,
}
