import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import moment from 'moment'
import { LeafletMap } from 'domains'
import { ReportContactInfo, TreatmentDetails, TreatmentDetailsMap } from '../_shared'
import { ObservationDetail } from './ObservationDetail'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportProbeObservation({
  season,
  regions,
  flights,
  brandColor,
  observations,
  brandLogo,
  title,
  footnote,
  crop,
  seedType,
  location,
  applicationDate,
  contactName,
  contactPhone,
  contactEmail,
  observationDate,
}) {
  const pins = observations.reduce((pinLocations, obs) => {
    if (!pinLocations.find(p => p.lat === obs.lat && p.lng === obs.lng)) {
      pinLocations.push({
        lat: obs.lat,
        lng: obs.lng,
        id: pinLocations.length,
        pinNumber: pinLocations.length + 1,
      })
    }
    return pinLocations
  }, [])

  const daysSinceApplication = () => {
    if (!flights || flights.length === 0) return null

    const recentFlightDate = moment.utc(flights[0].dateFlown)
    let days

    if (applicationDate) {
      const applDate = moment.utc(applicationDate)
      days = Math.floor(moment.duration(recentFlightDate.diff(applDate)).asDays())
    }
    return days && days > 0 ? days.toString() : null
  }

  const daysAfterApplication = daysSinceApplication()

  return (
    <React.Fragment>
      <Helmet title='View TrueCause Report' />
      <section
        className={`${styles.aerialImageryReport} ${styles.print} ${styles.aerialImageryReportProbeObservation}`}
        style={{
          backgroundColor: brandColor,
        }}
      >
        <div className={`row ${styles.logoRow}`}>
          <div className='col-8'>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <img className={styles.brandLogo} alt='Company Logo' src={brandLogo} />
        </div>

        <div className={`row ${styles.white} ${styles.aerialImageAndFieldDetailsContainer}`}>
          <div className='col-8'>
            <div className={styles.aerialImageryMap}>
              <LeafletMap
                hideLayerControls
                hideTransparencySlider
                hideZoomControls
                zoomDisabled
                panDisabled
                bounds={season ? season.field.bounds : []}
                boundaryGeometry={season ? season.field.geometry : {}}
                innerGeometries={
                  regions && regions.map(r => ({ id: r.id, geometry: r.geometry, display: true }))
                }
                innerGeometriesOutlineOnly
                innerGeometriesOutlineColor='#000'
                activeImage={flights[0]}
                pins={pins}
              />
            </div>
          </div>
          <div className={`col-4 ${styles.aerialImagerySidebar}`}>
            <div>
              {season && season.field && (
                <div className={styles.fieldDetails}>
                  Field Name: <br />
                  <strong>{season.field.title}</strong>
                </div>
              )}
              {observationDate && (
                <div className={styles.fieldDetails}>
                  Date: <br />
                  <strong>{observationDate}</strong>
                </div>
              )}
              {daysAfterApplication && (
                <div className={styles.fieldDetails}>
                  Days After Application: <br />
                  <strong>{daysAfterApplication}</strong>
                </div>
              )}
              {location && (
                <div className={styles.fieldDetails}>
                  Location: <br />
                  <strong>{location}</strong>
                </div>
              )}
              {crop && (
                <div className={styles.fieldDetails}>
                  Crop: <br />
                  <strong>{crop}</strong>
                </div>
              )}
              {seedType && (
                <div className={styles.fieldDetails}>
                  Hybrid: <br />
                  <strong>{seedType}</strong>
                </div>
              )}
              {applicationDate && (
                <div className={styles.fieldDetails}>
                  Treatment Application Date: <br />
                  <strong>{applicationDate}</strong>
                </div>
              )}
            </div>
            <div>
              <TreatmentDetailsMap regions={regions} season={season} />
              {regions && regions.length > 0 && <div>Competitive Treatment Regions</div>}
              <TreatmentDetails regions={regions} />
            </div>
          </div>
        </div>

        {observations && observations.length > 0 && (
          <div className={`${styles.white} ${styles.observationsContainer}`}>
            <h2 className='h3'>TrueCause Observations</h2>
            <div className={styles.observationDetailsContainer}>
              {observations.map((o, index) => (
                <ObservationDetail pins={pins} observation={o} />
              ))}
            </div>
          </div>
        )}

        <div className={`row ${styles.logoRow}`}>
          <ReportContactInfo
            header='Questions?'
            contactName={contactName}
            contactPhone={contactPhone}
            contactEmail={contactEmail}
          />
          <div>
            <img className={styles.brandLogo} alt='Company Logo' src={brandLogo} />
          </div>
        </div>
        {footnote && <div className={styles.footer}>{footnote}</div>}
      </section>
    </React.Fragment>
  )
}

AerialImageryReportProbeObservation.propTypes = {
  season: PropTypes.object.isRequired,
  regions: PropTypes.array,
  flights: PropTypes.array.isRequired,
  brandLogo: PropTypes.string.isRequired,
  title: PropTypes.string,
  footnote: PropTypes.string.isRequired,
  crop: PropTypes.string.isRequired,
  seedType: PropTypes.string,
  location: PropTypes.string,
  applicationDate: PropTypes.string,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  contactEmail: PropTypes.string,
  observationDate: PropTypes.string,
}
