const GET_REPORTS = 'aker/REPORTS/GET_REPORTS'
const GET_REPORTS_SUCCESS = 'aker/REPORTS/GET_REPORTS_SUCCESS'
const GET_REPORTS_FAIL = 'aker/REPORTS/GET_REPORTS_FAIL'

const GET_REPORTS_BY_SEASON = 'aker/REPORTS/GET_REPORTS_BY_SEASON'
const GET_REPORTS_BY_SEASON_SUCCESS = 'aker/REPORTS/GET_REPORTS_BY_SEASON_SUCCESS'
const GET_REPORTS_BY_SEASON_FAIL = 'aker/REPORTS/GET_REPORTS_BY_SEASON_FAIL'

const GET_REPORTS_BY_CUSTOMER_ACCOUNT = 'aker/REPORTS/GET_REPORTS_BY_CUSTOMER_ACCOUNT'
const GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS =
  'aker/REPORTS/GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS'
const GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL = 'aker/REPORTS/GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL'

const GET_REPORT = 'aker/REPORTS/GET_REPORT'
const GET_REPORT_SUCCESS = 'aker/REPORTS/GET_REPORT_SUCCESS'
const GET_REPORT_FAIL = 'aker/REPORTS/GET_REPORT_FAIL'

const DELETE_REPORT = 'aker/REPORTS/DELETE_REPORT'
const DELETE_REPORT_SUCCESS = 'aker/REPORTS/DELETE_REPORT_SUCCESS'
const DELETE_REPORT_FAIL = 'aker/REPORTS/DELETE_REPORT_FAIL'

const initialState = {
  reports: {
    loaded: false,
  },
  reportsBySeason: {
    loaded: false,
  },
  reportsByCustomerAccount: {
    loaded: false,
  },
  report: {
    loaded: false,
  },
}

export default function reports(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORTS_FAIL:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_REPORTS_BY_SEASON:
      return {
        ...state,
        reportsBySeason: {
          ...state.reportsBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORTS_BY_SEASON_SUCCESS:
      return {
        ...state,
        reportsBySeason: {
          ...state.reportsBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORTS_BY_SEASON_FAIL:
      return {
        ...state,
        reportsBySeason: {
          ...state.reportsBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_REPORTS_BY_CUSTOMER_ACCOUNT:
      return {
        ...state,
        reportsByCustomerAccount: {
          ...state.reportsByCustomerAccount,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        reportsByCustomerAccount: {
          ...state.reportsByCustomerAccount,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL:
      return {
        ...state,
        reportsByCustomerAccount: {
          ...state.reportsByCustomerAccount,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case DELETE_REPORT:
      return {
        ...state,
        report: {
          loading: true,
        },
      }
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          loading: false,
          loaded: true,
          errors: [],
          data: null,
        },
      }
    case DELETE_REPORT_FAIL:
      return {
        ...state,
        report: {
          loading: false,
          loaded: false,
          errors: action.error.errors,
        },
      }

    default:
      return state
  }
}

export function isReportsLoaded(globalState, farmId) {
  return (
    globalState.reports &&
    globalState.reports.reports &&
    globalState.reports.reports.loaded &&
    globalState.reports.reports.data.every(field => field.farm.id === farmId)
  )
}

export function getReports() {
  return {
    types: [GET_REPORTS, GET_REPORTS_SUCCESS, GET_REPORTS_FAIL],
    promise: client => client.get(`/legacy_reports`),
  }
}

export function isReportsBySeasonLoaded(globalState, seasonId) {
  return (
    globalState.reports &&
    globalState.reports.reportsBySeason &&
    globalState.reports.reportsBySeason.loaded &&
    globalState.reports.reportsBySeason.data.every(report => report.season.id === seasonId)
  )
}

export function getReportsBySeasonId(seasonId) {
  return {
    types: [GET_REPORTS_BY_SEASON, GET_REPORTS_BY_SEASON_SUCCESS, GET_REPORTS_BY_SEASON_FAIL],
    promise: client => client.get(`/seasons/${seasonId}/legacy_reports`),
  }
}

export function getReportsByCustomerAccount(customerAccountId) {
  let promise
  if (!!customerAccountId) {
    promise = client =>
      client.get(`/customer_accounts/${customerAccountId}/legacy_reports_by_season`)
  } else {
    promise = client => client.get(`/legacy_reports/by_season`)
  }

  return {
    types: [
      GET_REPORTS_BY_CUSTOMER_ACCOUNT,
      GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS,
      GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL,
    ],
    promise,
  }
}

export function isReportLoaded(globalState, reportId) {
  return (
    globalState.reports &&
    globalState.reports.report &&
    globalState.reports.report.loaded &&
    (reportId ? globalState.reports.report.data.id === parseInt(reportId, 10) : true)
  )
}

export function getReport(reportId) {
  return {
    types: [GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAIL],
    promise: client => client.get(`/reports/${reportId}`),
  }
}

export function deleteReport(reportId) {
  return {
    types: [DELETE_REPORT, DELETE_REPORT_SUCCESS, DELETE_REPORT_FAIL],
    promise: client => client.del(`/reports/${reportId}`),
  }
}
