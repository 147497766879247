import React from 'react'
import { withRouter, Link } from 'react-router'
import PropTypes from 'prop-types'
import {
  Breadcrumbs,
  ListContainer,
  ListTitleHeader,
  ListSection,
  ListSectionHeader,
  ListItemsContainer,
  EmptyListItems,
  AddButton,
} from '_shared'
import { FieldListItem } from './FieldListItem'
import styles from './FieldList.module.scss'

function FieldListComponent(props) {
  const { fields, farm } = props
  const { permissions } = farm
  const sortedFields =
    fields &&
    fields.sort((first, second) => {
      return first.title && first.title.localeCompare(second.title)
    })

  const breadcrumbs = [{ to: '/farms', text: 'All Farms' }, { text: farm.title }]

  const renderListHeader = () => {
    return (
      <ListTitleHeader
        title={null}
        selectedSection={false}
        className={styles.fieldListTitleHeaderContainer}
      >
        <div>
          <h1>{farm.title}</h1>
        </div>
        {permissions.canManage && (
          <div>
            <Link to={`/farm/${farm.id}/edit`} className='btn btn-default'>
              Edit farm
            </Link>
          </div>
        )}
      </ListTitleHeader>
    )
  }

  const renderListSection = () => {
    return (
      <ListSection>
        <ListSectionHeader header='Fields' className={styles.fieldsSectionHeaderContainer}>
          {permissions.canManage && (
            <AddButton to={`/farm/${farm.id}/field/create`} buttonText='Add New Field' />
          )}
        </ListSectionHeader>
        <ListItemsContainer key='fields-items' itemType='fields'>
          {sortedFields && sortedFields.length ? (
            sortedFields.map(field => <FieldListItem field={field} key={field.id} />)
          ) : (
            <EmptyListItems itemType='fields' />
          )}
        </ListItemsContainer>
      </ListSection>
    )
  }

  const renderFieldList = () => {
    return (
      <React.Fragment>
        <Breadcrumbs items={breadcrumbs} />

        {renderListHeader()}
        {renderListSection()}
      </React.Fragment>
    )
  }

  return <ListContainer key='fields'>{renderFieldList()}</ListContainer>
}

export const FieldList = withRouter(FieldListComponent)

FieldList.propTypes = {
  fields: PropTypes.array.isRequired,
  farm: PropTypes.object.isRequired,
}
