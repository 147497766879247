import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Form, Button, Alert, Row } from 'react-bootstrap'
import { WrappedInputField } from '../_shared'
import { email, required, match, minLength } from 'helpers'
import styles from './AccountSetupForm.module.scss'

/**
 * AccountSetupForm
 * Form for setting up an account, fields, validation and initialisation are done
 * automatically.
 */

class AccountSetupFormComponent extends Component {
  state = {
    dataUri: null,
    imgSrc: this.props.initialValues.companyLogo,
  }

  //Custom validation for grower fields.
  notificationValidator = (value, data) => {
    if (!data.notificationPreference) {
      if (!value) {
        return 'Required'
      } else {
        return undefined
      }
    }
  }

  updateImageSource = files => {
    const reader = new FileReader()
    const url = reader.readAsDataURL(files[0]) // eslint-disable-line no-unused-vars
    reader.onloadend = () => {
      this.setState({
        imgSrc: reader.result,
      })
    }
  }

  stepFromFormStep = () => {
    const { formStep } = this.props
    const stepSubstring = formStep.substring(6, formStep.lastIndexOf('/'))
    return eval(stepSubstring) // eslint-disable-line no-eval
  }

  descriptionForStep = () => {
    const { invitedBy } = this.props.initialValues
    const stepIndex = this.stepFromFormStep() - 1
    const stepDescriptions = [
      `You have been invited by ${invitedBy ||
        'a customer'} to setup your Aker account. Please provide some additional details below`,
      'Please verify your address and phone number.',
    ]
    return stepDescriptions[stepIndex] || null
  }

  renderEssentialFields = () => {
    const { role } = this.props.initialValues

    return (
      <div>
        <Row>
          <WrappedInputField
            name={'name'}
            title={'Name'}
            placeholder={''}
            columnWidth={12}
            type={'text'}
            required={true}
            validate={required}
            disabled={false}
            horizontal={true}
          />
        </Row>
        {role === 'Grower' && (
          <Row>
            <WrappedInputField
              name={'farmName'}
              title={'Farm Name'}
              placeholder={''}
              columnWidth={12}
              type={'text'}
              required={true}
              validate={required}
              disabled={false}
              horizontal={true}
            />
          </Row>
        )}
        <Row>
          <WrappedInputField
            name={'email'}
            placeholder={'Email address'}
            title={'Email address'}
            columnWidth={12}
            type={'email'}
            required={true}
            validate={[email, required]}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'password'}
            type={'password'}
            title={'Password'}
            placeholder={''}
            columnWidth={12}
            required={true}
            validate={[required, minLength(8)]}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'passwordConfirmation'}
            type={'password'}
            title={'Confirm Password'}
            placeholder={''}
            columnWidth={12}
            required={true}
            validate={[required, match('password')]}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <div className='col-4' />
          <div className={styles.helpText}>Password needs to be at least 8 characters.</div>
        </Row>
        {role === 'Grower' && (
          <Row>
            <WrappedInputField
              name={'phone'}
              title={'Phone number'}
              placeholder={''}
              columnWidth={12}
              type={'tel'}
              required={true}
              validate={required}
              disabled={false}
              horizontal={true}
            />
          </Row>
        )}
      </div>
    )
  }

  renderAddressFields = () => {
    return (
      <div>
        <Row>
          <WrappedInputField
            columnWidth={12}
            name={'address1'}
            title={'Address'}
            placeholder={''}
            type={'text'}
            required={false}
            validate={required}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'address2'}
            placeholder={'Optional'}
            title={''}
            columnWidth={12}
            type={'text'}
            required={false}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'city'}
            placeholder={''}
            title={'Town / City'}
            columnWidth={12}
            type={'text'}
            required={true}
            validate={required}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'zipCode'}
            columnWidth={12}
            title={'ZIP Code'}
            type={'text'}
            placeholder={''}
            required={true}
            validate={required}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'state'}
            title={'State'}
            placeholder={''}
            columnWidth={12}
            type={'text'}
            required={true}
            validate={required}
            disabled={false}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'phone'}
            title={'Phone number'}
            placeholder={''}
            columnWidth={12}
            required={true}
            type={'tel'}
            disabled={false}
            validate={required}
            horizontal={true}
          />
        </Row>
        <Row>
          <WrappedInputField
            name={'mobile'}
            title={'Mobile'}
            placeholder={'Optional'}
            columnWidth={12}
            type={'tel'}
            required={false}
            disabled={false}
            horizontal={true}
          />
        </Row>
      </div>
    )
  }

  renderNotificationFields = () => {
    return (
      <div>
        <Row>
          <Form.Group>
            {/* TODO: Make this required */}
            <Form.Label>
              How would you like to receive notifications of new reports, scouting progress, etc?
            </Form.Label>
            <fieldset>
              <div className={styles.RadioInputWrapper}>
                <label>
                  <Field
                    name='notificationPreference'
                    component='input'
                    type='radio'
                    value='email'
                    validate={this.notificationValidator}
                  />{' '}
                  Email
                </label>
              </div>
              <div className={styles.RadioInputWrapper}>
                <label>
                  <Field
                    name='notificationPreference'
                    component='input'
                    type='radio'
                    value='textMessages'
                    validate={this.notificationValidator}
                  />{' '}
                  Text Messages
                </label>
              </div>
              <div className={styles.RadioInputWrapper}>
                <label>
                  <Field
                    name='notificationPreference'
                    component='input'
                    type='radio'
                    value='none'
                    validate={this.notificationValidator}
                  />{' '}
                  Do not send me notifications
                </label>
              </div>
            </fieldset>
          </Form.Group>
        </Row>
      </div>
    )
  }

  renderFormStepFields = () => {
    const step = this.stepFromFormStep()
    switch (step) {
      case 1:
        return this.renderEssentialFields()
      case 2:
        return this.renderAddressFields()
      // case 3:
      //   return this.renderNotificationFields()
      default:
        return null
    }
  }

  render() {
    const rightArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#ffffff' strokeMiterlimit='10' points='2,2 7.5,7.5 2,13  ' />
      </svg>
    )
    const leftArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#ffffff' strokeMiterlimit='10' points='7,13 1.5,7.5 7,2  ' />
      </svg>
    )
    const {
      title,
      formStep,
      handleSubmit,
      formErrors,
      previousStep,
      showPreviousStep,
      nextButtonLabel,
    } = this.props

    return (
      <form className='form-horizontal form-inverse' onSubmit={handleSubmit}>
        {title && (
          <h2 className={styles.heading}>
            {title} {formStep && <span className={styles.headingLight}>{formStep}</span>}
          </h2>
        )}

        {formErrors &&
          formErrors.map(error => (
            <Alert key={error.code} variant='danger'>
              {error.message}
            </Alert>
          ))}

        <p>{this.descriptionForStep()}</p>
        <div>{this.renderFormStepFields()}</div>

        <div className={styles.buttonRow}>
          <div className={styles.buttonAreaLeft}>
            {showPreviousStep && (
              <Button variant='tertiary' onClick={previousStep}>
                <span className='icon-align-left'>{leftArrow}</span>Previous
              </Button>
            )}
          </div>
          <div className={styles.buttonAreaRight}>
            <Button type='submit' variant='primary'>
              {nextButtonLabel}
              <span className='icon-align-right'>{nextButtonLabel === 'Next' && rightArrow}</span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

AccountSetupFormComponent.propTypes = {
  title: PropTypes.string,
  formStep: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.array,
  previousStep: PropTypes.func.isRequired,
  showPreviousStep: PropTypes.bool.isRequired,
  nextButtonLabel: PropTypes.string.isRequired,
}

export default reduxForm({
  form: 'AccountSetupForm',
  destroyOnUnmount: false,
  touchOnBlur: false,
})(AccountSetupFormComponent)
