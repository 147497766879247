import React from 'react'
import PropTypes from 'prop-types'
import styles from './ImageReviewer.module.scss'

export function MeasurementDisplay({ label, value }) {
  return (
    <div className={styles.environmentalMeasurement}>
      <span className={styles.environmentalMeasurementLabel}>{label}</span>
      {value}
    </div>
  )
}

export function ImageMetadata({ task, notes, temperature, humidity, carbonDioxide, date }) {
  const { workOrderId, fieldName } = task
  return (
    <React.Fragment>
      <h1 className='h3'>Work Order: {workOrderId}</h1>
      <h2 className='h4'>Field: {fieldName}</h2>
      <div className={styles.environmentalData}>
        <MeasurementDisplay label='Date:' value={date ? ` ${date}` : ' N/A'} />
        <MeasurementDisplay
          label='Temp:'
          value={
            <React.Fragment>{temperature ? `${temperature.toFixed(2)}°` : 'N/A'}</React.Fragment>
          }
        />
        <MeasurementDisplay label='Humidity:' value={humidity ? humidity.toFixed(2) : 'N/A'} />
        <MeasurementDisplay
          label={
            <React.Fragment>
              CO<sub>2</sub>:
            </React.Fragment>
          }
          value={carbonDioxide ? ` ${carbonDioxide}ppm` : ' N/A'}
        />
      </div>
      {notes && (
        <div className={styles.notes}>
          <span className={styles.notesLabel}>Notes: </span>
          {notes}
        </div>
      )}
    </React.Fragment>
  )
}

ImageMetadata.propTypes = {
  task: PropTypes.object,
  notes: PropTypes.string,
  temperature: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  humidity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  carbonDioxide: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
}
