import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Leaflet from 'leaflet'
import { TransparencySlider } from './TransparencySlider'
import { ZoomControls } from './ZoomControls'
import { DrawingControls } from './DrawingControls'
import { MapLegend } from './MapLegend'
import { InnerGeometriesLayer } from './InnerGeometriesLayer'
import { FieldBoundaryLayer } from './FieldBoundaryLayer'
import { NoImageAvailableForField } from './NoImageAvailableForField'
import { SoilsLayer } from './SoilsLayer'
import { SatelliteLayer } from './SatelliteLayer'
import { StreetsLayer } from './StreetsLayer'
import { StressItemsLayer } from './StressItemsLayer'
import { ProbeCyclesLayer } from './ProbeCyclesLayer'
import { ImageLayer } from './ImageLayer'
import { LayerControls } from './LayerControls'
import { LegendLayerControls } from './LegendLayerControls'
import { BaseMapLayerControls } from './BaseMapLayerControls'
import { SoilsLayerControls } from './SoilsLayerControls'
import { DownloadMapControls } from './DownloadMapControls'
import { MapBounds } from './MapBounds'
import { MapPinsLayer } from './MapPinsLayer'
import { PinsForBoundaries } from './PinsForBoundaries'
import styles from './LeafletMap.module.scss'

export default function LeafletMap({
  hideLayerControls,
  hideTransparencySlider,
  hideZoomControls,
  zoomDisabled,
  panDisabled,
  hideLegend,
  hideBaseLayers,
  bounds,
  boundaryGeometry,
  boundaryOutlineColor,
  activeImage,
  drawingEnabled,
  geometryToEdit,
  setDrawnGeometry,
  pins,
  boundariesForPinMarkers,
  innerGeometries,
  innerGeometriesOutlineOnly,
  innerGeometriesOutlineColor,
  stressItems,
  probeCycles,
  hoveredMapPinId,
  updateHoveredMapPinId,
}) {
  const [showLayerControls] = useState(!hideLayerControls)
  const [showTransparencySlider] = useState(!hideTransparencySlider)
  const [showZoomControls] = useState(!hideZoomControls)
  const [showLegend] = useState(!hideLegend)
  const [showBaseLayers] = useState(!hideBaseLayers)

  const [layersTransparency, setLayersTransparency] = useState(100)
  const [legendEnabled, setLegendEnabled] = useState(true)
  const [soilsEnabled, setSoilsEnabled] = useState(false)
  const [baseMapsEnabled, setBaseMapsEnabled] = useState({
    streets: false,
    satellite: showBaseLayers,
  })
  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)

  useEffect(() => {
    const leafletMap = Leaflet.map(mapContainer.current, {
      zoomControl: false,
      attributionControl: false,
    })
      .setView([37.8, -96], 4)

    if (zoomDisabled) {
      leafletMap.touchZoom.disable()
      leafletMap.doubleClickZoom.disable()
      leafletMap.scrollWheelZoom.disable()
      leafletMap.boxZoom.disable()
    }

    if (panDisabled) {
      leafletMap.keyboard.disable()
      leafletMap.dragging.disable()
    }
    setMap(leafletMap)

    // Timeout needed for correct display in debug mode for some unknown reason
    // https://github.com/Leaflet/Leaflet/issues/941
    setTimeout(() => {
      leafletMap.invalidateSize()
    }, 10)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const setBaseMapsLayerEnabled = mapKey => {
    let newState = { ...baseMapsEnabled }
    Object.keys(newState).forEach(key => (newState[key] = false))
    newState[mapKey] = true
    setBaseMapsEnabled(newState)
  }

  return (
    <React.Fragment>
      <div
        className={`${styles.LeafletMap} ${showTransparencySlider ? styles.LeafletMapWithFooter : ''
          }`}
      >
        <div className={styles.LeafletMapInstance} ref={mapContainer} />
        {showZoomControls && <ZoomControls map={map} />}

        <DrawingControls
          map={map}
          drawingEnabled={drawingEnabled}
          setDrawnGeometry={setDrawnGeometry}
          geometryToEdit={geometryToEdit}
        />
        <MapLegend legendEnabled={showLegend && legendEnabled} activeImage={activeImage} />
        {bounds && bounds.length === 0 && <NoImageAvailableForField />}
        <MapBounds map={map} boundaryBounds={bounds} pinBounds={boundariesForPinMarkers} />
        <FieldBoundaryLayer
          map={map}
          geometry={boundaryGeometry}
          outlineColor={boundaryOutlineColor}
        />
        <InnerGeometriesLayer
          map={map}
          geometries={innerGeometries}
          outlineOnly={innerGeometriesOutlineOnly}
          outlineColor={innerGeometriesOutlineColor}
        />
        <SoilsLayer map={map} soilsEnabled={soilsEnabled} />
        <StreetsLayer map={map} streetsEnabled={baseMapsEnabled.streets} />
        <SatelliteLayer map={map} satelliteEnabled={baseMapsEnabled.satellite} />
        <ImageLayer
          map={map}
          activeImage={activeImage}
          bounds={bounds}
          layersTransparency={layersTransparency}
        />
        {pins && pins.length > 0 && (
          <MapPinsLayer
            map={map}
            pinLocations={[...new Set(pins)].map(p => ({ ...p, active: true }))}
          />
        )}
        {boundariesForPinMarkers && boundariesForPinMarkers.length > 0 && (
          <PinsForBoundaries boundaries={boundariesForPinMarkers} map={map} />
        )}
        {stressItems && stressItems.length > 0 && (
          <StressItemsLayer
            stressItems={stressItems}
            map={map}
            hoveredMapPinId={hoveredMapPinId}
            updateHoveredMapPinId={updateHoveredMapPinId}
          />
        )}
        {probeCycles && (
          <ProbeCyclesLayer
            probeCycles={probeCycles}
            map={map}
            hoveredMapPinId={hoveredMapPinId}
            updateHoveredMapPinId={updateHoveredMapPinId}
          />
        )}

        {showLayerControls && (
          <LayerControls>
            <LegendLayerControls
              shouldDisplayControls={!!activeImage}
              legendEnabled={legendEnabled}
              setLegendEnabled={enabled => setLegendEnabled(enabled)}
            />
            <SoilsLayerControls
              shouldDisplayControls={true}
              soilsEnabled={soilsEnabled}
              setSoilsEnabled={enabled => setSoilsEnabled(enabled)}
            />
            <BaseMapLayerControls
              baseMapsEnabled={baseMapsEnabled}
              setEnabled={setBaseMapsLayerEnabled}
            />
            <DownloadMapControls
              shouldDisplayControls={!!activeImage && !!activeImage.mapDownloadUrl}
              layersEnabled={true}
              activeImage={activeImage}
            />
          </LayerControls>
        )}
      </div>
      {showTransparencySlider && (
        <div className={styles.LeafletMapFooter}>
          <TransparencySlider
            LayersEnabled={true}
            handleLayerTransparency={value => setLayersTransparency(value)}
            value={layersTransparency}
          />
        </div>
      )}
    </React.Fragment>
  )
}

LeafletMap.propTypes = {
  hideLayerControls: PropTypes.bool, // whether to hide the layers menu (shown by default)
  hideTransparencySlider: PropTypes.bool, // whether to hide the footer transparency slider (shown by default)
  hideZoomControls: PropTypes.bool, // whether to hide the zoom controls (shown by default)
  zoomDisabled: PropTypes.bool, // whether to disable zooming (enabled by default)
  panDisabled: PropTypes.bool, // whether to disable panning (enabled by default)
  hideLegend: PropTypes.bool, // whether to hide the legend controls (shown by default)
  hideBaseLayers: PropTypes.bool, // whether to hide the background map layers (satellite) (shown by default)
  bounds: PropTypes.array, // the bounds of the map
  boundaryGeometry: PropTypes.object, // the boundary geometry to display (usually the field geometry)
  boundaryOutlineColor: PropTypes.string, // the color to use to outline the boundary geometry (white is default)
  activeImage: PropTypes.object, // the image that should be overlaid on the map
  drawingEnabled: PropTypes.bool, // whether to allow drawing on the map
  geometryToEdit: PropTypes.object, // geometry that should be editable
  setDrawnGeometry: PropTypes.func, // function to update the editable geometry as the user edits
  boundariesForPinMarkers: PropTypes.arrayOf(PropTypes.shape({ bounds: PropTypes.array })), // boundaries that we want to represent as a pin on the map, used for displaying pins for collection of fields
  pins: PropTypes.arrayOf(PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })), // generic pins layer if we want to just display a set of pins for a collection of lat/lng values
  innerGeometries: PropTypes.arrayOf(
    // inner geometries that you want to display inside the bounds of the field (such as regions)
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      geometry: PropTypes.object,
      display: PropTypes.bool,
    })
  ),
  innerGeometriesOutlineOnly: PropTypes.bool, // whether to only draw inner geometry outline (by default it is filled with a color)
  innerGeometriesOutlineColor: PropTypes.string,
  stressItems: PropTypes.arrayOf(
    // reviewed stress items to display pins & markers for on the map
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      issues: PropTypes.array,
    })
  ),
  probeCycles: PropTypes.array, // probe cycles to display pins & markers for on the map
  hoveredMapPinId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // which pin should have their hover state activated
  updateHoveredMapPinId: PropTypes.func, // function to update which pin should have their hovered state activated
}
