import React from 'react'
import PropTypes from 'prop-types'
import { TextInput, ListEditor } from '_shared'
import styles from './CustomerAccountSettings.module.scss'

export function HighlightsEditor({ highlights, setHighlights }) {
  const renderHighlight = (highlight, index, onChange) => {
    return (
      <TextInput
        name={`highlight-${index}`}
        label=''
        value={highlight}
        onChange={e => onChange(e.target.value, index)}
        className={styles.highlightInputContainer}
      />
    )
  }

  return (
    <div role='group' aria-labelledby='highlights-label' className={styles.highlightsListContainer}>
      <div id='highlights-label' className={styles.label}>
        Report highlights
      </div>
      <ListEditor
        items={highlights}
        setItems={setHighlights}
        render={renderHighlight}
        innerItemClassName={styles.highlightContainer}
      />
    </div>
  )
}

HighlightsEditor.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.string),
  setHighlights: PropTypes.func,
}
