// require('babel/polyfill');

export const ENVIRONMENT = process.env.REACT_APP_ENV

const allConfigurations = {
  development: {
    apiEndpoint: 'http://localhost:3001/api/v1',
    // apiEndpoint: 'https://back-office-api.herokuapp.com/api/v1',
    airbrakeProjectId: 0,
    airbrakeProjectKey: 'dev_mode_disable',
  },
  production: {
    apiEndpoint: 'https://back-office-api-production.herokuapp.com/api/v1',
    airbrakeProjectId: 123778,
    airbrakeProjectKey: '3e0c74c99f669a2561404b160c091691',
  },
  staging: {
    apiEndpoint: 'https://back-office-api.herokuapp.com/api/v1',
    airbrakeProjectId: 123778,
    airbrakeProjectKey: '3e0c74c99f669a2561404b160c091691',
  },
  test: {
    apiEndpoint: 'http://localhost:3001/api/v1',
    airbrakeProjectId: 0,
    airbrakeProjectKey: 'dev_mode_disable',
  },
}

if (!ENVIRONMENT) {
  throw new Error('Please set `REACT_APP_ENV`')
}

const currentEnvironmentConfiguration = allConfigurations[ENVIRONMENT]

export default {
  isProduction: ENVIRONMENT === 'production',
  endpoint: currentEnvironmentConfiguration.apiEndpoint || 'CHANGE THIS TO localhost:3000/api/v1',
  app: {
    title: 'Aker',
    contactInfo: {
      companyName: 'Aker',
      serviceEmail: 'info@aker.ag',
      servicePhone: '(507) 893-4545',
    },
    description: 'Aker | We keep every plant healthy.',
    head: {
      titleTemplate: 'Aker: %s',
      meta: [
        {
          name: 'description',
          content: 'Aker | We keep every plant healthy.',
        },
        { charset: 'utf-8' },
        { property: 'og:site_name', content: 'Aker Dashboard' },
        { property: 'og:image', content: '' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:title', content: 'Aker Dashboard' },
        {
          property: 'og:description',
          content: 'Aker | We keep every plant healthy.',
        },
        { property: 'og:card', content: 'summary' },
        { property: 'og:site', content: '@agdrones' },
        { property: 'og:creator', content: '@akeragus' },
        { property: 'og:title', content: 'Aker Dashboard' },
        {
          property: 'og:description',
          content: 'Aker | We keep every plant healthy.',
        },
        { property: 'og:image', content: '' },
        { property: 'og:image:width', content: '200' },
        { property: 'og:image:height', content: '200' },
      ],
    },
  },
  ENVIRONMENT,
  ...currentEnvironmentConfiguration,
}
