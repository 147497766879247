import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'

import PropTypes from 'prop-types'
import swal from '@sweetalert/with-react'
import NotificationSystem from 'react-notification-system'

import {
  deleteReport as deleteLegacyReport,
  getReportsByCustomerAccount as getLegacyReportsByCustomerAccount,
} from 'redux/modules/reports'
import { getReportsByCustomerAccount, deleteReport } from 'redux/modules/aerialImageryReports'
import { getTrialsByCustomerAccount, deleteTrial } from 'redux/modules/trials'

import { LoadingState } from '_shared'
import { CustomerAccountDropdown, CustomerAccountsContext } from 'domains'
import { ReportsAndTrials } from './ReportsAndTrials'

import styles from './Reports.module.scss'

// We are resolving an empty promise here because we need
// access to props.dispatch in component
const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {},
  },
])

const connectWrapper = connect(
  state => ({
    legacyReportsBySeason: state.reports.reportsByCustomerAccount,
    aerialImageryReportsBySeason: state.aerialImageryReports.reportsByCustomerAccount,
    appliedMaps: state.trials.trialsByCustomerAccount,
  }),

  {
    refreshLegacyReports: getLegacyReportsByCustomerAccount,
    refreshReports: getReportsByCustomerAccount,
    refreshTrials: getTrialsByCustomerAccount,
    deleteLegacyReport,
    deleteReport,
    deleteTrial,
  }
)

function ReportsIndexContainer({
  legacyReportsBySeason,
  aerialImageryReportsBySeason,
  appliedMaps,
  deleteLegacyReport,
  deleteReport,
  deleteTrial,
  refreshLegacyReports,
  refreshReports,
  refreshTrials,
  dispatch,
}) {
  const { selectedCustomerAccountId } = React.useContext(CustomerAccountsContext)
  const notificationSystem = useRef(null)

  const onCustomerAccountChange = (customerAccountId = null) => {
    dispatch(getReportsByCustomerAccount(customerAccountId))
    dispatch(getLegacyReportsByCustomerAccount(customerAccountId))
    dispatch(getTrialsByCustomerAccount(customerAccountId))
  }

  const handleDeleteReport = async reportToDelete => {
    const result = await swal({
      title: 'Are you sure you want to delete this report?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          className: '',
          closeModal: true,
        },
      },
    })

    if (result === true) {
      try {
        if (reportToDelete && reportToDelete.type === 'LegacyReport') {
          await deleteLegacyReport(reportToDelete.id)
        } else if (reportToDelete && reportToDelete.type === 'AerialImageryReport') {
          await deleteReport(reportToDelete.id)
        }
        notificationSystem.current.addNotification({
          message: 'Deleted Successfully',
          level: 'success',
          autoDismiss: 3,
        })
        refreshLegacyReports(selectedCustomerAccountId)
        refreshReports(selectedCustomerAccountId)
      } catch {
        notificationSystem.current.addNotification({
          message: 'Could not save. Please contact us at support@aker.ag',
          level: 'error',
          autoDismiss: 3,
        })
      }
    }
  }

  const handleDeleteTrial = async trialId => {
    await swal({
      title: 'Are you sure you want to delete this trial?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
      closeOnConfirm: true,
    })

    try {
      await deleteTrial(trialId)
      notificationSystem.current.addNotification({
        message: 'Deleted Successfully',
        level: 'success',
        autoDismiss: 3,
      })

      refreshTrials(selectedCustomerAccountId)
    } catch (err) {
      console.log(err)
      notificationSystem.current.addNotification({
        message: 'Could not save. Please contact us at support@aker.ag',
        level: 'error',
        autoDismiss: 3,
      })
    }
  }

  const isDataLoaded = collection => {
    return collection.loaded && collection.data
  }

  // merge the legacy reports with aerial imagery reports into 1 collection
  const getAllReportsBySeason = () => {
    return aerialImageryReportsBySeason.data.reduce((mergedReports, currentGrouping) => {
      const seasonGrouping = mergedReports.find(season => season.id === currentGrouping.id)
      if (!!seasonGrouping) {
        seasonGrouping.reports = seasonGrouping.reports.concat(currentGrouping.reports)
      } else {
        mergedReports.push(currentGrouping)
      }
      return mergedReports
    }, legacyReportsBySeason.data)
  }

  const trials =
    appliedMaps.loaded && appliedMaps.data && appliedMaps.data.hasTrials
      ? appliedMaps.data.trials
      : []

  return (
    <React.Fragment>
      <NotificationSystem ref={notificationSystem} />
      <div className='container'>
        <CustomerAccountDropdown
          onCustomerAccountChange={onCustomerAccountChange}
          containerClassName={styles.customerAccountDropdownContainer}
          dropdownClassName={styles.customerAccountDropdown}
        />
        {isDataLoaded(legacyReportsBySeason) &&
        isDataLoaded(aerialImageryReportsBySeason) &&
        isDataLoaded(appliedMaps) ? (
          <ReportsAndTrials
            reportsBySeason={getAllReportsBySeason()}
            trials={trials}
            deleteReport={handleDeleteReport}
            deleteTrial={handleDeleteTrial}
          />
        ) : (
          <LoadingState />
        )}
      </div>
    </React.Fragment>
  )
}

export default asyncConnectWrapper(connectWrapper(ReportsIndexContainer))

ReportsIndexContainer.propTypes = {
  legacyReportsBySeason: PropTypes.object,
  aerialImageryReportsBySeason: PropTypes.object,
  appliedMaps: PropTypes.object,
  deleteLegacyReport: PropTypes.func,
  deleteReport: PropTypes.func,
  deleteTrial: PropTypes.func,
  refreshLegacyReports: PropTypes.func,
  refreshReports: PropTypes.func,
  refreshTrials: PropTypes.func,
  dispatch: PropTypes.func,
}
