import React from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'
import styles from './Form.module.scss'

export function ColorPickerInput({ name, label, value, onChange }) {
  return (
    <div className={styles.formInput}>
      <label htmlFor={name}>{label}</label>
      <ChromePicker
        name={name}
        id={name}
        color={value}
        onChangeComplete={color => onChange(color.hex)}
        disableAlpha
      />
    </div>
  )
}

ColorPickerInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}
