import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { browserHistory } from 'react-router'
import swal from '@sweetalert/with-react'
import { TableWithCustomData, TableRow, LoadingState } from '_shared'
import { ProbeImageDisplay, DeleteSelection } from '../_shared'
import styles from '../ImageReviewer.module.scss'

export function BulkDeleteImages({ task, deleteImages }) {
  const [imagesToDelete, setImagesToDelete] = useState([])
  const imageReviewerByTaskUrl = `/image-reviewer/${task.id}`

  const tasksCells = () => {
    return (
      <React.Fragment>
        <div className={styles.cell}>
          <Link to={imageReviewerByTaskUrl}>{task.workOrderId}</Link>
        </div>
        <div className={styles.cell}>{task.fieldName}</div>
        <div className={styles.cell}>{task.totalImageCount}</div>
        <div className={styles.cell}>{task.percentComplete}%</div>
        <div className={styles.cell}>{task.updatedOn.format('MM/DD/YYYY')}</div>
      </React.Fragment>
    )
  }

  const onImageClick = imageId => {
    let updatedImagesToDelete = [...imagesToDelete]
    const existingImageId = updatedImagesToDelete.find(id => id === imageId)
    if (existingImageId) {
      updatedImagesToDelete = updatedImagesToDelete.filter(id => id !== imageId)
    } else {
      updatedImagesToDelete.push(imageId)
    }
    setImagesToDelete(updatedImagesToDelete)
  }

  const onSelectAllClick = allSelected => {
    if (allSelected) {
      setImagesToDelete(task.probeImages.map(img => img.id))
    } else {
      setImagesToDelete([])
    }
  }

  const handleDeleteImages = async () => {
    try {
      await deleteImages(imagesToDelete)
      const value = await swal({
        title: 'Great, your images have been deleted!',
        icon: 'success',
        closeOnConfirm: true,
        buttons: {
          cancel: 'Back to summary',
          confirm: {
            text: 'Review images',
            value: true,
          },
        },
      })
      if (value) {
        browserHistory.push(imageReviewerByTaskUrl)
      } else {
        browserHistory.push('/image-reviewer')
      }
    } catch (err) {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeOnConfirm: true,
      })
    }
  }

  return (
    <React.Fragment>
      <div className={styles.bulkImageReviewHeader}>
        <h1>Image Reviewer: Bulk Image Review</h1>
        <TableWithCustomData>
          <div className={`${styles.tableHeaders} ${styles.headerSpacing}`}>
            <h2 className='h4'>Work Order ID</h2>
            <h2 className='h4'>Field</h2>
            <h2 className='h4'>Total # of Images</h2>
            <h2 className='h4'>% complete</h2>
            <h2 className='h4'>Updated on</h2>
          </div>
          {task ? (
            <TableRow tableCells={() => tasksCells(task)} primary noBorder withHighlights />
          ) : (
            <LoadingState />
          )}
        </TableWithCustomData>
        <p>
          Select any images below that you would like to delete from the database upon initial
          review.
        </p>
        <DeleteSelection
          imagesToDelete={imagesToDelete}
          onSelectAllClick={onSelectAllClick}
          deleteImages={handleDeleteImages}
        />
      </div>
      <div className={styles.bulkDelete}>
        <div className={styles.bulkDeleteImgSelectionContainer}>
          {task.probeImages.map(img => (
            <ProbeImageDisplay
              key={img.id}
              image={img}
              active={imagesToDelete.includes(img.id)}
              selected={imagesToDelete.includes(img.id)}
              showSelectionCheckbox
              onThumbnailSelect={onImageClick}
              onCheckboxClick={onImageClick}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

BulkDeleteImages.propTypes = {
  task: PropTypes.object,
  deleteImages: PropTypes.func,
}
