import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AddButton } from '_shared'
import { isEmpty } from 'helpers'
import { WorkOrderHistory } from './WorkOrderHistory'
import styles from './Field.module.scss'

export function OrderHistoryTab({ field, activeSeason }) {
  const permissions = activeSeason.permissions || {}
  const [hasWorkOrders, setHasWorkOrders] = useState(false)

  useEffect(() => {
    setHasWorkOrders(activeSeason && activeSeason.workOrders && activeSeason.workOrders.length > 0)
  }, [activeSeason])

  return (
    <React.Fragment>
      {permissions.canManage && (
        <div className={styles.addButtonContainer}>
          <AddButton
            to={`/farm/${field.farm.id}/field/${field.id}/create-order`}
            buttonText='New Order'
            primary
          />
        </div>
      )}

      {!isEmpty(activeSeason) &&
        hasWorkOrders &&
        activeSeason.workOrders.map(order => <WorkOrderHistory workOrder={order} key={order.id} />)}
      {!hasWorkOrders && <h3 className='h5'>We don't have any work orders for this season yet</h3>}
    </React.Fragment>
  )
}

OrderHistoryTab.propTypes = {
  field: PropTypes.object.isRequired,
  activeSeason: PropTypes.object.isRequired,
}
