import React from 'react'
import PropTypes from 'prop-types'
import styles from './OnOffSwitch.module.scss'

export function OnOffSwitch({ label, name, value, onChange, className, labelClassName }) {
  return (
    <div className={`${styles.onOffSwitchContainer} ${value ? styles.checked : ''} ${className}`}>
      <input type='checkbox' name={name} id={name} checked={value} onChange={onChange} />
      <div className={styles.onOffSwitch} aria-hidden='true'>
        <div className={styles.switchBubbleContainer}>
          <div className={styles.switchInner}></div>
          <div className={styles.switchBubble}></div>
        </div>
      </div>

      <label htmlFor={name} className={labelClassName}>
        {label}
      </label>
    </div>
  )
}

OnOffSwitch.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
}
