import React from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'

import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { SidePanel } from 'components/_shared'
import { FarmList } from './FarmList'
import { clearAllErrors, clearError } from 'redux/modules/farmsandfields'
import { getFarms, addFarm, deleteFarm } from 'redux/modules/farms'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {},
  },
])

const connectWrapper = connect(
  state => ({
    user: state.auth.user,
    farms: state.farms.farms,
    farmsErrors: state.farms.farms.error,
  }),
  {
    clearAllErrors,
    clearError,
    addFarm,
    deleteFarm,
  }
)

function FarmListContainer({
  farms,
  clearError,
  clearAllErrors,
  addFarm,
  farmsErrors,
  user,
  dispatch,
}) {
  const onCustomerAccountChange = (customerAccountId = null) => {
    dispatch(getFarms(customerAccountId))
  }

  return (
    <React.Fragment>
      <Helmet title='Farms' />
      <SidePanel>
        <FarmList
          onCustomerAccountChange={onCustomerAccountChange}
          farms={farms.data}
          clearAllErrors={clearAllErrors}
          clearError={clearError}
          addFarm={addFarm}
          farmsErrors={farmsErrors}
          user={user}
        />
      </SidePanel>
    </React.Fragment>
  )
}

export default asyncConnectWrapper(connectWrapper(FarmListContainer))

FarmListContainer.propTypes = {
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  clearAllErrors: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  farms: PropTypes.object,
  farmsErrors: PropTypes.array,
  addFarm: PropTypes.func.isRequired,
  deleteFarm: PropTypes.func.isRequired,
}
