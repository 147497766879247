import { useEffect } from 'react'
import PropTypes from 'prop-types'

export function DrawMLBoxes({ locations, color, imageElement, canvasElement }) {
  const fullImageSize = [imageElement.naturalWidth, imageElement.naturalHeight]
  const imageSize = [imageElement.width, imageElement.height]
  imageSize[1] = (imageSize[0] * fullImageSize[1]) / fullImageSize[0]

  useEffect(() => {
    if (!locations || locations.length === 0) {
      clearCanvas()
    }
    if (locations && locations.length > 0) {
      drawMlLocationBoxes(locations, color)
      drawCanvasBoundaryBoxes()
    }
  }, [locations, color]) //eslint-disable-line react-hooks/exhaustive-deps

  const clearCanvas = () => {
    const ctx = canvasElement.getContext('2d')
    ctx.clearRect(0, 0, canvasElement.width, canvasElement.height)
  }

  const drawMlLocationBoxes = (locations, color) => {
    canvasElement.height = imageSize[1]
    canvasElement.width = imageSize[0]

    const ctx = canvasElement.getContext('2d')
    locations.forEach(function (pt) {
      ctx.beginPath()
      ctx.strokeStyle = color
      ctx.lineWidth = 2
      ctx.rect(pt[0] * imageSize[0] - 25, pt[1] * imageSize[1] - 25, 50, 50)
      ctx.stroke()
    })
  }

  const drawCanvasBoundaryBoxes = () => {
    const ctx = canvasElement.getContext('2d')

    const size = 15
    const width = 2
    ctx.beginPath()
    ctx.strokeStyle = '#FF0000'
    ctx.lineWidth = width
    ctx.rect(width, width, size, size)
    ctx.stroke()
    ctx.beginPath()
    ctx.strokeStyle = '#FF0000'
    ctx.lineWidth = width
    ctx.rect(imageSize[0] - size - width, imageSize[1] - size - width, size, size)
    ctx.stroke()
    ctx.beginPath()
    ctx.strokeStyle = '#FF0000'
    ctx.lineWidth = width
    ctx.rect(width, imageSize[1] - size - width, size, size)
    ctx.stroke()
    ctx.beginPath()
    ctx.strokeStyle = '#FF0000'
    ctx.lineWidth = width
    ctx.rect(imageSize[0] - size - width, width, size, size)
    ctx.stroke()
  }

  return null
}

DrawMLBoxes.propTypes = {
  locations: PropTypes.array,
  color: PropTypes.string,
  imageElement: PropTypes.object.isRequired,
  canvasElement: PropTypes.object.isRequired,
}
