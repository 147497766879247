import React from 'react'
import PropTypes from 'prop-types'
import { Modal as BootstrapModal } from 'react-bootstrap'
import styles from './Modal.module.scss'

export const Modal = ({ show, close, title, subTitle, size, children }) => {
  return (
    <BootstrapModal size={size} show={show} onHide={close}>
      <BootstrapModal.Header className={styles.modalHeader}>
        <BootstrapModal.Title className={styles.modalTitle}>
          <h2>{title}</h2>
          <h3>{subTitle}</h3>
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body className={styles.modalBody}>{children}</BootstrapModal.Body>
    </BootstrapModal>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
}
