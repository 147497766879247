import React from 'react'
import PropTypes from 'prop-types'
import styles from './LeafletMap.module.scss'

export function TransparencySlider({ LayersEnabled, handleLayerTransparency, value }) {
  return (
    <div className={styles.transparencySliderContainer}>
      <label htmlFor='transparencySlider'>Opacity: {value}%</label>
      <div className={styles.whiteBar}>
        <input
          id='transparencySlider'
          name='transparencySlider'
          className={styles.slider}
          type='range'
          disabled={!LayersEnabled}
          value={value}
          onChange={event => handleLayerTransparency(event.target.value)}
        />
      </div>
    </div>
  )
}

TransparencySlider.propTypes = {
  handleLayerTransparency: PropTypes.func.isRequired,
  LayersEnabled: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
}
