import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { ProbeImageDisplay } from './_shared'
import styles from './ImageReviewer.module.scss'

export const FavoriteImageUpdater = ({ url, isFavorited }, images) => {
  const imageToUpdate = images.find(image => image.imagePath === url)
  imageToUpdate.isFavorited = isFavorited

  return images
}

export const ImageReviewerCarousel = ({
  images,
  activeImageId,
  imagesToDelete,
  onThumbnailSelect,
  favoriteImageData,
  toggleFavoriteImage,
  updateCurrentPage,
  currentPageNumber,
  totalPageCount,
  onImageCheckboxClick,
  canDelete,
}) => {
  const [imagesToDisplay, setImagesToDisplay] = useState(images)

  useEffect(() => {
    if (!favoriteImageData) {
      return
    }

    let imagesToUpdate = [...imagesToDisplay]

    const updatedImages = FavoriteImageUpdater(favoriteImageData, imagesToUpdate)

    setImagesToDisplay(updatedImages)
  }, [favoriteImageData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setImagesToDisplay(images)
  }, [images]) // eslint-disable-line react-hooks/exhaustive-deps

  const showNextPageButton = () => {
    return totalPageCount > 1 && currentPageNumber < totalPageCount
  }

  const showPreviousPageButton = () => {
    return currentPageNumber > 1
  }

  const renderNoImagesMessage = () => {
    return (
      <div className={styles.noImagesToDisplay}>
        <h3 className='h5'>There are no images to display for the current filter</h3>
      </div>
    )
  }

  const renderCarousel = () => {
    return (
      <React.Fragment>
        <div className={styles.buttonContainer}>
          <Button
            variant='primary'
            disabled={!showPreviousPageButton()}
            onClick={() => updateCurrentPage(currentPageNumber - 1)}
          >
            Previous
          </Button>
        </div>
        {imagesToDisplay.map(image => {
          return (
            <ProbeImageDisplay
              key={image.id}
              image={image}
              active={activeImageId === image.id}
              selected={imagesToDelete.includes(image.id)}
              showIssuesBadge
              showFavoriteButton
              showSelectionCheckbox={canDelete}
              onThumbnailSelect={onThumbnailSelect}
              onCheckboxClick={onImageCheckboxClick}
              toggleFavoriteImage={toggleFavoriteImage}
            />
          )
        })}
        <div className={styles.buttonContainer}>
          <Button
            variant='primary'
            disabled={!showNextPageButton()}
            onClick={() => updateCurrentPage(currentPageNumber + 1)}
          >
            Next
          </Button>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className={styles.carousel}>
      <div className={styles.imagesToDisplay}>
        {!imagesToDisplay || imagesToDisplay.length === 0
          ? renderNoImagesMessage()
          : renderCarousel()}
      </div>
    </div>
  )
}

ImageReviewerCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  imagesToDelete: PropTypes.array.isRequired,
  activeImageId: PropTypes.number,
  currentPageNumber: PropTypes.number.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  onThumbnailSelect: PropTypes.func.isRequired,
  toggleFavoriteImage: PropTypes.func.isRequired,
  favoriteImageData: PropTypes.object,
  canDelete: PropTypes.bool,
}
