import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ContextHeader.module.scss'
export default class ContextHeader extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    handleBackEvent: PropTypes.func.isRequired,
  }

  render() {
    const { text, handleBackEvent } = this.props

    const leftArrow = (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='10px'
        height='15px'
        viewBox='0 0 10 15'
        enableBackground='new 0 0 10 15'
        xmlSpace='preserve'
      >
        <polyline fill='none' stroke='#007cc0' strokeMiterlimit='10' points='7,13 1.5,7.5 7,2  ' />
      </svg>
    )

    return (
      <div className={styles.ContextHeader} onClick={handleBackEvent}>
        {handleBackEvent && <span className={styles.back}>{leftArrow}</span>}
        <span className={styles.text}>{text}</span>
      </div>
    )
  }
}
