import React from 'react'
import PropTypes from 'prop-types'
import { CustomerAccountsContext } from 'domains'
import styles from '../AerialImageryReport.module.scss'

export function AerialImageryReportTemplatePicker({
  onChange,
  selectedTemplate,
  customerAccountId,
}) {
  const { customerAccounts } = React.useContext(CustomerAccountsContext)
  const customerAccount = customerAccounts.find(ca => ca.id === customerAccountId)
  const availableTemplates = customerAccount ? customerAccount.reportTemplates : []

  const previewImagesByName = {
    Original: require('assets/templates/template_original.jpg'),
    Modern: require('assets/templates/template_modern.jpg'),
    StackedTrial: require('assets/templates/template_revx.jpg'),
    BASF: require('assets/templates/template_revx.jpg'),
    ProbeObservation: require('assets/templates/template_truecause.jpg'),
  }

  const templates = availableTemplates.map(t => {
    return {
      ...t,
      previewImg: previewImagesByName[t.viewComponent] || previewImagesByName[0],
    }
  })

  return (
    <React.Fragment>
      <div id='theme-label' className={styles.templateLabel}>
        Please choose a report template
      </div>
      <div role='group' aria-labelledby='theme-label' className={styles.templatePicker}>
        {templates.map(template => (
          <label htmlFor={template.name} className={styles.templateInputLabel} key={template.id}>
            <span className={styles.templateInputContainer}>
              <div
                className={`${styles.templatePreviewImageContainer} ${
                  selectedTemplate && template.id === selectedTemplate.id ? styles.selected : ''
                }`}
              >
                <img
                  src={template.previewImg}
                  alt={`Preview of ${template.name} report template`}
                />
              </div>
              <input
                type='radio'
                title='theme'
                id={template.name}
                value={template.id}
                checked={selectedTemplate ? template.id === selectedTemplate.id : false}
                onChange={() => onChange(template)}
                className={styles.templateInput}
              />
              {template.name}
            </span>
          </label>
        ))}
      </div>
    </React.Fragment>
  )
}

AerialImageryReportTemplatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}
