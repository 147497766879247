import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Button } from 'react-bootstrap'
import { TableWithCustomData, TableGroupingPrimaryHeader, TableRow } from '_shared'
import { sortResourcesAlphabetically, filterFarmsAndFields } from '../../helpers/displayHelpers'
import { UserAccessModal } from './UserAccessModal'
import { FarmFieldAccessSearch } from './FarmFieldAccessSearch'
import styles from './FarmFieldAccess.module.scss'
import userAccessStyles from '../UserAccess/UserAccess.module.scss'

export const FARM_USER_ACCESS_ACTION = 'FarmFieldAccess/GET_FARM_USER_ACCESS'
export const FIELD_USER_ACCESS_ACTION = 'FarmFieldAccess/GET_FIELD_USER_ACCESS'

export const FarmFieldAccessComponent = ({
  farms,
  onResourceSelect,
  userAccessList,
  refreshUserAccess,
  ...props
}) => {
  const fieldCells = (field = null) => {
    return (
      <div className={styles.fieldRow}>
        {!field ? (
          <span>No Fields</span>
        ) : (
          <React.Fragment>
            <Button
              variant='link'
              onClick={() => displayAccessForField(field)}
              className={styles.fieldAccessButton}
            >
              {field.title}
            </Button>
            <Button
              variant='link'
              onClick={() => displayAccessForField(field)}
              className={styles.fieldAccessButton}
            >
              {field.usersWithAccessCount}
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }

  const displayAccessForFarm = farm => {
    onResourceSelect(FARM_USER_ACCESS_ACTION, farm.id)
    setResourceTitle(farm.title)
    setResourceType('Farm')
    setResourceId(farm.id)
    setAccessModal(true)
  }

  const displayAccessForField = field => {
    onResourceSelect(FIELD_USER_ACCESS_ACTION, field.id)
    setResourceTitle(field.title)
    setResourceType('Field')
    setResourceId(field.id)
    setAccessModal(true)
  }

  const renderRowsFor = farm => {
    return (
      <React.Fragment key={farm.id}>
        <TableGroupingPrimaryHeader>
          <Button
            variant='link'
            onClick={() => displayAccessForFarm(farm)}
            className={styles.farmTitle}
          >
            {farm.title}
          </Button>
          <span className={styles.farmCustomerName}>- {farm.customerAccountName}</span>
        </TableGroupingPrimaryHeader>
        {farm.fields.length === 0 && <TableRow tableCells={fieldCells} left noBorder />}
        <div className={styles.fieldRows}>
          {sortResourcesAlphabetically(farm.fields).map(field => {
            return (
              <TableRow
                key={field.id}
                tableCells={() => fieldCells(field)}
                left
                noBorder
                withHighlights
              />
            )
          })}
        </div>
      </React.Fragment>
    )
  }

  const closeAccessModal = () => {
    setAccessModal(false)
  }

  const handleCloseConfirmation = () => {
    refreshUserAccess()
    setAccessModal(false)
  }

  const handleInputChange = event => {
    const { value } = event.target
    setFilterString(value.toLowerCase())

    if (!value) {
      setFilteredFarms(farms)
    }
  }

  const updateFarmsAndFields = () => {
    const updatedFarms = filterFarmsAndFields(farms, filterString)
    setFilteredFarms(updatedFarms)
  }

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      updateFarmsAndFields()
    }
  }

  const handleSearchClick = () => {
    updateFarmsAndFields()
  }

  const [showAccessModal, setAccessModal] = useState(false)
  const [resourceTitle, setResourceTitle] = useState(null)
  const [resourceType, setResourceType] = useState(null)
  const [resourceId, setResourceId] = useState(null)
  const [filterString, setFilterString] = useState(null)
  const [filteredFarms, setFilteredFarms] = useState(farms)

  return (
    <React.Fragment>
      <TableWithCustomData title='User Access'>
        <FarmFieldAccessSearch
          handleInputChange={handleInputChange}
          handleKeyUp={handleKeyUp}
          handleSearchClick={handleSearchClick}
        />
        <div className={userAccessStyles.tableHeaders}>
          <h3>Farm</h3>
          <h3>Field</h3>
          <h3># of Users With Access</h3>
        </div>
        {sortResourcesAlphabetically(filteredFarms).map(farm => renderRowsFor(farm))}
      </TableWithCustomData>
      <UserAccessModal
        resourceTitle={resourceTitle}
        resourceType={resourceType}
        resourceId={resourceId}
        userAccessList={userAccessList}
        handleCloseConfirmation={handleCloseConfirmation}
        show={showAccessModal}
        close={closeAccessModal}
        {...props}
      />
    </React.Fragment>
  )
}

export const FarmFieldAccess = withRouter(FarmFieldAccessComponent)

FarmFieldAccess.propTypes = {
  farms: PropTypes.array.isRequired,
  refreshUserAccess: PropTypes.func.isRequired,
}
