import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import styles from './LeafletMap.module.scss'

export function StressItemPopup({ pin }) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handlePrevClick = pin => {
    let newIndex = activeIndex - 1
    if (newIndex < 0) {
      newIndex = pin.carouselIssues.length - 1
    }
    setActiveIndex(newIndex)
  }

  const handleNextClick = pin => {
    let newIndex = activeIndex + 1
    if (newIndex >= pin.carouselIssues.length) {
      newIndex = newIndex % pin.carouselIssues.length
    }
    setActiveIndex(newIndex)
  }

  return (
    <div className={styles.popupContent}>
      {pin.carouselIssues.length > 1 && (
        <button onClick={() => handlePrevClick(pin)} className={styles.prevButton}>
          <FaChevronLeft />
        </button>
      )}

      {pin.carouselIssues.map((issue, index) => {
        return (
          <div
            key={issue.id}
            className={`${styles.popupStressItem} ${index === activeIndex ? styles.active : ''}`}
          >
            <p>
              <span className={`${styles.popupLabel} ${styles.popupTitle}`}>
                Observation {issue.pinNumber}
              </span>
            </p>
            <p>
              <span className={styles.popupLabel}>Stress item:</span>
              <span>{issue.name}</span>
            </p>
            <p>
              <span className={styles.popupLabel}>Notes:</span>
              <span>{issue.notes}</span>
            </p>
            <p>
              <span className={styles.popupLabel}>Temp:</span>
              <span>{issue.temperature}&#x2103;</span>
            </p>
            <p>
              <span className={styles.popupLabel}>Humidity:</span>
              <span>{issue.humidity}%</span>
            </p>
            <p>
              <span className={styles.popupLabel}>CO2:</span>
              <span>{issue.co2}ppm</span>
            </p>
          </div>
        )
      })}
      {pin.carouselIssues.length > 1 && (
        <button onClick={() => handleNextClick(pin)} className={styles.nextButton}>
          <FaChevronRight />
        </button>
      )}
    </div>
  )
}

StressItemPopup.propTypes = {
  pin: PropTypes.shape({
    carouselIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        pinNumber: PropTypes.number,
        name: PropTypes.string,
        notes: PropTypes.string,
        temperature: PropTypes.number,
        humidity: PropTypes.number,
        co2: PropTypes.number,
      })
    ),
  }),
}
