import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import swal from '@sweetalert/with-react'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import { FormButtonContainer } from '_shared'
import styles from './Region.module.scss'

export function RegionsList({ season, regions, deleteRegion, updateRegions, onRegionChange }) {
  const onDeleteClick = async regionId => {
    const result = await swal({
      title: 'Are you sure you want to delete this region?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          className: '',
          closeModal: false,
        },
      },
    })

    result && handleDeleteRegion(regionId)
  }

  const handleDeleteRegion = async regionId => {
    try {
      await deleteRegion(regionId)
      await swal({
        title: 'Great, your region has been deleted!',
        icon: 'success',
        closeOnConfirm: true,
      })
      updateRegions()
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeOnConfirm: true,
      })
    }
  }

  return (
    <div className={styles.regionList}>
      <h3>Regions</h3>
      <div>
        {regions.map((region, index) => (
          <div className={styles.regionListItem} key={region.id}>
            <div className={styles.regionListItemCheckboxContainer}>
              <input
                type='checkbox'
                name={region.id}
                id={region.id}
                onChange={e => onRegionChange(region.id, e.target.checked)}
              />
              <label htmlFor={region.id}>
                {index + 1}. {region.name}
              </label>
            </div>
            <div>
              <Link
                to={`/farms/${season.farmId}/field/${season.field.id}/season/${season.id}/region/${region.id}`}
                className='btn'
              >
                <FaPencilAlt color='#007cc0' />
              </Link>
              <button className='btn' onClick={() => onDeleteClick(region.id)}>
                <FaTrashAlt color='#ff4c5a' />
              </button>
            </div>
          </div>
        ))}
      </div>

      <FormButtonContainer>
        <Link
          to={`/farms/${season.farmId}/field/${season.field.id}/season/${season.id}/create-region`}
          className={`btn btn-primary`}
        >
          Add region
        </Link>
      </FormButtonContainer>
    </div>
  )
}

RegionsList.propTypes = {
  regions: PropTypes.array,
}
