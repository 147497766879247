import React from 'react'
import PropTypes from 'prop-types'
import { BaseMapContainer, BaseMapRadioButton } from './MapLayerControl'

export function BaseMapLayerControls({ baseMapsEnabled, setEnabled }) {
  return !!baseMapsEnabled ? (
    <BaseMapContainer>
      {Object.keys(baseMapsEnabled).map(mapKey => {
        return (
          <BaseMapRadioButton
            key={mapKey}
            mapKey={mapKey}
            onChange={() => setEnabled(mapKey)}
            defaultChecked={baseMapsEnabled[mapKey]}
          />
        )
      })}
    </BaseMapContainer>
  ) : null
}

BaseMapLayerControls.propTypes = {
  baseMapsEnabled: PropTypes.object,
  setEnabled: PropTypes.func,
}
