import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { flatten } from 'helpers'
import styles from './ImageReviewer.module.scss'
import { Button } from 'react-bootstrap'

export function FilterSelectedItemsByTaggedFindings(taggedFindings, selectedStressItems) {
  const taggedFindingIds = taggedFindings.map(taggedFinding => taggedFinding.id)
  return selectedStressItems.filter(s => taggedFindingIds.includes(s))
}

export function StressItemsTagging({
  cropStressItems,
  updateTaggedFindings,
  taggedFindings,
  handleSubmit,
  activeImage,
}) {
  const [selectedStressItems, setSelectedStressItems] = useState(
    activeImage.cropStressItems.map(item => item.id)
  )

  const { collectedCrop } = activeImage
  const [observedCropId, setObservedCropId] = useState(collectedCrop.id)

  useEffect(() => {
    const updatedStressItems = FilterSelectedItemsByTaggedFindings(
      taggedFindings,
      selectedStressItems
    )
    setSelectedStressItems([...new Set(updatedStressItems)])
  }, [taggedFindings]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setObservedCropId(collectedCrop.id)
    setSelectedStressItems(activeImage.cropStressItems.map(item => item.id))
  }, [activeImage]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateSelectedStressItems = e => {
    let newStressItemId = parseInt(e.target.value)

    let updatedStressItems = [...selectedStressItems]
    if (e.target.checked) {
      updatedStressItems.push(newStressItemId)
    } else {
      updatedStressItems = updatedStressItems.filter(s => s !== newStressItemId)
    }
    updateTaggedFindings(observedCropId, updatedStressItems)
    setSelectedStressItems([...new Set(updatedStressItems)])
  }

  const updateObservedCropId = e => {
    const newCropId = parseInt(e.target.value)
    const updatedStressItems = keepStressItemsValidForNewCrop(newCropId)
    updateTaggedFindings(newCropId, updatedStressItems)
    setSelectedStressItems(updatedStressItems)
    setObservedCropId(newCropId)
  }

  const keepStressItemsValidForNewCrop = cropId => {
    const newCrop = cropStressItems.crops.find(c => c.id === cropId)
    const newCropStressItems = flatten(newCrop.cropStressTypes.map(t => t.cropStressItems))
    const newCropStressItemIds = newCropStressItems.map(i => i.id)
    let updatedStressItems = [...selectedStressItems]
    return updatedStressItems.filter(id => newCropStressItemIds.includes(id))
  }
  return (
    <div className={styles.stressItemsWrapper}>
      <div className={styles.taggingStressItemsContainer}>
        <div className={styles.cropSelection} role='group' aria-labelledby='crop-label'>
          <div id='crop-label' className={`h4 ${styles.fieldSetLabel}`}>
            Crop:
          </div>
          {cropStressItems.crops.map(crop => (
            <div className={styles.inputContainer} key={crop.id}>
              <input
                type='radio'
                id={`crop-${crop.id}`}
                name='observedCrop'
                value={crop.id}
                checked={crop.id === observedCropId}
                onChange={updateObservedCropId}
              />
              <label htmlFor={`crop-${crop.id}`}>{crop.name}</label>
            </div>
          ))}
        </div>
        <div role='group' aria-labelledby='stress-item-label'>
          <div id='stress-item-label' className={`h4 ${styles.fieldSetLabel}`}>
            Stress Items:
          </div>
          {cropStressItems.crops.map(crop => (
            <ul
              className={`${styles.stressTypeList} ${
                observedCropId === crop.id ? styles.active : ''
              }`}
              key={crop.id}
            >
              {crop.cropStressTypes.map(stressType => (
                <li key={stressType.id}>
                  <h2 className='h5'>{stressType.name}</h2>
                  <ul className={styles.stressItemList}>
                    {stressType.cropStressItems.map(stressItem => (
                      <li key={stressItem.id} className={styles.inputContainer}>
                        <input
                          type='checkbox'
                          id={`stressItem-${stressItem.id}`}
                          name='cropStressItem'
                          value={stressItem.id}
                          checked={selectedStressItems.includes(stressItem.id)}
                          onChange={updateSelectedStressItems}
                        />
                        <label htmlFor={`stressItem-${stressItem.id}`}>{stressItem.name}</label>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
      <Button
        variant='dark'
        disabled={!observedCropId}
        onClick={e => handleSubmit(e, observedCropId)}
      >
        Save
      </Button>
    </div>
  )
}

StressItemsTagging.propTypes = {
  cropStressItems: PropTypes.object.isRequired,
}
