import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Form } from 'react-bootstrap'
import styles from './LeafletMap.module.scss'
import classNames from 'classnames'

export function MapLayerControl({ hideMapLayerControl, active, children, ...props }) {
  const controlStyles = classNames(styles.LeafletMapLayerControl, {
    [styles.active]: active,
  })

  return (
    <Card className={controlStyles}>
      <CloseButton onClick={hideMapLayerControl} />
      <div className={styles.cardContainer}>{children}</div>
    </Card>
  )
}

MapLayerControl.propTypes = {
  hideMapLayerControl: PropTypes.func.isRequired,
}

export function CloseButton({ onClick, ...props }) {
  return (
    <button
      type='button'
      className={classNames('close', { [styles.closeButton]: true })}
      aria-label='close'
      onClick={onClick}
    >
      <span aria-hidden='true'>&times;</span>
    </button>
  )
}

export function TopLevelContainer(props) {
  const { children } = props

  return <div className='layers-group'>{children}</div>
}

export function TopLevelCheckbox({ title, onChange, defaultChecked, disabled, ...props }) {
  return (
    <div className={classNames(styles.indent, styles.TopLevelCheckbox)}>
      <input
        onChange={onChange}
        defaultChecked={defaultChecked}
        type='checkbox'
        id={title}
        name={title}
        value={title}
        disabled={disabled}
      />
      <label
        htmlFor={title}
        className={classNames(styles.TopLevelLabel, {
          [styles.checked]: defaultChecked,
        })}
      >
        {title}
      </label>
    </div>
  )
}

export function BaseMapContainer({ children, ...props }) {
  return (
    <div className='base-map-group'>
      <p>Base map</p>
      <Form className={styles.LeafletMapLayerControlsIndent}>{children}</Form>
    </div>
  )
}

export function BaseMapRadioButton({ mapKey, onChange, defaultChecked, disabled, ...props }) {
  return (
    <div key={mapKey}>
      <input
        onChange={onChange}
        defaultChecked={defaultChecked}
        type='radio'
        id={mapKey}
        name='baselayer-toggle'
        value={mapKey}
      />
      <label
        htmlFor={mapKey}
        className={classNames(styles.baseLabels, {
          [styles.checked]: defaultChecked,
        })}
      >
        {mapKey}
      </label>
    </div>
  )
}

export function DownloadButtonContainer({ onClick, disabled, image, ...props }) {
  return (
    <table>
      <tr>
        <div className={styles.downloadGroup}>
          <Button
            className={styles.downloadButton}
            type='submit'
            variant='primary'
            disabled={disabled}
            id='color'
            value={image.mapDownloadUrl}
            onClick={onClick}
          >
            Color TIF Download
          </Button>
        </div>
      </tr>
      <tr>
        <div className={styles.downloadGroup}>
          <Button
            className={styles.downloadButton}
            type='submit'
            variant='primary'
            disabled={disabled}
            id='index'
            value={image.mapIndexDownloadUrl}
            onClick={onClick}
          >
            Index TIF Download
          </Button>
        </div>
      </tr>
    </table>
  )
}
