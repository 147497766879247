import React, { Component } from 'react'
import PropTypes from 'prop-types'
import swal from '@sweetalert/with-react'
import { isFarmLoaded, getFarm, updateFarm, deleteFarm } from 'redux/modules/farms'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { browserHistory } from 'react-router'
import Helmet from 'react-helmet'
import { SidePanel, ContextHeader } from '../_shared'
import EditFarmForm from './EditFarmForm'
import EditFarmMap from './EditFarmMap'

const enhance = compose(
  asyncConnect([
    {
      deferred: false,
      promise: options => {
        const promises = []
        const { dispatch, getState } = options.store
        const { params } = options
        // Add one call for the farm drilldown
        if (params && params.farmId) {
          if (!isFarmLoaded(getState(), params.farmId)) {
            promises.push(dispatch(getFarm(params.farmId)))
          }
        }
        return Promise.all(promises)
      },
    },
  ]),
  connect(
    state => ({
      farm: state.farms.farm.data,
    }),
    {
      updateFarm,
      deleteFarm,
    }
  )
)
export default enhance(
  class EditFarm extends Component {
    static propTypes = {
      farm: PropTypes.object.isRequired,
      params: PropTypes.object.isRequired,
      updateFarm: PropTypes.func.isRequired,
      deleteFarm: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props)

      this.handleCloseEditForm = this.handleCloseEditForm.bind(this)
      this.fieldPolygon = {}
      this.state = {}
    }

    handleCloseEditForm() {
      window.location = `/farms/${this.props.farm.id}`
    }

    handleFarmSubmit = async data => {
      this.setState({
        isDisabled: true,
      })

      const requestParams = {
        farmTitle: data.farmTitle,
        farmId: this.props.farm.id,
      }

      try {
        await this.props.updateFarm(requestParams)
        this.setState({ isDisabled: false })

        await swal({
          title: 'Great, your farm has been updated!',
          icon: 'success',
          closeModal: true,
        })
        this.handleCloseEditForm()
      } catch (e) {
        await swal({
          title: "It's not you, it's us.",
          text: 'Please try again later or contact info@aker.ag.',
          icon: 'error',
          closeModal: true,
        })
        window.location.reload()
      }
    }

    handleFarmDelete = async () => {
      this.setState({
        isDisabled: true,
      })
      try {
        await this.props.deleteFarm(this.props.farm.id)
        this.setState({ isDisabled: false })

        await swal({
          title: 'Great, your farm has been deleted!',
          icon: 'success',
          closeOnConfirm: true,
        })
        browserHistory.push('/farms')
      } catch {
        await swal({
          title: "It's not you, it's us.",
          text: 'Please try again later or contact info@aker.ag.',
          icon: 'error',
          closeOnConfirm: true,
        })
        window.location.reload()
      }
    }

    render() {
      const { farm } = this.props
      return (
        <div style={{ display: 'flex', width: '100%' }}>
          <Helmet title='Edit Field' />
          <div className='Sidebar'>
            <SidePanel background={false}>
              <ContextHeader text={farm.title} handleBackEvent={this.handleCloseEditForm} />
              <EditFarmForm
                onSubmit={this.handleFarmSubmit}
                handleDelete={this.handleFarmDelete}
                isDisabled={this.state.isDisabled}
                handleFarmDelete={this.handleFarmDelete}
              />
            </SidePanel>
          </div>
          <div className='Map'>
            <EditFarmMap />
          </div>
        </div>
      )
    }
  }
)
