/* global swal */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { asyncConnect } from 'redux-connect'
import { connect } from 'react-redux'
import moment from 'moment'
import { isCropsLoaded, getCrops } from 'redux/modules/crops'
import { isFieldLoaded, getField } from 'redux/modules/fields'
import { createSeason } from 'redux/modules/seasons'
import Helmet from 'react-helmet'
import { SidePanel, ContextHeader } from '../_shared'
import CreateSeasonForm from './CreateSeasonForm'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch, getState } = options.store
      const { params } = options
      if (params && params.fieldId) {
        if (!isFieldLoaded(getState(), params.fieldId)) {
          promises.push(dispatch(getField(params.fieldId)))
        }
      }
      if (!isCropsLoaded(getState())) {
        promises.push(dispatch(getCrops()))
      }
      return Promise.all(promises)
    },
  },
])
const connectWrapper = connect(
  state => ({
    field: state.fields.field.data,
    crops: state.crops.crops.data,
  }),
  { createSeason }
)

class CreateSeasonComponent extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    crops: PropTypes.array.isRequired,
    createSeason: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.handleCloseSeasonForm = this.handleCloseSeasonForm.bind(this)
    this.handleSeasonSubmit = this.handleSeasonSubmit.bind(this)
    this.state = {
      isDisabled: false,
    }
  }

  handleCloseSeasonForm() {
    window.location = `${window.location.origin}/farms/${this.props.params.farmId}/field/${this.props.params.fieldId}`
  }

  async handleSeasonSubmit(data) {
    const that = this

    this.setState({
      isSubmitting: true,
    })

    try {
      await that.props.createSeason({
        fieldId: that.props.field.id,
        cropId: data.cropType,
        plantDate: moment.utc(data.plantDate).format('YYYY/MM/DD'),
      })

      this.setState({ isSubmitting: false })

      await swal({
        title: "Great, you've created a new season!",
        type: 'success',
        closeOnConfirm: true,
      })
      that.handleCloseSeasonForm()
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        type: 'error',
        closeOnConfirm: true,
      })
      window.location.reload()
    }
  }

  render() {
    const { field } = this.props
    return (
      <SidePanel background={false}>
        <Helmet title='Create Season' />
        <ContextHeader text={field.title} handleBackEvent={this.handleCloseSeasonForm} />
        <CreateSeasonForm
          onSubmit={this.handleSeasonSubmit}
          isDisabled={this.state.isSubmitting}
          submitButtonText='Create Season'
          headerText='Create Season'
        />
      </SidePanel>
    )
  }
}

export default asyncConnectWrapper(connectWrapper(CreateSeasonComponent))
