import React from 'react'
import PropTypes from 'prop-types'
import styles from './Form.module.scss'

export function TextInput({
  label,
  name,
  value,
  onChange,
  placeholder,
  required,
  children,
  className,
  ...props
}) {
  const renderInputWithChildren = () => {
    return (
      <div className={styles.inputWithChildrenContainer}>
        {renderInput()}
        {children}
      </div>
    )
  }

  const renderInput = () => {
    return (
      <input
        type='text'
        id={name}
        name={name}
        value={value}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        className='form-control'
        {...props}
      />
    )
  }

  return (
    <div className={`${styles.formInput} ${className}`}>
      <label htmlFor={name}>{label}</label>
      {!!children ? renderInputWithChildren() : renderInput()}
    </div>
  )
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}
