import React from 'react'
import { Row, Col, Button, Alert } from 'react-bootstrap'
import classNames from 'classnames'
import styles from './ContactInfoForm.module.scss'

export function ResetPassword(props) {
  const { resetHandler, resetErrors, resetSuccess, resettingPassword } = props
  const disableResetForm = typeof resetSuccess !== 'undefined' || resettingPassword || false
  const passwordResetStyles = classNames(styles.passwordResetRow, {
    'col-6': true,
  })

  return (
    <div>
      {resetSuccess && (
        <Alert variant='success'>
          An email was sent to: <strong>{resetSuccess.email}</strong>
        </Alert>
      )}

      {resetErrors &&
        resetErrors.errors.map(error => {
          return (
            <Alert key={error.code} variant='danger'>
              {error.message}
            </Alert>
          )
        })}
      {!disableResetForm && (
        <Col>
          <Row className={styles.small}>Password</Row>
          <Row>
            <div className={passwordResetStyles}>Want to change your password?</div>
            <div className='col-6'>
              <Button variant='link' onClick={resetHandler}>
                Send reset password email
              </Button>
            </div>
          </Row>
        </Col>
      )}
    </div>
  )
}
