import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import { getSeason } from 'redux/modules/seasons'
import { getRegionsBySeasonId } from 'redux/modules/regions'
import { getReport } from 'redux/modules/aerialImageryReports'
import { LoadingState } from '_shared'
import { CustomerAccountsContext } from 'domains'
import { AerialImageryReportOriginal } from './OriginalTemplate/AerialImageryReportOriginal'
import { AerialImageryReportModern } from './ModernTemplate/AerialImageryReportModern'
import { AerialImageryReportStackedTrial } from './StackedTrial/AerialImageryReportStackedTrial'
import { AerialImageryReportProbeObservation } from './ProbeObservationTemplate/AerialImageryReportProbeObservation'
import styles from './AerialImageryReport.module.scss'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch } = options.store
      const { params } = options
      if (params && params.seasonId) {
        promises.push(dispatch(getSeason(params.seasonId)))
        promises.push(dispatch(getRegionsBySeasonId(params.seasonId)))
      }

      if (params && params.reportId) {
        promises.push(dispatch(getReport(params.reportId)))
      }
    },
  },
])

const connectWrapper = connect(state => ({
  season: state.seasons.activeSeason.data,
  report: state.aerialImageryReports.report.data,
  regions: state.regions.regionsBySeason.data,
}))

export function AerialImageryReportContainerComponent({ season, report, regions }) {
  const akerLogo =
    report && report.template.name === 'modern'
      ? 'http://www.aker.ag/wp-content/uploads/2018/04/icon3.png'
      : require('assets/logo-aker-white.svg')
  const { customerAccounts } = React.useContext(CustomerAccountsContext)

  if (!report || !season) return <LoadingState />

  const shouldDisplayYieldAnalysis = () => {
    const regionsWithYield = regions.filter(r => r.yield)
    return regionsWithYield.length > 0 && !report.template.hideYieldAnalysisFromView
  }

  const calculateYieldAdvantage = () => {
    const yieldValues = regions
      .filter(r => r.yield)
      .map(r => r.yield)
      .sort()

    if (yieldValues.length === 0) {
      return null
    } else if (yieldValues.length === 1) {
      return yieldValues[0].toFixed(1)
    }
    return (yieldValues[yieldValues.length - 1] - yieldValues[0]).toFixed(1)
  }

  const calculateRevenueAdvantage = () => {
    const yieldAdvantage = calculateYieldAdvantage()

    if (yieldAdvantage && report.commodityPrice) {
      return (yieldAdvantage * report.commodityPrice).toFixed(2)
    }
  }

  const renderReport = () => {
    const selectedCustomerAccount = customerAccounts.find(c => c.id === season.customerAccount.id)
    const viewComponentsByName = {
      AerialImageryReportOriginal,
      AerialImageryReportModern,
      AerialImageryReportStackedTrial,
      AerialImageryReportBASF: AerialImageryReportStackedTrial,
      AerialImageryReportProbeObservation,
    }

    const ViewComponent =
      report && report.template && report.template.viewComponent
        ? viewComponentsByName[`AerialImageryReport${report.template.viewComponent}`]
        : viewComponentsByName[0]

    const viewReportProps = {
      season: season,
      regions: report.regions ?? [],
      flights: report.flights,
      observations: report.observations,
      brandColor: report.brandColor || selectedCustomerAccount.brandColor,
      brandColorLight: report.brandColor
        ? `${report.brandColor}2e`
        : `${selectedCustomerAccount.brandColor}2e`,
      brandLogo: report.logoUrl || selectedCustomerAccount.logoUrl || akerLogo,
      title: report.title,
      commodityPrice: report.commodityPrice,
      yieldAdvantage: calculateYieldAdvantage(),
      revenueAdvantage: calculateRevenueAdvantage(),
      footnote: report.footnote,
      highlightsHeader: selectedCustomerAccount.highlightsHeader,
      highlights: selectedCustomerAccount.highlights || [],
      productHeader: report.productHeader,
      crop: report.crop,
      seedType: report.seedType,
      location: report.location,
      plantDate: season.plantDate && moment.utc(season.plantDate).format('MM/DD/YYYY'),
      applicationDate:
        report.applicationDate && moment.utc(report.applicationDate).format('MM/DD/YYYY'),
      contactName: report.contactName,
      contactPhone: report.contactPhone,
      contactEmail: report.contactEmail,
      displayYieldAnalysis: shouldDisplayYieldAnalysis(),
      displayYieldAdvantage: !report.template.hideYieldAdvantageFromView,
      displayReveneueAdvantage: !report.template.hideRevenueAdvantageFromView,
      observationDate:
        report.observationDate && moment.utc(report.observationDate).format('MM/DD/YYYY'),
      // mapIsLoaded
      isBasf: report.template.viewComponent === 'BASF',
    }
    return <ViewComponent {...viewReportProps} />
  }

  return (
    <div className={styles.aerialImageryReportContainer}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                @media print {
                  .fa-leaf:before,
                  .fa-clock-o:before,
                  .fa-calendar:before,
                  .fa-circle:before,
                  .fa-arrow-up:before{
                    color: ${report.brandColor};
                  }
                }
            `,
        }}
      />
      {renderReport()}
    </div>
  )
}

const AerialImageryReportContainer = asyncConnectWrapper(
  connectWrapper(AerialImageryReportContainerComponent)
)

export default AerialImageryReportContainer

AerialImageryReportContainerComponent.propTypes = {
  season: PropTypes.object,
  report: PropTypes.object,
  regions: PropTypes.array,
}
