import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as reduxAsyncConnect } from 'redux-connect'
import { reducer as form } from 'redux-form'

import auth, { LOGOUT_SUCCESS, LOGOUT_FAIL } from './auth'
// Legacy modules
import account from './account'
import farmsandfields from './farmsandfields'
import maplayers from './maplayers'
import services from './services'
import testplottool from './testplottool'

import aerialImageryReports from './aerialImageryReports'
import crops from './crops'
import customerAccounts from './customerAccounts'
import farms from './farms'
import favoriteimages from './favoriteImages'
import fields from './fields'
import flights from './flights'
import probeData from './probeData'
import regions from './regions'
import reports from './reports'
import seasons from './seasons'
import useraccess from './useraccess'
import subscriptions from './subscriptions'
import trials from './trials'
import imagereview from './imagereview'
import probeImages from './probeImages'

const appReducer = combineReducers({
  routing: routerReducer,
  reduxAsyncConnect,
  form,
  auth,
  account,
  farmsandfields,
  maplayers,
  services,
  testplottool,
  aerialImageryReports,
  crops,
  customerAccounts,
  farms,
  favoriteimages,
  fields,
  flights,
  probeData,
  regions,
  reports,
  seasons,
  useraccess,
  subscriptions,
  trials,
  imagereview,
  probeImages
})

const rootReducer = (state, action) => {
  let nextState = state
  if (action.type === LOGOUT_SUCCESS || action.type === LOGOUT_FAIL) {
    // Workaround for: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
    const { routing } = nextState
    nextState = { routing }
  }

  return appReducer(nextState, action)
}

export default rootReducer
