import React, { useState, useEffect, createContext } from 'react'
import 'overlapping-marker-spiderfier-leaflet/dist/oms'
const OverlappingMarkerSpiderfier = window.OverlappingMarkerSpiderfier

export const OverlappingPinSpiderfierContext = createContext(null)

export function OverlappingPinSpiderfierContextProvider({ map, children }) {
  const [overlappingMarkerSpiderfier, setOverlappingMarkerSpiderfier] = useState(null)

  useEffect(() => {
    if (!map) return

    const oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      keepSpiderfied: true,
      nearbyDistance: 1, // pins within 1 pixel get spiderified
      legWeight: 8,
      circleFootSeparation: 30,
    })
    oms.legColors.highlighted = '#fff'

    oms.addListener('spiderfy', function(markers) {
      markers.forEach(marker => {
        marker.removeEventListener('mouseover')
        marker.removeEventListener('mouseout')
      })
    })

    setOverlappingMarkerSpiderfier(oms)
  }, [map]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OverlappingPinSpiderfierContext.Provider value={overlappingMarkerSpiderfier}>
      {children}
    </OverlappingPinSpiderfierContext.Provider>
  )
}
