/* eslint no-loop-func: 0*/
/* eslint array-callback-return: 0*/
import React, { Component, useRef } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import YieldAnalysisChart from '../YieldAnalysisChart/YieldAnalysisChart'
import { browserHistory } from 'react-router'
import moment from 'moment'
import L from 'leaflet'
import { FaCalendar, FaLeaf, FaClock, FaArrowLeft, FaArrowUp } from 'react-icons/fa'
import { useRect } from '@reach/rect'
import classNames from 'classnames'
import styles from './ModernShow.module.scss'

const connectWrapper = connect(state => ({
  template: state.reports.report.data,
  activeSeason: state.seasons.activeSeason.data,
  account: state.account.data,
}))

const WorkOrderDescriptor = props => {
  const ref = useRef()

  // This is needed to get the correct width for the rotated
  const rect = useRect(ref)

  return (
    <div
      ref={ref}
      style={{
        display: 'inline-block',
        padding: 10,
        border: 'solid 1px',
        marginLeft: rect && (rect.width / 2) * -1,
      }}
      {...props}
    />
  )
}
class ModernShowComponent extends Component {
  constructor(props) {
    super(props)
    this.maps = {}

    const mappedWorkOrders = {}
    ;[0, 1].forEach(element => {
      if (props.template && props.template.workOrders && props.template.workOrders[element]) {
        const workOrder = props.template.workOrders[element]
        mappedWorkOrders[workOrder.position] = {
          id: workOrder.id,
          position: workOrder.position,
          dateFlown: moment(workOrder.dateFlown).format('MM/DD/YYYY'),
          growthStage: workOrder.growthStage,
          leafletId: `leafletmap${workOrder.position}`,
        }
      }
    })
    this.state = {
      title: props.template && props.template.title,
      //
      workOrders: mappedWorkOrders,
      //
      trialDetailsLocation: props.template && props.template.trialDetailsLocation,
      trialDetailsHybrid: props.template && props.template.trialDetailsHybrid,
      trialDetailsApplicationDate: props.template && props.template.trialDetailsApplicationDate,
      //
      contactPhone: props.template && props.template.contactPhone,
      contactName: props.template && props.template.contactName,
      //
      chemicalUsed: props.template && props.template.chemicalUsed,
      //
      trials: props.activeSeason.trials,
      // UI_Activate3rdWO: false
      primaryColor:
        props.primaryColor || (props.template && props.template.primaryColor) || '#0095FF',
      highlights: props.highlights || (props.template && props.template.highlights),
      commodityPrice: props.template.commodityPrice,
      logoUrl: props.template && props.template.logoUrl,
      footnote: props.template && props.template.footnote,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.initializeMaps()
    }, 100)
  }

  onNavigateTo(route) {
    browserHistory.push(route)
  }

  getCropType = () => {
    return this.props.activeSeason.crop.name
  }

  getMapLayerFromWorkOrderId(workOrderId) {
    const activeSeason = this.props.activeSeason
    if (activeSeason) {
      return activeSeason.mapLayers.find(layer => layer.workOrderId === parseInt(workOrderId, 10))
    }
  }

  initializeMaps = () => {
    const field = this.props.activeSeason.field

    for (const position in this.state.workOrders) {
      if (this.state.workOrders.hasOwnProperty(position)) {
        const map = L.map(`leafletmap${position}`, {
          center: [field.location[0], field.location[1]],
          zoomControl: false,
          attributionControl: false,
        }).fitBounds(field.bounds, {
          padding: [0, 0],
          animated: false,
        })

        map.touchZoom.disable()
        map.doubleClickZoom.disable()
        map.scrollWheelZoom.disable()
        map.boxZoom.disable()
        map.keyboard.disable()
        map.dragging.disable()

        this.maps[`leafletmap${position}`] = map
      }
    }

    if (this.props.activeSeason.trials && this.props.activeSeason.trials.length > 0) {
      const trialMap = L.map(`leafletTrials`, {
        center: [field.location[0], field.location[1]],
        zoomControl: false,
        attributionControl: false,
      }).fitBounds(field.bounds, {
        padding: [0, 0],
        animated: false,
      })
      trialMap.touchZoom.disable()
      trialMap.doubleClickZoom.disable()
      trialMap.scrollWheelZoom.disable()
      trialMap.boxZoom.disable()
      trialMap.keyboard.disable()
      trialMap.dragging.disable()

      if (field.geometry) {
        const fieldLayer = L.geoJson(field.geometry, {
          color: 'black',
          fillColor: 'none',
          weight: 2,
          opacity: 1,
          lineJoin: 'round',
          // 'dashArray': '20,15',
        })
        fieldLayer.addTo(trialMap)
      }
      this.props.activeSeason.trials.map((trial, index) => {
        const layer = L.geoJson(trial.polygon, {
          color: 'black',
          fillColor: 'none',
          weight: 1,
          opacity: 1,
          lineJoin: 'round',
          // 'dashArray': '20,15',
        })
        layer.addTo(trialMap)
        const label = L.marker(layer.getBounds().getCenter(), {
          clickable: false,
          keyboard: false,
          icon: L.divIcon({
            html: `(${index + 1})`,
            iconSize: [28, 28],
            className: 'leaflet-text-label',
          }),
        })

        label.addTo(trialMap)
      })
    }

    this.renderMaps()
  }

  renderMaps = () => {
    // TODO: Add the line drawings/polygons
    const field = this.props.activeSeason.field

    for (const position in this.state.workOrders) {
      if (this.state.workOrders.hasOwnProperty(position)) {
        const workOrder = this.state.workOrders[position]
        if (!workOrder.id) {
          return
        }

        const map = this.maps[workOrder.leafletId]

        if (map) {
          map.eachLayer(layer => {
            map.removeLayer(layer)
          })
        }
        const mapLayer = this.getMapLayerFromWorkOrderId(workOrder.id)
        L.tileLayer(mapLayer.tileUrl).addTo(map)

        if (field.geometry) {
          const fieldLayer = L.geoJson(field.geometry, {
            color: 'black',
            fillColor: 'none',
            weight: 2,
            opacity: 1,
            lineJoin: 'round',
            // 'dashArray': '20,15',
          })
          fieldLayer.addTo(map)
        }

        if (this.props.activeSeason.trials && this.props.activeSeason.trials.length > 0) {
          this.props.activeSeason.trials.map(trial => {
            const layer = L.geoJson(trial.polygon, {
              color: 'black',
              fillColor: 'none',
              weight: 2,
              opacity: 1,
              lineJoin: 'round',
              // 'dashArray': '20,15',
            })
            layer.addTo(map)
          })
        }
      }
    }
  }

  render() {
    // const akerLogo = require('assets/logo-aker-white.svg');
    const thermalLegend = require('../../../assets/legends/thermal-legend.png')
    const ndviLegend = require('../../../assets/legends/NDVI-legend.png')
    const brandColor = this.state.primaryColor
    // add some transparency to the brand color to make it 'lighter'.
    // const brandColorLight = this.state.primaryColor + '2e';
    const activeSeason = this.props.activeSeason
    const template = this.props.template

    const shouldShowLegend = wo => {
      if (!wo) {
        return false
      }

      if (wo.type === 'thermal' || wo.type === 'ndvi') {
        return true
      }
    }

    const renderWorkOrders = () => {
      const workOrdersComponent = []
      const workOrders = template.workOrders
      const renderTimePassage = (workOrder, comparerWorkOrder) => {
        if (workOrder && comparerWorkOrder) {
          const diff = Math.floor(
            (new Date(workOrder.dateFlown) - new Date(comparerWorkOrder.dateFlown)) / 86400000
          )
          if (diff && diff > 0) {
            return (
              <span>
                Time Passage: <b>{diff} Days</b>
              </span>
            )
          }
        }
        return (
          <span>
            Time Passage: <b>-</b>
          </span>
        )
      }
      for (let position = 0; position < workOrders.length; position++) {
        const wo = workOrders[position]
        workOrdersComponent.push(
          <div key={position} className={styles.workOrder}>
            <div className={styles.mapWithText}>
              <WorkOrderDescriptor className={styles.rotatedText} style={{ color: brandColor }}>
                {workOrders.length > 1 &&
                  (position > 0 ? 'After Application' : 'Before Application')}
              </WorkOrderDescriptor>
              <WorkOrderDescriptor style={{ color: brandColor }}>
                {workOrders.length === 1 && 'Application'}
              </WorkOrderDescriptor>
              <div
                className={classNames(styles.mapContainerContainer, {
                  'col-12': true,
                })}
              >
                <div className={styles.mapContainer}>
                  <div id={`leafletmap${position}`} style={{ height: 250 }}></div>
                </div>
              </div>
            </div>
            <div className={styles.iconContainer}>
              <div>
                <span className={styles.iconWrapper}>
                  <FaCalendar style={{ color: brandColor }} />
                </span>
                Date: <b>{moment.utc(wo.dateFlown).format('MM/DD/YYYY')}</b>
              </div>
              <div>
                <span className={styles.iconWrapper}>
                  <FaLeaf style={{ color: brandColor }} />
                </span>
                Crop Stage: <b>{wo.growthStage ? wo.growthStage : '-'}</b>
              </div>
              <div>
                <span className={styles.iconWrapper}>
                  <FaClock style={{ color: brandColor }} />
                </span>
                {renderTimePassage(wo, workOrders[0])}
              </div>
            </div>
            {shouldShowLegend(wo) && (
              <div className={styles.legendContainer}>
                <div>
                  {wo.type === 'thermal' && (
                    <div className={styles.layerLegendThermal}>
                      <img src={thermalLegend} alt={'legend for thermal imagery'} />
                    </div>
                  )}

                  {wo.type === 'ndvi' && (
                    <div className={styles.layerLegendNDVI}>
                      <img src={ndviLegend} alt={'legend for ndvi imagery'} />
                    </div>
                  )}
                  <div className={styles.legendLabels}>
                    <div>Healthy</div>
                    <div>Less Healthy</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }
      return workOrdersComponent
    }

    const calculateYieldAdvantage = () => {
      const yieldValues = this.state.trials.reduce((result, trial) => {
        if (trial.yield) {
          result.push(trial.yield)
        }
        return result
      }, [])
      yieldValues.sort((first, second) => {
        return first - second
      })

      if (yieldValues.length === 0) {
        return null
      } else if (yieldValues.length === 1) {
        return yieldValues[0].toFixed(1)
      }
      return (yieldValues[yieldValues.length - 1] - yieldValues[0]).toFixed(1)
    }
    const yieldAdvantage = calculateYieldAdvantage()

    const calculateRevenueAdvantage = () => {
      if (yieldAdvantage && this.state.commodityPrice) {
        return (yieldAdvantage * this.state.commodityPrice).toFixed(2)
      }
    }
    const revenueAdvantage = calculateRevenueAdvantage()

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Helmet title='View Test Plot' />
        <section className={styles.ModernShow + ' ' + styles.print}>
          <style
            dangerouslySetInnerHTML={{
              __html: `
                @media print {
                  .fa-leaf:before,
                  .fa-clock-o:before,
                  .fa-calendar:before,
                  .fa-circle:before,
                  .fa-arrow-up:before{
                    color: ${brandColor};
                  }
                }
            `,
            }}
          />

          <button
            type='button'
            className={`btn btn-link ${styles.tptBackBtn}`}
            onClick={() =>
              this.onNavigateTo(
                `/farm/${activeSeason.farmId}/field/${activeSeason.field.id}/season/${activeSeason.id}/reports`
              )
            }
          >
            <FaArrowLeft />
            &nbsp;Back
          </button>

          <div className='row'>
            <div className='col-7'>
              <div
                data-section='header-main'
                className={styles.pageTitle}
                style={{ color: '#0095ff' }}
              >
                {template.title}
              </div>

              <div className={styles.infoGrid}>
                <div className={styles.infoRow}>
                  <div className={styles.subHeader} style={{ color: brandColor }}>
                    On Farm Trial Details:
                  </div>
                </div>
                <div className={styles.infoRow}>
                  <div className={styles.infoLabel}>Crop:</div>
                  <div className={styles.infoValue}>
                    <span>{this.getCropType()}</span>
                  </div>
                </div>
                <div className={styles.infoRow}>
                  <div className={styles.infoLabel}>Hybrid/Variety:</div>
                  <div className={styles.infoValue}>
                    {template.seedType ? template.seedType : '-'}
                  </div>
                </div>
                <div className={styles.infoRow}>
                  <div className={styles.infoLabel}>Planting Date:</div>
                  <div className={styles.infoValue}>
                    {activeSeason.plantDate &&
                      moment.utc(activeSeason.plantDate).format('MM/DD/YYYY')}
                  </div>
                </div>
                <div className={styles.infoRow}>
                  <div className={styles.infoLabel}>Application Date:</div>
                  <div className={styles.infoValue}>
                    {template.applicationDate &&
                      moment.utc(template.applicationDate).format('MM/DD/YYYY')}
                  </div>
                </div>
                {this.state.trials && this.state.trials.length > 0 && (
                  <div>
                    <div className={styles.infoRow}>
                      <div className={styles.subHeader} style={{ color: brandColor }}>
                        Treatment Details:
                      </div>
                    </div>
                    <div className={styles.infoRow} style={{}}>
                      <div
                        className={styles.infoContent}
                        style={{
                          float: 'left',
                          width: '33%',
                        }}
                      >
                        {this.state.trials.map((trial, index) => {
                          return (
                            <div key={trial.id}>
                              <div>
                                <span style={{ fontWeight: 'bold' }}>{index + 1}:</span>{' '}
                                {trial.isUntreated
                                  ? 'No Fungicide Applied'
                                  : `${trial.productName.charAt(0).toUpperCase() +
                                      trial.productName.slice(1)}`}
                              </div>
                              <div>
                                <div>
                                  {trial.appliedComments &&
                                    trial.appliedComments.map(comment => {
                                      return (
                                        <li className={styles.appliedComment}>
                                          <span> {comment} </span>
                                        </li>
                                      )
                                    })}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div
                        className={styles.infoContent}
                        style={{
                          width: '67%',
                          borderLeft: '1px solid #e3e3e3',
                        }}
                      >
                        <div className={styles.trialsMapContainer}>
                          <div id={`leafletTrials`} style={{ width: '100%', height: 160 }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className='col-5'
              style={{
                height: 370,
              }}
            >
              {!this.props.account.companyLogo && (
                <img
                  className={styles.brandLogo}
                  style={{
                    width: '100%',
                  }}
                  src='http://www.aker.ag/wp-content/uploads/2018/04/icon3.png'
                  alt='Aker Logo featuring a circle with a field pattern within'
                />
              )}
              {this.props.account.companyLogo && (
                <img
                  className={styles.brandLogo}
                  style={{
                    width: '100%',
                    maxHeight: 370,
                  }}
                  src={this.props.account.companyLogo}
                  alt='Company Logo'
                />
              )}
            </div>
          </div>
          <div className={`row ${styles.plantHealthImagery}`}>
            <div
              className={styles.pageTitle}
              style={{
                color: '#0095ff',
                textAlign: 'center',
                marginTop: 5,
              }}
            >
              Plant Health Imagery
            </div>
            <div className={styles.mapFlexContainer}>{renderWorkOrders()}</div>
          </div>
          <div className={`row ${styles.yieldWrapper}`}>
            <div className='col-6'>
              {this.state.trials && this.state.trials.length > 1 && (
                <YieldAnalysisChart
                  trials={this.state.trials}
                  primaryColor={brandColor}
                  variation='B'
                />
              )}
            </div>
            <div className='col-6'>
              <div>
                <div
                  className={styles.pageTitle}
                  style={{
                    color: brandColor,
                    textAlign: 'center',
                  }}
                >
                  Yield Advantage on Average
                </div>
                <div className={styles.yieldValueWrapper}>
                  <FaArrowUp
                    style={{
                      color: brandColor,
                      fontSize: '40px',
                      marginRight: 10,
                    }}
                  />
                  <span className={styles.yieldValue}>
                    {yieldAdvantage ? `${yieldAdvantage}` : 'N/A'}
                  </span>
                  <div className={styles.units}>
                    <div>{yieldAdvantage && 'bushels'}</div>
                    <div>{yieldAdvantage && 'per acre'}</div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={styles.pageTitle}
                  style={{
                    color: brandColor,
                    textAlign: 'center',
                  }}
                >
                  Revenue Advantage on Average
                </div>
                <div className={styles.yieldValueWrapper}>
                  <FaArrowUp
                    style={{
                      color: brandColor,
                      fontSize: '40px',
                      marginRight: 10,
                    }}
                  />
                  <span className={styles.yieldValue}>
                    {revenueAdvantage ? `${revenueAdvantage}` : 'N/A'}
                  </span>
                  <div className={styles.units}>
                    <div>{revenueAdvantage && 'U.S dollars'}</div>
                    <div>{revenueAdvantage && 'per acre'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footerPrint}>
            <div className='row'>
              <div className='col-12' style={{ fontSize: 8 }}>
                {this.state.footnote}
              </div>
            </div>
            <div className='row' style={{ marginTop: 10 }}>
              <div className='col-12' style={{ fontSize: 8 }}>
                Aerial imagery and document template is copyright of Aker Technologies, Inc. All
                Rights Reserved.
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

ModernShowComponent.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  primaryColor: PropTypes.string,
  highlights: PropTypes.array,
  account: PropTypes.object.isRequired,
}
export default connectWrapper(ModernShowComponent)
