import React, { useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { DrawMLBoxes } from 'domains/ImageReviewer/DrawMLBoxes'
import styles from './FullScreenImage.module.scss'

export const FullScreenImage = ({ src, onClose, image, showMlMetadata, mlBoxLocations, setMlBoxLocations }) => {
  const portalRoot = document.getElementById('full-screen-image-root')
  const [color, setColor] = useState('#FFFFFF')
  const [rightClicked, setRightClicked] = useState(false)
  const [imageElementSizes, setImageElementSizes] = useState(null)
  const [canvasElement, setCanvasElement] = useState(null)

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu)
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
      document.removeEventListener('click', handleClick)
    }
  })

  const handleClick = useCallback(() => {
    rightClicked && setRightClicked(false)
  }, [rightClicked])

  const handleContextMenu = useCallback(e => {
    setRightClicked(true)
  }, [])

  const onLoad = () => {
    const metadata =
      typeof image.mlMetaData === 'string' ? JSON.parse(image.mlMetaData) : image.mlMetaData
    if (!image || !image.mlMetaData || !metadata.bounding_boxes) {
      setMlBoxLocations([])
    } else if (!mlBoxLocations) {
      const fullImageSize = [
        imageElementSizes.naturalWidth,
        imageElementSizes.naturalHeight,
      ]
      const imageBounds =
        typeof metadata.bounding_boxes === 'string'
          ? JSON.parse(metadata.bounding_boxes)
          : metadata.bounding_boxes
      const allBoxLocationValues =
        typeof metadata.bounding_boxes_value === 'string'
          ? JSON.parse(metadata.bounding_boxes_value)
          : metadata.bounding_boxes_value

      let mlLocations = []
      imageBounds.forEach((pt, index) => {
        const newPt = [0, 0]
        newPt[1] = pt[0] / fullImageSize[1]
        newPt[0] = pt[1] / fullImageSize[0]
        if (allBoxLocationValues[index] > 50) {
          mlLocations.push(newPt)
        }
      })
      setMlBoxLocations(mlLocations)
    }
  }

  const logClick = e => {
    let clickLocation = [e.nativeEvent.offsetX, e.nativeEvent.offsetY]
    const imageSize = [imageElementSizes.width, imageElementSizes.height]
    const imageNaturalSize = [imageElementSizes.naturalWidth, imageElementSizes.naturalHeight]
    const imageAdjust = [0, 0]
    const isTheExtraSpaceOnTheSideOrTop = imageSize[0] / imageSize[1] < imageNaturalSize[0] / imageNaturalSize[1]
    if (isTheExtraSpaceOnTheSideOrTop) {
      imageSize[1] = (imageSize[0] / imageNaturalSize[0]) * imageNaturalSize[1]
      imageAdjust[1] = (imageElementSizes.height - imageSize[1]) / 2
    } else {
      imageSize[0] = (imageSize[1] / imageNaturalSize[1]) * imageNaturalSize[0]
      imageAdjust[0] = (imageElementSizes.width - imageSize[0]) / 2
    }
    clickLocation[0] -= imageAdjust[0]
    clickLocation[1] -= imageAdjust[1]
    clickLocation[0] /= imageSize[0]
    clickLocation[1] /= imageSize[1]

    let removedPt = false
    let newMlBoxLocation = [...mlBoxLocations]
    for (let index = 0; index < mlBoxLocations.length; index++) {
      let pt = mlBoxLocations[index]
      const clickIsInsidePt = Math.abs(pt[0] - clickLocation[0]) < 25 / imageSize[0] &&
        Math.abs(pt[1] - clickLocation[1]) < 25 / imageSize[1]
      if (clickIsInsidePt) {
        newMlBoxLocation.splice(index, 1)
        removedPt = true
      }
    }
    if (!removedPt) {
      setMlBoxLocations([...newMlBoxLocation, clickLocation])
    } else {
      setMlBoxLocations(newMlBoxLocation)
    }
    setColor('#00FFFF')
  }

  const imageElementRef = node => {
    if (node !== null && !imageElementSizes) {
      const height = node.offsetHeight
      const width = node.offsetWidth
      const naturalHeight = node.naturalHeight
      const naturalWidth = node.naturalWidth
      setImageElementSizes({ height, width, naturalHeight, naturalWidth })
    }
  }

  const canvasElementRef = node => {
    if (node !== null && !canvasElement) {
      setCanvasElement(node)
    }
  }

  return createPortal(
    <div className={styles.outerContainer} onClick={onClose} onLoad={onLoad}>
      <button className={styles.removeTaggedFindingIcon}>X</button>
      <div className={`${styles.shadowContainer}`} onClick={onClose} onLoad={onLoad}>
        <div className={styles.innerContainer}>
          <img
            src={src}
            alt={src}
            onClick={e => e.stopPropagation()}
            ref={imageElementRef}
          />
          <canvas
            className={rightClicked ? styles.rightClicked : ''}
            onClick={e => {
              logClick(e)
              e.stopPropagation()
            }}
            ref={canvasElementRef}
          ></canvas>
          {showMlMetadata && imageElementSizes && canvasElement && (
            <DrawMLBoxes
              locations={mlBoxLocations}
              color={color}
              imageElement={imageElementSizes}
              canvasElement={canvasElement}
            />
          )}
        </div>
      </div>
    </div>,
    portalRoot
  )
}
