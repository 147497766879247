import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '_shared'
import styles from './Reports.module.scss'

function getDisplayName(trial) {
  return trial.isUntreated ? 'Untreated' : trial.productName
}

function TrialRow({ trial, deleteTrial }) {
  const title = getDisplayName(trial)

  const tableCells = () => (
    <button type='button' className='btn btn-link' onClick={() => deleteTrial(trial.uuid)}>
      Delete
    </button>
  )

  return <TableRow title={title} ternary tableCells={tableCells} />
}

export function TrialRows({ trials, deleteTrial }) {
  const sortAlphabeticallyByDisplayName = (trialA, trialB) => {
    const nameA = getDisplayName(trialA)
    const nameB = getDisplayName(trialB)
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    // names must be equal
    return 0
  }

  if (trials && trials.length > 0) {
    return trials
      .sort(sortAlphabeticallyByDisplayName)
      .map(trial => <TrialRow key={trial.id} trial={trial} deleteTrial={deleteTrial} />)
  } else {
    return <div className={styles.emptyTrials} />
  }
}

TrialRows.propTypes = {
  trials: PropTypes.array,
  deleteTrial: PropTypes.func,
}
