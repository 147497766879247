import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import styles from './UserAccess.module.scss'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { SideMenu } from 'components'
import { addSubscriptions, removeSubscriptions } from 'redux/modules/subscriptions'
import { getReviewerCountSummary, getUserAccessList } from 'redux/modules/imagereview'
import { ImageReviewerAccess } from './ImageReviewerAccess'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { dispatch } = options.store

      promises.push(dispatch(getReviewerCountSummary()))

      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    user: state.auth.user,
    userAccessList: state.imagereview.userAccessList.data,
    customerAccounts: state.imagereview.customerAccounts.data,
    subscribers: state.subscriptions.subscribers.data,
    removedSubscribers: state.subscriptions.removedSubscribers.data,
    customerAccountsErrors: state.imagereview.customerAccounts.error,
  }),
  {
    pushState: push,
    getUserAccessList,
    addSubscriptions,
    removeSubscriptions,
  }
)

class ImageReviewerAccessContainer extends Component {
  onCustomerAccountSelect = customerAccountId => {
    this.props.dispatch(getUserAccessList(customerAccountId))
  }

  refreshUserAccess = () => {
    this.props.dispatch(getReviewerCountSummary())
  }

  handleRemoveSubscriptions = subscriptionIds => {
    this.props.dispatch(removeSubscriptions(subscriptionIds))
  }

  handleAddSubscriptions = (type = null, params) => {
    const { resourceType, resourceId, subscriberParams } = params
    this.props.dispatch(addSubscriptions(resourceType, resourceId, subscriberParams))
  }

  render() {
    const { user, userAccessList, customerAccounts, subscribers, removedSubscribers } = this.props

    return (
      <div className={`${styles.userAccessContainer}`}>
        <Helmet title='Image Reviewer Summary' />
        <SideMenu user={user} />
        <div className={`container ${styles.userAccessWrapper}`}>
          <ImageReviewerAccess
            customerAccounts={customerAccounts}
            onCustomerAccountSelect={this.onCustomerAccountSelect}
            subscribers={subscribers}
            removedSubscribers={removedSubscribers}
            userAccessList={userAccessList}
            handleAddSubscriptions={this.handleAddSubscriptions}
            handleRemoveSubscriptions={this.handleRemoveSubscriptions}
            refreshUserAccess={this.refreshUserAccess}
          />
        </div>
      </div>
    )
  }
}

export default asyncConnectWrapper(connectWrapper(ImageReviewerAccessContainer))

ImageReviewerAccessContainer.propTypes = {
  user: PropTypes.object.isRequired,
  workOrders: PropTypes.array.isRequired,
}
