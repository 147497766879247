import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CheckboxInput } from '_shared'
import { ProbeDataImageContainer } from './ProbeDataImageContainer'
import styles from './ProbeData.module.scss'

export function ProbeDataByStressItem({
  stressItemData,
  active,
  updateStressItemSelections,
  hoveredMapPinId,
  ...props
}) {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(active)
  }, [active])

  const handleChange = () => {
    updateStressItemSelections(stressItemData.id, !isActive)
    setIsActive(!isActive)
  }

  return (
    <div className={styles.stressItemContainer}>
      <CheckboxInput
        name={stressItemData.name}
        label={stressItemData.name}
        value={isActive}
        onChange={handleChange}
      />
      <div className={styles.issuesContainer}>
        {isActive &&
          stressItemData.issues.map(issue => (
            <ProbeDataImageContainer
              key={issue.id}
              issueData={issue}
              observationNumber={issue.pinNumber}
              hovered={hoveredMapPinId === issue.id}
              {...props}
            />
          ))}
      </div>
    </div>
  )
}

ProbeDataByStressItem.propTypes = {
  stressItemData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    issues: PropTypes.array.isRequired,
  }),
  active: PropTypes.bool,
  updateStressItemSelections: PropTypes.func.isRequired,
  hoveredMapPinId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
