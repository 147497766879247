const GET_FARMS = 'aker/FARMS/GET_FARMS'
const GET_FARMS_SUCCESS = 'aker/FARMS/GET_FARMS_SUCCESS'
const GET_FARMS_FAIL = 'aker/FARMS/GET_FARMS_FAIL'

export const GET_FARM = 'aker/FARMS/GET_FARM'
export const GET_FARM_SUCCESS = 'aker/FARMS/GET_FARM_SUCCESS'
export const GET_FARM_FAIL = 'aker/FARMS/GET_FARM_FAIL'

const ADD_FARM = 'aker/FARMS/ADD_FARM'
const ADD_FARM_SUCCESS = 'aker/FARMS/ADD_FARM_SUCCESS'
const ADD_FARM_FAIL = 'aker/FARMS/ADD_FARM_FAIL'

const initialState = {
  farms: {
    loaded: false,
  },
  farm: {
    loaded: false,
  },
}

export default function farms(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FARMS:
      return {
        ...state,
        farms: {
          ...state.farms,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_FARMS_SUCCESS:
      return {
        ...state,
        farms: {
          ...state.farms,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_FARMS_FAIL:
      return {
        ...state,
        farms: {
          ...state.farms,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case GET_FARM:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case GET_FARM_SUCCESS:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_FARM_FAIL:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }

    case ADD_FARM:
      return {
        ...state,
        farm: {
          ...state.farm,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case ADD_FARM_SUCCESS:
      return {
        ...state,
        farms: {
          ...state.farms,
          data: [...state.farms.data, action.result.data],
        },
        farm: {
          ...state.farm,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case ADD_FARM_FAIL:
      const farmErrors = action.error.errors.map(error => {
        return {
          method: 'add',
          ...error,
        }
      })
      return {
        ...state,
        farm: {
          loaded: false,
          loading: false,
          error: farmErrors,
          data: null,
        },
      }
    default:
      return state
  }
}

export function isFarmsLoaded(globalState) {
  return globalState.farms && globalState.farms.farms && globalState.farms.farms.loaded
}

export function getFarms(customerAccountId = null) {
  let promise
  if (customerAccountId) {
    promise = client => client.get(`/customer_accounts/${customerAccountId}/farms`)
  } else {
    promise = client => client.get('/farms')
  }

  return {
    types: [GET_FARMS, GET_FARMS_SUCCESS, GET_FARMS_FAIL],
    promise,
  }
}

export function isFarmLoaded(globalState, farmId) {
  return (
    globalState.farms &&
    globalState.farms.farm &&
    globalState.farms.farm.loaded &&
    (farmId ? globalState.farms.farm.data.id === parseInt(farmId, 10) : true)
  )
}

export function getFarm(farmId) {
  return {
    types: [GET_FARM, GET_FARM_SUCCESS, GET_FARM_FAIL],
    promise: client => client.get(`/farm/${farmId}`),
  }
}

export function updateFarm(requestParams) {
  return {
    types: [GET_FARM, GET_FARM_SUCCESS, GET_FARM_FAIL],
    promise: client =>
      client.patch(`/farm/${requestParams.farmId}`, {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function addFarm(data) {
  return {
    types: [ADD_FARM, ADD_FARM_SUCCESS, ADD_FARM_FAIL],
    promise: client =>
      client.post('/farm', {
        data: {
          data,
        },
      }),
  }
}

export function deleteFarm(farmId) {
  return {
    types: [GET_FARMS, GET_FARMS_SUCCESS, GET_FARMS_FAIL],
    promise: client => client.del(`/farm/${farmId}/`),
  }
}
