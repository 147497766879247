import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LoadingState, OnOffSwitch } from '_shared'
import { ProbeDataByDate } from './ProbeDataByDate'
import styles from './ProbeData.module.scss'

export const ALL_PROBE_DATA_TAB_ACTION = 'ProbeDataTab/GET_PROBE_OBSERVATIONS'
export const FLAGGED_PROBE_DATA_TAB_ACTION = 'ProbeDataTab/GET_FLAGGED_PROBE_OBSERVATIONS'

export function DateOpenStatusParser(observations, selectedObservationDate) {
  return observations.reduce((openStatuses, observation) => {
    openStatuses[observation.observedAt] = selectedObservationDate === observation.observedAt
    return openStatuses
  }, {})
}

export function ProbeDataTab({ activeSeason, onDataChange, selectedObservationDate, ...props }) {
  const [showFlaggedObservations, setShowFlaggedObservations] = useState(false)
  const [dateOpenStatuses, setDateOpenStatuses] = useState({})
  const [observations, setObservations] = useState([])
  const [observationsLoaded, setObservationsLoaded] = useState(false)
  const [hasObservations, setHasObservations] = useState(false)

  useEffect(() => {
    setObservationsLoaded(false)
    setHasObservations(false)
  }, [showFlaggedObservations])

  // Due to a "delay", we are intentionally NOT using the global store here
  // We're using the raw response instead
  // Using the redux store, the user sees a 'flash' of old data before the dispatch for new data is sent
  useEffect(() => {
    const action = showFlaggedObservations
      ? FLAGGED_PROBE_DATA_TAB_ACTION
      : ALL_PROBE_DATA_TAB_ACTION

    onDataChange(action, activeSeason.id).then(response => {
      setObservationsLoaded(true)
      setHasObservations(response.data && response.data.length > 0)
      setObservations(response.data)
    })
  }, [activeSeason, onDataChange, showFlaggedObservations])

  useEffect(() => {
    if (observations && observations.length > 0) {
      setDateOpenStatuses(DateOpenStatusParser(observations, selectedObservationDate))
    }
  }, [observations, selectedObservationDate])

  return (
    <div className='container'>
      <h2 className='h3'>TrueCause Observations</h2>
      <OnOffSwitch
        name='Flagged Only'
        value={showFlaggedObservations}
        label='Flagged Only'
        onChange={() => setShowFlaggedObservations(!showFlaggedObservations)}
        className={styles.onOffToggle}
      />
      {!observationsLoaded && (
        <div className={styles.loadingContainer}>
          <LoadingState />
        </div>
      )}
      {observationsLoaded && !hasObservations && (
        <h3 className='h5'>We don't have TrueCause observations for this season yet</h3>
      )}
      {hasObservations &&
        observations.map(date => {
          return (
            <ProbeDataByDate
              key={date.observedAt.toString()}
              dateData={date}
              open={dateOpenStatuses[date.observedAt] || false}
              {...props}
            />
          )
        })}
    </div>
  )
}

ProbeDataTab.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  selectedObservationDate: PropTypes.string,
  onDataChange: PropTypes.func.isRequired,
}
