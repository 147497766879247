const CREATE_TEMPLATE = 'aker/TESTPLOTTOOL/CREATE_TEMPLATE'
const CREATE_TEMPLATE_SUCCESS = 'aker/TESTPLOTTOOL/CREATE_TEMPLATE_SUCCESS'
const CREATE_TEMPLATE_FAIL = 'aker/TESTPLOTTOOL/CREATE_TEMPLATE_FAIL'
const GET_TEMPLATES = 'aker/TESTPLOTTOOL/GET_TEMPLATES'
const GET_TEMPLATES_SUCCESS = 'aker/TESTPLOTTOOL/GET_TEMPLATES_SUCCESS'
const GET_TEMPLATES_FAIL = 'aker/TESTPLOTTOOL/GET_TEMPLATES_FAIL'
const GET_TEMPLATE = 'aker/TESTPLOTTOOL/GET_TEMPLATE'
const GET_TEMPLATE_SUCCESS = 'aker/TESTPLOTTOOL/GET_TEMPLATE_SUCCESS'
const GET_TEMPLATE_FAIL = 'aker/TESTPLOTTOOL/GET_TEMPLATE_FAIL'

const DELETE_TEMPLATE = 'aker/TESTPLOTTOOL/DELETE_TEMPLATE'
const DELETE_TEMPLATE_SUCCESS = 'aker/TESTPLOTTOOL/DELETE_TEMPLATE_SUCCESS'
const DELETE_TEMPLATE_FAIL = 'aker/TESTPLOTTOOL/DELETE_TEMPLATE_FAIL'

const initialState = {
  loaded: false,
}

export default function testplottool(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.data,
        errors: [],
      }
    case CREATE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }
    case GET_TEMPLATES:
      return {
        ...state,
        loading: true,
      }
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.data,
        errors: [],
      }
    case GET_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }
    case GET_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.data,
        errors: [],
      }
    case GET_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }
    case DELETE_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        data: null,
      }
    case DELETE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }

    default:
      return state
  }
}

export function createTemplate(params) {
  return {
    types: [CREATE_TEMPLATE, CREATE_TEMPLATE_SUCCESS, CREATE_TEMPLATE_FAIL],
    promise: client =>
      client.post('/templates', {
        data: {
          ...params,
        },
      }),
  }
}

export function saveTemplate(templateId, params) {
  return {
    types: [CREATE_TEMPLATE, CREATE_TEMPLATE_SUCCESS, CREATE_TEMPLATE_FAIL],
    promise: client =>
      client.patch(`/templates/${templateId}`, {
        data: {
          ...params,
        },
      }),
  }
}

export function getTemplates() {
  return {
    types: [GET_TEMPLATES, GET_TEMPLATES_SUCCESS, GET_TEMPLATES_FAIL],
    promise: client => client.get(`/templates`),
  }
}

export function getTemplate(templateId) {
  return {
    types: [GET_TEMPLATE, GET_TEMPLATE_SUCCESS, GET_TEMPLATE_FAIL],
    promise: client => client.get(`/templates/${templateId}`),
  }
}

export function updateTemplateStatus(templateId, published = false) {
  return {
    types: [GET_TEMPLATE, GET_TEMPLATE_SUCCESS, GET_TEMPLATE_FAIL],
    promise: client =>
      client.patch(`/templates/${templateId}/status`, {
        data: {
          published: published,
        },
      }),
  }
}

export function deleteTemplate(templateId) {
  return {
    types: [DELETE_TEMPLATE, DELETE_TEMPLATE_SUCCESS, DELETE_TEMPLATE_FAIL],
    promise: client => client.del(`/templates/${templateId}`),
  }
}
