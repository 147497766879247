import React, { useState } from 'react'
import PropTypes from 'prop-types'
import swal from '@sweetalert/with-react'
import { FaTrashAlt } from 'react-icons/fa'
import { CheckboxInput } from '_shared'
import styles from '../ImageReviewer.module.scss'

export function DeleteSelection({ imagesToDelete, onSelectAllClick, deleteImages, inCarousel }) {
  const [allSelected, setAllSelected] = useState(false)

  const handleSelectAllClick = () => {
    const selected = !allSelected
    setAllSelected(selected)
    onSelectAllClick(selected)
  }

  const onDeleteClick = async () => {
    const result = await swal({
      title: 'Confirm deletion',
      content: (
        <div>
          You have selected{' '}
          <strong>
            {imagesToDelete.length} image{imagesToDelete.length > 1 ? 's' : ''}
          </strong>
          . Deleting these images will permanently remove them from the database and cannot be
          undone.
        </div>
      ),
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Delete selection',
          value: true,
          visible: true,
          className: '',
          closeModal: false,
        },
      },
    })
    result && deleteImages(imagesToDelete)
  }

  return (
    <div className={`${styles.bulkDeleteButtonContainer} ${inCarousel ? styles.inCarousel : ''}`}>
      <CheckboxInput
        name='selectAll'
        label='Select all'
        value={allSelected}
        onChange={handleSelectAllClick}
        className={styles.imageReviewSelectAll}
      />
      <button
        className={`btn ${styles.bulkDeleteBtn} ${
          imagesToDelete.length === 0 ? styles.inactive : ''
        }`}
        onClick={onDeleteClick}
        disabled={imagesToDelete.length === 0}
      >
        <FaTrashAlt />
        Delete Selection
      </button>
    </div>
  )
}

DeleteSelection.propTypes = {
  imagesToDelete: PropTypes.array.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  deleteImages: PropTypes.func.isRequired,
  inCarousel: PropTypes.bool,
}
