import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty } from 'helpers'
import { OnOffSwitch } from '_shared'
import styles from './Field.module.scss'
import { AerialImageryDownload } from './AerialImageryDownload'

export function AerialImageryTab({ activeSeason, selectedFlight, updateSelectedFlight }) {
  const [hasFlights, setHasFlights] = useState(false)
  const [flightsByDate, setFlightsByDate] = useState({})
  const [selectedDownload, setSelectedDownload] = useState(null)
  
  useEffect(() => {
    const flights =
      !isEmpty(activeSeason) &&
      activeSeason.mapLayers.reduce((groupedFlights, flight) => {
        const key = moment(flight.dateFlown).format('MM/DD/YYYY')
        groupedFlights[key] = groupedFlights[key] || []
        groupedFlights[key].push({
          ...flight,
          label: flight.type ? flight.type.toUpperCase() : 'Flight',
        })
        return groupedFlights
      }, {})
    setHasFlights(activeSeason.mapLayers && activeSeason.mapLayers.length > 0)
    setFlightsByDate(flights)
  }, [activeSeason])

  const handleFlightToggle = flight => {
    // switching off
    if (selectedFlight && selectedFlight.id === flight.id) {
      updateSelectedFlight(0)
      // switching on
    } else {
      updateSelectedFlight(flight.id)
    }
  }

  return (
    <div className='container'>
      <h2 className='h3'>Aerial Imagery</h2>
      {hasFlights &&
        Object.keys(flightsByDate).map(key => (
          <React.Fragment key={key}>
            <h3>{moment(key).format('MM/DD/YYYY')}</h3>
            {flightsByDate[key].map(flight => {
              return (
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <OnOffSwitch
                    key={flight.id}
                    label={flight.label}
                    name={flight.id}
                    value={selectedFlight ? selectedFlight.id === flight.id : false}
                    onChange={() => handleFlightToggle(flight)}
                    className={styles.aerialImageryToggle}
                  />
                  <AerialImageryDownload
                    label={flight.label}
                    name={flight.id}
                    taskUUID={flight.uuid}
                    customerId={activeSeason.customerAccount.id}
                    selectedDownload={selectedDownload}
                    setSelectedDownload={setSelectedDownload}
                  />
                </div>
              )
            })}
          </React.Fragment>
        ))}

      {!hasFlights && <h3 className='h5'>We don't have aerial imagery for this season yet</h3>}
    </div>
  )
}

AerialImageryTab.propTypes = {
  activeSeason: PropTypes.object.isRequired,
}
