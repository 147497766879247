import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { LeafletMap } from 'domains'

/**
 * MapView
 * Shows a map containing elements based on the route.
 */
const enhance = compose(
  connect(state => ({
    field: state.fields.field.data,
  }))
)

export default enhance(
  class EditFieldMap extends Component {
    static propTypes = {
      field: PropTypes.object,
      setEditFieldPolygon: PropTypes.func,
    }

    handleEditFieldSave = fieldGeometryData => {
      this.props.setEditFieldPolygon({
        polygon: JSON.stringify(fieldGeometryData.polygon),
        size: fieldGeometryData.size,
        bounds: fieldGeometryData.bounds,
      })
    }

    render() {
      return (
        <LeafletMap
          drawingEnabled
          bounds={this.props.field.bounds}
          geometryToEdit={{ geometry: this.props.field.geometry }}
          setDrawnGeometry={this.handleEditFieldSave}
          hideTransparencySlider
        />
      )
    }
  }
)
