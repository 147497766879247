import React, { Component } from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import { push } from 'react-router-redux'

import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { SidePanel } from 'components/_shared'
import { FieldList } from './FieldList'
import { getFarm } from 'redux/modules/farms'
import { getFields } from 'redux/modules/fields'
import { clearAllErrors, clearError } from 'redux/modules/farmsandfields'
import { addField, deleteField } from 'redux/modules/fields'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { params, store } = options
      const { dispatch } = store
      if (params && params.farmId) {
        promises.push(dispatch(getFarm(params.farmId)))
        promises.push(dispatch(getFields(params.farmId)))
      } else {
        promises.push(dispatch(getFields()))
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    user: state.auth.user,
    fields: state.fields.fields,
    fieldsErrors: state.fields.fields.error,
    farm: state.farms.farm,
  }),
  {
    clearAllErrors,
    clearError,
    addField,
    deleteField,
    pushState: push,
  }
)

class FieldListContainer extends Component {
  render() {
    const { fields, farm } = this.props
    return (
      <React.Fragment>
        <Helmet title='Fields' />
        <SidePanel wide>
          <FieldList fields={fields.data} farm={farm.data} />
        </SidePanel>
      </React.Fragment>
    )
  }
}

export default asyncConnectWrapper(connectWrapper(FieldListContainer))

FieldListContainer.propTypes = {
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pushState: PropTypes.func.isRequired,
  clearAllErrors: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fieldsErrors: PropTypes.array,
  addField: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
}
