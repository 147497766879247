import React from 'react'
import { withRouter, Link } from 'react-router'
import PropTypes from 'prop-types'
import { Breadcrumbs, ListContainer, ListTitleHeader, ListSection } from '_shared'
import { SeasonData } from './SeasonData'
import styles from './Field.module.scss'

export function FieldViewComponent({
  field,
  seasons,
  handleSingleRegionToggle,
  regions,
  editRegion,
  cancelEditRegion,
  saveEditRegion,
  saveNewRegion,
  toggleAddNewRegion,
  deleteRegion,
  handleRegionInfoUpdate,
  regionToEdit,
  ...props
}) {
  const { permissions } = field
  const breadcrumbs = [
    { to: '/farms', text: 'All Farms' },
    { to: `/farms/${field.farm.id}`, text: field.farm.title },
    { text: field.title },
  ]

  return (
    <ListContainer key='field'>
      <Breadcrumbs items={breadcrumbs} />
      <ListTitleHeader
        title={null}
        selectedSection={false}
        className={styles.fieldViewTitleHeaderContainer}
      >
        <div>
          <h1 className='h3'>{field.title}</h1>
        </div>
        {permissions.canManage && (
          <div>
            <Link to={`/farm/${field.farm.id}/field/${field.id}/edit`} className='btn btn-default'>
              Edit field
            </Link>
          </div>
        )}
      </ListTitleHeader>
      <ListSection>
        <SeasonData
          field={field}
          seasons={seasons}
          handleSingleRegionToggle={handleSingleRegionToggle}
          regions={regions}
          editRegion={editRegion}
          cancelEditRegion={cancelEditRegion}
          saveEditRegion={saveEditRegion}
          saveNewRegion={saveNewRegion}
          toggleAddNewRegion={toggleAddNewRegion}
          deleteRegion={deleteRegion}
          handleRegionInfoUpdate={handleRegionInfoUpdate}
          regionToEdit={regionToEdit}
          {...props}
        />
      </ListSection>
    </ListContainer>
  )
}

export const FieldView = withRouter(FieldViewComponent)

FieldView.propTypes = {
  field: PropTypes.object.isRequired,
}
