import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from '../AerialImageryReport.module.scss'

export function YieldAnalysisChart({ regions, brandColor, variation }) {
  const [sortedRegions, setSortedRegions] = useState(regions || [])
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)
  const [intervals, setIntervals] = useState([])

  useEffect(() => {
    const linespace = (from, to, len) => {
      const arr = new Array(len)
      const step = (to - from) / (len - 1)
      let index = 0
      for (; index < len; ++index) {
        arr[index] = Math.round(from + index * step)
      }
      return arr
    }
    const sorted = [...regions].sort((r1, r2) => r2.yield - r1.yield)
    setSortedRegions(sorted)

    const yieldValues = [...sorted]
      .reverse()
      .filter(r => r.yield)
      .map(r => r.yield)

    if (yieldValues[0]) {
      const theoreticalMin = yieldValues[0] - 20 < 0 ? 0 : yieldValues[0] - 20
      const theoreticalMax = yieldValues[yieldValues.length - 1] + 10
      setMax(theoreticalMax)
      setMin(theoreticalMin)
      setIntervals(linespace(theoreticalMin, theoreticalMax, 6))
    }
  }, [regions])

  const renderLegend = () => {
    return (
      <div className={styles.topLegend}>
        {sortedRegions &&
          sortedRegions.map((region, index) => {
            return (
              <div
                key={region.id}
                style={{
                  display: 'inline-block',
                  width: `${100 / sortedRegions.length}%`,
                  textAlign: 'center',
                }}
              >
                <div>
                  <span
                    style={{
                      width: 15,
                      height: 15,
                      display: 'inline-block',
                      marginRight: 4,
                      boxShadow: `inset 0 0 0 1000px ${
                        index !== 0 ? '#ccc' : brandColor
                      } !important`,
                    }}
                  ></span>
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                    }}
                  >
                    {region.untreated ? 'Untreated' : region.name}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  const renderTreatmentRows = () => {
    const rows = sortedRegions.map((region, index) => {
      let widthPercentage = 0
      if (region.yield) {
        widthPercentage = (region.yield - min) / (max - min)
      }

      const barBackgroundColor = index !== 0 ? '#ccc' : brandColor
      const barTextColor = index !== 0 ? '#000' : '#fff'

      return (
        <div key={region.id} className={styles.yacRow}>
          <div
            className={styles.yacRowColor}
            style={{
              width: region.yield ? `${widthPercentage * 100}%` : 'auto',
              backgroundColor: barBackgroundColor,
              color: barTextColor,
            }}
          >
            {region.untreated ? 'Untreated' : region.name}
          </div>
          <div className={styles.yacRowNum}>{region.yield ? region.yield : 'N/A'}</div>
        </div>
      )
    })

    return rows
  }

  return (
    <div className={`${styles.YieldAnalysisChart} ${variation === 'B' && styles.variation}`}>
      {renderLegend()}
      <div className={styles.yacTop}>
        <div className={styles.yacName1}>Treatment</div>
        {renderTreatmentRows()}
      </div>
      <div className={styles.yacBottom} id='yacBottom'>
        {intervals.map((interval, index) => {
          if (intervals.length - 1 === index) {
            return null
          }
          return (
            <div key={interval} className={styles.yacBottomLegendRow}>
              <div className={styles.value}>{interval}</div>
            </div>
          )
        })}
      </div>
      <div className={styles.yacName2}>Yield (bushels/acre)</div>
    </div>
  )
}

YieldAnalysisChart.propTypes = {
  regions: PropTypes.array.isRequired,
  brandColor: PropTypes.string.isRequired,
  variation: PropTypes.string,
}
