import React from 'react'
import PropTypes from 'prop-types'
import styles from '../AerialImageryReport.module.scss'

export function ReportContactInfo({ brandColor, header, contactPhone, contactName, contactEmail }) {
  if (!contactName && !contactPhone && !contactEmail) return null

  return (
    <div className={styles.contactInfoContainer}>
      <h2 className='h4' style={{ color: brandColor }}>
        {header}
      </h2>
      <div>
        <p>
          {contactName && <span>{contactName}</span>}
          {contactPhone && <span>{` - ${contactPhone}`}</span>}
        </p>
        {contactEmail && (
          <p>
            <span>{contactEmail}</span>
          </p>
        )}
      </div>
    </div>
  )
}

ReportContactInfo.propTypes = {
  header: PropTypes.string.isRequired,
  contactPhone: PropTypes.string,
  contactName: PropTypes.string,
  contactEmail: PropTypes.string,
  brandColor: PropTypes.string.isRequired,
}
