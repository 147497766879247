import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import PropTypes from 'prop-types'
import styles from './Form.module.scss'

export function DatePicker({ name, label, value, onChange, beforeDate, ...props }) {
  const DATE_FORMAT = 'MM/DD/YYYY'

  const handleChange = day => {
    onChange(formatDate(day, DATE_FORMAT))
  }

  return (
    <div className={styles.formInput}>
      <label htmlFor={name}>{label}</label>

      <DayPickerInput
        id={name}
        name={name}
        disabledDays={beforeDate}
        onDayChange={handleChange}
        value={!!value ? parseDate(value) : undefined}
        selectedDay={!!value ? parseDate(value) : undefined}
        formatDate={formatDate}
        parseDate={parseDate}
        format={DATE_FORMAT}
        placeholder={`${formatDate(new Date(), DATE_FORMAT)}`}
        {...props}
        inputProps={{ className: 'form-control' }}
      />
    </div>
  )
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
}
