import React from 'react'
import { connect } from 'react-redux'
import { asyncConnect } from 'redux-connect'
import PropTypes from 'prop-types'
import { getSeason } from 'redux/modules/seasons'
import { getRegion, updateRegion } from 'redux/modules/regions'
import { EditRegionFormAndMap } from './EditRegionFormAndMap'

const asyncConnectWrapper = asyncConnect([
  {
    deferred: false,
    promise: options => {
      const promises = []
      const { params, store } = options
      const { dispatch } = store
      if (params && params.seasonId) {
        promises.push(dispatch(getSeason(params.seasonId)))
      }
      if (params && params.regionId) {
        promises.push(dispatch(getRegion(params.regionId)))
      }
      return Promise.all(promises)
    },
  },
])

const connectWrapper = connect(
  state => ({
    season: state.seasons.activeSeason.data,
    region: state.regions.region.data,
  }),
  {
    updateRegion,
  }
)

function EditRegionContainer({ season, region, updateRegion }) {
  return <EditRegionFormAndMap season={season} region={region} updateRegion={updateRegion} />
}

export default asyncConnectWrapper(connectWrapper(EditRegionContainer))

EditRegionContainer.propTypes = {
  region: PropTypes.object.isRequired,
}
