import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LeafletMap } from 'domains'

/**
 * MapView
 * Shows a map containing elements based on the route.
 */
const connectWrapper = connect((state, ownProps) => ({
  field: state.fields.field.data,
  ownProps,
}))

function FieldViewMapComponent({ field }) {
  return (
    <LeafletMap bounds={field.bounds} boundaryGeometry={field.geometry} hideTransparencySlider />
  )
}

export default connectWrapper(FieldViewMapComponent)

FieldViewMapComponent.propTypes = {
  ownProps: PropTypes.object.isRequired,
  field: PropTypes.object,
}
