import React from 'react'
import { Link } from 'react-router'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { PlusIcon } from '_shared'
import styles from './AddButton.module.scss'

export default function AddButton({ onClick, to, buttonText, primary, secondary }) {
  let variant = 'default'
  let iconProps = {
    cloudburst: true,
  }

  if (primary) {
    variant = 'primary'
    iconProps = {
      cloudburst: false,
      white: true,
    }
  } else if (secondary) {
    variant = 'secondary'
    iconProps = {
      cloudburst: false,
      azure: true,
    }
  }

  function buttonInnerContent() {
    return (
      <React.Fragment>
        <span className='icon-align-left'>
          <PlusIcon {...iconProps} />
        </span>
        <span className={styles.buttonLabel}>{buttonText}</span>
      </React.Fragment>
    )
  }

  return (
    <div className={styles.buttonContainer}>
      {onClick && (
        <Button variant={variant} onClick={onClick}>
          {buttonInnerContent()}
        </Button>
      )}
      {!onClick && to && (
        <Link to={to} className={`btn btn-${variant}`}>
          {buttonInnerContent()}
        </Link>
      )}
    </div>
  )
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
}
