import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { FaCaretDown } from 'react-icons/fa'
import styles from './Form.module.scss'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaCaretDown color='black' />
    </components.DropdownIndicator>
  )
}

export function Dropdown({ name, label, value, onChange, options, className, ...props }) {
  const customStyles = {
    indicatorSeparator: base => ({
      ...base,
      background: 'none',
    }),
    menu: base => ({
      ...base,
      'z-index': 10,
    }),
  }

  return (
    <div className={`${className} ${!!label ? styles.formInput : ''}`}>
      {!!label && <label htmlFor={name}>{label}</label>}
      <Select
        name={name}
        inputId={name}
        options={options}
        onChange={onChange}
        value={value}
        components={{ DropdownIndicator }}
        styles={customStyles}
        {...props}
      />
    </div>
  )
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}
