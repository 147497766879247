import React, { useState } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import swal from '@sweetalert/with-react'
import { SidePanel } from 'components/_shared'
import { Breadcrumbs } from '_shared'
import { LeafletMap, PolygonToFeatureGeometry } from 'domains'
import { RegionForm } from './RegionForm'
import styles from './Region.module.scss'

export function EditRegionFormAndMap({ season, region, updateRegion }) {
  const [regionGeometryData, setRegionGeometryData] = useState({
    bounds: region.bounds,
    polygon: PolygonToFeatureGeometry(region.geometry),
    size: region.size,
  })

  const breadcrumbs = [
    { to: '/farms', text: 'All Farms' },
    { to: `/farms/${season.farm.id}`, text: season.farm.title },
    { to: `/farms/${season.farm.id}/field/${season.field.id}`, text: season.field.title },
    {
      to: `/farms/${season.farm.id}/field/${season.field.id}/season/${season.id}/edit`,
      text: season.title,
    },
  ]

  const handleSave = async formData => {
    if (!regionGeometryData.size || !regionGeometryData.bounds || !regionGeometryData.polygon) {
      await swal({
        title: 'Oops, please draw a region',
        text: 'It looks like your region boundaries are invalid. Please draw a valid region.',
        icon: 'error',
        closeModal: true,
      })
      return
    }

    const requestParams = {
      regionId: region.id,
      size: regionGeometryData.size,
      bounds: regionGeometryData.bounds,
      polygon: JSON.stringify(regionGeometryData.polygon),
      ...formData,
    }
    try {
      await updateRegion(requestParams)

      await swal({
        title: 'Great, your region has been updated!',
        icon: 'success',
        closeModal: true,
      })
      browserHistory.push(
        `/farms/${season.farmId}/field/${season.field.id}/season/${season.id}/edit`
      )
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeModal: true,
      })
    }
  }

  return (
    <React.Fragment>
      <Helmet title='Edit Region' />
      <div className='Sidebar'>
        <SidePanel background={false}>
          <Breadcrumbs items={breadcrumbs} linkLastItem />
          <div className={styles.regionForm}>
            <h1>Edit Region</h1>
            <RegionForm season={season} region={region} handleSave={handleSave} />
          </div>
        </SidePanel>
      </div>
      <div className='Map'>
        <LeafletMap
          drawingEnabled
          bounds={season.field.bounds}
          boundaryGeometry={season.field.geometry}
          geometryToEdit={regionGeometryData.polygon}
          setDrawnGeometry={setRegionGeometryData}
          hideTransparencySlider
        />
      </div>
    </React.Fragment>
  )
}

EditRegionFormAndMap.propTypes = {
  season: PropTypes.object.isRequired,
  region: PropTypes.object.isRequired,
  updateRegion: PropTypes.func,
}
