import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TableRow } from '_shared'
import styles from './Reports.module.scss'

export function ReportRow({ report, deleteReport, season }) {
  const { farm, field } = season
  const [showShareDropDown, setShowShareDropDown] = useState(false)
  const bucket =
    process.env.REACT_APP_ENV === 'production' ? "aker-reports" : "aker-reports-staging"
  const customer = report.customerAccount ? report.customerAccount.id : null
  const s3PdfUrl = `https://${bucket}.s3.amazonaws.com/${customer}/${report.uuid}.pdf`

  let viewLinkHref, editLinkHref
  // legacy report
  if (report.type === 'LegacyReport') {
    viewLinkHref = `/farm/${farm.id}/field/${field.id}/season/${season.id}/test-plot-tool/${report.id}/show`
  } else if (report.type === 'AerialImageryReport') {
    viewLinkHref = `/farm/${farm.id}/field/${field.id}/season/${season.id}/aerial-imagery-report/${report.id}`
    editLinkHref = `/farm/${farm.id}/field/${field.id}/season/${season.id}/aerial-imagery-report/${report.id}/edit`
  }

  const copyReportLink = () => {
    navigator.clipboard.writeText(s3PdfUrl)
  }

  const downloadReport = async () => {
    window.open(s3PdfUrl)
  }

  const title = report.title ? report.title : 'NOT NAMED'
  const subtitle = `Last Updated: ${moment(report.updatedAt).format('MM/DD/YYYY')}`

  const tableCells = () => (
    <React.Fragment>
      <a href={viewLinkHref} className='btn btn-link'>
        View
      </a>
      {editLinkHref && (
        <a href={editLinkHref} className='btn btn-link'>
          Edit
        </a>
      )}
      <div className={styles.shareContainer}>
        <button
          type='button'
          className='btn btn-link'
          onClick={() => setShowShareDropDown(!showShareDropDown)}
        >
          Share
        </button>
        <div className={`${styles.shareDropDown} ${showShareDropDown && styles.show}`} id='test-id'>
          <button onClick={downloadReport}>
            Download</button>
          <span> | </span>
          <button onClick={copyReportLink}>Copy Link</button>
        </div>
      </div>
      <button
        type='button'
        className='btn btn-link btn-delete'
        onClick={() => deleteReport(report)}
      >
        Delete
      </button>
    </React.Fragment>
  )

  return <TableRow title={title} subtitle={subtitle} ternary tableCells={tableCells} />
}

export function ReportRows({ reports, deleteReport, season }) {
  const sortByUpdatedAtDescending = (reportA, reportB) => {
    return new Date(reportB.updatedAt) - new Date(reportA.updatedAt)
  }

  const hasReports = reports && reports.length > 0
  if (hasReports) {
    return reports
      .sort(sortByUpdatedAtDescending)
      .map(report => (
        <ReportRow key={report.id} report={report} deleteReport={deleteReport} season={season} />
      ))
  } else {
    return <div className={styles.emptyReports}>You have no reports</div>
  }
}

ReportRows.propTypes = {
  reports: PropTypes.array,
  deleteReport: PropTypes.func,
}
