import React, { useState, useEffect, createContext } from 'react'
import ApiClient from 'helpers/ApiClient'

export const CustomerAccountsContext = createContext({
  customerAccounts: [],
  selectedCustomerAccountId: null,
  setSelectedCustomerAccountId: () => {},
  refreshCustomerAccounts: () => {},
})

export default function CustomerAccountsProvider({ children }) {
  const client = new ApiClient()
  const [customerAccounts, setCustomerAccounts] = useState([])
  const [selectedCustomerAccountId, setSelectedCustomerAccountId] = useState(null)

  const refreshCustomerAccounts = () => {
    client.get('/customer_accounts').then(response => {
      setCustomerAccounts(response.data || [])
    })
  }

  useEffect(() => {
    refreshCustomerAccounts()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomerAccountsContext.Provider
      value={{
        customerAccounts: customerAccounts,
        selectedCustomerAccountId: selectedCustomerAccountId,
        setSelectedCustomerAccountId: setSelectedCustomerAccountId,
        refreshCustomerAccounts: refreshCustomerAccounts,
      }}
    >
      {children}
    </CustomerAccountsContext.Provider>
  )
}
