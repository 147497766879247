import React from 'react'
import { DayPicker } from 'react-day-picker'
import styles from './DayRangePicker.module.scss'

export function DayRangePicker({
  beforeDate,
  handleDayClick,
  reduxInput,
  from,
  to,
  modifiers,
  ...props
}) {
  return (
    <React.Fragment>
      {!from && !to && 'Please select the first day.'}
      {from && !to && 'Please select the last day.'}
      {from &&
        to &&
        `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}
      <DayPicker
        {...reduxInput}
        className={styles.DayRangePicker}
        key='requestedDates'
        id='requestedDates'
        disabledDays={beforeDate}
        onDayClick={(day, modifiers, e) => handleDayClick(day, modifiers)}
        modifiers={modifiers}
        selectedDays={[from, { from, to }]}
        numberOfMonths={2}
      />
    </React.Fragment>
  )
}
