/* eslint no-loop-func: 0*/
/* eslint array-callback-return: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import moment from 'moment'
import L from 'leaflet'
import classNames from 'classnames'
import { FaArrowLeft } from 'react-icons/fa'
import { WorkOrderComponent } from './WorkOrderComponent'
import { ContactInfoComponent } from './ContactInfoComponent'
import { BulletedText } from '../BulletedTextComponent'
import YieldAnalysisChart from '../../YieldAnalysisChart/YieldAnalysisChart'
import styles from './RevXResultsToolShow.module.scss'

const connectWrapper = connect(state => ({
  template: state.reports.report.data,
  activeSeason: state.seasons.activeSeason.data,
  account: state.account.data,
}))

export class RevXResultsToolShowComponent extends Component {
  constructor(props) {
    super(props)
    this.maps = {}

    const highlights = [
      'Plants can’t get up and move, so when they’re impacted by stresses like disease or adverse environmental conditions, they divert energy and resources away from yield production to survival.',
      'BASF Plant Health fungicides can mitigate the impacts of these stresses, allowing crops to maximize photosynthetic potential and yield, no matter what Mother Nature throws their way.',
      'NDVI imagery can help you See the Difference of increased biomass and greening and overall crop health.',
    ]

    const mappedWorkOrders = {}
    ;[0, 1].forEach(element => {
      if (props.template && props.template.workOrders && props.template.workOrders[element]) {
        const workOrder = props.template.workOrders[element]
        mappedWorkOrders[workOrder.position] = {
          id: workOrder.id,
          position: workOrder.position,
          dateFlown: moment(workOrder.dateFlown).format('MM/DD/YYYY'),
          growthStage: workOrder.growthStage,
          leafletId: `leafletmap${workOrder.position}`,
        }
      }
    })
    this.state = {
      title: props.template && props.template.title,
      //
      workOrders: mappedWorkOrders,
      //
      trialDetailsLocation: props.template && props.template.trialDetailsLocation,
      trialDetailsHybrid: props.template && props.template.trialDetailsHybrid,
      trialDetailsApplicationDate: props.template && props.template.trialDetailsApplicationDate,
      //
      contactPhone: props.template && props.template.contactPhone,
      contactName: props.template && props.template.contactName,
      //
      chemicalUsed: props.template && props.template.productHeader,
      //
      trials: props.activeSeason.trials,
      primaryColor:
        props.primaryColor || (props.template && props.template.primaryColor) || '#0095FF',
      highlights: highlights,
      commodityPrice: props.template.commodityPrice,
      logoUrl: props.template && props.template.logoUrl,
      footnote: props.template && props.template.footnote,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.initializeMaps()
    }, 100)
  }

  onNavigateTo(route) {
    browserHistory.push(route)
  }

  getCropType = () => {
    return this.props.activeSeason.crop.name
  }

  getMapLayerFromWorkOrderId(workOrderId) {
    const activeSeason = this.props.activeSeason
    if (activeSeason) {
      return activeSeason.mapLayers.find(layer => layer.workOrderId === parseInt(workOrderId, 10))
    }
  }

  trialsHaveYield = () => {
    const trialsWithYield = this.state.trials.filter(trial => trial.yield)
    return trialsWithYield.length > 0
  }

  initializeMaps = () => {
    const currentField = this.props.activeSeason.field
    const fieldCenter = [currentField.location[0], currentField.location[1]]

    for (const position in this.state.workOrders) {
      if (this.state.workOrders.hasOwnProperty(position)) {
        const map = L.map(`leafletmap${position}`, {
          center: fieldCenter,
          zoomControl: false,
          attributionControl: false,
        }).fitBounds(currentField.bounds, {
          padding: [0, 0],
          animated: false,
        })

        map.touchZoom.disable()
        map.doubleClickZoom.disable()
        map.scrollWheelZoom.disable()
        map.boxZoom.disable()
        map.keyboard.disable()
        map.dragging.disable()

        this.maps[`leafletmap${position}`] = map
      }
    }

    if (this.props.activeSeason.trials && this.props.activeSeason.trials.length > 0) {
      const trialMap = L.map(`leafletTrials`, {
        center: fieldCenter,
        zoomControl: false,
        attributionControl: false,
      }).fitBounds(currentField.bounds, {
        padding: [0, 0],
        animated: false,
      })
      trialMap.touchZoom.disable()
      trialMap.doubleClickZoom.disable()
      trialMap.scrollWheelZoom.disable()
      trialMap.boxZoom.disable()
      trialMap.keyboard.disable()
      trialMap.dragging.disable()

      const baseLayer = 'satellite'
      L.control.layers(baseLayer).addTo(trialMap)

      if (currentField.geometry) {
        const fieldLayer = L.geoJson(currentField.geometry, {
          color: 'black',
          fillColor: 'none',
          weight: 2,
          opacity: 1,
          lineJoin: 'round',
        })
        fieldLayer.addTo(trialMap)
      }
      this.props.activeSeason.trials.map((trial, index) => {
        const layer = L.geoJson(trial.polygon, {
          color: 'black',
          fillColor: 'none',
          weight: 1,
          opacity: 1,
          lineJoin: 'round',
          // 'dashArray': '20,15',
        })
        layer.addTo(trialMap)
        const label = L.marker(layer.getBounds().getCenter(), {
          clickable: false,
          keyboard: false,
          icon: L.divIcon({
            html: `(${index + 1})`,
            iconSize: [28, 28],
            className: 'leaflet-text-label',
          }),
        })

        label.addTo(trialMap)
      })
    }

    this.renderMaps()
  }

  renderMaps = () => {
    const { activeSeason } = this.props

    for (const position in this.state.workOrders) {
      if (this.state.workOrders.hasOwnProperty(position)) {
        const workOrder = this.state.workOrders[position]
        if (!workOrder.id) {
          return
        }

        const map = this.maps[workOrder.leafletId]

        if (map) {
          map.eachLayer(layer => {
            map.removeLayer(layer)
          })
        }
        const mapLayer = this.getMapLayerFromWorkOrderId(workOrder.id)
        const basePath = 'https://api.mapbox.com/styles/v1/'
        const accessToken =
          'pk.eyJ1IjoiYWtlcmFnIiwiYSI6ImNqazczOTNqNzI1YXMzcW50MHF3NTg3dDMifQ.mQ7ADlqYrVVwJVO9cWqiKQ'
        const baseLayerUrl =`${basePath}{id}/{z}/{x}/{y}?access_token=${accessToken}` // eslint-disable-line no-unused-vars

        L.tileLayer(baseLayerUrl, {
          id: 'akerag/ckg8f9hmk06e819mospasxho4/tiles',
          maxZoom: 20,
          maxNativeZoom: 19,
          tileSize: 512,
          zoomOffset: -1      
        }).addTo(map)

        L.tileLayer(mapLayer.tileUrl).addTo(map)

        if (activeSeason.trials && activeSeason.trials.length > 0) {
          activeSeason.trials.map(trial => {
            const layer = L.geoJson(trial.polygon, {
              color: 'black',
              fillColor: 'none',
              weight: 2,
              opacity: 1,
              lineJoin: 'round',
            })
            layer.addTo(map)
          })
        }
      }
    }
  }

  render() {
    const akerLogo = require('assets/logo-aker-blue.svg')
    const brandColor = this.state.primaryColor
    // add some transparency to the brand color to make it 'lighter'.
    const brandColorLight = this.state.primaryColor + '2e'
    const highlights = this.state.highlights
    const { activeSeason, template } = this.props

    const daysSinceApplication = workOrder => {
      const { applicationDate } = this.props.template
      const recentFlightDate = moment.utc(workOrder.dateFlown)
      let days

      if (applicationDate) {
        const applDate = moment.utc(applicationDate)
        days = Math.floor(moment.duration(recentFlightDate.diff(applDate)).asDays())
      }
      return days && days > 0 ? days.toString() : '--'
    }

    const renderWorkOrders = () => {
      return template.workOrders.map((workOrder, index) => (
        <WorkOrderComponent
          brandColor={brandColor}
          index={index}
          timeSinceApplication={daysSinceApplication(workOrder)}
          workOrder={workOrder}
          workOrdersCount={template.workOrders.length}
          key={workOrder.id}
        />
      ))
    }

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Helmet title='View RevX Results' />
        <section className={classNames(styles.RevXResultsTool, styles.print)}>
          <style
            dangerouslySetInnerHTML={{
              __html: `
                @media print {
                  .fa-circle:before,
                  .fa-arrow-up:before{
                    color: ${brandColor};
                  }
                }
            `,
            }}
          />

          <button
            type='button'
            className={classNames('btn', 'btn-link', styles.tptBackBtn)}
            onClick={() =>
              this.onNavigateTo(
                `/farm/${activeSeason.farmId}/field/${activeSeason.field.id}/season/${activeSeason.id}/reports`
              )
            }
          >
            <FaArrowLeft />
            &nbsp;Back
          </button>

          <div className='row'>
            <div className={styles.twoThirds} />
            <div
              className={styles.oneThird}
              style={{ overflow: 'hidden', height: 40, backgroundColor: brandColorLight }}
            />
          </div>

          <div className='row'>
            <div className={styles.brandedHeaderBarContainer}>
              <div className={styles.brandedHeaderBar}>
                <div className={classNames(styles.twoThirds, styles.akerInner)}>
                  <img className={styles.brandLogo} alt='Company Logo' src={akerLogo} />
                </div>
                <div
                  className={classNames(styles.oneThird, styles.basfInner)}
                  style={{ backgroundColor: brandColor }}
                >
                  <img
                    className={styles.brandLogo}
                    alt='Company Logo'
                    src={this.props.account.companyLogo ? this.props.account.companyLogo : akerLogo}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className={classNames('main-content', styles.twoThirds)}>
              <div data-section='header-main' style={{ color: brandColor }}>
                <h1 className={styles.h1}>Experience the Difference with BASF Plant Health</h1>
              </div>
              <div className={styles.revXContainer}>
                <div className={styles.pageTitle}>{template.title} </div>
                <div>{renderWorkOrders()}</div>
              </div>
              {this.trialsHaveYield() && (
                <div data-section='analysis' className={styles.revXContainer}>
                  <h2 className={styles.h2}>Yield Analysis</h2>
                  <div className='chart'>
                    <YieldAnalysisChart trials={this.state.trials} primaryColor={brandColor} />
                  </div>
                </div>
              )}

              <div className={classNames('row', styles.footer)}>
                <div className={classNames('col-12', styles.small)}>{this.state.footnote}</div>
              </div>
              <div className={classNames('row', styles.footer)}>
                <div className={classNames('col-12', styles.small)}>
                  Aerial imagery and document template is copyright of Aker Technologies, Inc. All
                  Rights Reserved.
                </div>
              </div>
            </div>

            <div
              className={classNames(styles.oneThird, styles.revXSidebar)}
              style={{ overflow: 'hidden', backgroundColor: brandColorLight }}
            >
              <div style={{ zIndex: 3 }}>
                <div className={styles.revXContainer}>
                  <h2 className={styles.h2} style={{ color: brandColor }}>
                    RevX Field Details
                  </h2>
                  <div>
                    <BulletedText key='location' brandColor={brandColor}>
                      <div className={styles.highlight}>
                        Location: <b>{template.location}</b>
                      </div>
                    </BulletedText>
                    <BulletedText key='crop' brandColor={brandColor}>
                      <div className={styles.highlight}>
                        Crop: <b>{template.crop}</b>
                      </div>
                    </BulletedText>
                    <BulletedText key='product' brandColor={brandColor}>
                      <div className={styles.highlight}>
                        BASF PH Product: <b>{template.productHeader}</b>
                      </div>
                    </BulletedText>
                    {template.applicationDate && (
                      <BulletedText key='application-date' brandColor={brandColor}>
                        <div className={styles.highlight}>
                          Application Date:{' '}
                          <b>{moment.utc(template.applicationDate).format('MM/DD/YYYY')}</b>
                        </div>
                      </BulletedText>
                    )}

                    {this.state.trials && this.state.trials.length !== 0 && (
                      <BulletedText key='trials' brandColor={brandColor}>
                        <div className={styles.highlight}>Competitive Treatment(s): </div>
                      </BulletedText>
                    )}
                    {this.state.trials &&
                      this.state.trials.length !== 0 &&
                      this.state.trials.map((trial, index) => {
                        return (
                          <div key={trial.id} className={styles.treatmentDetail}>
                            <div>
                              ({index + 1}){' '}
                              {trial.isUntreated
                                ? 'No Fungicide Applied'
                                : `${trial.productName.charAt(0).toUpperCase() +
                                    trial.productName.slice(1)}`}
                            </div>
                            <div>
                              <div className={styles.treatmentDetailsList}>
                                {trial.appliedComments &&
                                  trial.appliedComments.map((comment, commentIndex) => {
                                    return (
                                      <li key={commentIndex} className={styles.appliedComment}>
                                        <span> {comment} </span>
                                      </li>
                                    )
                                  })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    {this.state.trials && this.state.trials.length > 0 && (
                      <div className={styles.trialsMapContainer}>
                        <div id={`leafletTrials`} style={{ width: '100%', height: 200 }}></div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.revXContainer}>
                  {highlights && highlights.length > 0 && (
                    <div className={styles.revXContainer}>
                      <h2 className={styles.h2} style={{ color: brandColor }}>
                        Why Aker Ag Drone Imagery?
                      </h2>
                      <div>
                        {highlights.map((highlight, index) => {
                          return (
                            <BulletedText key={`highlight-${index}`} brandColor={brandColor}>
                              <div className={styles.highlight}>{highlight}</div>
                            </BulletedText>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {(this.state.contactName || this.state.contactPhone) && (
                  <ContactInfoComponent
                    brandColor={brandColor}
                    contactName={this.state.contactName}
                    contactPhone={this.state.contactPhone}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

RevXResultsToolShowComponent.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  primaryColor: PropTypes.string,
  account: PropTypes.object.isRequired,
}

export default connectWrapper(RevXResultsToolShowComponent)
