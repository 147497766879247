import React from 'react'

const ImportKMLField = ({
  loading,
  importedFieldData,
  selectedImportedFieldId,
  passSelectedImportedFieldId,
}) => {
  const handleMouseEventOnFieldName = (fieldId, event) => {
    const { id, mouseEvent } = selectedImportedFieldId
    const hoveringNoFields = !fieldId && !event
    const hoveringDifferentField = fieldId !== id && event === mouseEvent
    const hoverEventChange = hoveringDifferentField || hoveringNoFields
    if (hoverEventChange) {
      importedFieldData.forEach(field => {
        const currentFieldId = field.importedFieldId
        const importMarkers = document.getElementsByClassName(`importMarker-${currentFieldId}`)
        if (importMarkers[0]) {
          importMarkers[0].style.height = importMarkers[0].previousHeight
          importMarkers[0].style.width = importMarkers[0].previousWidth
          importMarkers[0].style.opacity = importMarkers[0].previousOpacity
        }
      })
    }
    if (event === 'hover') {
      resizeMarkerOnHover(fieldId)
    }
    passSelectedImportedFieldId(fieldId, event)
  }

  const resizeMarkerOnHover = fieldId => {
    importedFieldData.forEach(field => {
      if (fieldId === +field.importedFieldId) {
        const importMarkers = document.getElementsByClassName(`importMarker-${fieldId}`)
        if (importMarkers[0]) {
          importMarkers[0].previousHeight = importMarkers[0].style.height
          importMarkers[0].previousWidth = importMarkers[0].style.width
          importMarkers[0].previousOpacity = importMarkers[0].style.opacity
          importMarkers[0].style.height = '20px'
          importMarkers[0].style.width = '20px'
          importMarkers[0].style.opacity = '90%'
        }
      }
    })
  }

  return (
    <>
      <h2>Select field to import</h2>
      <div className='create-field-import-body'>
        {loading && <p>Loading fields...</p>}
        {importedFieldData.map((field, i) => {
          return (
            <div
              className='import-field-row'
              key={i}
              onMouseEnter={() => handleMouseEventOnFieldName(field.importedFieldId, 'hover')}
              onMouseLeave={() => handleMouseEventOnFieldName(null, null)}
              onClick={() => handleMouseEventOnFieldName(field.importedFieldId, 'click')}
            >
              <p>{field.name}</p>
              <p>{field.acres} ac</p>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ImportKMLField
