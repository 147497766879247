/* eslint no-unused-vars: 0*/
/* global L */
export const sld = `
<StyledLayerDescriptor version="1.0.0" xmlns="http://www.opengis.net/sld" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd">
<NamedLayer>
<Name>mapunitpoly</Name>
<UserStyle>
<FeatureTypeStyle>
<Rule>
<MinScaleDenominator>0.010000</MinScaleDenominator>
<MaxScaleDenominator>250000.000000</MaxScaleDenominator>
<PolygonSymbolizer>
<Stroke>
<CssParameter name="stroke">#000000</CssParameter>
<CssParameter name="stroke-width">0.75</CssParameter>
</Stroke>
</PolygonSymbolizer>
<TextSymbolizer>
<Label>musym</Label>
<Font>
<CssParameter name="font-family">arial</CssParameter>
<CssParameter name="font-weight">bold</CssParameter>
<CssParameter name="font-size">12.00</CssParameter>
</Font>
<LabelPlacement>
<PointPlacement>
<AnchorPoint>
<AnchorPointX>0.5</AnchorPointX>
<AnchorPointY>0.5</AnchorPointY>
</AnchorPoint>
</PointPlacement>
</LabelPlacement>
<Fill>
<CssParameter name="fill">#000000</CssParameter>
</Fill>
</TextSymbolizer>
</Rule>
</FeatureTypeStyle>
</UserStyle>
</NamedLayer>
<NamedLayer>
<Name>featpoint</Name>
<UserStyle>
<FeatureTypeStyle>
<Rule>
<MinScaleDenominator>0.010000</MinScaleDenominator>
<MaxScaleDenominator>250000.000000</MaxScaleDenominator>
<PointSymbolizer>
<Graphic>
<Mark>
<WellKnownName>square</WellKnownName>
<Fill>
<CssParameter name="fill">#ffffff</CssParameter>
</Fill>
</Mark>
<Size>1</Size>
</Graphic>
</PointSymbolizer>
<TextSymbolizer>
<Label>markercharacter</Label>
<Font>
<CssParameter name="font-family">nrcs</CssParameter>
<CssParameter name="font-size">8.00</CssParameter>
</Font>
<LabelPlacement>
<PointPlacement>
<AnchorPoint>
<AnchorPointX>0.5</AnchorPointX>
<AnchorPointY>0.5</AnchorPointY>
</AnchorPoint>
</PointPlacement>
</LabelPlacement>
<Fill>
<CssParameter name="fill">#ffffff</CssParameter>
</Fill>
</TextSymbolizer>
</Rule>
<Rule>
<ogc:Filter><ogc:PropertyIsEqualTo><ogc:PropertyName>MarkerCharacter</ogc:PropertyName><ogc:Literal> </ogc:Literal></ogc:PropertyIsEqualTo></ogc:Filter>
<MinScaleDenominator>0.010000</MinScaleDenominator>
<MaxScaleDenominator>250000.000000</MaxScaleDenominator>
<PointSymbolizer>
<Graphic>
<Mark>
<WellKnownName>triangle</WellKnownName>
<Fill>
<CssParameter name="fill">#ffffff</CssParameter>
</Fill>
</Mark>
<Size>8</Size>
</Graphic>
</PointSymbolizer>
</Rule>
</FeatureTypeStyle>
</UserStyle>
</NamedLayer>
</StyledLayerDescriptor>
`
