import React from 'react'
import PropTypes from 'prop-types'
import { Toggle } from '_shared'
import { ImageMLMetadata } from './ImageMLMetadata'
import styles from './ImageReviewer.module.scss'

export function ImageMLMetadataContainer({
  image,
  activeImageRef,
  canvasRef,
  showMlMetadata,
  setShowMlMetadata,
}) {
  const onToggle = { id: 'on', label: 'Show ML Data' }

  if (!image) return null

  return (
    image &&
    image.mlMetaData && (
      <div className={styles.imageMlMetadataContainer}>
        <Toggle
          items={[onToggle]}
          selectedItem={showMlMetadata ? onToggle : null}
          offItemSelected={!showMlMetadata}
          updateSelectedItem={() => setShowMlMetadata(!showMlMetadata)}
        />
        <ImageMLMetadata
          image={image}
          showMetadata={showMlMetadata}
          activeImageRef={activeImageRef}
          canvasRef={canvasRef}
        />
      </div>
    )
  )
}

ImageMLMetadataContainer.propTypes = {
  image: PropTypes.object,
  activeImageRef: PropTypes.object,
  canvasRef: PropTypes.object,
  showMlMetadata: PropTypes.bool,
  setShowMlMetadata: PropTypes.func,
}
