import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import styles from './ModalSections.module.scss'
import { SectionButtonContainer } from './SectionButtonContainer'
import { UserAccessList } from '../UserAccessList'

export const AccessListSection = ({ close, list, onAddUsers, onRemoveUsers }) => {
  return (
    <React.Fragment>
      <UserAccessList list={list} />
      <SectionButtonContainer>
        <Button variant='outline-dark' onClick={close}>
          Close
        </Button>
        <div className={styles.accessListButtons}>
          {list && list.permissions.canRemoveSubscriptions && (
            <Button variant='outline-dark' onClick={onRemoveUsers}>
              Remove Users
            </Button>
          )}
          {list && list.permissions.canAddSubscriptions && (
            <Button variant='dark' onClick={onAddUsers}>
              + Add Users
            </Button>
          )}
        </div>
      </SectionButtonContainer>
    </React.Fragment>
  )
}

AccessListSection.propTypes = {
  onAddUsers: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  list: PropTypes.object,
}
