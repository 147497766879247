import React from 'react'
import PropTypes from 'prop-types'
import styles from './LeafletMap.module.scss'
import { MapPinsLayer } from './MapPinsLayer'

export function ProbeCyclesLayer({ probeCycles, map, hoveredMapPinId, updateHoveredMapPinId }) {
  const getPopupContent = (cycle, labelNumber) => {
    return (
      <div className={styles.popupContent}>
        <p>
          <span className={styles.popupLabel}>Observation {labelNumber}</span>
        </p>
        <hr />
        <p>
          <span className={styles.popupLabel}>Notes:</span>
          <span>{cycle.notes}</span>
        </p>
        <p>
          <span className={styles.popupLabel}>Temp:</span>
          <span>{cycle.temperature}&#x2103;</span>
        </p>
        <p>
          <span className={styles.popupLabel}>Humidity:</span>
          <span>{cycle.humidity}%</span>
        </p>
        <p>
          <span className={styles.popupLabel}>CO2:</span>
          <span>{cycle.co2}ppm</span>
        </p>
      </div>
    )
  }

  return (
    <MapPinsLayer
      pinLocations={probeCycles}
      map={map}
      popupContent={(cycle, labelNumber) => getPopupContent(cycle, labelNumber)}
      hoveredMapPinId={hoveredMapPinId}
      updateHoveredMapPinId={updateHoveredMapPinId}
    />
  )
}

ProbeCyclesLayer.propTypes = {
  probeCycles: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    })
  ),
  map: PropTypes.object,
  hoveredMapPinId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateHoveredMapPinId: PropTypes.func,
}
