import React from 'react'
import PropTypes from 'prop-types'
import styles from '../AerialImageryReport.module.scss'

export function TreatmentDetails({ regions }) {
  const CHAR_CODE_TO_START_UPPERCASE_ALPHABET = 65

  if (!regions || regions.length === 0) return null
  return regions.map((region, index) => {
    return (
      <div key={region.id} className={styles.treatmentDetail}>
        {`${String.fromCharCode(CHAR_CODE_TO_START_UPPERCASE_ALPHABET + index)}) ${
          region.untreated ? 'No Fungicide Applied' : region.name
        } (${Number.parseFloat(region.size).toFixed(2)} ac.)`}
      </div>
    )
  })
}

TreatmentDetails.propTypes = {
  regions: PropTypes.array,
}
