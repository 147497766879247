import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import swal from '@sweetalert/with-react'
import { Row, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { FaSpinner } from 'react-icons/fa'
import { WrappedInputField } from '../_shared'
import editFarmFormValidation from './editFarmFormValidation'
import styles from './EditFarmForm.module.scss'

const connectWrapper = connect(state => ({
  farm: state.farms.farm.data,
  role: state.auth.user && state.auth.user.role,
}))

class EditFarmFormComponent extends Component {
  constructor(props) {
    super(props)
    this.props.dispatch(change('EditFieldForm', 'farmTitle', this.props.farm.title))
  }

  handleDeleteFarmClick = async () => {
    const result = await swal({
      title: 'Are you sure you want to delete this farm?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          className: '',
          closeModal: false,
        },
      },
    })

    result && this.props.handleFarmDelete()
  }

  render() {
    const { handleSubmit, isDisabled } = this.props

    return (
      <div className={styles.editFarmFormWrapper}>
        <div className={styles.headingWrapper}>
          <h1 className={styles.heading}>Edit Farm</h1>
        </div>
        <Form.Group className={styles.editFarmForm}>
          <Row>
            <WrappedInputField
              name={'farmTitle'}
              columnWidth={12}
              required={true}
              title={'Farm Name'}
              type={'text'}
              placeholder={''}
              disabled={false}
              horizontal={true}
            />
          </Row>
        </Form.Group>

        <Row className={styles.buttonContainer}>
          <Button
            variant='outline-danger'
            className={styles.button}
            onClick={this.handleDeleteFarmClick}
            disabled={isDisabled}
          >
            {isDisabled && <FaSpinner />}
            Delete
          </Button>

          <Button
            variant='primary'
            className={styles.button}
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            {isDisabled && <FaSpinner />}
            Save
          </Button>
        </Row>
      </div>
    )
  }
}
EditFarmFormComponent.propTypes = {
  farm: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  role: PropTypes.string,
  handleFarmDelete: PropTypes.func,
}

export default reduxForm({
  form: 'EditFieldForm',
  touchOnBlur: true,
  validate: editFarmFormValidation,
})(connectWrapper(EditFarmFormComponent))
