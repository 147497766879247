import React from 'react'
import PropTypes from 'prop-types'
import { LeafletMap } from 'domains'
import { AerialImageryLegend } from './AerialImageryLegend'
import styles from '../AerialImageryReport.module.scss'

export function FlightDisplay({ flight, season, regions, hideBaseLayers, hideLegend, children }) {
  const showLegend = !hideLegend

  return (
    <React.Fragment>
      <div className={styles.aerialImageryMap}>
        <LeafletMap
          hideLayerControls
          hideTransparencySlider
          hideZoomControls
          zoomDisabled
          panDisabled
          hideLegend
          hideBaseLayers={hideBaseLayers}
          bounds={season ? season.field.bounds : []}
          boundaryGeometry={season ? season.field.geometry : {}}
          innerGeometries={
            regions && regions.map(r => ({ id: r.id, geometry: r.geometry, display: true }))
          }
          innerGeometriesOutlineOnly
          innerGeometriesOutlineColor='#000'
          activeImage={flight}
        />
      </div>
      {children}
      {showLegend && <AerialImageryLegend type={flight.type} />}
    </React.Fragment>
  )
}

FlightDisplay.propTypes = {
  season: PropTypes.object,
  flight: PropTypes.object,
  regions: PropTypes.array,
  hideBaseLayers: PropTypes.bool,
  children: PropTypes.any,
}
