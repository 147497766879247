const GET_USER_ACCESS_LIST = 'aker/USER_ACCESS/GET_USER_ACCESS_LIST'
const GET_USER_ACCESS_LIST_SUCCESS = 'aker/USER_ACCESS/GET_USER_ACCESS_LIST_SUCCESS'
const GET_USER_ACCESS_LIST_FAIL = 'aker/USER_ACCESS/GET_USER_ACCESS_LIST_FAIL'

const initialState = {
  userAccessList: {
    loaded: false,
  },
}

export default function useraccess(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_ACCESS_LIST:
      return {
        ...state,
        userAccessList: {
          ...state.userAccessList,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case GET_USER_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        userAccessList: {
          ...state.userAccessList,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_USER_ACCESS_LIST_FAIL:
      return {
        ...state,
        userAccessList: {
          ...state.userAccessList,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    default:
      return state
  }
}

export function getUserAccessList(resourceType, resourceId) {
  return {
    types: [GET_USER_ACCESS_LIST, GET_USER_ACCESS_LIST_SUCCESS, GET_USER_ACCESS_LIST_FAIL],
    promise: client => client.get(`/${resourceType}/${resourceId}/user-access-list`),
  }
}
