import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import styles from './Breadcrumbs.module.scss'

export function Breadcrumbs({ items, linkLastItem }) {
  let linkedCrumbs = [...items]
  let lastItem

  if (!linkLastItem) {
    lastItem = linkedCrumbs.pop()
  }

  return (
    <div className={styles.breadcrumb}>
      {linkedCrumbs.map((item, index) => (
        <Link key={item.to} to={item.to}>
          {item.text} {index === items.length - 1 ? '' : '>'}
        </Link>
      ))}
      {lastItem && <span>{lastItem.text}</span>}
    </div>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ to: PropTypes.string, text: PropTypes.string })),
}
