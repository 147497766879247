import React, { useState } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { TextInput, CheckboxInput, TextAreaInput, FormButtonContainer } from '_shared'

export function RegionFormComponent({ region, handleSave }) {
  const [name, setName] = useState(region ? region.name : '')
  const [exclusion, setExclusion] = useState(region ? region.exclusion : false)
  const [untreated, setUntreated] = useState(region ? region.untreated : false)
  const [regionYield, setRegionYield] = useState(region ? region.yield || '' : '')
  const [notes, setNotes] = useState(region ? region.notes || '' : '')

  const onSubmit = e => {
    e.preventDefault()

    handleSave({ name, exclusion, untreated, yield: regionYield, notes })
  }

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        name='name'
        label='Region name'
        value={name}
        required
        onChange={e => setName(e.target.value)}
      />
      <CheckboxInput
        name='exclusion'
        label='Exclude from reporting?'
        value={exclusion}
        onChange={e => setExclusion(e.target.checked)}
      />
      <CheckboxInput
        name='untreated'
        label='Untreated?'
        value={untreated}
        onChange={e => setUntreated(e.target.checked)}
      />
      <TextInput
        name='yield'
        label='Yield'
        value={regionYield}
        onChange={e => setRegionYield(e.target.value)}
      />
      <TextAreaInput
        name='notes'
        label='Notes'
        value={notes}
        onChange={e => setNotes(e.target.value)}
      />
      <FormButtonContainer>
        <button className='btn btn-primary' type='submit'>
          Save
        </button>
      </FormButtonContainer>
    </form>
  )
}

export const RegionForm = withRouter(RegionFormComponent)

RegionFormComponent.propTypes = {
  region: PropTypes.object,
  handleSave: PropTypes.func,
}
