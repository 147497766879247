import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DayPicker, { DateUtils } from 'react-day-picker'
import { reduxForm, change } from 'redux-form'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import { ReduxFormInput } from '../_shared'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'
import { required } from 'helpers'
import styles from './CreateSeasonForm.module.scss'

const connectWrapper = connect(state => ({
  crops: state.crops.crops.data,
}))

class CreateSeasonFormComponent extends Component {
  constructor() {
    super()
    this.handlePlantDateChange = this.handlePlantDateChange.bind(this)
    this.state = {}
  }

  handlePlantDateChange(day) {
    this.props.dispatch(change('CreateSeasonForm', 'plantDate', day))
    this.setState({
      plantDate: day,
    })
  }

  render() {
    const { handleSubmit, isDisabled, submitButtonText, headerText, helperText } = this.props

    const renderCrops = () => {
      return this.props.crops.map(crop => {
        return (
          <option key={crop.id} value={crop.id}>
            {crop.name}
          </option>
        )
      })
    }

    return (
      <div className={styles.createSeasonFormWrapper}>
        <div className={styles.headingWrapper}>
          <h1 className={styles.heading}>{headerText}</h1>
        </div>
        <div className={styles.createSeasonForm}>
          {helperText && <h6 className={styles.helperText}>{helperText} </h6>}
          <div className={`${styles.marginBottom} row`}>
            <div className='col-5'>What is your crop type?</div>
            <div className='col-7'>
              <ReduxFormInput
                fieldName={'cropType'}
                type={'select'}
                required={true}
                validate={required}
              >
                {({ errorClassName, ...reduxInput }) => (
                  <Form.Control
                    key='cropType'
                    id='cropType'
                    as='select'
                    disabled={false}
                    className={classNames(errorClassName, { [styles.createSeasonInput]: true })}
                    {...reduxInput}
                  >
                    <option value={''}> Select a Crop Type </option>
                    {renderCrops()}
                  </Form.Control>
                )}
              </ReduxFormInput>
            </div>
          </div>
          <div className={styles.inputLabel}>What is your approximate planting date?</div>
          <ReduxFormInput fieldName={'plantDate'} type={'text'} required={false}>
            {({ errorClassName, ...reduxInput }) => (
              <DayPicker
                {...reduxInput}
                onDayClick={(day, _modifiers, e) => {
                  reduxInput.onChange(day)
                  this.handlePlantDateChange(day)
                }}
                selectedDays={day => DateUtils.isSameDay(this.state.plantDate, day)}
                key='plantDate'
                id='plantDate'
                className={errorClassName}
              />
            )}
          </ReduxFormInput>

          <Button
            variant='primary'
            className={styles.button}
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            {isDisabled && <FaSpinner />}
            {submitButtonText}
          </Button>
        </div>
      </div>
    )
  }
}

CreateSeasonFormComponent.propTypes = {
  crops: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  helperText: PropTypes.string,
}

export default reduxForm({
  form: 'CreateSeasonForm',
  touchOnBlur: true,
})(connectWrapper(CreateSeasonFormComponent))
