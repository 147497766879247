import React from 'react'
import PropTypes from 'prop-types'
import styles from './ImageReviewer.module.scss'

export function ActiveImage({ image, showFullScreen, activeImageRef, canvasRef }) {
  return (
    <div className={`${styles.taggingImageContainer}`}>
      {image && (
        <div>
          <img
            alt={image.imagePath}
            src={image.imagePath}
            onClick={showFullScreen}
            ref={activeImageRef}
          />
          <canvas onClick={showFullScreen} ref={canvasRef} />
        </div>
      )}
      {!image && (
        <div className={styles.noActiveImage}>
          <h3 className='h5'>No image selected</h3>
        </div>
      )}
    </div>
  )
}

ActiveImage.propTypes = {
  image: PropTypes.object,
  showFullScreen: PropTypes.func,
}
