import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DrawMLBoxes } from './DrawMLBoxes'

export function ImageMLMetadata({ image, activeImageRef, canvasRef, showMetadata }) {
  const [boxLocations, setBoxLocations] = useState([])
  const metadata =
    typeof image.mlMetaData === 'string' ? JSON.parse(image.mlMetaData) : image.mlMetaData
  const activeImageElement = activeImageRef.current

  useEffect(() => {
    if (!showMetadata || !metadata.bounding_boxes) {
      setBoxLocations([])
    } else {
      const fullImageSize = [activeImageElement.naturalWidth, activeImageElement.naturalHeight]
      const allBoxLocations =
        typeof metadata.bounding_boxes === 'string'
          ? JSON.parse(metadata.bounding_boxes)
          : metadata.bounding_boxes
      const allBoxLocationValues =
        typeof metadata.bounding_boxes_value === 'string'
          ? JSON.parse(metadata.bounding_boxes_value)
          : metadata.bounding_boxes_value

      let mlLocations = []
      allBoxLocations.forEach((pt, index) => {
        const newPt = [0, 0]
        newPt[1] = pt[0] / fullImageSize[1]
        newPt[0] = pt[1] / fullImageSize[0]
        if (allBoxLocationValues[index] > 50) {
          mlLocations.push(newPt)
        }
      })
      setBoxLocations(mlLocations)
    }
  }, [image, showMetadata]) //eslint-disable-line react-hooks/exhaustive-deps

  const getMLMetaDataText = () => {
    if (!metadata) return 'No ML Data For Image'
    let probs =
      typeof metadata.combined_probability === 'string'
        ? JSON.parse(metadata.combined_probability)
        : metadata.combined_probability || []
    var classes = [
      'Bad-Image',
      'Blurry-Image',
      'Corn-Healthy',
      'Corn-Disease',
      'Corn-Nutrient',
      'Corn-Pest',
      'Cotton',
      'Soybeans-Healthy',
      'Soybeans-Disease',
      'Soybeans-Nutrient',
      'Soybeans-Pest',
      'Weeds',
    ]
    if (metadata.CropType === 'Bad or Blurry') {
      return 'Bad or Blurry'
    } else {
      // not sure idea what this is handling...
      if (probs.length !== classes.length) {
        try {
          probs = metadata.combined_probability || []
        } catch (e) {
          probs = image.mlMetaData.combined_probability //alert(e); // error in the above string (in this case, yes)!
        }
      }

      return `${classes[metadata.prediction_index]}\n${probs[metadata.prediction_index]}%`
    }
  }

  return (
    <div>
      {showMetadata && <label>{getMLMetaDataText()}</label>}
      {activeImageRef.current && canvasRef.current && (
        <DrawMLBoxes
          locations={boxLocations}
          color='#FFFFFF'
          imageElement={activeImageRef.current}
          canvasElement={canvasRef.current}
        />
      )}
    </div>
  )
}

ImageMLMetadata.propTypes = {
  image: PropTypes.object,
}
