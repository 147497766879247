import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FlightPicker } from './FlightPicker'
import styles from '../AerialImageryReport.module.scss'

export function BeforeAndAfterFlightPicker({
  report,
  season,
  flights,
  regions,
  cropId,
  setFlights,
}) {
  const [beforeAndAfter, setBeforeAndAfter] = useState([
    {
      id: report && report.flights.length > 0 ? report.flights[0].id : '',
      growthStage: report && report.flights.length > 0 ? report.flights[0].growthStage : '',
      position: 0,
    },
    {
      id: report && report.flights.length > 1 ? report.flights[1].id : '',
      growthStage: report && report.flights.length > 1 ? report.flights[1].growthStage : '',
      position: 1,
    },
  ])

  return (
    <div className={styles.aerialImageryContainer}>
      <FlightPicker
        season={season || { field: {} }}
        flights={flights}
        regions={regions}
        showGrowthStage
        selectedCropId={cropId}
        selectedFlightId={beforeAndAfter[0].id}
        selectedGrowthStage={beforeAndAfter[0].growthStage}
        onFlightChange={flight => {
          let newState = [{ ...beforeAndAfter[0], id: flight.id }, { ...beforeAndAfter[1] }]
          setBeforeAndAfter(newState)
          setFlights(newState)
        }}
        onGrowthStageChange={growthStage => {
          let newState = [
            { ...beforeAndAfter[0], growthStage: growthStage },
            { ...beforeAndAfter[1] },
          ]
          setBeforeAndAfter(newState)
          setFlights(newState)
        }}
        label='Before Application'
        name='Before'
      />
      <FlightPicker
        season={season || { field: {} }}
        flights={flights}
        regions={regions}
        showGrowthStage
        selectedCropId={cropId}
        selectedFlightId={beforeAndAfter[1].id}
        selectedGrowthStage={beforeAndAfter[1].growthStage}
        onFlightChange={flight => {
          let newState = [{ ...beforeAndAfter[0] }, { ...beforeAndAfter[1], id: flight.id }]
          setBeforeAndAfter(newState)
          setFlights(newState)
        }}
        onGrowthStageChange={growthStage => {
          let newState = [
            { ...beforeAndAfter[0] },
            { ...beforeAndAfter[1], growthStage: growthStage },
          ]
          setBeforeAndAfter(newState)
          setFlights(newState)
        }}
        label='After Application'
        name='After'
      />
    </div>
  )
}

BeforeAndAfterFlightPicker.propTypes = {
  setFlights: PropTypes.func,
  season: PropTypes.object,
  flights: PropTypes.array,
  regions: PropTypes.array,
  cropId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  report: PropTypes.shape({
    template: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
    flights: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        growthStage: PropTypes.string,
      })
    ),
  }),
}
