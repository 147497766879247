const GET_SEASONS = 'aker/SEASONS/GET_SEASONS'
const GET_SEASONS_SUCCESS = 'aker/SEASONS/GET_SEASONS_SUCCESS'
const GET_SEASONS_FAIL = 'aker/SEASONS/GET_SEASONS_FAIL'

const GET_CURRENT_SEASONS = 'aker/SEASONS/GET_CURRENT_SEASONS'
const GET_CURRENT_SEASONS_SUCCESS = 'aker/SEASONS/GET_CURRENT_SEASONS_SUCCESS'
const GET_CURRENT_SEASONS_FAIL = 'aker/SEASONS/GET_CURRENT_SEASONS_FAIL'

const GET_SEASON = 'aker/SEASONS/GET_SEASON'
const GET_SEASON_SUCCESS = 'aker/SEASONS/GET_SEASON_SUCCESS'
const GET_SEASON_FAIL = 'aker/SEASONS/GET_SEASON_FAIL'

const CREATE_SEASON = 'aker/SEASONS/CREATE_SEASON'
const CREATE_SEASON_SUCCESS = 'aker/SEASONS/CREATE_SEASON_SUCCESS'
const CREATE_SEASON_FAIL = 'aker/SEASONS/CREATE_SEASON_FAIL'

const EDIT_SEASON = 'aker/SEASONS/EDIT_SEASON'
const EDIT_SEASON_SUCCESS = 'aker/SEASONS/EDIT_SEASON_SUCCESS'
const EDIT_SEASON_FAIL = 'aker/SEASONS/EDIT_SEASON_FAIL'

const DELETE_SEASON = 'aker/SEASONS/DELETE_SEASON'
const DELETE_SEASON_SUCCESS = 'aker/SEASONS/DELETE_SEASON_SUCCESS'
const DELETE_SEASON_FAIL = 'aker/SEASONS/DELETE_SEASON_FAIL'

const CHANGE_ACTIVE_SEASON_SUCCESS = 'aker/SEASONS/CHANGE_ACTIVE_SEASON_SUCCESS'
const CHANGE_ACTIVE_SEASON_FAIL = 'aker/SEASONS/CHANGE_ACTIVE_SEASON_FAIL'

const CHANGE_ACTIVE_WORK_ORDER = 'aker/FARMSANDFIELDS/CHANGE_ACTIVE_WORK_ORDER'

const initialState = {
  seasons: {
    loaded: false,
  },
  currentSeasons: {
    loaded: false,
  },
  activeSeason: {
    loaded: false,
  },
  activeWorkOrder: null,
}

export default function seasons(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SEASONS:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeSeason: {
          ...state.activeSeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeWorkOrder: null,
      }
    case GET_SEASONS_SUCCESS:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: true,
          data: findActiveSeason(action.result.data),
        },
        activeWorkOrder: getActiveWorkOrder(findActiveSeason(action.result.data)),
      }
    case GET_SEASONS_FAIL:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeWorkOrder: null,
      }
    case GET_CURRENT_SEASONS:
      return {
        ...state,
        currentSeasons: {
          ...state.currentSeasons,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeSeason: {
          ...state.activeSeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeWorkOrder: null,
      }
    case GET_CURRENT_SEASONS_SUCCESS:
      return {
        ...state,
        currentSeasons: {
          ...state.currentSeasons,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: true,
          data: findActiveSeason(action.result.data),
        },
        activeWorkOrder: getActiveWorkOrder(findActiveSeason(action.result.data)),
      }
    case GET_CURRENT_SEASONS_FAIL:
      return {
        ...state,
        currentSeasons: {
          ...state.currentSeasons,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeWorkOrder: null,
      }
    case GET_SEASON:
      return {
        ...state,
        activeSeason: {
          ...state.activeSeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeWorkOrder: null,
      }
    case GET_SEASON_SUCCESS:
      return {
        ...state,
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        activeWorkOrder: getActiveWorkOrder(action.result.data),
      }
    case GET_SEASON_FAIL:
      return {
        ...state,
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeWorkOrder: null,
      }
    case CREATE_SEASON:
    case EDIT_SEASON:
      return {
        ...state,
        activeSeason: {
          ...state.activeSeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeWorkOrder: null,
      }
    case CREATE_SEASON_SUCCESS:
    case EDIT_SEASON_SUCCESS:
      return {
        ...state,
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        activeWorkOrder: getActiveWorkOrder(action.result.data),
      }
    case CREATE_SEASON_FAIL:
    case EDIT_SEASON_FAIL:
      return {
        ...state,
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeWorkOrder: null,
      }
    case DELETE_SEASON:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeSeason: {
          ...state.activeSeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
        activeWorkOrder: null,
      }
    case DELETE_SEASON_SUCCESS:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: true,
          data: findActiveSeason(action.result.data),
        },
        activeWorkOrder: getActiveWorkOrder(findActiveSeason(action.result.data)),
      }
    case DELETE_SEASON_FAIL:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeWorkOrder: null,
      }
    case CHANGE_ACTIVE_SEASON_SUCCESS:
      return {
        ...state,
        activeSeason: {
          data: action.activeSeason,
          loading: false,
          loaded: true,
        },
        activeWorkOrder: getActiveWorkOrder(action.activeSeason),
      }
    case CHANGE_ACTIVE_SEASON_FAIL:
      return {
        ...state,
        activeSeason: {
          ...state.activeSeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
        activeWorkOrder: {
          ...state.activeWorkOrder,
          loading: false,
          loaded: false,
        },
      }
    case CHANGE_ACTIVE_WORK_ORDER:
      return {
        ...state,
        activeWorkOrder: action.workOrderId,
      }
    default:
      return state
  }
}

export function isSeasonsLoaded(globalState, fieldId) {
  return (
    globalState.seasons &&
    globalState.seasons.seasons &&
    globalState.seasons.seasons.loaded &&
    globalState.seasons.seasons.data.every(season => season.field.id === fieldId)
  )
}

export function getSeasons(fieldId = null) {
  let promise
  if (fieldId) {
    promise = client => client.get(`/field/${fieldId}/seasons`)
  } else {
    promise = client => client.get('/seasons')
  }
  return {
    types: [GET_SEASONS, GET_SEASONS_SUCCESS, GET_SEASONS_FAIL],
    promise,
  }
}

export function getCurrentSeasons(customerAccountId = null) {
  let promise
  if (customerAccountId) {
    promise = client => client.get(`/customer_accounts/${customerAccountId}/seasons?status=current`)
  } else {
    promise = client => client.get('/seasons?status=current')
  }
  return {
    types: [GET_CURRENT_SEASONS, GET_CURRENT_SEASONS_SUCCESS, GET_CURRENT_SEASONS_FAIL],
    promise,
  }
}

export function isSeasonLoaded(globalState, seasonId) {
  const alreadyActiveSeason =
    globalState.seasons &&
    globalState.seasons.activeSeason &&
    globalState.seasons.activeSeason.loaded &&
    (seasonId ? globalState.seasons.activeSeason.data.id === parseInt(seasonId, 10) : true)

  const loadedInSeasonsList =
    globalState.seasons &&
    globalState.seasons.seasons &&
    globalState.seasons.seasons.loaded &&
    globalState.seasons.seasons.data.find(s => s.id === seasonId)

  return alreadyActiveSeason || loadedInSeasonsList
}

export function getSeason(seasonId) {
  return {
    types: [GET_SEASON, GET_SEASON_SUCCESS, GET_SEASON_FAIL],
    promise: client => client.get(`/seasons/${seasonId}`),
  }
}

export function createSeason(requestParams) {
  return {
    types: [CREATE_SEASON, CREATE_SEASON_SUCCESS, CREATE_SEASON_FAIL],
    promise: client =>
      client.post('/seasons', {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function editSeason(requestParams) {
  return {
    types: [EDIT_SEASON, EDIT_SEASON_SUCCESS, EDIT_SEASON_FAIL],
    promise: client =>
      client.patch(`/seasons/${requestParams.seasonId}`, {
        data: {
          data: {
            ...requestParams,
          },
        },
      }),
  }
}

export function deleteSeason(seasonId) {
  return {
    types: [DELETE_SEASON, DELETE_SEASON_SUCCESS, DELETE_SEASON_FAIL],
    promise: client => client.del(`/seasons/${seasonId}`),
  }
}

export function changeActiveSeason(seasons, seasonId = null) {
  let activeSeason
  if (seasonId) {
    activeSeason = seasons.find(s => s.id === seasonId)
  } else {
    activeSeason = findActiveSeason(seasons)
  }
  if (activeSeason) {
    return {
      type: CHANGE_ACTIVE_SEASON_SUCCESS,
      activeSeason: activeSeason,
    }
  } else {
    return {
      type: CHANGE_ACTIVE_SEASON_FAIL,
      error: {
        errors: [`could not find season with id: ${seasonId}`],
      },
    }
  }
}

export function getActiveWorkOrder(season = {}) {
  if (season.mapLayers && season.mapLayers.length > 0) {
    return season.mapLayers[0].id
  }
}

export function changeActiveWorkOrder(workOrderId) {
  return {
    type: CHANGE_ACTIVE_WORK_ORDER,
    workOrderId: workOrderId,
  }
}

function findActiveSeason(seasons) {
  const initializedSeasons = seasons || []
  return getCurrentSeason(initializedSeasons) || initializedSeasons[0] || {}
}

function getCurrentSeason(seasons) {
  const currentSeason = seasons.find(season => season.current)
  return currentSeason
}
