import React from 'react'
import PropTypes from 'prop-types'
import { BulletedText } from './BulletedText'
import styles from '../AerialImageryReport.module.scss'

export function ReportHighlights({ highlightsHeader, highlights, brandColor }) {
  if (!highlights || highlights.length === 0) return null

  return (
    <div className={styles.withBottomMargin}>
      {highlightsHeader && (
        <h2 className='h4' style={{ color: brandColor }}>
          {highlightsHeader}
        </h2>
      )}

      {highlights && highlights.length > 0 && (
        <div>
          {highlights.map((highlight, index) => {
            return (
              <BulletedText key={`highlight-${index}`} brandColor={brandColor}>
                <div className={styles.highlight}>{highlight}</div>
              </BulletedText>
            )
          })}
        </div>
      )}
    </div>
  )
}

ReportHighlights.propTypes = {
  highlightsHeader: PropTypes.string,
  highlights: PropTypes.array,
  brandColor: PropTypes.string.isRequired,
}
