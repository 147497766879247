/*eslint-disable*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cookie from 'react-cookie'
import { browserHistory } from 'react-router'

export default class LoginWithToken extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    const authToken = props.params.authToken
    cookie.save('authToken', authToken, { path: '/' })
    browserHistory.push('/')
  }

  render() {
    return false
  }
}
