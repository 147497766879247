import React from 'react'
import PropTypes from 'prop-types'
import { ListEditor } from '_shared'
import { FlightPicker } from './FlightPicker'
import styles from '../AerialImageryReport.module.scss'

export function NthFlightPicker({ report, season, flights, regions, flightLimit, setFlights }) {
  const renderFlightPicker = (flight, index, onChange) => {
    return (
      <FlightPicker
        season={season || { field: {} }}
        flights={flights}
        regions={regions}
        showGrowthStage={false}
        selectedFlightId={flight.id}
        onFlightChange={flight => {
          onChange(flight, index)
        }}
        label={`Image ${index + 1}`}
        name={`Image${index + 1}`}
      />
    )
  }

  return (
    <ListEditor
      items={report ? report.flights : []}
      setItems={setFlights}
      maxNumberOfItems={flightLimit}
      outerItemClassName={styles.aerialImageryContainer}
      innerItemClassName={styles.flightPickerContainer}
      customItemMap={(flight, index) => ({ id: flight.id, position: index })}
      render={renderFlightPicker}
    />
  )
}

NthFlightPicker.propTypes = {
  setFlights: PropTypes.func,
  season: PropTypes.object,
  flights: PropTypes.array,
  regions: PropTypes.array,
  flightLimit: PropTypes.number,
  report: PropTypes.shape({
    flights: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        growthStage: PropTypes.string,
      })
    ),
  }),
}
