import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { SectionButtonContainer } from './SectionButtonContainer'
import styles from './ModalSections.module.scss'

export const UnsubscribedConfirmationSection = ({
  handleCloseConfirmation,
  removedSubscribers,
}) => {
  return (
    <React.Fragment>
      <p>The following users no longer have access to this farm:</p>
      <ul className={styles.subscribersList}>
        {removedSubscribers.map((user, index) => {
          return (
            <li key={index}>
              <h3>{user.email}</h3>
              <span>{user.role}</span>
            </li>
          )
        })}
      </ul>
      <SectionButtonContainer>
        <Button variant='dark' onClick={handleCloseConfirmation}>
          Close
        </Button>
      </SectionButtonContainer>
    </React.Fragment>
  )
}

UnsubscribedConfirmationSection.propTypes = {
  handleCloseConfirmation: PropTypes.func.isRequired,
  removedSubscribers: PropTypes.array.isRequired,
}
