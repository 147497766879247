import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import swal from '@sweetalert/with-react'
import { SidePanel } from 'components/_shared'
import { LeafletMap, PolygonToFeatureGeometry } from 'domains'
import { FieldView } from './FieldView'
import {
  ORDER_HISTORY_DATA_KEY,
  AERIAL_IMAGERY_DATA_KEY,
  PROBE_DATA_KEY,
  NEW_IMAGERY_DATA_KEY,
} from './SeasonData'

export function StressItemsActiveParser(observations, observedAt) {
  return observations.reduce((stressItems, obs) => {
    return obs.cropStressTypes.reduce((innerStressItems, type) => {
      const active = obs.observedAt === observedAt
      let parsedStressItems = type.cropStressItems.map(s => ({ active: active, ...s }))
      return innerStressItems.concat(parsedStressItems)
    }, stressItems)
  }, [])
}

export function ProbeCyclesActiveParser(probeCyclesByDate, observedAt) {
  return probeCyclesByDate.reduce((cycles, date) => {
    const active = date.observedAt === observedAt
    let parsedCycles = date.cycles.map(cycle => ({ active: active, ...cycle }))
    return cycles.concat(parsedCycles)
  }, [])
}

export function ActiveFlightSelector(observations, observedAt, flightId) {
  const observationDate = observations.find(o => o.observedAt === observedAt)
  return observationDate.flights.find(f => f.id === flightId)
}

export default function FieldViewAndMap({
  field,
  seasons,
  regions,
  activeSeason,
  observations,
  probeCycles,
  favoriteImageData,
  changeActiveSeason,
  onDataChange,
  toggleFavoriteImage,
  getRegionsBySeasonId,
  ...props
}) {
  const [visibleData, setVisibleData] = useState({
    [AERIAL_IMAGERY_DATA_KEY]: true,
    [PROBE_DATA_KEY]: false,
    [NEW_IMAGERY_DATA_KEY]: false,
    [ORDER_HISTORY_DATA_KEY]: false,
  })
  const [selectedObservationDate, setSelectedObservationDate] = useState('')
  const [selectedFlight, setSelectedFlight] = useState(null)
  const [stressItems, setStressItems] = useState([])
  const [cycles, setCycles] = useState([])
  const [hoveredMapPinId, setHoveredMapPinId] = useState(null)
  const [regionsDisplay, setRegionsDisplay] = useState([])
  const [regionToEdit, setRegionToEdit] = useState({})
  const [newRegionGeometryData, setNewRegionGeometryData] = useState({})
  const [shouldEditRegion, setShouldEditRegion] = useState(false)

  useEffect(() => {
    getRegionsBySeasonId(activeSeason.id)
  }, [activeSeason.id]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (regions.loaded) {
      setRegionsDisplay(
        regions.data.map(r => ({
          display: false,
          ...r,
        }))
      )
    }
    // eslint-disable-next-line
  }, [regions])

  useEffect(() => {
    if (visibleData[PROBE_DATA_KEY] && observations && observations.length > 0) {
      const date = observations[0]
      setSelectedObservationDate(date.observedAt)
      setStressItems(StressItemsActiveParser(observations, date.observedAt))
    }
  }, [observations, visibleData])

  useEffect(() => {
    if (visibleData[NEW_IMAGERY_DATA_KEY] && probeCycles && probeCycles.length > 0) {
      const date = probeCycles[0]
      setSelectedObservationDate(date.observedAt)
      setCycles(ProbeCyclesActiveParser(probeCycles, date.observedAt))
    }
  }, [probeCycles, visibleData])

  const updateSelectedObservationDate = observedAt => {
    setSelectedObservationDate(observedAt)
    setStressItems(StressItemsActiveParser(observations, observedAt))
    setCycles(ProbeCyclesActiveParser(probeCycles, observedAt))
  }

  const updateSelectedStressItems = (id, isSelected) => {
    let updatedStressItems = [...stressItems]
    let stressItem = updatedStressItems.find(s => s.id === id)
    stressItem.active = isSelected
    setStressItems(updatedStressItems)
  }

  const updateSelectedFlight = id => {
    if (visibleData[PROBE_DATA_KEY]) {
      setSelectedFlight(ActiveFlightSelector(observations, selectedObservationDate, id))
    } else {
      setSelectedFlight(activeSeason.mapLayers.find(f => f.id === id))
    }
  }

  const updateHoveredMapPinId = (id, isHovered) => {
    setHoveredMapPinId(isHovered ? id : null)
  }

  const updateVisibleData = dataKey => {
    const updatedVisibleData = { ...visibleData }
    Object.keys(updatedVisibleData).forEach(key => {
      updatedVisibleData[key] = dataKey === key
    })
    setVisibleData(updatedVisibleData)
  }

  const handleSingleRegionToggle = (regionId, shouldDisplay) => {
    if (regionId === 'new') return
    let newRegionsDisplay = [...regionsDisplay]
    if (regionId) {
      let region = newRegionsDisplay.find(r => r.id === regionId)
      region.display = shouldDisplay
    }
    setRegionsDisplay(newRegionsDisplay)
  }

  const editRegion = region => {
    handleSingleRegionToggle(region.id, true)
    region.polygon = PolygonToFeatureGeometry(region.geometry)
    updateEditingRegion(true, region)
  }

  const updateEditingRegion = (editRegionOn, region) => {
    setShouldEditRegion(editRegionOn)
    setRegionToEdit(region)
  }

  const cancelEditRegion = async () => {
    const newRegionsDisplay = await Promise.all(
      regionsDisplay.filter(region => {
        return region.id !== 'new'
      })
    )
    setRegionsDisplay(newRegionsDisplay)
    handleSingleRegionToggle(regionToEdit.id)
    updateEditingRegion(false, {})
  }

  const handleRegionInfoUpdate = (prop, value) => {
    const newRegionToEdit = { ...regionToEdit }
    newRegionToEdit[prop] = value
    setRegionToEdit(newRegionToEdit)
  }

  const saveEditRegion = async () => {
    const updatedRegion = {
      ...regionToEdit,
      ...newRegionGeometryData,
    }
    if (newRegionGeometryData.polygon && newRegionGeometryData.polygon.geometry) {
      updatedRegion.geometry = newRegionGeometryData.polygon.geometry
    }

    if (!updatedRegion.size || !updatedRegion.bounds || !updatedRegion.polygon) {
      await swal({
        title: 'Oops, please draw a region',
        text: 'It looks like your region boundaries are invalid. Please draw a valid region.',
        icon: 'error',
        closeModal: true,
      })
      return
    }
    const requestParams = {
      ...updatedRegion,
      regionId: updatedRegion.id,
      polygon: JSON.stringify(updatedRegion.polygon),
    }
    try {
      await props.updateRegion(requestParams)
      const newRegionsDisplay = await Promise.all(
        regionsDisplay.map(region => (region.id === updatedRegion.id ? updatedRegion : region))
      )
      setRegionsDisplay(newRegionsDisplay)
      updateEditingRegion(false, {})
      await swal({
        title: 'Great, your region has been updated!',
        icon: 'success',
        closeModal: true,
      })
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeModal: true,
      })
    }
  }

  const toggleAddNewRegion = async () => {
    if (regionsDisplay.length === 0 || regionsDisplay[regionsDisplay.length - 1].id) {
      const newRegionsDisplay = [...regionsDisplay]
      let newEmptyRegion = {
        bounds: [],
        exclusion: false,
        name: '',
        notes: '',
        polygon: '',
        seasonId: activeSeason.id,
        size: 0,
        untreated: false,
        yield: '',
      }
      newEmptyRegion.id = 'new'
      newRegionsDisplay.push(newEmptyRegion)
      setRegionsDisplay(newRegionsDisplay)
      updateEditingRegion(true, newEmptyRegion)
    }
  }

  const saveNewRegion = async () => {
    if (
      !newRegionGeometryData.size ||
      !newRegionGeometryData.bounds ||
      !newRegionGeometryData.polygon
    ) {
      await swal({
        title: 'Oops, please draw a region',
        text: 'It looks like your region boundaries are invalid. Please draw a valid region.',
        icon: 'error',
        closeModal: true,
      })
      return
    }

    const requestParams = {
      ...regionToEdit,
      ...newRegionGeometryData,
      polygon: JSON.stringify(newRegionGeometryData.polygon),
    }
    requestParams.name = requestParams.name ? requestParams.name : '(No Name)'
    delete requestParams.id

    try {
      const newRegionAdded = await props.createRegion(requestParams)
      const newRegionsDisplay = regionsDisplay.filter(region => region.id !== 'new')
      newRegionAdded.data.display = true
      newRegionsDisplay.push(newRegionAdded.data)
      setRegionsDisplay(newRegionsDisplay)
      updateEditingRegion(false, {})
      await swal({
        title: 'Great, your region has been created!',
        icon: 'success',
        closeModal: true,
      })
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeModal: true,
      })
    }
  }

  const handleDeleteRegion = async regionId => {
    try {
      await props.deleteRegion(regionId)
      updateEditingRegion(false, {})
      await swal({
        title: 'Great, your region has been deleted!',
        icon: 'success',
        closeOnConfirm: true,
      })
      getRegionsBySeasonId(activeSeason.id)
    } catch {
      await swal({
        title: "It's not you, it's us.",
        text: 'Please try again later or contact info@aker.ag.',
        icon: 'error',
        closeOnConfirm: true,
      })
    }
  }

  return (
    <React.Fragment>
      <Helmet title='Fields' />
      <div className='Sidebar Sidebar-wide'>
        <SidePanel wide>
          <FieldView
            field={field}
            seasons={seasons}
            regions={regionsDisplay}
            activeSeason={activeSeason}
            onSeasonChange={changeActiveSeason}
            onDataChange={onDataChange}
            visibleData={visibleData}
            updateVisibleData={updateVisibleData}
            selectedObservationDate={selectedObservationDate}
            updateSelectedObservationDate={updateSelectedObservationDate}
            updateSelectedStressItems={updateSelectedStressItems}
            hoveredMapPinId={hoveredMapPinId}
            updateHoveredMapPinId={updateHoveredMapPinId}
            selectedFlight={selectedFlight}
            updateSelectedFlight={updateSelectedFlight}
            favoriteImageData={favoriteImageData}
            toggleFavoriteImage={toggleFavoriteImage}
            handleSingleRegionToggle={handleSingleRegionToggle}
            editRegion={editRegion}
            cancelEditRegion={cancelEditRegion}
            saveEditRegion={saveEditRegion}
            saveNewRegion={saveNewRegion}
            toggleAddNewRegion={toggleAddNewRegion}
            deleteRegion={handleDeleteRegion}
            handleRegionInfoUpdate={handleRegionInfoUpdate}
            regionToEdit={regionToEdit}
          />
        </SidePanel>
      </div>
      <div className='Map Map-narrow'>
        <LeafletMap
          bounds={field.bounds}
          boundaryGeometry={field.geometry}
          activeImage={selectedFlight}
          stressItems={visibleData[PROBE_DATA_KEY] ? stressItems.filter(s => !!s.active) : []}
          probeCycles={visibleData[NEW_IMAGERY_DATA_KEY] ? cycles : []}
          hoveredMapPinId={
            visibleData[PROBE_DATA_KEY] || visibleData[NEW_IMAGERY_DATA_KEY]
              ? hoveredMapPinId
              : null
          }
          updateHoveredMapPinId={
            visibleData[PROBE_DATA_KEY] || visibleData[NEW_IMAGERY_DATA_KEY]
              ? updateHoveredMapPinId
              : null
          }
          innerGeometries={regionsDisplay}
          drawingEnabled={shouldEditRegion}
          geometryToEdit={regionToEdit.polygon}
          setDrawnGeometry={setNewRegionGeometryData}
        />
      </div>
    </React.Fragment>
  )
}

FieldViewAndMap.propTypes = {
  field: PropTypes.object.isRequired,
  seasons: PropTypes.array.isRequired,
  activeSeason: PropTypes.object.isRequired,
  observations: PropTypes.array,
  probeCycles: PropTypes.array,
  favoriteImageData: PropTypes.object,
  changeActiveSeason: PropTypes.func.isRequired,
  onDataChange: PropTypes.func.isRequired,
  toggleFavoriteImage: PropTypes.func,
}
