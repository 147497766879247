import cookie from 'react-cookie'

import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, LOGOUT_FAIL, logout } from '../modules/auth'

import { LOAD_ACCOUNT_BY_TOKEN_SUCCESS } from '../modules/account'

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState)
      }

      const { promise, types, ...rest } = action // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action)
      }

      const [REQUEST, SUCCESS, FAILURE] = types
      next({ ...rest, type: REQUEST })
      const actionPromise = promise(client)
      actionPromise
        .then(
          result => {
            if (SUCCESS === LOGIN_SUCCESS || SUCCESS === LOAD_ACCOUNT_BY_TOKEN_SUCCESS) {
              cookie.save('authToken', result.data.token, { path: '/' })
            } else if (SUCCESS === LOGOUT_SUCCESS) {
              cookie.remove('authToken', { path: '/' })
            }

            return next({ ...rest, result, type: SUCCESS })
          },
          error => {
            // Account for 500 errors or errors not sent from the backend
            if (error.message && !error.errors) {
              error.errors = [{ code: error.code || Math.random(), message: error.message }]
            }

            if (FAILURE === LOGOUT_FAIL) {
              // Still remove auth cookie on logout failure. Assume token is expired
              cookie.remove('authToken', { path: '/' })
            } else if (error.statusCode === 401) {
              // Ignore if user is logging in or logging out
              if (FAILURE !== LOGOUT_FAIL && FAILURE !== LOGIN_FAIL) {
                // Logout
                dispatch(logout())
              }
            }

            return next({ ...rest, error, type: FAILURE })
          }
        )
        .catch(error => {
          console.error('MIDDLEWARE ERROR:', error)
          next({ ...rest, error, type: FAILURE })
        })

      return actionPromise
    }
  }
}
