const GET_TASKS_WITH_UNREVIEWED_IMAGES = 'aker/IMAGE_REVIEW/GET_TASKS_WITH_UNREVIEWED_IMAGES'
const GET_TASKS_WITH_UNREVIEWED_IMAGES_SUCCESS =
  'aker/IMAGE_REVIEW/GET_TASKS_WITH_UNREVIEWED_IMAGES_SUCCESS'
const GET_TASKS_WITH_UNREVIEWED_IMAGES_FAIL =
  'aker/IMAGE_REVIEW/GET_TASKS_WITH_UNREVIEWED_IMAGES_FAIL'

const GET_IMAGES_DATA_BY_TASK = 'aker/IMAGE_REVIEW/GET_IMAGES_DATA_BY_TASK'
const GET_IMAGES_DATA_BY_TASK_SUCCESS = 'aker/IMAGE_REVIEW/GET_IMAGES_DATA_BY_TASK_SUCCESS'
const GET_IMAGES_DATA_BY_TASK_FAIL = 'aker/IMAGE_REVIEW/GET_IMAGES_DATA_BY_TASK_FAIL'

const GET_REVIEWER_COUNT_SUMMARY = 'aker/IMAGE_REVIEW/GET_REVIEWER_COUNT_SUMMARY'
const GET_REVIEWER_COUNT_SUMMARY_SUCCESS = 'aker/IMAGE_REVIEW/GET_REVIEWER_COUNT_SUMMARY_SUCCESS'
const GET_REVIEWER_COUNT_SUMMARY_FAIL = 'aker/IMAGE_REVIEW/GET_REVIEWER_COUNT_SUMMARY_FAIL'

const GET_CROP_STRESS_ITEMS = 'aker/IMAGE_REVIEW/GET_CROP_STRESS_ITEMS'
const GET_CROP_STRESS_ITEMS_SUCCESS = 'aker/IMAGE_REVIEW/GET_CROP_STRESS_ITEMS_SUCCESS'
const GET_CROP_STRESS_ITEMS_FAIL = 'aker/IMAGE_REVIEW/GET_CROP_STRESS_ITEMS_FAIL'

const GET_USER_ACCESS_LIST = 'aker/IMAGE_REVIEW/GET_USER_ACCESS_LIST'
const GET_USER_ACCESS_LIST_SUCCESS = 'aker/IMAGE_REVIEW/GET_USER_ACCESS_LIST_SUCCESS'
const GET_USER_ACCESS_LIST_FAIL = 'aker/IMAGE_REVIEW/GET_USER_ACCESS_LIST_FAIL'

const UPDATE_IMAGE_REVIEW = 'aker/IMAGE_REVIEW/UPDATE_IMAGE_REVIEW'
const UPDATE_IMAGE_REVIEW_SUCCESS = 'aker/IMAGE_REVIEW/UPDATE_IMAGE_REVIEW_SUCCESS'
const UPDATE_IMAGE_REVIEW_FAIL = 'aker/IMAGE_REVIEW/UPDATE_IMAGE_REVIEW_FAIL'

const TOGGLE_FAVORITE_IMAGE = 'aker/IMAGE_REVIEW/TOGGLE_FAVORITE_IMAGE'
const TOGGLE_FAVORITE_IMAGE_SUCCESS = 'aker/IMAGE_REVIEW/TOGGLE_FAVORITE_IMAGE_SUCCESS'
const TOGGLE_FAVORITE_IMAGE_FAIL = 'aker/IMAGE_REVIEW/TOGGLE_FAVORITE_IMAGE_FAIL'

const DELETE_IMAGES = 'aker/IMAGE_REVIEW/DELETE_IMAGES'
const DELETE_IMAGES_SUCCESS = 'aker/IMAGE_REVIEW/DELETE_IMAGES_SUCCESS'
const DELETE_IMAGES_FAIL = 'aker/IMAGE_REVIEW/DELETE_IMAGES_FAIL'

const initialState = {
  tasks: {
    loaded: false,
  },
  imageDataByTask: {
    loaded: false,
  },
  customerAccounts: {
    loaded: false,
  },
  userAccessList: {
    loaded: false,
  },
  cropStressItems: {
    loaded: false,
  },
  toggleFavoriteImageData: {
    loaded: false,
  },
}

export default function imagereview(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TASKS_WITH_UNREVIEWED_IMAGES:
      return {
        ...state,
        tasks: {
          ...state.data,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_TASKS_WITH_UNREVIEWED_IMAGES_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.data,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_TASKS_WITH_UNREVIEWED_IMAGES_FAIL:
      return {
        ...state,
        tasks: {
          ...state.data,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_IMAGES_DATA_BY_TASK:
      return {
        ...state,
        imageDataByTask: {
          ...state.imageDataByTask,
          loading: true,
          error: undefined,
        },
      }
    case GET_IMAGES_DATA_BY_TASK_SUCCESS:
      return {
        ...state,
        imageDataByTask: {
          ...state.imageDataByTask,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_IMAGES_DATA_BY_TASK_FAIL:
      return {
        ...state,
        imageDataByTask: {
          ...state.imageDataByTask,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }
    case UPDATE_IMAGE_REVIEW:
      return {
        ...state,
        imageDataByTask: {
          ...state.imageDataByTask,
          loading: true,
          error: undefined,
        },
      }
    case UPDATE_IMAGE_REVIEW_SUCCESS:
      return {
        ...state,
        imageDataByTask: {
          ...state.imageDataByTask,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case UPDATE_IMAGE_REVIEW_FAIL:
      return {
        ...state,
        imageDataByTask: {
          ...state.imageDataByTask,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }

    case GET_REVIEWER_COUNT_SUMMARY:
      return {
        ...state,
        customerAccounts: {
          ...state.customerAccounts,
          loading: true,
          error: undefined,
        },
      }
    case GET_REVIEWER_COUNT_SUMMARY_SUCCESS:
      return {
        ...state,
        customerAccounts: {
          ...state.customerAccounts,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }

    case GET_REVIEWER_COUNT_SUMMARY_FAIL:
      return {
        ...state,
        customerAccounts: {
          ...state.customerAccounts,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }

    case GET_USER_ACCESS_LIST:
      return {
        ...state,
        userAccessList: {
          ...state.userAccessList,
          loading: true,
          error: undefined,
        },
      }
    case GET_USER_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        userAccessList: {
          ...state.userAccessList,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }

    case GET_USER_ACCESS_LIST_FAIL:
      return {
        ...state,
        userAccessList: {
          ...state.userAccessList,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }

    case GET_CROP_STRESS_ITEMS:
      return {
        ...state,
        cropStressItems: {
          ...state.cropStressItems,
          loading: true,
          error: undefined,
        },
      }

    case GET_CROP_STRESS_ITEMS_SUCCESS:
      return {
        ...state,
        cropStressItems: {
          ...state.cropStressItems,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }

    case GET_CROP_STRESS_ITEMS_FAIL:
      return {
        ...state,
        cropStressItems: {
          ...state.cropStressItems,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }
    case TOGGLE_FAVORITE_IMAGE:
      return {
        ...state,
        toggleFavoriteImageData: {
          ...state.toggleFavoriteImageData,
          loading: true,
          error: undefined,
        },
      }

    case TOGGLE_FAVORITE_IMAGE_SUCCESS:
      return {
        ...state,
        toggleFavoriteImageData: {
          ...state.toggleFavoriteImageData,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }

    case TOGGLE_FAVORITE_IMAGE_FAIL:
      return {
        ...state,
        toggleFavoriteImageData: {
          ...state.toggleFavoriteImageData,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }
    case DELETE_IMAGES:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: true,
          error: undefined,
        },
      }

    case DELETE_IMAGES_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }

    case DELETE_IMAGES_FAIL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: false,
          loaded: false,
          data: action.error.errors,
        },
      }
    default:
      return state
  }
}

export function isImageDataByTaskLoaded(globalState, taskId) {
  return (
    globalState.imagereview &&
    globalState.imagereview.imageDataByTask &&
    globalState.imagereview.imageDataByTask.loaded &&
    (taskId ? globalState.imagereview.imageDataByTask.data.id === parseInt(taskId, 10) : true)
  )
}

export function isSummaryDataLoaded(globalState) {
  return (
    globalState.imagereview && globalState.imagereview.data && globalState.imagereview.data.loaded
  )
}

export function isCropStressItemsLoaded(globalState) {
  return (
    globalState.imagereview &&
    globalState.imagereview.cropStressItems &&
    globalState.imagereview.cropStressItems.loaded
  )
}

export function getTasksWithUnreviewedImages(customerAccountId = null) {
  let promise
  if (customerAccountId) {
    promise = client => client.get(`/image_review/by_customer_account/${customerAccountId}`)
  } else {
    promise = client => client.get('/image_review')
  }

  return {
    types: [
      GET_TASKS_WITH_UNREVIEWED_IMAGES,
      GET_TASKS_WITH_UNREVIEWED_IMAGES_SUCCESS,
      GET_TASKS_WITH_UNREVIEWED_IMAGES_FAIL,
    ],
    promise,
  }
}

export function getImagesDataByTask(taskId, status = null, pageNumber = 1) {
  let url = `/image_review/${taskId}/?page=${pageNumber}`
  if (status) {
    url += `&status=${status}`
  }

  return {
    types: [GET_IMAGES_DATA_BY_TASK, GET_IMAGES_DATA_BY_TASK_SUCCESS, GET_IMAGES_DATA_BY_TASK_FAIL],
    promise: client => client.get(url),
  }
}

export function updateImageReview(taskId, data, status = null, pageNumber = 1) {
  let url = `/image_review/${taskId}/?page=${pageNumber}`
  if (status) {
    url += `&status=${status}`
  }

  return {
    types: [UPDATE_IMAGE_REVIEW, UPDATE_IMAGE_REVIEW_SUCCESS, UPDATE_IMAGE_REVIEW_FAIL],
    promise: client => client.put(url, { data: { data } }),
  }
}

export function getReviewerCountSummary() {
  return {
    types: [
      GET_REVIEWER_COUNT_SUMMARY,
      GET_REVIEWER_COUNT_SUMMARY_SUCCESS,
      GET_REVIEWER_COUNT_SUMMARY_FAIL,
    ],
    promise: client => client.get(`/image_review/reviewer_count_summary`),
  }
}

export function getUserAccessList(customerAccountId) {
  return {
    types: [GET_USER_ACCESS_LIST, GET_USER_ACCESS_LIST_SUCCESS, GET_USER_ACCESS_LIST_FAIL],
    promise: client => client.get(`/image_review/${customerAccountId}/user_access_list/`),
  }
}

export function getCropStressItems(taskId) {
  return {
    types: [GET_CROP_STRESS_ITEMS, GET_CROP_STRESS_ITEMS_SUCCESS, GET_CROP_STRESS_ITEMS_FAIL],
    promise: client => client.get(`/image_review/${taskId}/crop_stress_items`),
  }
}

export function toggleFavoriteImage(imagePath) {
  return {
    types: [TOGGLE_FAVORITE_IMAGE, TOGGLE_FAVORITE_IMAGE_SUCCESS, TOGGLE_FAVORITE_IMAGE_FAIL],
    promise: client =>
      client.post(`/image_review/favorite_image`, {
        data: {
          data: {
            url: imagePath,
          },
        },
      }),
  }
}

export function deleteImages(imageIds) {
  return {
    types: [DELETE_IMAGES, DELETE_IMAGES_SUCCESS, DELETE_IMAGES_FAIL],
    promise: client =>
      client.del(`/image_review/images`, {
        data: {
          data: {
            probe_image_ids: imageIds,
          },
        },
      }),
  }
}
