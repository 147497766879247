import React from 'react'
import PropTypes from 'prop-types'
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Dropdown from 'react-bootstrap/Dropdown'
import { PledgeStatus } from 'components'
import config from '../../config'
import styles from './MainNavigation.module.scss'
import { NavItem, NavLink } from 'react-bootstrap'

/**
 * MainNavigation
 * Encompasses the global navigation
 */
export const MainNavigation = ({ user, handleLogout }) => {
  const renderFarmsLink = () => {
    return (
      <LinkContainer to='/farms'>
        <Nav.Link>Farms</Nav.Link>
      </LinkContainer>
    )
  }

  const renderFieldsLink = () => {
    return (
      <LinkContainer to={`/farm/${user.farmId}`}>
        <Nav.Link>Fields</Nav.Link>
      </LinkContainer>
    )
  }

  const logo = require('assets/logo-aker-white.svg')

  return (
    <Navbar
      fixed='top'
      expand='md'
      variant='dark'
      bg-dark='true'
      className={[styles.noPrint, 'justify-content-end']}
    >
      <Navbar.Brand className={styles.logoLink} href='/' data-test={'logoLink'}>
        <img src={logo} alt={config.app.title} />
      </Navbar.Brand>
      <Navbar.Toggle />

      {user && (
        <Navbar.Collapse className={styles.centeredNav}>
          <Nav />
          <Nav justify className={styles.centerNavBlock}>
            <IndexLinkContainer to='/'>
              <Nav.Link>Dashboard</Nav.Link>
            </IndexLinkContainer>
            <LinkContainer to='/reports'>
              <Nav.Link>Reports</Nav.Link>
            </LinkContainer>
            {user.subscribedToFields ? renderFieldsLink() : renderFarmsLink()}
            {user.isImageReviewer && (
              <LinkContainer to='/image-reviewer'>
                <Nav.Link>Image Reviewer</Nav.Link>
              </LinkContainer>
            )}
          </Nav>

          <Nav>
            <Dropdown id='dropdown-account' as={NavItem}>
              <Dropdown.Toggle as={NavLink} variant='dark' className={styles.dropdown}>
                Account
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='dropdown-header'>
                  <PledgeStatus
                    title='Work Order Status'
                    total={user.workOrdersCount}
                    used={user.completedWorkOrdersCount}
                    errors={null}
                    isInMenu
                  />
                </Dropdown.Item>

                <Dropdown.Divider />
                <LinkContainer to='account'>
                  <Dropdown.Item>My Account</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to='user-access'>
                  <Dropdown.Item>Farm/Field Access</Dropdown.Item>
                </LinkContainer>
                {user.isCustomerAccountManager && (
                  <React.Fragment>
                    <LinkContainer to='image-reviewer-access'>
                      <Dropdown.Item>Image Reviewer Access</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/settings'>
                      <Dropdown.Item>Settings</Dropdown.Item>
                    </LinkContainer>
                  </React.Fragment>
                )}
                <Dropdown.Divider />
                <LinkContainer to='/logout'>
                  <Dropdown.Item onClick={handleLogout}>
                    Logout <span className='text-muted'>({user.name})</span>
                  </Dropdown.Item>
                </LinkContainer>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      )}
    </Navbar>
  )
}

MainNavigation.propTypes = {
  user: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
}
