// import memoize from 'lru-memoize';
import { createValidator, required, email } from 'helpers'

/**
 * Applied rules to all possible form fields.
 *
 * @type {Object}   Field names and applieds rule
 */

export default createValidator({
  name: [required],
  email: [required, email],
  address1: [required],
  address2: [],
  city: [required],
  zipCode: [required],
  state: [required],
  phone: [required],
  mobile: [],
})

//TODO: Revisit the utility of this lru-memoize functionality
// export default memoize(10)(contactInfoFormValidation);
