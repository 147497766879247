const GET_REPORTS = 'aker/AERIAL_IMAGERY_REPORTS/GET_REPORTS'
const GET_REPORTS_SUCCESS = 'aker/AERIAL_IMAGERY_REPORTS/GET_REPORTS_SUCCESS'
const GET_REPORTS_FAIL = 'aker/AERIAL_IMAGERY_REPORTS/GET_REPORTS_FAIL'

const GET_REPORTS_BY_CUSTOMER_ACCOUNT =
  'aker/AERIAL_IMAGERY_REPORTS/GET_REPORTS_BY_CUSTOMER_ACCOUNT'
const GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS =
  'aker/AERIAL_IMAGERY_REPORTS/GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS'
const GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL =
  'aker/AERIAL_IMAGERY_REPORTS/GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL'

const GET_REPORTS_BY_SEASON = 'aker/AERIAL_IMAGERY_REPORTSGET_REPORTS_BY_SEASON'
const GET_REPORTS_BY_SEASON_SUCCESS = 'aker/AERIAL_IMAGERY_REPORTSGET_REPORTS_BY_SEASON_SUCCESS'
const GET_REPORTS_BY_SEASON_FAIL = 'aker/AERIAL_IMAGERY_REPORTSGET_REPORTS_BY_SEASON_FAIL'

const GET_REPORT = 'aker/AERIAL_IMAGERY_REPORTS/GET_REPORT'
const GET_REPORT_SUCCESS = 'aker/AERIAL_IMAGERY_REPORTS/GET_REPORT_SUCCESS'
const GET_REPORT_FAIL = 'aker/AERIAL_IMAGERY_REPORTS/GET_REPORT_FAIL'

const CREATE_REPORT = 'aker/AERIAL_IMAGERY_REPORTS/CREATE_REPORT'
const CREATE_REPORT_SUCCESS = 'aker/AERIAL_IMAGERY_REPORTS/CREATE_REPORT_SUCCESS'
const CREATE_REPORT_FAIL = 'aker/AERIAL_IMAGERY_REPORTS/CREATE_REPORT_FAIL'

const UPDATE_REPORT = 'aker/AERIAL_IMAGERY_REPORTS/UPDATE_REPORT'
const UPDATE_REPORT_SUCCESS = 'aker/AERIAL_IMAGERY_REPORTS/UPDATE_REPORT_SUCCESS'
const UPDATE_REPORT_FAIL = 'aker/AERIAL_IMAGERY_REPORTS/UPDATE_REPORT_FAIL'

const DELETE_REPORT = 'aker/AERIAL_IMAGERY_REPORTS/DELETE_REPORT'
const DELETE_REPORT_SUCCESS = 'aker/AERIAL_IMAGERY_REPORTS/DELETE_REPORT_SUCCESS'
const DELETE_REPORT_FAIL = 'aker/AERIAL_IMAGERY_REPORTS/DELETE_REPORT_FAIL'

const initialState = {
  reports: {
    loaded: false,
  },
  reportsByCustomerAccount: {
    loaded: false,
  },
  reportsBySeason: {
    loaded: false,
  },
  report: {
    loaded: false,
  },
}

export default function aerialImageryReports(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORTS_FAIL:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_REPORTS_BY_CUSTOMER_ACCOUNT:
      return {
        ...state,
        reportsByCustomerAccount: {
          ...state.reportsByCustomerAccount,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        reportsByCustomerAccount: {
          ...state.reportsByCustomerAccount,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL:
      return {
        ...state,
        reportsByCustomerAccount: {
          ...state.reportsByCustomerAccount,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_REPORTS_BY_SEASON:
      return {
        ...state,
        reportsBySeason: {
          ...state.reportsBySeason,
          loading: true,
          loaded: false,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORTS_BY_SEASON_SUCCESS:
      return {
        ...state,
        reportsBySeason: {
          ...state.reportsBySeason,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORTS_BY_SEASON_FAIL:
      return {
        ...state,
        reportsBySeason: {
          ...state.reportsBySeason,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case GET_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          loading: true,
          error: undefined, // Clear errors
        },
      }
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
          loaded: true,
          data: action.result.data,
        },
      }
    case GET_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
          loaded: false,
          error: action.error.errors,
        },
      }
    case CREATE_REPORT:
      return {
        ...state,
        loading: true,
      }
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        report: action.result.data,
        errors: [],
      }
    case CREATE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }
    case UPDATE_REPORT:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        report: action.result.data,
        errors: [],
      }
    case UPDATE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.error.errors,
      }
    case DELETE_REPORT:
      return {
        ...state,
        report: {
          loading: true,
        },
      }
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          loading: false,
          loaded: true,
          errors: [],
          data: null,
        },
      }
    case DELETE_REPORT_FAIL:
      return {
        ...state,
        report: {
          loading: false,
          loaded: false,
          errors: action.error.errors,
        },
      }

    default:
      return state
  }
}

export function getReports() {
  return {
    types: [GET_REPORTS, GET_REPORTS_SUCCESS, GET_REPORTS_FAIL],
    promise: client => client.get(`/aerial_imagery_reports`),
  }
}

export function getReportsByCustomerAccount(customerAccountId) {
  let promise
  if (!!customerAccountId) {
    promise = client => client.get(`/customer_accounts/${customerAccountId}/reports_by_season`)
  } else {
    promise = client => client.get(`/aerial_imagery_reports/by_season`)
  }

  return {
    types: [
      GET_REPORTS_BY_CUSTOMER_ACCOUNT,
      GET_REPORTS_BY_CUSTOMER_ACCOUNT_SUCCESS,
      GET_REPORTS_BY_CUSTOMER_ACCOUNT_FAIL,
    ],
    promise,
  }
}

export function getReportsBySeason(seasonId) {
  return {
    types: [GET_REPORTS_BY_SEASON, GET_REPORTS_BY_SEASON_SUCCESS, GET_REPORTS_BY_SEASON_FAIL],
    promise: client => client.get(`/seasons/${seasonId}/reports`),
  }
}

export function getReport(reportId) {
  return {
    types: [GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAIL],
    promise: client => client.get(`/aerial_imagery_reports/${reportId}`),
  }
}

export function createReport(params) {
  return {
    types: [CREATE_REPORT, CREATE_REPORT_SUCCESS, CREATE_REPORT_FAIL],
    promise: client =>
      client.post('/aerial_imagery_reports', {
        data: {
          data: {
            ...params,
          },
        },
      }),
  }
}

export function updateReport(reportId, params) {
  return {
    types: [UPDATE_REPORT, UPDATE_REPORT_SUCCESS, UPDATE_REPORT_FAIL],
    promise: client =>
      client.put(`/aerial_imagery_reports/${reportId}`, {
        data: {
          data: {
            ...params,
          },
        },
      }),
  }
}

export function deleteReport(reportId) {
  return {
    types: [DELETE_REPORT, DELETE_REPORT_SUCCESS, DELETE_REPORT_FAIL],
    promise: client => client.del(`/aerial_imagery_reports/${reportId}`),
  }
}
