import React from 'react'
import PropTypes from 'prop-types'
import styles from './ImageReviewer.module.scss'
import { Toggle } from '_shared'

export const FILTER_OPTIONS = [
  { id: 'unreviewed', label: 'Unreviewed' },
  { id: 'reviewed', label: 'Reviewed' },
  { id: 'healthy', label: 'Healthy' },
  { id: 'unhealthy', label: 'Unhealthy' },
  { id: 'favorited', label: 'Favorited' },
  { id: 'rereviewed', label: 'Rereviewed' },
  { id: 'unrereviewed', label: 'Non-Rereviewed' },
  { id: 'deleted', label: 'Deleted' },
]

export const DEFAULT_STATUS_QUERY = FILTER_OPTIONS[0].id

export function ImageReviewerFilter({ selectedToggleItem, setSelectedToggleItem }) {
  const handleToggle = id => {
    const selectedOption = FILTER_OPTIONS.find(option => option.id === id)
    setSelectedToggleItem(selectedOption || null)
  }

  return (
    <Toggle
      items={FILTER_OPTIONS}
      updateSelectedItem={handleToggle}
      selectedItem={selectedToggleItem}
      offItemSelected={!selectedToggleItem}
      offLabel={'All'}
      className={styles.reviewToggle}
    />
  )
}

ImageReviewerFilter.propTypes = {
  selectedToggleItem: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }),
  setSelectedToggleItem: PropTypes.func.isRequired,
}
