import React from 'react'
import PropTypes from 'prop-types'
import styles from '../AerialImageryReport.module.scss'

export function ObservationDetail({ observation, pins }) {
  const pin = pins.find(p => p.lat === observation.lat && p.lng === observation.lng)

  return (
    <div className={styles.observationDetail}>
      <div>
        <div className='pinWrapper'>
          <div className='pinLabel'>{pin.pinNumber}</div>
          <div className='pin '></div>
        </div>
        <img
          src={observation.thumbnailPath}
          alt={`${observation.cropStressItem.name} found at [${observation.lat}, ${observation.lng}]`}
        />
      </div>
      <div>
        <strong>{observation.cropStressItem.name}</strong>
      </div>
      <p>{observation.notes}</p>
    </div>
  )
}

ObservationDetail.propTypes = {
  observation: PropTypes.shape({
    notes: PropTypes.string,
    thumbnailPath: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    cropStressItem: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
}
