import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ProgressBar, Alert } from 'react-bootstrap'
import { injectIntl } from 'react-intl'
import styles from './PledgeStatus.module.scss'

/**
 * PledgeStatus
 * Retuns a component showing the title and current pledge status
 * of a product
 */

// This is optional but highly recommended
// since it prevents memory leak
// TODO: Determine whether the intlShape in the old app was being used.
// // Replaces intlShape: https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#creating-intl-without-using-provider
// const cache = createIntlCache()

// const intl = createIntl(
//   {
//     locale: 'en',
//     messages: {},
//   },
//   cache
// )

const component = class PledgeStatus extends Component {
  static propTypes = {
    title: PropTypes.string,
    total: PropTypes.number.isRequired,
    used: PropTypes.number.isRequired,
    errors: PropTypes.array,
    isInMenu: PropTypes.bool,
  }

  render() {
    const { title, total, used, errors, isInMenu } = this.props

    const difference = Math.abs(total - used)

    let subtitle
    let completedLabel
    let incompleteLabel
    const totalFormatted = this.props.intl.formatNumber(total)
    const usedFormatted = this.props.intl.formatNumber(used)
    const differenceFormatted = this.props.intl.formatNumber(difference)

    subtitle =
      total === 0
        ? `No work orders requested`
        : `${totalFormatted} work order${total > 1 ? 's' : ''} created`
    completedLabel = `${usedFormatted} completed`
    incompleteLabel = `${differenceFormatted} ${isInMenu ? 'left' : 'incomplete'}`

    return (
      <div className={styles.PledgeStatus}>
        {errors && errors.length !== 0 && (
          <Alert variant='danger'>
            <h2 className={styles.PledgeErrorHeading}>Couldn't load Service Status</h2>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error.message}</li>
              ))}
            </ul>
          </Alert>
        )}

        {(!errors || errors.length === 0) && (
          <div>
            {title && <p className={styles.PledgeDetailsPackageName}>{title}</p>}

            {subtitle && <p className={styles.PledgeDetailsTotalPledge}>{subtitle}</p>}
            <ProgressBar className={styles.ProgressBar}>
              <ProgressBar min={0} max={total} now={used} variant='info' />
            </ProgressBar>

            <div className={styles.PledgeDetails}>
              <span className={styles.PledgeDetailsUsed}>{completedLabel}</span>
              {incompleteLabel && (
                <span className={styles.PledgeDetailsLeft}>{incompleteLabel}</span>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default injectIntl(component)
