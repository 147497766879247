import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { LeafletMap } from 'domains'

/**
 * FieldsListMap
 * Shows a map displaying all of the fields from redux state.
 */
const connectWrapper = connect((state, ownProps) => ({
  fields: state.fields.fields.data,
  ownProps,
}))

class FieldsListMapComponent extends Component {
  render() {
    const fieldsWithBounds =
      this.props.fields &&
      this.props.fields.filter(field => field.bounds && field.bounds.length === 2)

    const fields = fieldsWithBounds.map(f => ({
      onClick: () => {
        browserHistory.push(`/farm/${f.farm.id}/field/${f.id}`)
      },
      ...f,
    }))

    return <LeafletMap boundariesForPinMarkers={fields} hideTransparencySlider />
  }
}

export default connectWrapper(FieldsListMapComponent)

FieldsListMapComponent.propTypes = {
  fields: PropTypes.array.isRequired,
  pushState: PropTypes.func.isRequired,
}
