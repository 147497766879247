/* eslint array-callback-return: 0*/
import React, { Component } from 'react'
import styles from './DashboardHeader.module.scss'

/**
 * DashboardHeader
 * Header with large background image, Aker slogan and the status of your fields.
 */
export default class DashboardHeader extends Component {
  render() {
    return (
      <div className={styles.DashboardHeader}>
        <div className={styles.colLeft}>
          <div className={styles.innerColLeft}>
            <h1 className={styles.appSlogan}>Keeping every plant healthy</h1>
          </div>
        </div>
      </div>
    )
  }
}
