import React, { useState, useEffect, createContext } from 'react'
import ApiClient from 'helpers/ApiClient'

export const CropsContext = createContext({
  crops: [],
})

export default function CropsProvider({ children }) {
  const client = new ApiClient()
  const [crops, setCrops] = useState([])

  const refreshCrops = () => {
    client.get('/crops').then(response => {
      setCrops(response.data || [])
    })
  }

  useEffect(() => {
    refreshCrops()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CropsContext.Provider
      value={{
        crops: crops,
      }}
    >
      {children}
    </CropsContext.Provider>
  )
}
